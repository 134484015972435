import { District } from '../../api/District'
import { normalizeDistrictForUI, normalizeDistrictForGQL, GQLDistrict } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import gql from 'graphql-tag'
import mapError from './mapError'
import createDistrict, { CreateDistrictParams } from '../../api/commands/createDistrict'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const CREATE_DISTRICT = gql`
    mutation createDistrict($input: V2_CreateDistrictInput!) {
        createDistrict(input: $input) {
            id
            districtId
            name
            active
            visible
            treeCounter
            sisId
            ncesId
            address {
                address
                googlePlaceId
            }
            generalDisclaimer
            startAt
            createdAt
            createdBy
            updatedAt
            updatedBy
        }
    }
`

type GQLResult = {
    createDistrict: GQLDistrict
}

export default function factory({ apolloClient }: Deps): createDistrict {
    return async (input: CreateDistrictParams): Promise<District> => {

        try {

            const result = await apolloClient.mutate<GQLResult>({
                mutation: CREATE_DISTRICT,
                variables: {
                    input: normalizeDistrictForGQL(input as District),
                }
            })

            return normalizeDistrictForUI(result.data!.createDistrict)

        } catch (error) {

            console.log(error)

            throw mapError(error)
        }
    }
}

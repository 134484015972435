import downloadFTPCredentials from '../../api/queries/downloadFTPCredentials'
import { Config } from '../../config'

type Deps = {
    config: Config,
}

export default function factory({ config }: Deps): downloadFTPCredentials {
    return ({ hierarchyId, hierarchyType }) => {
        const downloadUrl = `https://portal-bff.${config.DOMAIN}/downloads/sis/${hierarchyType}/${hierarchyId}/credentials`
        window.open(downloadUrl, '_blank')
    }
}

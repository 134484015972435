import React, { FunctionComponent, useEffect } from 'react'
import SchoolForm from './SchoolForm'
import QueryStringNotification from '@peachjar/ui/dist/lib/components/Notifications/QueryStringNotification'

import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import { SwitchError } from '@peachjar/ui/dist/lib/components/Errors'
import { useDispatch } from 'react-redux'
import { createSchool, clearReq } from '../../../redux/schools'
import { createSchoolReq } from '../../../redux/schoolsSelectors'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { School } from '../../../api/School'
import { useHistory } from 'react-router-dom'
import { createSchoolParams } from '../../../redux/schoolsSelectors'
import { setCreateParams as setSchoolCreateParams } from '../../../redux/schools'

import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'

export type CreateSchoolPageBodyProps = {
    isIndependent: boolean,
    districtId?: number,
    onCreateSuccess?: (school: School) => void,
    cancelRedirectUri?: string,
}

const CreateSchoolPageBody: FunctionComponent<CreateSchoolPageBodyProps> = ({
    isIndependent = true,
    districtId = -1,
    onCreateSuccess = () => true,
    cancelRedirectUri,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const { close: closeNotification } = useGlobalNotifications()
    const createParams = createSchoolParams() as School

    const clearInitialParams = () => dispatch(setSchoolCreateParams(null))

    const req = createSchoolReq()

    // If the req is successful, redirect to the update page.
    useEffect(() => {
        if (req.status === Statuses.loading) {
            closeNotification()
        }
        if (req.status === Statuses.loaded) {
            dispatch(clearReq())
            onCreateSuccess(req.model!)
        }
    }, [req])

    return (
        <Sections>
            <Section>
                <QueryStringNotification />
                <SwitchError error={req.error} />
                <SchoolForm
                    school={createParams}
                    isEdit={false}
                    errors={{ sisId: { message: '', lastNonUniqueVal: null } }}
                    isIndependent={isIndependent}
                    isSubmitting={req.status === Statuses.loading}
                    onSubmit={params => {
                        clearInitialParams()
                        dispatch(createSchool({
                            params: {
                                name: params.name!,
                                active: params.active!,
                                invisible: !params.invisible!,
                                sisId: params.name!,
                                ncesId: params.ncesId!,
                                address: params.address!,
                                enrollmentCount: params.enrollmentCount!,
                                lowGrade: params.lowGrade!,
                                highGrade: params.highGrade!,
                                isIndependent,
                                generalDisclaimer: params.generalDisclaimer || null,
                                treeCounter: params.treeCounter,
                                startAt: params.startAt || null,
                            },
                            options: {
                                associateToDistrict: isIndependent ? undefined : districtId,
                            }
                        }))
                    }}
                    onCancel={() => {
                        clearInitialParams()
                        if (cancelRedirectUri) {
                            history.push(cancelRedirectUri)
                        } else {
                            history.goBack()
                        }
                    }}
                />
            </Section>
        </Sections>
    )
}

export default CreateSchoolPageBody

import CodedError, { UIError } from '../../api/CodedError'
import { GraphQLError } from 'graphql'
import { get, has, isArray } from 'lodash'

function normalizeError(errs: any): GraphQLError | null {
    let graphqlErrors: GraphQLError[] = []
    if (has(errs, 'graphQLErrors')) {
        graphqlErrors = get(errs, 'graphQLErrors', [])
    } else {
        graphqlErrors = isArray(errs) ? errs : [errs]
    }
    if (graphqlErrors.length > 0) {
        return graphqlErrors[0]
    }
    return null
}

export default function mapError(errs: GraphQLError[]): CodedError {
    const err = normalizeError(errs)
    if (!err) {
        console.error('UNKNOWN ERROR:', err)
        return new UIError('internal.bad_implementation', get(errs, 'message', 'Unknown'))
    }
    let code =  get(err, 'extensions.code', 'internal.bad_implementation')
    if (code === 'BAD_USER_INPUT') {
        code = 'request.invalid.unprocessable.payload'
    }
    const message =  get(err, 'message', 'Internal Error')
    return new UIError(code, message)
}

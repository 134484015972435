import React, { FunctionComponent, useEffect } from 'react'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'
import BaseIndependentSchoolPage from './components/BaseIndependentSchoolPage'
import CommunityPageBody from '../Community/CommunityPageBody'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { getSchool } from '../../redux/schools'
import { RootState } from '../../redux'
import { School } from '../../api/School'

const IndependentSchoolCommunityPage: FunctionComponent<{}> = () => {

    const panic = usePanic()

    const { schoolId: schoolIdParam } = useParams<{ schoolId: string }>()
    const schoolId = parseInt(schoolIdParam, 10)

    const req = useSelector<RootState, AsyncReqState<School>>(state => state.schools.school)

    const dispatch = useDispatch()

    useEffect(() => { dispatch(getSchool(schoolId)) }, [])

    if (req.isLoading()) {
        return <LoadingSpinner />
    }

    // Could not load the school
    if (req.error) {
        return panic(req.error)
    }

    const school = req.model!

    return (
        <BaseIndependentSchoolPage
            title='Community Settings'
            school={school}
            tabIndex={3}
        >
            <Sections>
                <Section>
                    <CommunityPageBody
                        hierarchyName={school.name}
                        hierarchyType={'school'}
                        hierarchyId={schoolId}
                    />
                </Section>
            </Sections>
        </BaseIndependentSchoolPage>
    )
}

export default IndependentSchoolCommunityPage

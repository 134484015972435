import React, { FunctionComponent, useMemo, memo } from 'react'
import AutocompleteFilter from '@peachjar/ui/dist/lib/components/Filters/AutocompleteFilter'
import { SiteInfo } from '../../../api/SiteInfo'
import { NoFilters } from './UsersListFilters'
import { sortBy } from 'lodash'
import { useSelector } from 'react-redux'

export const AllSites: SiteInfo = Object.freeze({
    id: NoFilters.site,
    name: 'All',
    type: 'school'
})

export type CustomizationProps = {
  description?: string
  disabled?: boolean
}

type Props = {
    key?: string,
    value?: number,
    sites: SiteInfo[]
    onSiteSelected: (site: SiteInfo) => void,
}
export const selectDistrictId = (state: { districts: { district: { model: { districtId: any } } } }) => state.districts?.district?.model?.districtId
const SiteSelectionAutocomplete: FunctionComponent<Props> = ({
    key = 'site_selection',
    value,
    sites,
    onSiteSelected,
}) => {
    const districtId = useSelector(selectDistrictId)
    const choices = useMemo(() => {
        const mapped = sites.map(s => ({
            description: s.name,
            ...s,
        }))
        return sortBy(mapped, m => m.description)
    }, [sites])

    const siteSelection = choices.find(s => s.id === value) || null

    return (
      // @ts-ignore
        <AutocompleteFilter<SiteInfo & CustomizationProps>
            // This is unfortunately needed because the input text will not
            // clear when value changes.
            key={key}
            label='Sites'
            variant='outlined'
            promotedOptionId={districtId}
            smallDescriptiveText={'Schools'}
            choices={choices}
            value={siteSelection}
            isEqual={(site1, site2) => site1?.type === site2?.type && site1?.id === site2?.id}
            onChange={(item) => onSiteSelected(item ? item : AllSites)}
        />
    )
}

export default memo(SiteSelectionAutocomplete)

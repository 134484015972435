import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Section } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { ButtonSecondaryLarge, ButtonPrimaryLarge, ButtonFlatLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import { uploadSisCSV, saveCsvMapping } from '../../../redux/parentSettings'
import { uploadSisCSVReq } from '../../../redux/parentSettingsSelectors'
import { useDispatch } from 'react-redux'
import { GradeLevelMap } from '../../../api/CsvMapping'
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import Routes from '../../../routes'
import { useHistory } from 'react-router-dom'
import { css } from 'emotion'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'
import { ClearableSelect } from './ClearableSelect'

const gradesMappedLocal = {}

type Props = {
    hierarchyType: string,
    hierarchyId: number,
    isMapper: boolean,
    forceContinue: boolean,
    onNeedsRefreshing: () => void,
    isStaff?: boolean,
    csvGrades: Array<string>,
    page: number,
    setPage: any,
    jobId: string,
    gradesMapped: boolean,
    setGradesMapped: any,
    school_sis_id: string,
    setSchool_sis_id: any,
    pkMap: Array<string>,
    setPkMap: any,
    kgMap: Array<string>,
    setKgMap: any,
    grade1Map: Array<string>,
    setGrade1Map: any,
    grade2Map: Array<string>,
    setGrade2Map: any,
    grade3Map: Array<string>,
    setGrade3Map: any,
    grade4Map: Array<string>,
    setGrade4Map: any,
    grade5Map: Array<string>,
    setGrade5Map: any,
    grade6Map: Array<string>,
    setGrade6Map: any,
    grade7Map: Array<string>,
    setGrade7Map: any,
    grade8Map: Array<string>,
    setGrade8Map: any,
    grade9Map: Array<string>,
    setGrade9Map: any,
    grade10Map: Array<string>,
    setGrade10Map: any,
    grade11Map: Array<string>,
    setGrade11Map: any,
    grade12Map: Array<string>,
    setGrade12Map: any,
    gradeCEMap: Array<string>,
    setGradeCEMap: any,
    gradeLevelList: Array<any>,
    acceptedFiles: Array<any>,
    header: boolean
}

const MapGrades: FunctionComponent<Props> = ({
    hierarchyId,
    hierarchyType,
    isMapper,
    forceContinue,
    isStaff = false,
    csvGrades,
    page,
    setPage,
    jobId,
    gradesMapped,
    setGradesMapped,
    pkMap,
    setPkMap,
    kgMap,
    setKgMap,
    grade1Map,
    setGrade1Map,
    grade2Map,
    setGrade2Map,
    grade3Map,
    setGrade3Map,
    grade4Map,
    setGrade4Map,
    grade5Map,
    setGrade5Map,
    grade6Map,
    setGrade6Map,
    grade7Map,
    setGrade7Map,
    grade8Map,
    setGrade8Map,
    grade9Map,
    setGrade9Map,
    grade10Map,
    setGrade10Map,
    grade11Map,
    setGrade11Map,
    grade12Map,
    setGrade12Map,
    gradeCEMap,
    setGradeCEMap,
    gradeLevelList,
    acceptedFiles,
    header
}) => {

    // initialize state and global values
    const dispatch = useDispatch()
    const history = useHistory()
    const [gradeDiff, setGradeDiff] = useState(0)
    const [extraNum, setExtraNum] = useState(0)
    const [open, setOpen] = useState(false)
    const [mapExtra, setMapExtra] = useState(false)
    const [showUnmapped, setShowUnmapped] = useState(false)
    const [disableContinue, setDisableContinue] = useState(true)
    let sisIdMap = {}
    const [extraPeachjar, setExtraPeachjar] = useState({})
    const [extraCsv, setExtraCsv] = useState({})
    const [errorPk, setErrorPk] = useState(false)
    const [errorKg, setErrorKg] = useState(false)
    const [error1, setError1] = useState(false)
    const [error2, setError2] = useState(false)
    const [error3, setError3] = useState(false)
    const [error4, setError4] = useState(false)
    const [error5, setError5] = useState(false)
    const [error6, setError6] = useState(false)
    const [error7, setError7] = useState(false)
    const [error8, setError8] = useState(false)
    const [error9, setError9] = useState(false)
    const [error10, setError10] = useState(false)
    const [error11, setError11] = useState(false)
    const [error12, setError12] = useState(false)
    const [errorCE, setErrorCE] = useState(false)
    const isFirstRender = useRef(true);

    // assign values from selections to grade mapping
    let gradeLevelMap: GradeLevelMap = 
        {
            prekindergarten: pkMap.length == 0 ? undefined : pkMap,
            kindergarten: pkMap.length == 0 ? undefined : kgMap,
            grade1: grade1Map.length == 0 ? undefined : grade1Map,
            grade2: grade2Map.length == 0 ? undefined : grade2Map,
            grade3: grade3Map.length == 0 ? undefined : grade3Map,
            grade4: grade4Map.length == 0 ? undefined : grade4Map,
            grade5: grade5Map.length == 0 ? undefined : grade5Map,
            grade6: grade6Map.length == 0 ? undefined : grade6Map,
            grade7: grade7Map.length == 0 ? undefined : grade7Map,
            grade8: grade8Map.length == 0 ? undefined : grade8Map,
            grade9: grade9Map.length == 0 ? undefined : grade9Map,
            grade10: grade10Map.length == 0 ? undefined : grade10Map,
            grade11: grade10Map.length == 0 ? undefined : grade11Map,
            grade12: grade12Map.length == 0 ? undefined : grade12Map,
            continueEducation: gradeCEMap.length == 0 ? undefined : gradeCEMap,
        }
    
        // set styles
        const styles = {
            districtSettings: css`
                color: #3a833c;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                    text-decoration: underline #3a833c !important;
                }`,
        }

        // error function for red highlight
        function setErrors() {
            if(pkMap.length === 0)
                setErrorPk(true)
            else
                setErrorPk(false)
            if(kgMap.length === 0)
                setErrorKg(true)
            else
                setErrorKg(false)
            if(grade1Map.length === 0)
                setError1(true)
            else
                setError1(false)
            if(grade2Map.length === 0)
                setError2(true)
            else
                setError2(false)
            if(grade3Map.length === 0)
                setError3(true)
            else
                setError3(false)
            if(grade4Map.length === 0)
                setError4(true)
            else
                setError4(false)
            if(grade5Map.length === 0)
                setError5(true)
            else
                setError5(false)
            if(grade6Map.length === 0)
                setError6(true)
            else
                setError6(false)
            if(grade7Map.length === 0)
                setError7(true)
            else
                setError7(false)
            if(grade8Map.length === 0)
                setError8(true)
            else
                setError8(false)
            if(grade9Map.length === 0)
                setError9(true)
            else
                setError9(false)
            if(grade10Map.length === 0)
                setError10(true)
            else
                setError10(false)
            if(grade11Map.length === 0)
                setError11(true)
            else
                setError11(false)
            if(grade12Map.length === 0)
                setError12(true)
            else
                setError12(false)
            if(gradeCEMap.length === 0)
                setErrorCE(true)
            else
                setErrorCE(false)
        }

    // function to create multiple grade mappings on continue
    async function handleContinue() {
        // initialize if update is needed
        let updatePK = false
        let updateKG = false
        let update1 = false
        let update2 = false
        let update3 = false
        let update4 = false
        let update5 = false
        let update6 = false
        let update7 = false
        let update8 = false
        let update9 = false
        let update10 = false
        let update11 = false
        let update12 = false
        let updateCE = false
        let extraPK: string[] = []
        let extraKG: string[] = []
        let extra1: string[] = []
        let extra2: string[] = []
        let extra3: string[] = []
        let extra4: string[] = []
        let extra5: string[] = []
        let extra6: string[] = []
        let extra7: string[] = []
        let extra8: string[] = []
        let extra9: string[] = []
        let extra10: string[] = []
        let extra11: string[] = []
        let extra12: string[] = []
        let extraCE: string[] = []

        // check for changes in temp arrays to update update values
        for (let x in Object.keys(extraPeachjar)) {
            if(extraPeachjar[x] !== undefined && extraCsv[x] !== undefined) {
                if (extraPeachjar[x] === 'PK') {
                    extraPK.push(extraCsv[x])
                    updatePK = true
                }
                if (extraPeachjar[x] === 'KG') {
                    extraKG.push(extraCsv[x])
                    updateKG = true
                }
                if (extraPeachjar[x] === '1') {
                    extra1.push(extraCsv[x])
                    update1 = true
                }
                if (extraPeachjar[x] === '2') {
                    extra2.push(extraCsv[x])
                    update2 = true
                }
                if (extraPeachjar[x] === '3') {
                    extra3.push(extraCsv[x])
                    update3 = true
                }
                if (extraPeachjar[x] === '4') {
                    extra4.push(extraCsv[x])
                    update4 = true
                }
                if (extraPeachjar[x] === '5') {
                    extra5.push(extraCsv[x])
                    update5 = true
                }
                if (extraPeachjar[x] === '6') {
                    extra6.push(extraCsv[x])
                    update6 = true
                }
                if (extraPeachjar[x] === '7') {
                    extra7.push(extraCsv[x])
                    update7 = true
                }
                if (extraPeachjar[x] === '8') {
                    extra8.push(extraCsv[x])
                    update8 = true
                }
                if (extraPeachjar[x] === '9') {
                    extra9.push(extraCsv[x])
                    update9 = true
                }
                if (extraPeachjar[x] === '10') {
                    extra10.push(extraCsv[x])
                    update10 = true
                }
                if (extraPeachjar[x] === '11') {
                    extra11.push(extraCsv[x])
                    update11 = true
                }
                if (extraPeachjar[x] === '12') {
                    extra12.push(extraCsv[x])
                    update12 = true
                }
                if (extraPeachjar[x] === 'CE') {
                    extraCE.push(extraCsv[x])
                    updateCE = true
                }
            }
        }

        // if update is needed, combine arrays and set state for rerender
        if (updatePK)
            setPkMap([...pkMap, ...extraPK])
        if (updateKG)
            setKgMap([...kgMap, ...extraKG])
        if (update1)
            setGrade1Map([...grade1Map, ...extra1])
        if (update2)
            setGrade2Map([...grade2Map, ...extra2])
        if (update3)
            setGrade3Map([...grade3Map, ...extra3])
        if (update4)
            setGrade4Map([...grade4Map, ...extra4])
        if (update5)
            setGrade5Map([...grade5Map, ...extra5])
        if (update6)
            setGrade6Map([...grade6Map, ...extra6])
        if (update7)
            setGrade7Map([...grade7Map, ...extra7])
        if (update8)
            setGrade8Map([...grade8Map, ...extra8])
        if (update9)
            setGrade9Map([...grade9Map, ...extra9])
        if (update10)
            setGrade10Map([...grade10Map, ...extra10])
        if (update11)
            setGrade11Map([...grade11Map, ...extra11])
        if (update12)
            setGrade12Map([...grade12Map, ...extra12])
        if (updateCE)
            setGradeCEMap([...gradeCEMap, ...extraCE])
        
        // set grades mapped to true to allow next page
        setGradesMapped(true)
    }

    // function to open modal and calculate missing grade number
    function handleModal() {
        let gradeNumber = 0
        let gradeArray = Object.values(gradeLevelMap)
        let tempArray = Array.from(new Set(gradeArray.flat()))
        for (const index in tempArray)
            if (tempArray[index] !== "" && tempArray[index] !== undefined) {
                gradeNumber++
            }

        let extraCount = 0
        for (let i = 0; i < Object.keys(extraPeachjar).length; i++) {
            if(extraCsv[i] !== undefined && extraPeachjar[i] !== undefined) {
                extraCount++;
            }
            setExtraNum(extraCount)
        }
        if((gradeNumber + Object.values(extraCsv).length) < csvGrades.length) {
            setGradeDiff(csvGrades.length - gradeNumber)
            setOpen(true)
        }
        else
            handleContinue()
    }

    // calculate missing grades
    function calcGradeDiff() {
        let gradeNumber = 0
        let gradeArray = Object.values(gradeLevelMap)
        let tempArray = Array.from(new Set(gradeArray.flat()))
        for (const index in tempArray)
            if (tempArray[index] !== "" && tempArray[index] !== undefined) {
                gradeNumber++
            }
        if(gradeNumber < csvGrades.length) {
            setGradeDiff(csvGrades.length - gradeNumber)
        }
    }

    // onChange function for PK for set and clear
    function changePk(event) {
        const value = event as string
        if(value !== '') {
            setPkMap([value])
            gradesMappedLocal["PK"] = value
        }
        else {
            setPkMap([])
            delete gradesMappedLocal["PK"]
        }
    }

    // onChange function for KG for set and clear
    function changeKg(event) {
        const value = event as string
        if(value !== '') {
            setKgMap([value])
            gradesMappedLocal["KG"] = value
        }
        else {
            setKgMap([])
            delete gradesMappedLocal["KG"]
        }
    }

    // onChange function for grade1 for set and clear
    function change1(event) {
        const value = event as string
        if(value !== '') {
            setGrade1Map([value])
            gradesMappedLocal["grade1"] = value
        }
        else {
            setGrade1Map([])
            delete gradesMappedLocal["grade1"]
        }
    }

    // onChange function for grade2 for set and clear
    function change2(event) {
        const value = event as string
        if(value !== '') {
            setGrade2Map([value])
            gradesMappedLocal["grade2"] = value
        }
        else {
            setGrade2Map([])
            delete gradesMappedLocal["grade2"]
        }
    }

    // onChange function for grade3 for set and clear
    function change3(event) {
        const value = event as string
        if(value !== '') {
            setGrade3Map([value])
            gradesMappedLocal["grade3"] = value
        }
        else {
            setGrade3Map([])
            delete gradesMappedLocal["grade3"]
        }
    }

    // onChange function for grade4 for set and clear
    function change4(event) {
        const value = event as string
        if(value !== '') {
            setGrade4Map([value])
            gradesMappedLocal["grade4"] = value
        }
        else {
            setGrade4Map([])
            delete gradesMappedLocal["grade4"]
        }
    }

    // onChange function for grade5 for set and clear
    function change5(event) {
        const value = event as string
        if(value !== '') {
            setGrade5Map([value])
            gradesMappedLocal["grade5"] = value
        }
        else {
            setGrade5Map([])
            delete gradesMappedLocal["grade5"]
        }
    }

    // onChange function for grade6 for set and clear
    function change6(event) {
        const value = event as string
        if(value !== '') {
            setGrade6Map([value])
            gradesMappedLocal["grade6"] = value
        }
        else {
            setGrade6Map([])
            delete gradesMappedLocal["grade6"]
        }
    }

    // onChange function for grade7 for set and clear
    function change7(event) {
        const value = event as string
        if(value !== '') {
            setGrade7Map([value])
            gradesMappedLocal["grade7"] = value
        }
        else {
            setGrade7Map([])
            delete gradesMappedLocal["grade7"]
        }
    }

    // onChange function for grade8 for set and clear
    function change8(event) {
        const value = event as string
        if(value !== '') {
            setGrade8Map([value])
            gradesMappedLocal["grade8"] = value
        }
        else {
            setGrade8Map([])
            delete gradesMappedLocal["grade8"]
        }
    }

    // onChange function for grade9 for set and clear
    function change9(event) {
        const value = event as string
        if(value !== '') {
            setGrade9Map([value])
            gradesMappedLocal["grade9"] = value
        }
        else {
            setGrade9Map([])
            delete gradesMappedLocal["grade9"]
        }
    }

    // onChange function for grade10 for set and clear
    function change10(event) {
        const value = event as string
        if(value !== '') {
            setGrade10Map([value])
            gradesMappedLocal["grade10"] = value
        }
        else {
            setGrade10Map([])
            delete gradesMappedLocal["grade10"]
        }
    }

    // onChange function for grade11 for set and clear
    function change11(event) {
        const value = event as string
        if(value !== '') {
            setGrade11Map([value])
            gradesMappedLocal["grade11"] = value
        }
        else {
            setGrade11Map([])
            delete gradesMappedLocal["grade11"]
        }
    }

    // onChange function for grade12 for set and clear
    function change12(event) {
        const value = event as string
        if(value !== '') {
            setGrade12Map([value])
            gradesMappedLocal["grade12"] = value
        }
        else {
            setGrade12Map([])
            delete gradesMappedLocal["grade12"]
        }
    }

    // onChange function for gradeCE for set and clear
    function changeCE(event) {
        const value = event as string
        if(value !== '') {
            setGradeCEMap([value])
            gradesMappedLocal["gradeCE"] = value
        }
        else {
            setGradeCEMap([])
            delete gradesMappedLocal["gradeCE"]
        }
    }

    // function for modal reupload onclick
    function handleReupload() {
        setOpen(true)
        setPage(0)
    }

    // function to remove value from dopdown if already selected
    function displayValue(value) {
        if (pkMap.includes(value) || kgMap.includes(value) || grade1Map.includes(value) || grade2Map.includes(value) || 
            grade3Map.includes(value) || grade4Map.includes(value) || grade5Map.includes(value) || grade6Map.includes(value) || 
            grade7Map.includes(value) || grade8Map.includes(value) || grade9Map.includes(value) || grade10Map.includes(value) || 
            grade11Map.includes(value) || grade12Map.includes(value) || gradeCEMap.includes(value))
            return false
        for (let x in extraCsv)
            if (extraCsv[x] === value) 
                return false
        return true
    }

    // save grade mapping and continue upload
    // do nothing on first render
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const saveAndUpload = async () => {
            await dispatch(saveCsvMapping({
            hierarchyType,
            hierarchyId,
            header,
            sisIdMap,
            gradeLevelMap}))
            
            await dispatch(uploadSisCSV({
            csvFileType: isStaff ? 'staff' : undefined,
            hierarchyType,
            hierarchyId,
            jobId,
            isMapper,
            forceContinue,
            file: acceptedFiles[0],
            }))    

            setPage(page+1)
        }
        saveAndUpload()
    }, [gradesMapped])

    // show warning if there are unmapped grades
    useEffect(() => {
        if (Object.keys(gradesMappedLocal).length === gradeLevelList.length && gradeDiff > 0)
            setShowUnmapped(true)
        else
            setShowUnmapped(false)
    }, [gradeDiff])

    // calculate the number of missing grades when the mapping changes
    useEffect(() => {
        calcGradeDiff()
    }, [gradeLevelMap])

    // disable continue button unless the required grades are mapped
    useEffect(() => {
        if(Object.keys(gradesMappedLocal).length < gradeLevelList.length)
            setDisableContinue(true)
        else
            setDisableContinue(false)
    }, [Object.keys(gradesMappedLocal).length])

    return (
        <Grid container style={{ marginBottom: '24px'}} className="gradePage">
            <Section headline={'Map CSV Grades'}>
                <p>Select grades in your CSV file that match the district grades across the active schools in your Peachjar 
                    account. If you don't see a district grade available for matching, go to your <span className={styles.districtSettings} onClick={() => history.push(Routes.districts.update.from(hierarchyId))}>District &amp; 
                    School Settings</span> to update your district's grades per school. Then return to continue mapping
                    your CSV file.</p>
            <Grid container item xs={6} className="verticalContainer1">
            <Section>
                <Grid container item>
                    <Grid item xs={5}>
                        <p>District Grade</p>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={5}>
                        <p>CSV File Grade</p>
                    </Grid>
                </Grid>
                {gradeLevelList.includes('PK') &&
                    <div className="pkContainer">
                        <Grid container item alignItems='center' className="pkGrid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="PK" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {changePk(event)}}
                                    value={pkMap.length > 0 ? pkMap[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={errorPk}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('KG') &&
                    <div className="kg">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="KG" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {changeKg(event)}}
                                    value={kgMap.length > 0 ? kgMap[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={errorKg}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('1') &&
                    <div className="grade1">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item className="labelContainer" xs={5}>
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="1" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change1(event)}}
                                    value={grade1Map.length > 0 ? grade1Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error1}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('2') &&
                    <div className="grade2">
                        <Grid container item alignItems='center' className="grid">
                            <Grid className="labelContainer" item xs={5}>
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="2" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change2(event)}}
                                    value={grade2Map.length > 0 ? grade2Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error2}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('3') &&
                    <div className="grade3">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="3" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change3(event)}}
                                    value={grade3Map.length > 0 ? grade3Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error3}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('4') &&
                    <div className="grade4">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="4" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change4(event)}}
                                    value={grade4Map.length > 0 ? grade4Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error4}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('5') &&
                    <div className="grade5">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="5" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change5(event)}}
                                    value={grade5Map.length > 0 ? grade5Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error5}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('6') &&
                    <div className="grade6">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="6" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change6(event)}}
                                    value={grade6Map.length > 0 ? grade6Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error6}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('7') &&
                    <div className="grade7">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="7" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change7(event)}}
                                    value={grade7Map.length > 0 ? grade7Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error7}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('8') &&
                    <div className="grade8">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="8" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change8(event)}}
                                    value={grade8Map.length > 0 ? grade8Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error8}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('9') &&
                    <div className="grade9">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="9" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change9(event)}}
                                    value={grade9Map.length > 0 ? grade9Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error9}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('10') &&
                    <div className="grade10">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="10" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change10(event)}}
                                    value={grade10Map.length > 0 ? grade10Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error10}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('11') &&
                    <div className="grade11">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label"type="text" variant="outlined" style={{width: '100%'}}  value="11" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change11(event)}}
                                    value={grade11Map.length > 0 ? grade11Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error11}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('12') &&
                    <div className="grade12">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="12" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {change12(event)}}
                                    value={grade12Map.length > 0 ? grade12Map[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={error12}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
                {gradeLevelList.includes('CE') &&
                    <div className="gradeCE">
                        <Grid container item alignItems='center' className="grid">
                            <Grid item xs={5} className="labelContainer">
                                <TextField className="label" type="text" variant="outlined" style={{width: '100%'}}  value="CE" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5} className="selectContainer">
                                <ClearableSelect
                                    onChange={event => {changeCE(event)}}
                                    value={gradeCEMap.length > 0 ? gradeCEMap[0] : ""}
                                    options={csvGrades}
                                    show={displayValue}
                                    error={errorCE}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                }
            </Section>
            </Grid>
                    {(mapExtra === false && showUnmapped) &&
                    <div>
                        <br/>
                        <InlineNotification 
                            variant='info' 
                            message={<span className="mapExtraText">There are {gradeDiff} unmapped grades in your CSV file. If you need to map more than one grade entry 
                            in your CSV file to a single grade, select Add Mappings.</span>} 
                            action=
                                {<ButtonSecondaryLarge
                                    onClick= {() => {setMapExtra(true); calcGradeDiff()}}
                                    className="mapExtraButton"
                                >
                                Add Mappings
                                </ButtonSecondaryLarge>}
                        />
                    </div>
                    }
                    {mapExtra === true &&
                        <div>
                            <br/>
                            <span>Optionally, map more than one grade entry in your CSV file to a single district grade. 
                                For example, you can map PK1 and PK2 to PK. If you want to ignore a grade entry in your CSV file, 
                                leave it unmapped.
                            </span>
                            <br/><br/><br/>
                            {Array.from(Array(gradeDiff)).map((value, index) => <div className={"div"+index}>
                                <Grid container item xs={6} alignItems='center' className="grid">
                                    <Grid item xs={5} className="selectContainer">
                                        <Select
                                            onChange={event => {(event.target.value as string) !== '' ? setExtraPeachjar({...extraPeachjar, [index]: event.target.value as string}): setExtraPeachjar({...extraPeachjar, [index]: undefined})}}
                                            variant="outlined"
                                            displayEmpty
                                            className="select"
                                            style={{width: '100%',  height: '32px',  fontSize: '14px', fontWeight: 'bold'}}
                                            defaultValue=""
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                        >
                                            <MenuItem value="">Select a district grade</MenuItem>
                                            {gradeLevelList.map((column) => <MenuItem style={{fontSize: '14px'}} className={"option"+gradeLevelList.indexOf(column)} value={column}>{column}</MenuItem>)}
                                        </Select><br/>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5} className="selectContainer">
                                        <ClearableSelect
                                            onChange={event => {(event as string) !== '' ? setExtraCsv({...extraCsv, [index]: event as string}): setExtraCsv({...extraCsv, [index]: undefined})}}
                                            value={extraCsv[index] ? extraCsv[index] : ""}
                                            options={csvGrades}
                                            show={displayValue}
                                        />
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>)}
                        </div>
                    }
            </Section>
            <Section>
                <Grid container
                    justifyContent="flex-end"
                    className="buttonContainer"
                    alignItems="center">
                        <ButtonFlatLarge
                            className="backButton" 
                            onClick={() => {setPage(page-1)}}>
                            Back
                        </ButtonFlatLarge>
                        <div onClick={setErrors}>
                            <ButtonPrimaryLarge disabled={disableContinue} 
                                className="continueButton"
                                onClick={() => handleModal()}>
                                Done
                            </ButtonPrimaryLarge>
                        </div>
                        <Modal
                            title=''
                            open={open}
                            width='sm'
                            close={() => setOpen(false)}>
                                <ModalHeader>Unmapped grades</ModalHeader>
                                <DialogContent style={{ padding: '16px' }}>
                                    <DialogContentText id="modalText">
                                        There are {gradeDiff-extraNum} grade entries in your CSV file that have not been mapped. If you wish to map them, 
                                        please update and upload your file again by selecting Reupload File. Otherwise, 
                                        select ignore to proceed.
                                    </DialogContentText>
                                    <DialogActions className="actions" style={{ padding: '16px 32px' }}>
                                        <ButtonFlatLarge className="reuploadButton" onClick={() => handleReupload()}>Reupload File</ButtonFlatLarge>
                                        <ButtonFlatLarge className="ignoreButton" onClick={() => handleContinue()}>Ignore</ButtonFlatLarge>
                                    </DialogActions>
                                </DialogContent>
                    </Modal>
                </Grid>
            </Section>
        </Grid>
    )
}

export default MapGrades
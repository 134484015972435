import React from 'react'
import { Typography } from '@material-ui/core'
import styles from './AppHeader.styles'

type Props = {
  children: React.ReactNode,
  classNames?: string
}

const AppHeader = ({ children }: Props) => (
  <Typography variant='h4' className={styles.appHeader}>{children}</Typography>
)

export default AppHeader

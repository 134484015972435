import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { maxBy } from 'lodash'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import SwitchError from '@peachjar/ui/dist/lib/components/Errors/SwitchError'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { getSisCsvMapping, getSisJobsReq } from '../../redux/parentSettingsSelectors'
import { getCsvMapping, getSisJobs, resetUpload } from '../../redux/parentSettings'
import ManualUploadMapper from './components/ManualUploadMapper'
import Tabs from '../Tabs/Tabs'
import MapColumns from './components/MapColumns'
import MapGrades from './components/MapGrades'
import MapSummary from './components/MapSummary'
import { getDistrictReq } from '../../redux/districtsSelectors'
import {toOrdinal, createGradeRange, GradeLevels} from '../../api/GradeLevels'


const {
    } = Typo

type Props = {
    hierarchyType: string,
    hierarchyId: number,
}

const ParentsPageBody: FunctionComponent<Props> = ({
    hierarchyId,
    hierarchyType,
}) => {

    // initialize data and state
    const dispatch = useDispatch()
    const panic = usePanic()
    const sisJobsReq = getSisJobsReq()
    const districtReq = getDistrictReq()
    let mapping = getSisCsvMapping()
    const firstLoad = useRef(false)
    const [page, setPage] = useState(0)
    const [jobId, setJobId] = useState("")
    const [csvUploaded, setCsvUploaded] = useState(false)
    const [csvColumns, setCsvColumns] = useState([])
    const [selectColumns, setSelectColumns] = useState([])
    const [columnsMapped, setColumnsMapped] = useState(false)
    const [school_sis_id, setSchool_sis_id] = useState("")
    const [grades, setGrades] = useState("")
    const [emails, setEmails] = useState("")
    const [emails2, setEmails2] = useState("")
    const [emails3, setEmails3] = useState("")
    const [emails4, setEmails4] = useState("")
    const [emails5, setEmails5] = useState("")
    const [sisIndex, setSisIndex] = useState(-1)
    const [gradesIndex, setGradesIndex] = useState(-1)
    const [emailsIndex, setEmailsIndex] = useState(-1)
    const [data, setData] = useState([])
    const [csvGrades, setCsvGrades] = useState([])
    const [acceptedFiles, setAcceptedFiles] = useState([])
    const [pkMap, setPkMap] = useState([])
    const [kgMap, setKgMap] = useState([])
    const [grade1Map, setGrade1Map] = useState([])
    const [grade2Map, setGrade2Map] = useState([])
    const [grade3Map, setGrade3Map] = useState([])
    const [grade4Map, setGrade4Map] = useState([])
    const [grade5Map, setGrade5Map] = useState([])
    const [grade6Map, setGrade6Map] = useState([])
    const [grade7Map, setGrade7Map] = useState([])
    const [grade8Map, setGrade8Map] = useState([])
    const [grade9Map, setGrade9Map] = useState([])
    const [grade10Map, setGrade10Map] = useState([])
    const [grade11Map, setGrade11Map] = useState([])
    const [grade12Map, setGrade12Map] = useState([])
    const [gradeCEMap, setGradeCEMap] = useState([])
    const [gradesMapped, setGradesMapped] = useState(false)
    const [lowGrade, setLowGrade] = useState("")
    const [highGrade, setHighGrade] = useState("")
    const [gradeLevelList, setGradeLevelList] = useState([])
    const [header, setHeader] = useState(true)
    const [tabNumber, setTabNumber] = useState(2)
    const [, setHasMapping] = useState(false)
    const [gradeLevelMap, setGradeLevelMap] = useState({})

    // function to clear state when starting a new mapping
    function clearState() {
        dispatch(resetUpload())
        setPage(0)
        setJobId("")
        setCsvUploaded(false)
        setCsvColumns([])
        setSelectColumns([])
        setColumnsMapped(false)
        setSchool_sis_id("")
        setGrades("")
        setEmails("")
        setEmails2("")
        setEmails3("")
        setEmails4("")
        setEmails5("")
        setSisIndex(-1)
        setGradesIndex(-1)
        setEmailsIndex(-1)
        setData([])
        setCsvGrades([])
        setAcceptedFiles([])
        setPkMap([])
        setKgMap([])
        setGrade1Map([])
        setGrade2Map([])
        setGrade3Map([])
        setGrade4Map([])
        setGrade5Map([])
        setGrade6Map([])
        setGrade7Map([])
        setGrade8Map([])
        setGrade9Map([])
        setGrade10Map([])
        setGrade11Map([])
        setGrade12Map([])
        setGradeCEMap([])
        setGradesMapped(false)
        setLowGrade("")
        setHighGrade("")
        setGradeLevelList([])
        setTabNumber(2)
        setHasMapping(false)
        setGradeLevelMap({})
    }

    // function to pull sis job data
    const dispatchGetSisJobs = () => {
        dispatch(getSisJobs({ hierarchyId, hierarchyType }))
    }

    // calculate grade range that must be mapped based off of active schools
    useEffect(() => {
        dispatchGetSisJobs()
        if(districtReq.model){
            if(districtReq.model.schools!.length > 0){
                for(let i=0; i<districtReq.model.schools!.length; i++) {
                    if (districtReq.model.schools![i].transitionalGroupType === "school" && districtReq.model.schools![i].active) {
                        if(lowGrade === "" || toOrdinal(districtReq.model.schools![i].lowGrade as GradeLevels) < toOrdinal(lowGrade as GradeLevels)){
                            setLowGrade(districtReq.model.schools![i].lowGrade as string)
                        }
                        if(highGrade === "" || toOrdinal(districtReq.model.schools![i].highGrade as GradeLevels) > toOrdinal(highGrade as GradeLevels)) {
                            setHighGrade(districtReq.model.schools![i].highGrade as string)
                        }
                    }
                }
            }
        }
        setGradeLevelList(createGradeRange(lowGrade as GradeLevels, highGrade as GradeLevels) as any)
    }, [lowGrade, highGrade])

    // track first load
    useEffect(() => {
        if (sisJobsReq.status === Statuses.loaded && !firstLoad.current) {
            firstLoad.current = true
        }
    }, [sisJobsReq])

    // pull current csv mapping on load
    useEffect(() => {
        dispatch(getCsvMapping({ hierarchyId, hierarchyType }))
    }, [])

    // initialize current mapping information and skip to summary page
    useEffect(() => {
        if(mapping != undefined) {
            if(mapping.model != null) {
                if(mapping.model.mapping != null) {
                    let sisIdMap = mapping.model.mapping.sisIdMap
                    let gradeLevelMap = mapping.model.mapping.gradeLevelMap
                    let hasSisIdMap = true
                    let hasGradeLevelMap = false
                    if(sisIdMap === null || sisIdMap.sisId === "" || sisIdMap.email === "") {
                        hasSisIdMap = false
                    }
                    for (const column in gradeLevelMap) {
                        if(gradeLevelMap[column] !== null && gradeLevelMap[column] !== "GradeLevelMap") {
                            hasGradeLevelMap = true
                        }
                    }
                    if(mapping.model.header === false)
                        setHeader(false)
                    if(hasSisIdMap) {
                        setHasMapping(true)
                        setSchool_sis_id(sisIdMap.sisId)
                        setEmails(sisIdMap.email)
                        if(sisIdMap.email2)
                            setEmails2(sisIdMap.email2)
                        if(sisIdMap.email3)
                            setEmails3(sisIdMap.email3)
                        if(sisIdMap.email4)
                            setEmails4(sisIdMap.email4)
                        if(sisIdMap.email5)
                            setEmails5(sisIdMap.email5)
                        setPage(3)
                    }
                    if(hasGradeLevelMap) {
                        setGrades(sisIdMap.grade)
                        setGradeLevelMap(gradeLevelMap)
                    }
                }
            }
        }
    }, [mapping])

    if (sisJobsReq.isLoading() && !firstLoad.current) {
        return <LoadingSpinner />
    }

    if (sisJobsReq.error && !firstLoad.current) {
        return panic(sisJobsReq.error)
    }

    const sisJobs = sisJobsReq.model!

    const lastJob = maxBy(sisJobs, s => s.createdAt)

    return (
        <Sections>
            <Section>
                {page !== 3 && (
                    <Tabs 
                        page={page}
                        setPage={setPage}
                        csvUploaded={csvUploaded}
                        columnsMapped={columnsMapped}
                        grades={grades}
                        number={tabNumber}
                    />
                )}
            </Section>
            {page === 0 && (
                <Section headline={'Set Up Parent List Mapping'}>
                    <SwitchError error={sisJobsReq.error} />
                    <div style={{ marginBottom: '24px' }}>
                        <ManualUploadMapper
                            lastJob={lastJob}
                            hierarchyId={hierarchyId}
                            hierarchyType={hierarchyType}
                            isMapper={true}
                            forceContinue={false}
                            onNeedsRefreshing={dispatchGetSisJobs}
                            page={page}
                            setJobId={setJobId}
                            setPage={setPage}
                            setCsvUploaded={setCsvUploaded}
                            csvColumns={csvColumns}
                            setCsvColumns={setCsvColumns}
                            acceptedFiles={acceptedFiles}
                            setAcceptedFiles={setAcceptedFiles}
                        />
                    </div>
                </Section>)}
            {page === 1 && (
            <Section>
                <SwitchError error={sisJobsReq.error} />
                <div style={{ marginBottom: '24px' }}>
                    <MapColumns 
                        hierarchyId={hierarchyId}
                        hierarchyType={hierarchyType}
                        isMapper={true}
                        forceContinue={true}
                        onNeedsRefreshing={dispatchGetSisJobs}
                        page={page}
                        setPage={setPage}
                        jobId={jobId}
                        setColumnsMapped={setColumnsMapped}
                        school_sis_id={school_sis_id}
                        setSchool_sis_id={setSchool_sis_id}
                        grades={grades}
                        setGrades={setGrades}
                        emails={emails}
                        setEmails={setEmails}
                        emails2={emails2}
                        setEmails2={setEmails2}
                        emails3={emails3}
                        setEmails3={setEmails3}
                        emails4={emails4}
                        setEmails4={setEmails4}
                        emails5={emails5}
                        setEmails5={setEmails5}
                        sisIndex={sisIndex}
                        setSisIndex={setSisIndex}
                        gradesIndex={gradesIndex}
                        setGradesIndex={setGradesIndex}
                        emailsIndex={emailsIndex}
                        setEmailsIndex={setEmailsIndex}
                        csvColumns={csvColumns}
                        setCsvColumns={setCsvColumns}
                        selectColumns={selectColumns}
                        setSelectColumns={setSelectColumns}
                        setCsvGrades={setCsvGrades}
                        acceptedFiles={acceptedFiles}
                        data={data}
                        setData={setData}
                        header={header}
                        setHeader={setHeader}
                        setTabNumber={setTabNumber}
                        setJobId={setJobId}
                    />
                </div>
            </Section>)}
            {page === 2 && (
            <Section>
                <SwitchError error={sisJobsReq.error} />
                <div style={{ marginBottom: '24px' }}>
                    <MapGrades
                        hierarchyId={hierarchyId}
                        hierarchyType={hierarchyType}
                        isMapper={false}
                        forceContinue={true}
                        onNeedsRefreshing={dispatchGetSisJobs}
                        page={page}
                        setPage={setPage}
                        jobId={jobId}
                        school_sis_id={school_sis_id}
                        setSchool_sis_id={setSchool_sis_id}
                        pkMap={pkMap}
                        setPkMap={setPkMap}
                        kgMap={kgMap}
                        setKgMap={setKgMap}
                        grade1Map={grade1Map}
                        setGrade1Map={setGrade1Map}
                        grade2Map={grade2Map}
                        setGrade2Map={setGrade2Map}
                        grade3Map={grade3Map}
                        setGrade3Map={setGrade3Map}
                        grade4Map={grade4Map}
                        setGrade4Map={setGrade4Map}
                        grade5Map={grade5Map}
                        setGrade5Map={setGrade5Map}
                        grade6Map={grade6Map}
                        setGrade6Map={setGrade6Map}
                        grade7Map={grade7Map}
                        setGrade7Map={setGrade7Map}
                        grade8Map={grade8Map}
                        setGrade8Map={setGrade8Map}
                        grade9Map={grade9Map}
                        setGrade9Map={setGrade9Map}
                        grade10Map={grade10Map}
                        setGrade10Map={setGrade10Map}
                        grade11Map={grade11Map}
                        setGrade11Map={setGrade11Map}
                        grade12Map={grade12Map}
                        setGrade12Map={setGrade12Map}
                        gradeCEMap={gradeCEMap}
                        setGradeCEMap={setGradeCEMap}
                        csvGrades={csvGrades}
                        setGradesMapped={setGradesMapped}
                        gradesMapped={gradesMapped}
                        acceptedFiles={acceptedFiles}
                        gradeLevelList={gradeLevelList}
                        header={header}
                    />
                </div>
            </Section>)}
            {page === 3 && (
            <Section>
                <SwitchError error={sisJobsReq.error} />
                <div style={{ marginBottom: '24px' }}>
                    <MapSummary
                        hierarchyId={hierarchyId}
                        hierarchyType={hierarchyType}
                        school_sis_id={school_sis_id}
                        grades={grades}
                        emails={emails}
                        emails2={emails2}
                        emails3={emails3}
                        emails4={emails4}
                        emails5={emails5}
                        pkMap={pkMap}
                        kgMap={kgMap}
                        grade1Map={grade1Map}
                        grade2Map={grade2Map}
                        grade3Map={grade3Map}
                        grade4Map={grade4Map}
                        grade5Map={grade5Map}
                        grade6Map={grade6Map}
                        grade7Map={grade7Map}
                        grade8Map={grade8Map}
                        grade9Map={grade9Map}
                        grade10Map={grade10Map}
                        grade11Map={grade11Map}
                        grade12Map={grade12Map}
                        gradeCEMap={gradeCEMap}
                        gradeLevelList={gradeLevelList}
                        setPage={setPage}
                        setSisId={setSchool_sis_id}
                        setEmails={setEmails}
                        setGrades={setGrades}
                        gradeLevelMap={gradeLevelMap}
                        setTabNumber={setTabNumber}
                        clearState={clearState}
                    />
                </div>
            </Section>)}
        </Sections>
    )
}

export default ParentsPageBody
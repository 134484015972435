import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { SisJob, SisJobIdentifiers } from '../api/SisJob'
import { StaffJob } from '../api/StaffJob';
import { Deps } from '../dependencies/schema'
import { BulkAddUserResult } from '../api/commands/bulkAddUser'
import AsyncReqState, { notStarted, reducerFromAsyncThunk } from '@peachjar/ui/dist/api/AsyncReqState'
import { Hierarchy } from '../api/queries/downloadFTPCredentials'
import { UserCsvIdentifiers } from '../api/queries/downloadUsersCsv';
import { CsvMappingParams } from '../api/commands/saveCsvMapping';
import { CsvMapping } from '../api/CsvMapping';
import { SisGradeResult } from '../api/SisGradeResult';
import { GetSisCsvMappingParams } from '../api/commands/getSisCsvMapping';

export type UploadPhases = 'not-started' | 'get-upload-url' | 'uploading' | 'processing' | 'complete' | 'failed'

const PERCENTAGE_AFTER_GET_URL = 10
const PERCENTAGE_BEFORE_PROCESSING = 10
const PROCESSING_PROGRESS_RATIO = 1 - ((PERCENTAGE_AFTER_GET_URL + PERCENTAGE_BEFORE_PROCESSING) / 100)

export type InvalidFileContext = {
    filename: string,
    reason: 'bad-ext' | 'too-large',
}

type State = {
    saveMappingResults: AsyncReqState<any>;
    sisCsvMapping: AsyncReqState<any>;
    getSisJobs: AsyncReqState<SisJob[]>
    getStaffJobs: AsyncReqState<StaffJob[]>
    uploadCsv: AsyncReqState<SisJob|StaffJob>
    continueSisJob: AsyncReqState<SisJob>
    uploadErrors: AsyncReqState<string[]>
    uploadCsvProgress: number,
    uploadPhase: UploadPhases,
    // This is to preserve manual upload status
    fileToUpload: File | null,
    invalidFile: InvalidFileContext | null,
}

type ThunkContext = {
    state: State,
    extra: Deps,
}

type GetSisJobsParam = {
    hierarchyId: number,
    hierarchyType: string,
}

export const getSisJobs = createAsyncThunk<SisJob[], GetSisJobsParam, ThunkContext>(
    'parents/getJobs',
    (params, { extra: { getSisJobs } }) =>
        getSisJobs(params.hierarchyId, params.hierarchyType)
)
export const getStaffJobs = createAsyncThunk<StaffJob[], GetSisJobsParam, ThunkContext>(
    'parents/getStaffJobs',
    (params, { extra: { getStaffJobs } }) =>
    getStaffJobs(params.hierarchyId, params.hierarchyType)
)

function scaleProgress(uploadProgress: number): number {
    return (uploadProgress * PROCESSING_PROGRESS_RATIO) + PERCENTAGE_AFTER_GET_URL
}

export type UploadParams = {
    csvFileType?: string,
    jobId?: string,
    hierarchyType: string,
    hierarchyId: number,
    isMapper: boolean,
    forceContinue: boolean,
    file: File,
}

export const uploadSisCSV = createAsyncThunk<SisJob | StaffJob, UploadParams, ThunkContext>(
    'parents/upload',
    async (
        params,
        {
            extra: {
                getSisCSVUploadLink,
                uploadSisCSVToCDN,
                createSisJob,
                createStaffJob,
            },
            dispatch,
        }
    ) => {

        try {

            dispatch(updateUploadProgress({ progress: 5 }))
            dispatch(updateUploadPhase({ phase: 'get-upload-url' }))
            
            let { cdnUrl, jobId } = await getSisCSVUploadLink(params)
            if(params.jobId) {
                jobId = params.jobId
            }
            dispatch(updateUploadProgress({ progress: PERCENTAGE_AFTER_GET_URL }))
            dispatch(updateUploadPhase({ phase: 'uploading' }))

            await uploadSisCSVToCDN({
                cdnUrl: cdnUrl,
                file: params.file,
                onUploadProgress(uploadProgress) {
                    // Scale the upload progress to the ratio of overall processing it represents.
                    dispatch(updateUploadProgress({ progress: scaleProgress(uploadProgress) }))
                }
            })

            // Sometimes uploads are so fast that progress events are not fired.  So lets
            // ensure the status goes up to the actual end-percentage of the upload process.
            dispatch(updateUploadProgress({ progress: scaleProgress(100) }))
            dispatch(updateUploadPhase({ phase: 'processing' }))

            let result: SisJob | StaffJob
            if (!params.csvFileType) {
                result = await createSisJob({
                    jobId,
                    ...params,
                })
            } else {
                result = await createStaffJob({
                    jobId,
                    ...params,
                })
            }

            dispatch(updateUploadProgress({ progress: 100 }))
            dispatch(updateUploadPhase({ phase: 'complete' }))

            return result

        } catch (error) {

            dispatch(updateUploadProgress({ progress: 100 }))
            dispatch(updateUploadPhase({ phase: 'failed' }))

            throw error
        }
    }
)

export const continueSisJob = createAsyncThunk<SisJob, SisJobIdentifiers, ThunkContext>(
    'parents/continue-job',
    (params, { extra: { continueSisJob } }) =>
        continueSisJob(params)
)

export const downloadCsvFile = createAsyncThunk<boolean, SisJobIdentifiers, ThunkContext>(
    'parents/download-csv-file',
    (identifiers, { extra: { downloadSisCSVFile } }) => {
        downloadSisCSVFile(identifiers)
        return true
    }
)

export const downloadChangeCsvFile = createAsyncThunk<boolean, SisJobIdentifiers, ThunkContext>(
    'parents/download-csv-file',
    (identifiers, { extra: { downloadSisChangeCSVFile } }) => {
        downloadSisChangeCSVFile(identifiers)
        return true
    }
)

export const downloadErrorFile = createAsyncThunk<boolean, SisJobIdentifiers, ThunkContext>(
    'parents/download-error-file',
    (identifiers, { extra: { downloadSisErrorFile } }) => {
        downloadSisErrorFile(identifiers)
        return true
    }
)

export const downloadCredentials = createAsyncThunk<boolean, Hierarchy, ThunkContext>(
    'parents/download-credentials',
    (hierarchy, { extra: { downloadFTPCredentials } }) => {
        downloadFTPCredentials(hierarchy)
        return true
    }
)

export const downloadUsersCsv = createAsyncThunk<boolean, UserCsvIdentifiers, ThunkContext>(
    'parents/download-users-csv',
    (hierarchy, { extra: { downloadUsersCsv } }) => {
        downloadUsersCsv(hierarchy)
        return true
    }
)

export const getSisJobErrors = createAsyncThunk<string[], SisJobIdentifiers, ThunkContext>(
    'parents/get-sis-job-errors',
    (params, { extra: { getSisJobErrors } }) =>
        getSisJobErrors(params)
)

// export const saveCsvMapping = createAsyncThunk<CsvMapping, CsvMappingParams, ThunkContext>(
//     'mapping',
//     async (params, { extra: { saveCsvMapping } }) => {
//         let result: CsvMapping
//         result = await saveCsvMapping(params)
//         return result
//     }
// )

export const saveCsvMapping = createAsyncThunk<CsvMapping, CsvMappingParams, ThunkContext>(
    'mapping/save',
    (params, { extra: { saveCsvMapping } }) =>  saveCsvMapping(params)
)

export const getCsvMapping = createAsyncThunk<SisGradeResult, GetSisCsvMappingParams, ThunkContext>(
    'mapping/get',
    (params, { extra: { getSisCsvMapping } }) =>  getSisCsvMapping(params)
)

const slice = createSlice({
    name: 'parents',
    initialState: {
        getSisJobs: notStarted(),
        getStaffJobs: notStarted(),
        uploadCsv: notStarted(),
        continueSisJob: notStarted(),
        uploadErrors: notStarted(),
        uploadCsvProgress: 0,
        uploadPhase: 'not-started',
        fileToUpload: null,
        invalidFile: null,
    } as State,
    reducers: {
        updateUploadProgress(state, action: PayloadAction<{ progress: number }>) {
            state.uploadCsvProgress = action.payload.progress
        },
        updateUploadPhase(state, action: PayloadAction<{ phase: UploadPhases }>) {
            state.uploadPhase = action.payload.phase
        },
        setFileToUpload(state, action: PayloadAction<{ file: File | null }>) {
            state.fileToUpload = action.payload.file
        },
        setInvalidFile(state, action: PayloadAction<{ ctx: InvalidFileContext }>) {
            state.invalidFile = action.payload.ctx
        },
        clearInvalidFile(state) {
            state.invalidFile = null
        },
        resetUpload(state) {
            state.fileToUpload = null
            state.uploadCsvProgress = 0
            state.uploadPhase = 'not-started'
            state.invalidFile = null
        }
    },
    extraReducers: (builder) => {
        reducerFromAsyncThunk(builder, 'getSisJobs', getSisJobs)
        reducerFromAsyncThunk(builder, 'getStaffJobs', getStaffJobs)
        reducerFromAsyncThunk<State>(builder, 'uploadCsv', uploadSisCSV)
        reducerFromAsyncThunk<State>(builder, 'continueSisJob', continueSisJob)
        reducerFromAsyncThunk<State>(builder, 'uploadErrors', getSisJobErrors)
        reducerFromAsyncThunk<State>(builder, 'saveMappingResults', saveCsvMapping)
        reducerFromAsyncThunk<State>(builder, 'sisCsvMapping', getCsvMapping)
    }
})

export const updateUploadPhase = slice.actions.updateUploadPhase
export const updateUploadProgress = slice.actions.updateUploadProgress
export const setFileToUpload = slice.actions.setFileToUpload
export const setInvalidFile = slice.actions.setInvalidFile
export const clearInvalidFile = slice.actions.clearInvalidFile
export const resetUpload = slice.actions.resetUpload

export default slice

import React, { FunctionComponent, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import AnchorTextButton from '../../../components/Buttons/AnchorTextButton'
import SpinningSubmitButton from '@peachjar/ui/dist/lib/components/Buttons/SpinningSubmit'

import { SisJob } from '../../../api/SisJob'
import { useDispatch } from 'react-redux'
import { continueSisJob, downloadChangeCsvFile } from '../../../redux/parentSettings'
import { continueSisJobReq } from '../../../redux/parentSettingsSelectors'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import { StaffJob } from '../../../api/StaffJob';

const { Dark: { Paragraph }, DarkMuted: { Note } } = Typo

type PausedAddendumProps = {
    isManual: boolean,
    sisJob: SisJob | StaffJob,
    onSwitchManualUpload?: () => void,
    onContinuePressed?: () => void,
    isStaff?: boolean,
}

const ManualMessage = () => (
    <Paragraph >
        Your upload has been paused because we detected <strong>over 20% net change</strong> in your
        parent emails. Download the CSV file to view changes in the parent emails.
    </Paragraph>
)

const AutomaticMessage = () => {
    return <Paragraph >
        The current sync job has been paused because we detected over 20% net
        change in your parent emails. Download the email changes below to review.
    </Paragraph>
}

const ManualResumeMessage = () => (
    <Paragraph >
        If the <strong>changes are unexpected</strong>, update your CSV file and
        reupload it.
        <div style={{ marginTop: '4px' }}>
            If the <strong>changes are expected</strong>, select Continue With Changes
            to unpause your upload.
        </div>
    </Paragraph>
)

type AutoResumeProps = {
    onSwitchManualUpload?: () => void,
}

const AutomaticResumeMessage: FunctionComponent<AutoResumeProps> = ({ onSwitchManualUpload }) => (
    <Paragraph >
        If the <strong>changes are unexpected</strong>, update your CSV file and
        reupload it through SFTP or <AnchorTextButton onClick={() => {
        if (onSwitchManualUpload) {
            onSwitchManualUpload()
        }
    }}>manually upload</AnchorTextButton> it.
        The sync job will automatically run with the new file.
        <div style={{ marginTop: '4px' }}>
            If the <strong>changes are expected</strong>, select Continue With Changes
            to unpause your automatic sync job.
        </div>
    </Paragraph>
)

const PausedAddendum: FunctionComponent<PausedAddendumProps> = ({
    sisJob,
    isManual,
    onSwitchManualUpload,
    onContinuePressed,
    isStaff
}) => {
    const dispatch = useDispatch()
    const continueJobReq = continueSisJobReq()

    useEffect(() => {
        if (continueJobReq.status === Statuses.loaded && !!onContinuePressed) {
            onContinuePressed()
        }
    }, [continueJobReq])

    return (
        <Grid container>
            <Grid item xs={12}>{isManual ? <ManualMessage /> : <AutomaticMessage />}</Grid>
            <Grid item xs={12} style={{ marginTop: '8px' }}>
                <ButtonSecondarySmall
                    onClick={() => {
                        dispatch(downloadChangeCsvFile({
                            hierarchyType: sisJob.academic.groupType,
                            hierarchyId: sisJob.academic.groupId,
                            jobId: sisJob.jobUuid,
                            isStaff
                        }))
                    }}
                >
                    Download Email Changes (CSV)
                </ButtonSecondarySmall>
            </Grid>
            { !isStaff && <>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                {isManual ?
                    <ManualResumeMessage /> :
                    <AutomaticResumeMessage onSwitchManualUpload={onSwitchManualUpload} />}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '8px' }}>
                <SpinningSubmitButton
                    type='button'
                    buttonType='secondarySmall'
                    noExtraClass={true}
                    loading={continueJobReq.status === Statuses.loading}
                    disabled={continueJobReq.status !== Statuses.notStarted}
                    onClick={() => {
                        dispatch(continueSisJob({
                            hierarchyType: sisJob.academic.groupType,
                            hierarchyId: sisJob.academic.groupId,
                            jobId: sisJob.jobUuid,
                        }))
                    }}
                >
                    Continue With Changes
                </SpinningSubmitButton>
            </Grid>
            </>}
        </Grid>
    )
}

export default PausedAddendum

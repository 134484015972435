import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { maxBy } from 'lodash'
import { Box } from '@material-ui/core'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip'
import SwitchError from '@peachjar/ui/dist/lib/components/Errors/SwitchError'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import SisJobTable from './components/SisJobTable'
import UploadModeChooser, { Mode } from './components/UploadModeChooser'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { getSisJobsReq, getSisCsvMapping } from '../../redux/parentSettingsSelectors'
import { downloadCsvFile, getCsvMapping, getSisJobs } from '../../redux/parentSettings'
import SyncStatus from './components/SyncStatus'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'

const {
    DarkMuted: { Note: MutedNote },
    Dark: { Paragraph },
    DarkBold: { Paragraph: BoldParagraph }
} = Typo

type Props = {
    hierarchyType: string,
    hierarchyId: number,
}
const InfoMessage = () => (
    <span>
        Start setting up your parent sync by setting up your parent list mapping.
    </span>
)

const ParentsPageBody: FunctionComponent<Props> = ({
    hierarchyId,
    hierarchyType,
}) => {

    const dispatch = useDispatch()
    const panic = usePanic()
    const mapping = getSisCsvMapping()
    const sisJobsReq = getSisJobsReq()
    const firstLoad = useRef(false)
    const [uploadMode, setUploadMode] = useState<Mode>(Mode.SFTP)

    const dispatchGetSisJobs = () => {
        dispatch(getSisJobs({ hierarchyId, hierarchyType }))
    }

    useEffect(() => {
        dispatchGetSisJobs()
        dispatch(getCsvMapping({ hierarchyId, hierarchyType }))
    }, [])

    useEffect(() => {
        if (sisJobsReq.status === Statuses.loaded && !firstLoad.current) {
            firstLoad.current = true
        }
    }, [sisJobsReq])

    if (sisJobsReq.isLoading() && !firstLoad.current) {
        return <LoadingSpinner />
    }

    if (sisJobsReq.error && !firstLoad.current) {
        return panic(sisJobsReq.error)
    }

    const sisJobs = sisJobsReq.model!

    const lastJob = maxBy(sisJobs, s => s.createdAt)

    return (
        <Sections>
            <Section headline={'File Preparation'}>
                <SwitchError error={sisJobsReq.error} />
                <div style={{ marginBottom: '24px' }}>
                    <Paragraph >
                        Start setting up your parent email list mapping by uploading a CSV file below. 
                        Make sure your file contains School SIS ID, Grades and Parent Email. 
                        We recommend your file to have these columns as headers. 
                        Make sure your file size is no more than 50 MB.
                        <ul>
                            <li>Headers</li>
                            <li>Site SIS ID, Grades, Email</li>
                            <li>Grade range</li>
                            <li>File size</li>
                        </ul>
                        Your district currently has <strong>K-5</strong> indicated in District & School Settings. 
                        If you are planning to map more grades, please update the grade range in 
                        District & School Settings and return to this page.
                    </Paragraph>
                <div>
                    <InlineNotification 
                        variant='info' 
                        message={<InfoMessage/>} 
                        action=
                            {<ButtonSecondarySmall
                                onClick={() => {
                                    window.open('https://schmgmt.peachjar.net:10443/mgmt/district/50000/mapping', '_self')
                                }}
                            >
                            Parent List Mapping
                            </ButtonSecondarySmall>}
                    />
                </div>
                </div>
            </Section>
        </Sections>
    )
}

const SYNC_LOGS_TOOLTIP = <>
    <strong>New/Existing/Removed</strong><br/>
    The number of emails per student in the CSV that were added, already existed, or removed from Peachjar’s system.<br/>
    <br/>
    <strong>Self Managed</strong><br/>
    The number of emails associated with parents who have chosen to manage their own account. These parents are automatically notified of relevant changes from the CSV.<br/>
    <br/>
    <strong>Duplicate</strong><br/>
    The number of emails that are repeated for parents in the CSV. Emails for parents who have multiple students in schools (SIS ID) are not considered duplicates.<br/>
    <br/>
    <strong>Invalid</strong><br/>
    The number of emails in the CSV that are either not validly formatted or associated with an unknown school (SIS ID).<br/>
    <br/>
    <strong>Total</strong><br/>
    The total number of emails present in the CSV.<br/>
</>

export default ParentsPageBody

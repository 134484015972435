import React, { FunctionComponent } from 'react'
import SFTPUpload from './SFTPUpload'
import ManualUpload from './ManualUpload'
import TabBar from '@peachjar/ui/dist/lib/components/TabBar/TabBar'

import { SisJob } from '../../../api/SisJob'
import { StaffJob } from '../../../api/StaffJob';

export enum Mode {
    SFTP = 'sftp',
    MANUAL = 'manual',
}

type Props = {
    mode: Mode,
    onModeChange: (mode: Mode) => void,
    lastJob?: SisJob | StaffJob,
    hierarchyId: number,
    hierarchyType: string,
    onNeedsRefreshing: () => void,
    isStaff?: boolean,
    hasMapping?: boolean,
    previousJobNumber?: number
}

const UploadModeChooser: FunctionComponent<Props> = ({
    mode,
    onModeChange,
    lastJob,
    hierarchyType,
    hierarchyId,
    onNeedsRefreshing,
    isStaff = false,
    hasMapping,
    previousJobNumber
}) => {
    if(previousJobNumber == null)
        previousJobNumber = 1
    if(hasMapping == null)
        hasMapping = false

    return (
        <>
            {(hasMapping || previousJobNumber > 0) &&
                <TabBar tabs={[
                    {
                        label: 'Auto Sync',
                        isActive: mode === Mode.SFTP,
                        onClick: () => onModeChange(Mode.SFTP)
                    },
                    {
                        label: 'Manual Sync',
                        isActive: mode === Mode.MANUAL,
                        onClick: () => onModeChange(Mode.MANUAL)
                    },
                ]} />
            }
            {mode === Mode.SFTP && (hasMapping || previousJobNumber > 0) &&
                <SFTPUpload
                    hierarchyType={hierarchyType}
                    hierarchyId={hierarchyId}
                    isStaff={ isStaff }
                />
            }
            {mode === Mode.MANUAL && (hasMapping || previousJobNumber > 0)  &&
                <ManualUpload
                    lastJob={lastJob}
                    hierarchyType={hierarchyType}
                    hierarchyId={hierarchyId}
                    isMapper={false}
                    forceContinue={false}
                    onNeedsRefreshing={onNeedsRefreshing}
                    isStaff={isStaff}
                />
            }
        </>
    )
}

export default UploadModeChooser

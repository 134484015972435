import downloadUsersCsv from '../../api/queries/downloadUsersCsv'
import { Config } from '../../config'

type Deps = {
    config: Config,
}

export default function factory({ config }: Deps): downloadUsersCsv {
    return ({ hierarchyId, hierarchyType, isStaff }) => {
        const type = isStaff ? 'staff' : 'volunteer'
        const downloadUrl = `https://portal-bff.${config.DOMAIN}/downloads/${hierarchyType}/${hierarchyId}`
        window.open(downloadUrl, '_blank')
    }
}

import React, { FunctionComponent } from 'react'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Grid from '@material-ui/core/Grid'

import { useDispatch } from 'react-redux'
import { downloadCredentials } from '../../../redux/parentSettings'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import { SisJob } from '../../../api/SisJob'

const { Dark: { Paragraph } } = Typo

type Props = {
    hierarchyType: string,
    hierarchyId: number,
    lastJob?: SisJob,
    isStaff?: boolean
}

const SFTPUpload: FunctionComponent<Props> = ({
    hierarchyType,
    hierarchyId,
    isStaff
}) => {
    const dispatch = useDispatch()
    const instructionUrl = isStaff 
                            ? 'https://cdn.peachjar.com/assets/Staff+SFTP+Sync+Instructions.pdf?version=1' 
                            : 'https://cdn.peachjar.com/assets/Parent+SFTP+Sync+Instructions.pdf?version=1'  
    return (
        <>
            <div style={{ margin: '16px 0 8px 0' }}>
                { isStaff 
                ? <Paragraph>
                    Automate syncing your staff list by uploading your CSV file using Secure File Transfer Protocol (SFTP). We encourage
                    you to set up your process to update the CSV file nightly or weekly to ensure your staff list stays up-to-date.
                    File sync jobs automatically run upon your file being successfully uploaded.
                  </Paragraph>
                : <Paragraph>
                    Automate syncing your parent list by uploading your CSV file using Secure File Transfer Protocol (SFTP). We encourage
                    you to set up your process to update the CSV file nightly or weekly to ensure your parent list stays up-to-date.
                    File sync jobs automatically run upon your file being successfully uploaded.
                  </Paragraph>
                }
            </div>
            <Grid container spacing={1}>
                <Grid item>
                    <ButtonSecondarySmall
                        onClick={() => {
                            window.open(
                                instructionUrl,
                                '_blank'
                            )
                        }}
                    >
                        View SFTP Sync Instructions (PDF)
                    </ButtonSecondarySmall>
                </Grid>
                <Grid item>
                    <ButtonSecondarySmall
                        onClick={() => dispatch(downloadCredentials({ hierarchyType, hierarchyId }))}
                    >
                        View SFTP Sync Credentials (TXT)
                    </ButtonSecondarySmall>
                </Grid>
            </Grid>
        </>
    )
}

export default SFTPUpload

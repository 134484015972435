import ApolloClient from 'apollo-boost'

import getSisJobErrors from '../../api/queries/getSisJobErrors'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    query getSisJobError($input: SisJobInput) {
        payload: sisJobErrors(input: $input) {
            hierarchyType
            hierarchyId
            jobUuid
            errors
        }
    }
`

type SisJobErrors = {
    hierarchyType: string,
    hierarchyId: number,
    jobUuid: string,
    errors: string[],
}

type QueryResult = {
    payload: SisJobErrors
}

export default function factory({ apolloClient }: Deps): getSisJobErrors {
    return async (params) => {

        try {

            const result = await apolloClient.query<QueryResult>({
                query: QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    input: {
                        academic: {
                            groupId: params.hierarchyId,
                            groupType: params.hierarchyType,
                        },
                        jobUuid: params.jobId,
                    }
                },
            })

            return result.data.payload.errors

        } catch (error) {
            throw mapError(error)
        }
    }
}

import React, { FunctionComponent, memo } from 'react'
import AutocompleteFilter from '@peachjar/ui/dist/lib/components/Filters/AutocompleteFilter'

import { Roles } from '../../../api/Role'

export type Role = {
    role: Roles,
    description: string,
}

const RoleOptions: Role[] = [
    { role: Roles.Admin, description: 'Admin' },
    { role: Roles.Approver, description: 'Approver' },
    { role: Roles.Uploader, description: 'Uploader' },
    { role: Roles.None, description: 'No Roles' },
    { role: Roles.Default, description: 'Any Role' },
]

type Props = {
    key?: string,
    value?: Roles | null,
    only?: Roles[],
    onRoleSelected: (role: Roles) => void,
}

const RoleSelectionAutocomplete: FunctionComponent<Props> = ({
    key = 'role_selection',
    value = Roles.All,
    only = [Roles.None, Roles.Admin, Roles.Approver, Roles.Uploader, Roles.Default],
    onRoleSelected,
}) => {

    const availableRoles = RoleOptions.filter(o => only?.includes(o.role))
    const roleSelection = availableRoles.find(ar => ar.role === value) || null

    return (
        <AutocompleteFilter<Role>
            key={key}
            label='Roles'
            variant='outlined'
            choices={availableRoles}
            value={roleSelection}
            onChange={(r: Role) => onRoleSelected(r ? r.role : Roles.All)}
        />
    )
}

export default memo(RoleSelectionAutocomplete)

// @ts-nocheck
import React from 'react'

const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <g
      id="Style-Guide"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="Illustrations" transform="translate(-1370.000000, -435.000000)">
        <g
          id="Download-decorative-icon"
          transform="translate(1398.000000, 463.000000) scale(1, -1) translate(-1398.000000, -463.000000) translate(1370.000000, 435.000000)"
        >
          <circle id="Mask" fill="#DFE1E7" cx={28} cy={28} r={28} />
          <rect
            id="Rectangle"
            fill="#47506B"
            x={13}
            y={14}
            width={27}
            height={30}
            rx={1}
          />
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x={15}
            y={12}
            width={27}
            height={30}
            rx={1}
          />
          <rect
            id="Rectangle-6"
            fill="#C6C9D5"
            x={19}
            y={29}
            width="19.481481"
            height="1.03703704"
            rx="0.518518519"
          />
          <rect
            id="Rectangle-6"
            fill="#C6C9D5"
            x={19}
            y={33}
            width="19.481481"
            height="1.03703704"
            rx="0.518518519"
          />
          <rect
            id="Rectangle-6"
            fill="#C6C9D5"
            x={19}
            y={37}
            width="19.481481"
            height="1.03703704"
            rx="0.518518519"
          />
          <g
            id="Group-7"
            transform="translate(28.500000, 17.500000) scale(1, -1) translate(-28.500000, -17.500000) translate(21.000000, 10.000000)"
            fill="#757D99"
          >
            <rect id="Rectangle-5" x={5} y={8} width={5} height={7} />
            <polygon id="Triangle" points="7.5 0 15 8 0 8" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default UploadIcon

import React, { FC, ComponentType } from 'react'
import { css } from 'emotion'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import SisErrorIcon from './icons/SisErrorIcon'
import SisPauseIcon from './icons/SisPauseIcon'
import SisProcessingIcon from './icons/SisProcessingIcon'
import SisWaitIcon from './icons/SisWaitIcon'
import InvalidIcon from './icons/InvalidIcon'
import Grid from '@material-ui/core/Grid'
import PausedAddendum from './PausedAddendum'
import FailedAddendum from './FailedAddendum'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import { useDispatch } from 'react-redux'
import { downloadErrorFile } from '../../../redux/parentSettings'

import { JobStatus, SisJob } from '../../../api/SisJob'

import colors from '@peachjar/ui/dist/lib/styles/colors'
import { StaffJob } from '../../../api/StaffJob';

const { Dark: { Paragraph } } = Typo

type Props = {
    lastJob?: SisJob | StaffJob
    onSwitchManualUpload?: () => void,
    onContinuePressed?: () => void,
    isStaff?: boolean
}

const Paused: FC<Props> = (props) => {
    const { onSwitchManualUpload, onContinuePressed, isStaff } = props
    const lastJob = props.lastJob!

    return (
        <Grid container justify='flex-start' alignItems='flex-start' direction='row' spacing={2}>
            <Grid item xs={12} md={1}>
                <SisPauseIcon />
            </Grid>
            <Grid item xs={12} md={11}>
                <PausedAddendum
                    isStaff={isStaff}
                    isManual={false}
                    sisJob={lastJob!}
                    onSwitchManualUpload={onSwitchManualUpload}
                    onContinuePressed={onContinuePressed}
                />
            </Grid>
        </Grid>
    )
}


const Waiting: FC<Props> = ({ lastJob, isStaff }) => {
    const invalidEmailNum = lastJob?.details?.invalidRecords?.invalidEmails?.length ?? 0
    const invalidSchoolNum = lastJob?.details?.invalidRecords?.invalidSchools?.length ?? 0
    const invalidGradeNum = lastJob?.details?.invalidRecords?.invalidGrades ?? 0
    const notValid = invalidEmailNum > 0 || invalidSchoolNum > 0 || invalidGradeNum > 0
    const valid = invalidEmailNum === 0 && invalidSchoolNum === 0 && invalidGradeNum === 0
    const dispatch = useDispatch()

    return (
        <Grid container justify='flex-start' alignItems='center' direction='row' spacing={2}>
            { valid &&
                <Grid item>
                    <SisWaitIcon color={colors.leaf} />
                </Grid>
            }
            { valid &&
                <Grid item >
                    <Paragraph >
                        The last sync job was successful and had no invalid entries! The sync job is waiting for the next file upload.
                    </Paragraph>
                </Grid>
            }
            { notValid &&
                <Grid item>
                    <InvalidIcon />
                </Grid>
            }
            { notValid &&
                <Grid item style={{marginTop: '-70px'}}>
                    <Paragraph style={{marginLeft: '80px', inlineSize: 'minContent'}} >
                        Although the last sync job was successful, one or more of your { isStaff ? 'staff' : 'parent'} emails have
                        an <b>invalid format or invalid school association.</b> Download the invalid entries
                        below for the specific emails or school associations that need to be fixed.
                    </Paragraph>
                    <Paragraph style={{color: 'red', marginTop: '10px', marginLeft: '80px'}} >
                        Invalid Entries:
                    </Paragraph>
                    <Paragraph style={{marginLeft: '80px'}} >
                        {invalidEmailNum} email address formatting issues.
                    </Paragraph>
                    <Paragraph style={{marginLeft: '80px'}} >
                        {invalidSchoolNum} SIS IDs unknown or missing.
                    </Paragraph>
                    <Paragraph style={{marginLeft: '80px'}} >
                        {invalidGradeNum} Grades unknown or missing.
                    </Paragraph>
                </Grid>
            }
            { notValid &&
                <Grid item xs={12} style={{ marginTop: '8px', marginLeft: '80px' }}>
                    <ButtonSecondarySmall onClick={() =>
                        dispatch(downloadErrorFile({
                            hierarchyId: lastJob?.academic.groupId!,
                            hierarchyType: lastJob?.academic.groupType!,
                            jobId: lastJob?.jobUuid!,
                            isStaff
                        }))
                    }>
                        Download Invalid Entries
                    </ButtonSecondarySmall>
                </Grid>
            }
        </Grid>
    )
}

const Processing: FC<Props> = () => {
    return (
        <Grid container justify='flex-start' alignItems='center' direction='row' spacing={2}>
            <Grid item>
                <SisProcessingIcon />
            </Grid>
            <Grid item>
                <Paragraph >
                    The file has been successfully uploaded and the sync job is running smoothly.
                </Paragraph>
            </Grid>
        </Grid>
    )
}

const NeverRan: FC<Props> = () => {
    return (
        <Grid container justify='flex-start' alignItems='center' direction='row' spacing={2}>
            <Grid item>
                <SisWaitIcon />
            </Grid>
            <Grid item>
                <Paragraph >
                    No file has been uploaded. Therefore, the file sync job has yet to run.
                </Paragraph>
            </Grid>
        </Grid>
    )
}

const Errored: FC<Props> = ({ lastJob, isStaff }) => {
    return (
        <Grid container justify='flex-start' alignItems='flex-start' direction='row' spacing={2}>
            <Grid item xs={12} md={1}>
                <SisErrorIcon />
            </Grid>
            <Grid item xs={12} md={11}>
                <FailedAddendum sisJob={lastJob!} isManual={false} isStaff={ isStaff } />
            </Grid>
        </Grid>
    )
}

function getSyncStatusComponent(status: JobStatus): ComponentType<Props> {
    switch (status) {
        case JobStatus.Paused: return Paused
        case JobStatus.Failed: return Errored
        case JobStatus.Pending: return Processing
        case JobStatus.Processing: return Processing
        default: return Waiting
    }
}

const SyncStatus: FC<Props> = ({
    lastJob,
    onSwitchManualUpload,
    onContinuePressed,
    isStaff = false
}) => {
    const Component = lastJob ? getSyncStatusComponent(lastJob.status) : NeverRan
    return (
        <div className={classNames.syncStatusBox}>
            <Component
                lastJob={lastJob}
                onSwitchManualUpload={onSwitchManualUpload}
                onContinuePressed={onContinuePressed}
                isStaff={isStaff}
            />
        </div>
    )
}

const classNames = {
    syncStatusBox: css`
        padding: 32px;
        border: 1px solid ${colors.silver};
        border-radius: 3px;
    `,
}

export default SyncStatus

/**
 * Represents an error with a canonical PJ error code. These are what the
 */
export default interface CodedError {
    code: string,
    message: string,
    [key: string]: any,
}

export function invalidParam(param: string, message?: string): CodedError {
    return {
        code: 'request.invalid.parameters',
        message: message || `The "${param}" parameter is invalid.`,
        param,
    }
}

export function notAuthorized(
    resource: string,
    action: string,
    identifier?: any,
    message?: string
): CodedError {
    return {
        code: 'request.security.authorization',
        message: message || `You are not allowed to perform this action.`,
        action,
        resource,
        identifier,
    }
}

export function badConfiguration(source: string, parameter: string, value: any, message?: string): CodedError {
    return {
        code: 'internal.configuration',
        message: message || 'Internal error.',
        source,
        parameter,
        value,
    }
}

export class UIError extends Error implements CodedError {
    public readonly isUIError = true
    constructor(public code: string, message: string) {
        super(message)
    }
}

import { School } from '../../api/School'
import { normalizeSchoolForUI, GQLSchool } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import getSchool from '../../api/queries/getSchool'
import gql from 'graphql-tag'
import mapError from './mapError'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const GET_SCHOOL = gql`
    query school($schoolId: Int!) {
        school: getSchool(input: { schoolId: $schoolId }) {
            id
            schoolId
            name
            active
            visible
            treeCounter
            sisId
            ncesId
            address {
                googlePlaceId
                address
            }
            lowGrade
            highGrade
            enrollmentCount
            startAt
            generalDisclaimer
            isIndependent
            createdAt
            createdBy
            updatedAt
            updatedBy
            district {
                id
                districtId
                name
                active
                visible
                treeCounter
                sisId
                ncesId
                address {
                    googlePlaceId
                    address
                }
                generalDisclaimer
                startAt
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
        }
    }
`

type QueryResult = {
    school: GQLSchool,
}

export default function factory({ apolloClient }: Deps): getSchool {
    return async (schoolId: number): Promise<School> => {
        try {

            const result = await apolloClient.query<QueryResult>({
                query: GET_SCHOOL,
                fetchPolicy: 'network-only',
                variables: { schoolId },
            })

            return normalizeSchoolForUI(result.data!.school)

        } catch (error) {

            throw mapError(error)
        }
    }
}

import React, { FunctionComponent } from 'react'

const CsvFileIcon: FunctionComponent<{}> = ({}) => {
    return (
        <svg
            width='41px'
            height='49px'
            viewBox='0 0 41 49'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >
            <title>77C136EC-B1F6-4B92-820B-0873EFC7DD44@1x</title>
            <g id='Settings' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                <g id='Parent-Settings---manual-success' transform='translate(-332.000000, -800.000000)'>
                    <g id='CSV_file' transform='translate(332.000000, 800.000000)'>
                        <rect id='Rectangle' fill='#757D99' fill-rule='nonzero' x='3' y='0' width='38' height='46' rx='1'></rect>
                        <rect id='Rectangle' fill='#DFE1E7' fill-rule='nonzero' x='0' y='3' width='38' height='46' rx='1'></rect>
                        <path d='M6.42469136,29.6666667 L21.2529489,29.6666667 C21.6920494,29.6666667 22.0480106,30.0226279 22.0480106,30.4617284 C22.0480106,30.9008289 21.6920494,31.2567901 21.2529489,31.2567901 L6.42469136,31.2567901 C5.98559089,31.2567901 5.62962963,30.9008289 5.62962963,30.4617284 C5.62962963,30.0226279 5.98559089,29.6666667 6.42469136,29.6666667 Z' id='Rectangle-6' fill='#47506B' fill-rule='nonzero'></path>
                        <path d='M6.42469136,34.8 L15.2529489,34.8 C15.6920494,34.8 16.0480106,35.1559613 16.0480106,35.5950617 C16.0480106,36.0341622 15.6920494,36.3901235 15.2529489,36.3901235 L6.42469136,36.3901235 C5.98559089,36.3901235 5.62962963,36.0341622 5.62962963,35.5950617 C5.62962963,35.1559613 5.98559089,34.8 6.42469136,34.8 Z' id='Rectangle-6' fill='#47506B' fill-rule='nonzero'></path>
                        <path d='M6.42469136,39.9333333 L27.2529489,39.9333333 C27.6920494,39.9333333 28.0480106,40.2892946 28.0480106,40.7283951 C28.0480106,41.1674955 27.6920494,41.5234568 27.2529489,41.5234568 L6.42469136,41.5234568 C5.98559089,41.5234568 5.62962963,41.1674955 5.62962963,40.7283951 C5.62962963,40.2892946 5.98559089,39.9333333 6.42469136,39.9333333 Z' id='Rectangle-6' fill='#47506B' fill-rule='nonzero'></path>
                        <text id='csv' fill='#2D344B' font-family='ProximaNova-Regular, Proxima Nova' font-size='16' font-weight='normal'>
                            <tspan x='7' y='20'>csv</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CsvFileIcon

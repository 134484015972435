import React, { FunctionComponent } from 'react'

type Props = {
    color?: string
}
const InvalidIcon: FunctionComponent<Props> = ({color}) => {
    return (
      <svg
        width='48px'
        height='48px'
        viewBox='0 0 48 48'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
      <title>1EE2A114-9040-4F8A-AE29-10B412889510@1x</title>
      <g id='Settings' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
          <g id='Parent-Settings---SFTP-invalid-entries' transform='translate(-340.000000, -960.000000)'>
              <g id='Group-5' transform='translate(316.000000, 904.000000)'>
                  <g id='Group-4' transform='translate(0.000000, 32.000000)'>
                      <g id='invalid-entries-icon' transform='translate(24.000000, 24.000000)'>
                          <circle id='Oval' fill='#FCC35D' cx='24' cy='24' r='24'></circle>
                          <g id='Group' transform='translate(21.000000, 10.000000)' fill='#FFFFFF'>
                              <rect id='Rectangle' x='0' y='0' width='7' height='21' rx='1'></rect>
                              <rect id='Rectangle' transform='translate(3.500000, 26.000000) scale(1, -1) translate(-3.500000, -26.000000) ' x='0' y='23' width='7' height='6' rx='1'></rect>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  )
}
export default InvalidIcon

import ApolloClient from 'apollo-boost'
import UserAndSiteRoles from '../../api/UserAndSiteRoles'

import updateUser, { UpdateUserParams } from '../../api/commands/updateUser'
import mapError from './mapError'
import gql from 'graphql-tag'

const UPDATE_STAFF_MEMBER = gql`
    mutation updateUser($input: UpdateStaffMemberInput!) {
        user: updateStaffMember(input: $input) {
            userId
            firstName
            lastName
            site {
                id
                type
                name
            }
            email
            isApprover
            isUploader
            isAdmin
        }
    }
`

const UPDATE_VOLUNTEER = gql`
    mutation updateUser($input: UpdateVolunteerInput!) {
        user: updateVolunteer(input: $input) {
            userId
            firstName
            lastName
            site {
                id
                type
                name
            }
            email
            isUploader
        }
    }
`

type Deps = {
    apolloClient: ApolloClient<any>,
}

type GQLResult = {
    user: UserAndSiteRoles,
}

export default function factory({ apolloClient }: Deps): updateUser {
    return async (params: UpdateUserParams): Promise<UserAndSiteRoles> => {
        try {

            const result = await apolloClient.mutate<GQLResult>({
                mutation: params.updates.isVolunteer ? UPDATE_VOLUNTEER : UPDATE_STAFF_MEMBER,
                variables: {
                    input: {
                        updates: Object.assign({
                            userId: params.updates.userId,
                            firstName: params.updates.firstName,
                            lastName: params.updates.lastName,
                            site: params.updates.site,
                            isUploader: params.updates.isUploader,
                        }, params.updates.isVolunteer ? {} : {
                            isAdmin: params.updates.isAdmin,
                            isApprover: params.updates.isApprover,
                        }),
                        previous: Object.assign({
                            site: params.previous.site,
                            userId: params.previous.userId,
                            isUploader: params.previous.isUploader,
                        }, params.previous.isVolunteer ? {} : {
                            isAdmin: params.previous.isAdmin,
                            isApprover: params.previous.isApprover,
                        })
                    }
                }
            })

            return Object.assign({
                isAdmin: false,
                isApprover: false,
            }, result.data!.user)

        } catch (error) {
            throw mapError(error)
        }
    }
}

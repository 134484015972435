import React, { FunctionComponent, useEffect, useState } from 'react'
import SchoolForm, { ExternalErrors } from './SchoolForm'
import AsyncReqState, { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import SuccessNotification from '@peachjar/ui/dist/lib/components/Notifications/SuccessNotification'
import QueryStringNotification from '@peachjar/ui/dist/lib/components/Notifications/QueryStringNotification'
import InvokeOnError from '@peachjar/ui/dist/lib/components/Errors/InvokeOnError'

import { Sections, Section } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { useDispatch, useSelector } from 'react-redux'
import { useSchoolPermissions } from '../../../hooks/usePermissions'
import { Match, SwitchError } from '@peachjar/ui/dist/lib/components/Errors'
import { updateSchoolInfo, clearUpdateReq } from '../../../redux/schools'
import { RootState } from '../../../redux'
import { School } from '../../../api/School'
import { useHistory } from 'react-router-dom'
import { Errors } from '../../Users/components/constants'

import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'

export type UpdateSchoolPageBodyProps = {
    isIndependent?: boolean,
    school: School,
    onSubmitSuccess?: () => void,
}

const UpdateSchoolPageBody: FunctionComponent<UpdateSchoolPageBodyProps> = ({
    isIndependent = true,
    school,
    onSubmitSuccess = () => true,
}) => {

    const permissions = useSchoolPermissions(school.schoolId)

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null)
    const [lastSisId, setLastSisId] = useState<string | null>(null)
    const [errors, setErrors] = useState<ExternalErrors>({
        sisId: {
            lastNonUniqueVal: lastSisId,
            message: Errors.NonUniqueSisID.message,
        },
    })

    const { close: closeNotification } = useGlobalNotifications()

    const updateReq = useSelector<RootState, AsyncReqState<School>>(state => state.schools.updateSchoolInfo)

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (updateReq.status === Statuses.loading) {
            closeNotification()
        }
        if (updateReq.status === Statuses.loaded) {
            setLastUpdated(updateReq.model?.updatedAt || null)
            dispatch(clearUpdateReq())
            onSubmitSuccess()
        }
    }, [updateReq])

    return (
        <Sections>
            <Section>
                <QueryStringNotification />
                {isIndependent && <SuccessNotification
                    id={`update_req_${lastUpdated}`}
                    isVisible={!!lastUpdated}
                    message='School settings are updated.'
                />}
                <SwitchError error={updateReq.error}>
                    <Match code={Errors.NonUniqueSisID.code}>
                        <InvokeOnError
                            error={updateReq.error}
                            onError={() => {
                                setErrors(Object.assign(errors, {
                                    sisId: {
                                        lastNonUniqueVal: lastSisId,
                                        message: Errors.NonUniqueSisID.message,
                                    }
                                }))
                            }}
                        />
                    </Match>
                </SwitchError>
                <SchoolForm
                    isEdit={true}
                    isSubmitting={updateReq.status === Statuses.loading}
                    isIndependent={isIndependent}
                    errors={errors}
                    permissions={permissions}
                    school={school}
                    onSubmit={updates => {
                        setLastSisId(updates.sisId || null)
                        dispatch(updateSchoolInfo({
                            ...updates,
                            invisible: !updates?.invisible
                        }))
                    }}
                    onCancel={() => history.goBack()}
                    lastUpdated={lastUpdated}
                />
            </Section>
        </Sections>
    )
}

export default UpdateSchoolPageBody

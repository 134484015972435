/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux'
import { RootState } from './index'

import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'
import UserAndSiteRoles from '../api/UserAndSiteRoles'
import { BulkAddUserResult } from '../api/commands/bulkAddUser'
import { UsersListFilters } from '../pages/Users/components/UsersListFilters'
import { UserAndSiteRolesPaginated } from '../api/queries/getUserAndSiteRolesPaginated'


export const bulkAddUserReq = () => {
    return useSelector<RootState, AsyncReqState<BulkAddUserResult>>(state => state.usersAndRoles.bulkAddUser)
}
export const addUserReq = () => {
    return useSelector<RootState, AsyncReqState<UserAndSiteRoles>>(state => state.usersAndRoles.addUser)
}

export const updateUserReq = () => {
    return useSelector<RootState, AsyncReqState<UserAndSiteRoles>>(state => state.usersAndRoles.updateUser)
}

export const removeUserReq = () => {
    return useSelector<RootState, AsyncReqState<void>>(state => state.usersAndRoles.removeUser)
}

export const getUsersAndRolesReq = () => {
    return useSelector<RootState, AsyncReqState<UserAndSiteRoles[]>>(state => state.usersAndRoles.users)
}
export const getStaffUsersAndRolesReq = () => {
    return useSelector<RootState, AsyncReqState<UserAndSiteRolesPaginated>>(state => state.usersAndRoles.staffUsers)
}
export const getVolunteerUsersAndRolesReq = () => {
    return useSelector<RootState, AsyncReqState<UserAndSiteRolesPaginated>>(state => state.usersAndRoles.volunteerUsers)
}

export const getStaffFilters = () => {
    return useSelector<RootState, UsersListFilters>(state =>  state.usersAndRoles.staffFilters)
}

export const getVolunteerFilters = () => {
    return useSelector<RootState, UsersListFilters>(state =>  state.usersAndRoles.volunteerFilters)
}


import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { School } from '../api/School'
import { UpdateableFields } from '../api/commands/updateSchoolInfo'
import { Deps } from '../dependencies/schema'
import { CreateSchoolParams, CreateSchoolOptions } from '../api/commands/createSchool'
import { AcademicGroupIdentifiers, Hierarchy } from '../api/commands/launchGroup'

import AsyncReqState, { notStarted, reducerFromAsyncThunk } from '@peachjar/ui/dist/api/AsyncReqState'

type State = {
    createParams: Partial<School> | null,
    school: AsyncReqState<School>,
    updateSchoolInfo: AsyncReqState<School>,
    createSchool: AsyncReqState<School>,
    launchSchool: AsyncReqState<AcademicGroupIdentifiers[]>,
}

type ThunkContext = {
    state: State,
    extra: Deps,
}

export const getSchool = createAsyncThunk<School, number, ThunkContext>(
    'schools/get',
    async (schoolId, { extra: { getSchool } }) => getSchool(schoolId)
)

export const updateSchoolInfo = createAsyncThunk<School, Partial<UpdateableFields>, ThunkContext>(
    'schools/update',
    async (updates, { extra: { updateSchoolInfo } }) => updateSchoolInfo(updates)
)

export const launchSchool = createAsyncThunk<AcademicGroupIdentifiers[], number, ThunkContext>(
    'schools/launch',
    (id, { extra: { launchGroup } }) => launchGroup({
        id,
        type: 'school',
    })
)

// TODO: refactor.  There shouldn't be mapping between async thunk args and a backend request.
// We need to just make all API calls single params.  If the impl wants to deconstruct that,
// then let it.
export type CreateSchoolThunkArgs = {
    params: CreateSchoolParams,
    options: CreateSchoolOptions,
}

export const createSchool = createAsyncThunk<School, CreateSchoolThunkArgs, ThunkContext>(
    'schools/create',
    async (args, { extra: { createSchool } }) => createSchool(args.params, args.options)
)

const slice = createSlice({
    name: 'schools',
    initialState: {
        createParams: null,
        school: notStarted(),
        updateSchoolInfo: notStarted(),
        createSchool: notStarted(),
        launchSchool: notStarted(),
    } as State,
    reducers: {
        setCreateParams: (state, action: PayloadAction<Partial<School> | null>) => {
            state.createParams = action.payload
        },
        clearReq: (state) => {
            state.school = notStarted()
            state.updateSchoolInfo = notStarted()
            state.createSchool = notStarted()
        },
        clearUpdateReq: (state) => {
            state.updateSchoolInfo = notStarted()
        }
    },
    extraReducers: (builder) => {
        reducerFromAsyncThunk(builder, 'school', getSchool)
        reducerFromAsyncThunk(builder, 'updateSchoolInfo', updateSchoolInfo)
        reducerFromAsyncThunk(builder, 'createSchool', createSchool)
        reducerFromAsyncThunk(builder, 'launchSchool', launchSchool)
    }
})

export const clearReq = slice.actions.clearReq
export const clearUpdateReq = slice.actions.clearUpdateReq
export const setCreateParams = slice.actions.setCreateParams

export default slice

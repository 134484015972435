import React, { FunctionComponent } from 'react'

const SisProcessingIcon: FunctionComponent<{}> = ({}) => {
    return (
        <svg
            width='48px'
            height='48px'
            viewBox='0 0 48 48'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <title>2B6ED5C9-FB69-44F1-A2DA-892070259753@1x</title>
            <g id='Settings' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                <g id='Parent-Settings---SFTP-success' transform='translate(-340.000000, -904.000000)'>
                    <g id='sync-processing-icon' transform='translate(340.000000, 904.000000)'>
                        <circle id='Oval' fill='#DFE1E7' cx='24' cy='24' r='24'></circle>
                        <g id='Group-2' transform='translate(4.142857, 6.857143)' fill='#FFFFFF'>
                            <g id='Group' transform='translate(9.571429, 0.000000)'>
                                <path d='M7.62928169,13.14914 C10.133739,9.47564307 13.7333943,7.63889459 18.4282475,7.63889459 C23.1439902,7.63889459 26.8780864,9.97364401 29.6305359,14.6431428 C29.9109365,15.118924 29.7525971,15.7319427 29.2768378,16.0123804 C29.2557536,16.0248086 29.2342202,16.0364585 29.2122811,16.0473065 L26.4208094,17.4275909 C25.9605496,17.6551922 25.4027164,17.4972205 25.1301205,17.0620965 C24.4863703,16.034527 23.8671667,15.307141 23.2725096,14.8799384 C22.0320418,13.9887845 20.8527367,12.8645013 18.0764537,13.1663779 C15.0614806,13.4942083 13.3518035,14.8137823 12.3181108,15.6567733 C12.1044286,15.8310342 11.922703,16.023538 11.7729342,16.2342846 C11.4530093,16.6844722 10.8287091,16.7900666 10.3785237,16.4701387 C10.3670326,16.4619724 10.3557155,16.4535641 10.34458,16.4449194 L7.84228707,14.5023425 C7.42498686,14.178353 7.33168288,13.5856531 7.62928169,13.14914 Z' id='Rectangle' transform='translate(18.638012, 12.738796) rotate(55.000000) translate(-18.638012, -12.738796) '></path>
                                <path d='M6.44023555,1.67472316 L11.603127,11.2629501 C11.734046,11.5060855 11.6430767,11.8093165 11.3999413,11.9402355 C11.3270871,11.9794647 11.245636,12 11.1628914,12 L0.837108579,12 C0.560966204,12 0.337108579,11.7761424 0.337108579,11.5 C0.337108579,11.4172554 0.357643843,11.3358043 0.396873029,11.2629501 L5.55976445,1.67472316 C5.6906835,1.43158778 5.99391453,1.34061847 6.23704991,1.47153753 C6.32320039,1.51792625 6.39384683,1.58857269 6.44023555,1.67472316 Z' id='Triangle' transform='translate(6.000000, 6.428571) rotate(-92.000000) translate(-6.000000, -6.428571) '></path>
                            </g>
                            <g id='Group' transform='translate(15.142857, 21.285714) rotate(180.000000) translate(-15.142857, -21.285714) translate(0.142857, 8.285714)'>
                                <path d='M7.62928169,13.14914 C10.133739,9.47564307 13.7333943,7.63889459 18.4282475,7.63889459 C23.1439902,7.63889459 26.8780864,9.97364401 29.6305359,14.6431428 C29.9109365,15.118924 29.7525971,15.7319427 29.2768378,16.0123804 C29.2557536,16.0248086 29.2342202,16.0364585 29.2122811,16.0473065 L26.4208094,17.4275909 C25.9605496,17.6551922 25.4027164,17.4972205 25.1301205,17.0620965 C24.4863703,16.034527 23.8671667,15.307141 23.2725096,14.8799384 C22.0320418,13.9887845 20.8527367,12.8645013 18.0764537,13.1663779 C15.0614806,13.4942083 13.3518035,14.8137823 12.3181108,15.6567733 C12.1044286,15.8310342 11.922703,16.023538 11.7729342,16.2342846 C11.4530093,16.6844722 10.8287091,16.7900666 10.3785237,16.4701387 C10.3670326,16.4619724 10.3557155,16.4535641 10.34458,16.4449194 L7.84228707,14.5023425 C7.42498686,14.178353 7.33168288,13.5856531 7.62928169,13.14914 Z' id='Rectangle' transform='translate(18.638012, 12.738796) rotate(55.000000) translate(-18.638012, -12.738796) '></path>
                                <path d='M6.44023555,1.67472316 L11.603127,11.2629501 C11.734046,11.5060855 11.6430767,11.8093165 11.3999413,11.9402355 C11.3270871,11.9794647 11.245636,12 11.1628914,12 L0.837108579,12 C0.560966204,12 0.337108579,11.7761424 0.337108579,11.5 C0.337108579,11.4172554 0.357643843,11.3358043 0.396873029,11.2629501 L5.55976445,1.67472316 C5.6906835,1.43158778 5.99391453,1.34061847 6.23704991,1.47153753 C6.32320039,1.51792625 6.39384683,1.58857269 6.44023555,1.67472316 Z' id='Triangle' transform='translate(6.000000, 6.428571) rotate(-92.000000) translate(-6.000000, -6.428571) '></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SisProcessingIcon

import getSchoolsByDistrictNcesId, { Result } from '../api/queries/getSchoolsByDistrictNcesId'
import { nces, schools } from './data'
import { UIError } from '@peachjar/ui/dist/api/CodedError'
import wait from './wait'

export default function factory(): getSchoolsByDistrictNcesId {
    return async (districtNcesId: string): Promise<Result> => {
        await wait(1000)
        return {
            existing: schools.slice(0, 2),
            nces: nces.schools,
        }
    }
}

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Deps } from '../dependencies/schema'
import { BulkAddUserParam, BulkAddUserResult } from '../api/commands/bulkAddUser'
import { AddUserParams } from '../api/commands/addUser'
import { UpdateUserParams } from '../api/commands/updateUser'
import { GetUserAndSiteRolesParams } from '../api/queries/getUserAndSiteRoles'
import { GetUserAndSiteRolesPaginatedParams, UserAndSiteRolesPaginated } from '../api/queries/getUserAndSiteRolesPaginated'

import AsyncReqState, { notStarted, reducerFromAsyncThunk } from '@peachjar/ui/dist/api/AsyncReqState'
import UserAndSiteRoles from '../api/UserAndSiteRoles'
import { UsersListFilters, NoFilters } from '../pages/Users/components/UsersListFilters'
import { Roles } from '../api/Role'

type State = {
    bulkAddUser: AsyncReqState<BulkAddUserResult>,
    addUser: AsyncReqState<UserAndSiteRoles>,
    updateUser: AsyncReqState<UserAndSiteRoles>,
    removeUser: AsyncReqState<void>
    users: AsyncReqState<UserAndSiteRoles[]>,
    staffUsers: AsyncReqState<UserAndSiteRolesPaginated>,
    volunteerUsers: AsyncReqState<UserAndSiteRolesPaginated>,
    staffFilters: UsersListFilters,
    volunteerFilters: UsersListFilters,
}

type ThunkContext = {
    state: State,
    extra: Deps,
}

export const bulkAddUser = createAsyncThunk<BulkAddUserResult, BulkAddUserParam, ThunkContext>(
    'usersAndRoles/bulkAddUser',
    async (params, { extra: { bulkAddUser } }) => bulkAddUser(params)
)

export const addUser = createAsyncThunk<UserAndSiteRoles, AddUserParams, ThunkContext>(
    'usersAndRoles/addUser',
    async (params, { extra: { addUser } }) => addUser(params)
)

export const updateUser = createAsyncThunk<UserAndSiteRoles, UpdateUserParams, ThunkContext>(
    'usersAndRoles/updateUser',
    async (params, { extra: { updateUser } }) => updateUser(params)
)

export const removeUser = createAsyncThunk<UserAndSiteRoles, UserAndSiteRoles, ThunkContext>(
    'usersAndRoles/removeUser',
    async (usr, { extra: { removeUser } }) => removeUser(usr)
)

export const getUsersAndRoles = createAsyncThunk<UserAndSiteRoles[], GetUserAndSiteRolesParams, ThunkContext>(
    'usersAndRoles/users',
    (params, { extra: { getUserAndSiteRoles} }) =>
        getUserAndSiteRoles(params)
)
export const getStaffUsersAndRolesPaginated = createAsyncThunk<UserAndSiteRolesPaginated, GetUserAndSiteRolesPaginatedParams, ThunkContext>(
    'usersAndRoles/staffUsers',
    (params, { extra: { getUserAndSiteRolesPaginated } }) =>
        getUserAndSiteRolesPaginated(params)
)
export const getVolunteerUsersAndRolesPaginated = createAsyncThunk<UserAndSiteRolesPaginated, GetUserAndSiteRolesPaginatedParams, ThunkContext>(
    'usersAndRoles/volunteerUsers',
    (params, { extra: { getUserAndSiteRolesPaginated } }) =>
        getUserAndSiteRolesPaginated(params)
)

const slice = createSlice({
    name: 'usersAndRoles',
    initialState: {
        addUser: notStarted(),
        updateUser: notStarted(),
        removeUser: notStarted(),
        users: notStarted(),
        staffUsers: notStarted(),
        volunteerUsers: notStarted(),
        staffFilters: {
            site: -1,
            role: Roles.Default
        },
        volunteerFilters: NoFilters,
    } as State,
    reducers: {
        clearReq(state) {
            state.addUser = notStarted()
            state.updateUser = notStarted()
            state.removeUser = notStarted()
        },
        setStaffFilters: (state, action: PayloadAction<Partial<UsersListFilters>>) => {
            state.staffFilters = {
                ...state.staffFilters,
                ...action.payload
            }
        },
        setVolunteerFilters: (state, action: PayloadAction<Partial<UsersListFilters>>) => {
            state.volunteerFilters = {
                ...state.volunteerFilters,
                ...action.payload
            }
        },
    },
    extraReducers: (builder) => {
        reducerFromAsyncThunk(builder, 'addUser', addUser)
        reducerFromAsyncThunk(builder, 'updateUser', updateUser)
        reducerFromAsyncThunk<State, UserAndSiteRoles>(builder, 'removeUser', removeUser, (state, ur) => {
            const users = (state.users.model || []).filter(u => u.userId !== ur.userId)
            state.users.model = users
        })
        reducerFromAsyncThunk(builder, 'staffUsers', getStaffUsersAndRolesPaginated)
        reducerFromAsyncThunk(builder, 'volunteerUsers', getVolunteerUsersAndRolesPaginated)
    }
})

export const clearReq = slice.actions.clearReq
export const setStaffFilters = slice.actions.setStaffFilters
export const setVolunteerFilters = slice.actions.setVolunteerFilters

export default slice

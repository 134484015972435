import React, { FunctionComponent } from 'react'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'

type Props = {
    filename: string,
}

const FileTooLargeNotification: FunctionComponent<Props> = ({
    filename,
}) => {
    return (
        <InlineNotification
            variant='error'
            message={
                <span>
                    Whoa, the file you uploaded (<strong>{filename}</strong>) is
                    too large to process. We can process files up to a
                    <strong>maximum of 50 MB</strong>. You can separate your
                    parent email list into multiple files to avoid exceeding the
                    file size limit.
                </span>
            }
        />
    )
}

export default FileTooLargeNotification

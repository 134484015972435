import React from 'react'
import CheckedRoute from '@peachjar/ui/dist/lib/components/Router/CheckedRoute'
import Routes from './routes'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { APP_ROUTES } from './config/constants'
import { NotFoundPage } from './pages/Errors/NotFoundPage'

// Pages
import SamplePage from './pages/Index'
import CreateDistrictOrIndySchoolPage from './pages/Workflows/CreateDistrictOrIndySchoolPage'
//// District
import CreateDistrictPage from './pages/District/CreateDistrictPage'
import UpdateDistrictPage from './pages/District/UpdateDistrictPage'
import AddSchoolToDistrictPage from './pages/District/AddSchoolToDistrictPage'
import UpdateDistrictSchoolPage from './pages/District/UpdateSchoolPage'
import DistrictUsersAndRolesPage from './pages/District/DistrictUsersAndRolesPage'
import SchoolUsersAndRolesPage from './pages/District/SchoolUsersAndRolesPage'
import DistrictCommunityPage from './pages/District/DistrictCommunityPage'
import DistrictParentsSettingPage from './pages/District/ParentsSettingPage'
import DistrictMappingSettingPage from './pages/District/MappingSettingPage'
import DistrictStaffSettingPage from './pages/District/DistrictStaffSettingPage'
import SchoolStaffSettingPage from './pages/District/SchoolStaffSettingPage'
//// Independent School
import CreateIndependentSchoolPage from './pages/School/CreateIndependentSchoolPage'
import UpdateIndependentSchoolPage from './pages/School/UpdateIndependentSchoolPage'
import IndependentSchoolUsersAndRolesPage from './pages/School/IndependentSchoolUsersAndRolesPage'
import IndependentSchoolCommunityPage from './pages/School/IndependentSchoolCommunityPage'
import SchoolParentsSettingPage from './pages/School/ParentsSettingPage'
// Search
import SearchPage from './pages/Search'
import ValidateParam from '@peachjar/ui/dist/lib/components/Router/ValidateParam'

const { appBasename } = APP_ROUTES

const App = ({...rest}) => {
  return (
    <>
      {/*<NotificationContainer />*/}
      <Router basename={appBasename}>
        <Switch>
          <Route exact path='/' component={SamplePage} />
          <CheckedRoute
              {...Routes.workflows.newDistrictOrIndySchool}
              component={CreateDistrictOrIndySchoolPage}
          />
          <CheckedRoute
              {...Routes.districts.search}
              component={SearchPage}
          />
          <CheckedRoute
              {...Routes.districts.new}
              component={CreateDistrictPage}
          />
          <Route
              {...Routes.districts.update}
              component={(props) => (
                  <ValidateParam paramSchema={Routes.districts.update.paramSchema}>
                    <UpdateDistrictPage {...props} />
                  </ValidateParam>
              )}
          />
          <CheckedRoute
              {...Routes.districts.users}
              component={DistrictUsersAndRolesPage}
          />
          <CheckedRoute
              {...Routes.districts.staffUsers}
              component={DistrictStaffSettingPage}
          />
          <CheckedRoute
              {...Routes.districts.community}
              component={DistrictCommunityPage}
          />
          <CheckedRoute
              {...Routes.districts.parents}
              component={DistrictParentsSettingPage}
          />
          <CheckedRoute
              {...Routes.districts.mapping}
              component={DistrictMappingSettingPage}
          />
          <CheckedRoute
              {...Routes.districts.schools.new}
              component={AddSchoolToDistrictPage}
          />
          <CheckedRoute
              {...Routes.districts.schools.update}
              component={UpdateDistrictSchoolPage}
          />
          <CheckedRoute
              {...Routes.districts.schools.new}
              component={AddSchoolToDistrictPage}
          />
          <CheckedRoute
              {...Routes.districts.schools.users}
              component={SchoolUsersAndRolesPage}
          />
          <CheckedRoute
              {...Routes.districts.schools.staffUsers}
              component={SchoolStaffSettingPage}
          />
          <CheckedRoute
              {...Routes.schools.search}
              component={SearchPage}
          />
          <CheckedRoute
              {...Routes.schools.new}
              component={CreateIndependentSchoolPage}
          />
          <CheckedRoute
              {...Routes.schools.update}
              component={UpdateIndependentSchoolPage}
          />
          <CheckedRoute
              {...Routes.schools.users}
              component={IndependentSchoolUsersAndRolesPage}
          />
          <CheckedRoute
              {...Routes.schools.community}
              component={IndependentSchoolCommunityPage}
          />
          <CheckedRoute
              {...Routes.schools.parents}
              component={SchoolParentsSettingPage}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </>
  )
}

export default App

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { maxBy } from 'lodash'
import { Box } from '@material-ui/core'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip'
import SwitchError from '@peachjar/ui/dist/lib/components/Errors/SwitchError'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import StaffJobTable from './components/StaffJobTable'
import UploadModeChooser, { Mode } from '../Parents/components/UploadModeChooser'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { getStaffJobsReq } from '../../redux/parentSettingsSelectors'
import { downloadCsvFile, getStaffJobs } from '../../redux/parentSettings'
import SyncStatus from '../Parents/components/SyncStatus'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'

const {
    DarkMuted: { Note: MutedNote },
    Dark: { Paragraph },
    DarkBold: { Paragraph: BoldParagraph }
} = Typo

type Props = {
    hierarchyType: string,
    hierarchyId: number,
}
const InfoMessage = () => (
    <span>
        When updating your CSV file, include the full staff list for each
        school you would like to update. Omitting a staff member for a district/school
        you are updating, will <strong>remove</strong> them from the staff list.
    </span>
)

const StaffPageBody: FunctionComponent<Props> = ({
    hierarchyId,
    hierarchyType,
}) => {

    const dispatch = useDispatch()
    const panic = usePanic()
    const staffJobsReq = getStaffJobsReq()
    const firstLoad = useRef(false)
    const [uploadMode, setUploadMode] = useState<Mode>(Mode.MANUAL)

    const dispatchGetStaffJobs = () => {
        dispatch(getStaffJobs({ hierarchyId, hierarchyType }))
    }

    useEffect(() => {
        dispatchGetStaffJobs()
    }, [])

    useEffect(() => {
        if (staffJobsReq.status === Statuses.loaded && !firstLoad.current) {
            firstLoad.current = true
        }
    }, [staffJobsReq])

    if (staffJobsReq.isLoading() && !firstLoad.current) {
        return <LoadingSpinner />
    }

    if (staffJobsReq.error && !firstLoad.current) {
        return panic(staffJobsReq.error)
    }

    const staffJobs = staffJobsReq.model!

    const lastJob = maxBy(staffJobs, s => s.createdAt)

    return (
        <Sections>
            <Section headline={'Staff List'}>
                <SwitchError error={staffJobsReq.error} />
                <div style={{ marginBottom: '8px' }}>
                    <Paragraph >
                    Use a CSV file to sync your staff list with your Peachjar account. 
                    A staff account will be created for each new email address provided in the uploaded staff list 
                    and they will receive a welcome email from Peachjar.
                    </Paragraph>
                    <Paragraph  style={{ marginTop: '8px'}}>
                    To stay informed on flyers being distributed to parents, staff members are automatically 
                    set to receive new flyer email notifications for their school. 
                    This school ‘following’ setting can be managed in each staff member's Account Information. 
                    </Paragraph>
                    <Paragraph  style={{ marginTop: '8px'}}>
                        View the instructions for the correct CSV file
                        format to ensure your staff list is
                        successfully uploaded and synchronized.
                    </Paragraph>
                </div>
                <div style={{ marginBottom: '24px' }}>
                    <ButtonSecondarySmall
                        onClick={() => {
                            window.open(
                                'https://cdn.peachjar.com/assets/staff_csv_file_instruction.pdf?version=2',
                                '_blank'
                            )
                        }}
                    >
                        View Staff CSV File Instructions (PDF)
                    </ButtonSecondarySmall>
                </div>
                <div style={{ marginBottom: '24px'}}>
                    <InlineNotification variant='warning' message={<InfoMessage/>} />
                </div>
                <InlineNotification
                    variant='warning'
                    message={
                        <span>
                        Due to the importance staff with roles, 
                        they must be removed individually in the Users & Roles Settings.
                        </span>
                    }
                />
                <Paragraph  style={{ marginTop: '24px' }}>
                <InlineNotification
                    variant='info'
                    message={
                        <span>
                        To add a staff member to multiple schools, enter them on separate rows with the appropriate SIS ID and roles for each site.
                        </span>
                    }
                />
                </Paragraph>
            </Section>
            <Section headline={'Staff List Sync'}>
                <UploadModeChooser
                    mode={uploadMode}
                    onModeChange={setUploadMode}
                    lastJob={lastJob}
                    hierarchyId={hierarchyId}
                    hierarchyType={hierarchyType}
                    onNeedsRefreshing={dispatchGetStaffJobs}
                    isStaff={ true }
                />
            </Section>
            {lastJob && <Section>
                <BoldParagraph  style={{ marginBottom: '8px' }}>Last File Uploaded</BoldParagraph>
                <ButtonSecondarySmall onClick={() =>
                    dispatch(downloadCsvFile({
                        hierarchyId: lastJob?.academic.groupId,
                        hierarchyType: lastJob?.academic.groupType,
                        jobId: lastJob?.jobUuid,
                        isStaff: true
                    }))
                }>
                    Download Last Staff CSV File
                </ButtonSecondarySmall>
            </Section>}
            <Section>
                <BoldParagraph  style={{ marginBottom: '8px' }}>Sync Status</BoldParagraph>
                <SyncStatus
                    lastJob={lastJob}
                    onContinuePressed={dispatchGetStaffJobs}
                    onSwitchManualUpload={() => setUploadMode(Mode.MANUAL)}
                    isStaff={ true }
                />
            </Section>
            <Section>
                <Box display='flex' alignItems='center' style={{ marginBottom: '8px' }}>
                    <BoldParagraph >
                        Sync Logs
                    </BoldParagraph>
                    <Tooltip title={SYNC_LOGS_TOOLTIP as unknown as string} />
                </Box>
                {staffJobsReq.isLoading()
                    ? <LoadingSpinner />
                    : <>
                        <StaffJobTable staffJobs={staffJobs} />
                        <MutedNote >The sync logs display the last 50 file sync jobs.</MutedNote>
                    </>
                }
            </Section>
        </Sections>
    )
}

const SYNC_LOGS_TOOLTIP = <>
    <strong>New/Existing/Removed</strong><br/>
    The number of emails per site in the CSV that were added, already existed, or removed from Peachjar’s system<br/>
    <br/>
    <strong>Duplicate</strong><br/>
    The number of emails that are repeated for staff in the CSV. Emails for staff who are associated with multiple sites (SIS ID) are not considered duplicates.<br/>
    <br/>
    <strong>Total</strong><br/>
    The total number of emails per site present in the CSV<br/>
</>

export default StaffPageBody

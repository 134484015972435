import { GradeLevels } from './GradeLevels'
import { District } from './District'
import { Address } from './Address'

export enum TransitionalGroupTypes {
    School = 'school',
    Staff = 'staff',
    ParentGroup = 'parent-group',
    SchoolStaff = 'school-staff',
    DistrictStaff = 'district-staff'
}

export type School = {
    id: string,
    schoolId: number,
    name: string,
    active: boolean,
    invisible: boolean
    live: boolean
    treeCounter: boolean,
    sisId: string | null,
    ncesId: string | null,
    address: Address | null,
    enrollmentCount: number | null,
    lowGrade: GradeLevels | null,
    highGrade: GradeLevels | null,
    startAt: Date | null,
    generalDisclaimer: string | null,
    // If true, the school is registered/managed outside of a district
    isIndependent: boolean,
    transitionalGroupType: TransitionalGroupTypes,
    // System Managed Props
    // Time Stamps
    createdAt: Date,
    createdBy: string,
    updatedAt: Date,
    updatedBy: string,
    launchedAt: Date | null,
    launchedBy: string | null,
    // District - present only if a nested retrieval occurred.
    district?: District,
}

export type SchoolMatcher = (school: School) => boolean

export function canGoLive(districtStartAt?: Date): SchoolMatcher {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
    const tomorrow = new Date()
    tomorrow.setTime(today.getTime() + 24 * 60 * 60 * 1000)
    return (school: School): boolean => {
        const startAt = school.startAt || districtStartAt
        if (!startAt) {
            return false
        }
        if (!school.active) {
            return false
        }
        if (school.live) {
            return false
        }
        return startAt < tomorrow
    }
}


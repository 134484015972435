/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux'
import { RootState } from './index'
import { School } from '../api/School'

import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'

export const createSchoolParams = () => {
    return useSelector<RootState, Partial<School> | null>(state => state.schools.createParams)
}

export const createSchoolReq = () => {
    return useSelector<RootState, AsyncReqState<School>>(state => state.schools.createSchool)
}

export const getSchoolReq = () => {
    return useSelector<RootState, AsyncReqState<School>>(state => state.schools.school)
}

export const updateSchoolInfoReq = () => {
    return useSelector<RootState, AsyncReqState<School>>(state => state.schools.updateSchoolInfo)
}

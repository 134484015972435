import { LoggerApi } from '@peachjar/kernel-sanders'
import { isBoolean } from 'lodash'

import ApolloClient from 'apollo-boost'

import findAcademicGroupByName, { Params, Results } from '../../api/queries/findAcademicGroupByName'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
    logger: LoggerApi,
}

const FIND_BY_NAME_QUERY = gql`
    query findAcademicGroupsByName($input: V2_FindAcademicGroupsByNameInput) {
        results: findAcademicGroupsByName(input: $input) {
            existing {
                name
                city
                state
                initial {
                    ...on V2_District {
                        id
                        ncesId
                        districtId
                    }
                    ...on V2_School {
                        id
                        ncesId
                        schoolId
                    }
                }
            }
            nces {
                name
                city
                state
                initial {
                    ...on V2_District {
                        id
                        name
                        ncesId
                        address {
                            address
                            googlePlaceId
                        }
                    }
                    ...on V2_School {
                        id
                        name
                        ncesId
                        address {
                            address
                            googlePlaceId
                        }
                        lowGrade
                        highGrade
                        enrollmentCount
                    }
                }
            }
        }
    }
`

type QueryResult = {
    results: Results
}

export default function factory({ apolloClient, logger }: Deps): findAcademicGroupByName {
    return async (params: Params): Promise<Results> => {

        if (params.nameMatch === '') {
            return {
                existing: [],
                nces: [],
            }
        }

        const input: Params = {
            nameMatch: params.nameMatch,
            showOnlyAvailableNCES: isBoolean(params.showOnlyAvailableNCES) ? params.showOnlyAvailableNCES : true,
            // Normalize to school from 'independentSchool'
            entityType: params.entityType === 'district' ? 'district' : 'school',
            limit: params.limit || 5,
        }

        try {

            const result = await apolloClient.query<QueryResult>({
                query: FIND_BY_NAME_QUERY,
                fetchPolicy: 'no-cache',
                variables: { input },
            })

            return result.data.results

        } catch (error) {

            const mappedError = mapError(error)

            logger.captureWithScope(error, { input })

            throw mappedError
        }
    }
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import UsersPageBody from '../Users/components/UsersPageBody'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import BaseDistrictPage from './components/BaseDistrictPage'
import UserAndSiteRoles from '../../api/UserAndSiteRoles'

import { useParams } from 'react-router-dom'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { useDispatch } from 'react-redux'
import { getDistrictReq } from '../../redux/districtsSelectors'
import { getApprovalSettingsReq, getIsSettingsRefresh } from '../../redux/approvalSettingsSelectors';
import { getDistrict } from '../../redux/districts'
import { getApprovalSettings } from '../../redux/approvalSettings'
import { ApprovalSettings } from '../../api/ApprovalSettings'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import { getAuthUsersReq } from '../../redux/authUserSelectors'

type Props = {}

const DistrictAdminStaffNote = () => (
  <>
    Staff can be assigned admin, approver, and uploader roles at district and school sites. 
    At least <strong>one admin</strong> is required at the district. <strong>One or two approvers</strong> are required at the district as well, 
    depending on your flyer approval workflow in Approval Settings.
  </>
)

export const SUBTEXT = "Manage your staff and volunteers at your district and school sites. Each user entry in the staff and volunteer lists is associated with a district or school site and can have the appropriate role(s) for that site. Therefore, a staff or volunteer user can be associated with more than one site with the appropriate role(s) assigned for each site."

const DistrictUsersPage: FunctionComponent<Props> = () => {
  const panic = usePanic()
  const dispatch = useDispatch()

  const { districtId: districtIdParam } = useParams<{ districtId: string }>()
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isRefreshing, setNeedsRefreshing] = useState(false)
  const [users, setUsers] = useState<UserAndSiteRoles[]>([])
  const [settings, setApprovalSettings] = useState<ApprovalSettings | null>(
    null
  )

  const districtId = parseInt(districtIdParam, 10)

  
  const districtReq = getDistrictReq()
  const approvalSettingsReq = getApprovalSettingsReq()
  const isSettingsRefresh = getIsSettingsRefresh()
  const authUser = getAuthUsersReq()
  const isPlatformUser = authUser.loginType === 'platform' 

  const refresh = () => {
    fetchApprovalSettings()
  }

  const fetchApprovalSettings = () => {
    dispatch(
      getApprovalSettings(
        isPlatformUser 
        ? {
          hierarchyId: -1,
          hierarchyType: ''
        }
        : {
          hierarchyId: districtId,
          hierarchyType: 'district',
        })
    )
  }

  useEffect(() => {
    refresh()
    dispatch(getDistrict(districtId))
  }, [])

  useEffect(() => {
    if (!approvalSettingsReq.isLoading()) {
      setNeedsRefreshing(false)
      if (isFirstLoad) {
        setIsFirstLoad(false)
      }
    }
  }, [approvalSettingsReq, settings])

 

  useEffect(() => {
    if (approvalSettingsReq.status === Statuses.loaded) {
      setApprovalSettings(approvalSettingsReq.model!)
    }
  }, [approvalSettingsReq.model])

  useEffect(() => {
    if (
      isRefreshing &&
      !approvalSettingsReq.isLoading()
    ) {
      refresh()
    }
  }, [isRefreshing])
  
  if (
    (
      districtReq.isLoading() ||
      approvalSettingsReq.isLoading()) &&
    !isRefreshing &&
    !isSettingsRefresh
  ) {
    return <LoadingSpinner />
  }

  

  if (districtReq.error) {
    return panic(districtReq.error)
  }

  const district = districtReq.model!

  return (
    <BaseDistrictPage
      title="User & Role Settings"
      district={district}
      subText={SUBTEXT}
      tabIndex={1}
    >
      <UsersPageBody
        subheading="District & School Staff"
        primary={district}
        sites={district.schools}
        districtId = {district.districtId}
        districtName = {district.name}
        staffNote={<DistrictAdminStaffNote />}
        approvalSettings={settings!}
        isRefreshing={isRefreshing}
        onNeedsRefreshing={() => {
          setNeedsRefreshing(true)
        }}
        academicGroupType="district"
        academicGroupId={districtId}
      />
    </BaseDistrictPage>
  )
}

export default DistrictUsersPage

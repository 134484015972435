import { isNumber } from 'lodash'
import { users } from './data'
import { UIError } from '@peachjar/ui/dist/api/CodedError'

import UserAndSiteRoles from '../api/UserAndSiteRoles'

import removeUser from '../api/commands/removeUser'
import wait from './wait'

export default function factory(): removeUser {
    return async (ur: UserAndSiteRoles): Promise<UserAndSiteRoles> => {

        await wait(1000)

        const index = users.findIndex(u => {
            return (
                u.userId === ur.userId &&
                u.site.type === ur.site.type &&
                u.site.id === ur.site.id
            )
        })

        if (isNumber(index)) {

            const user = users[index]

            // Mutate the original array
            users.splice(index, 1)

            return user
        } else {
            throw new UIError('request.resource.not_found', 'User or site not found')
        }
    }
}

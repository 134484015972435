import { padStart, toString } from 'lodash'
import { School, TransitionalGroupTypes } from './api/School';

export function truncate(str: string, maxSize: number = 24): string {
    if (str.length > maxSize) {
        return str.slice(0, maxSize - 3) + '...'
    }
    return str
}

export function formatDate(dtg: Date): string {
    return [
        padStart(toString(dtg.getMonth() + 1), 2, '0'),
        padStart(toString(dtg.getDate()), 2, '0'),
        toString(dtg.getFullYear()),
    ].join('/')
}

export const SCHOOL_STAFF_REGEX = /district\s(\&|and)\sschool\sstaff/i


export const isParent = ( school: School ) => {
    return !school.name.match(SCHOOL_STAFF_REGEX) &&
      [TransitionalGroupTypes.School, 
        TransitionalGroupTypes.ParentGroup].includes(school.transitionalGroupType)
      
  }
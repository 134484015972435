import { omit } from 'lodash'

import ApolloClient from 'apollo-boost'

import saveCommunitySettings from '../../api/commands/saveCommunitySettings'
import gql from 'graphql-tag'
import mapError from './mapError'
import { CommunitySettings } from '../../api/CommunitySettings'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const MUTATION = gql`
    mutation updateDistrict($input: CommunitySettingsInput!) {
        settings: saveCommunitySettings(input: $input) {
            hierarchyType
            hierarchyId
            flyerDisclaimer
            guidelinesUrl
            allowOrgsToDistributeToStaff
        }
    }
`

type GQLResult = {
    settings: CommunitySettings,
}

export default function factory({ apolloClient }: Deps): saveCommunitySettings {
    return async (input) => {

        try {

            const result = await apolloClient.mutate<GQLResult>({
                mutation: MUTATION,
                variables: { input }
            })

            return result.data?.settings!

        } catch (error) {

            console.log(error)

            throw mapError(error)
        }
    }
}

import ApolloClient from 'apollo-boost'

import resolveAddresses, { MaybeAddress } from '../../api/queries/resolveAddresses'
import gql from 'graphql-tag'
import mapError from './mapError'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    query resolveAddresses($freeTextPlaceQueries: [String]!) {
        addresses: findPlaces(freeTextPlaceQueries: $freeTextPlaceQueries) {
            googlePlaceId: placeId
            address: formattedAddress
        }
    }
`

type QueryResult = {
    addresses: MaybeAddress[]
}

export default function factory({ apolloClient }: Deps): resolveAddresses {
    return async (freeTextPlaceQueries) => {
        try {

            const result = await apolloClient.query<QueryResult>({
                query: QUERY,
                fetchPolicy: 'network-only',
                variables: { freeTextPlaceQueries },
            })

            return result.data.addresses

        } catch (error) {

            throw mapError(error)
        }
    }
}

import { DrawerComponent } from '@peachjar/ui/dist/lib/hooks/useAppDrawer'
import AddEditUserDrawer from './AddEditUserDrawer'
import BulkAddUserDrawer from './BulkAddUserDrawer'
import UserAndSiteRoles from '../../../api/UserAndSiteRoles'

export const BULK_ADD_STAFF_DRAWER = 'bulkAddStaff'
export const ADD_STAFF_DRAWER = 'addStaff'
export const EDIT_STAFF_DRAWER = 'editStaff'
export const BULK_ADD_VOLUNTEER_DRAWER = 'bulkAddVolunteer'
export const ADD_VOLUNTEER_DRAWER = 'addVolunteer'
export const EDIT_VOLUNTEER_DRAWER = 'editVolunteer'

// Fuck TypeScript.  Need to fix PJUI not allowing
// object with Toggle to be recognized as DrawerComponent
export const drawers: DrawerComponent[] = [
    {
        name: BULK_ADD_STAFF_DRAWER,
        drawerTitle: 'Bulk Manage Staff',
        variant: 'small',
        // @ts-ignore
        component: BulkAddUserDrawer,
    },
    {
        name: ADD_STAFF_DRAWER,
        drawerTitle: 'Add Staff',
        variant: 'small',
        // @ts-ignore
        component: AddEditUserDrawer,
    },
    {
        name: EDIT_STAFF_DRAWER,
        drawerTitle: 'Edit Staff',
        variant: 'small',
        // @ts-ignore
        component: AddEditUserDrawer,
    },
    {
        name: BULK_ADD_VOLUNTEER_DRAWER,
        drawerTitle: 'Bulk Add Volunteer',
        variant: 'small',
        // @ts-ignore
        component: BulkAddUserDrawer,
    },
    {
        name: ADD_VOLUNTEER_DRAWER,
        drawerTitle: 'Add Volunteer',
        variant: 'small',
        // @ts-ignore
        component: AddEditUserDrawer,
    },
    {
        name: EDIT_VOLUNTEER_DRAWER,
        drawerTitle: 'Edit Volunteer',
        variant: 'small',
        // @ts-ignore
        component: AddEditUserDrawer,
    },
]

export function mapToDrawerId(isVolunteer: boolean, user: UserAndSiteRoles | null): string {
    if (!user) {
        return isVolunteer ? ADD_VOLUNTEER_DRAWER : ADD_STAFF_DRAWER
    }
    return user.isVolunteer ? EDIT_VOLUNTEER_DRAWER : EDIT_STAFF_DRAWER
}
export function mapToBulkDrawerId(isVolunteer: boolean): string {
    return isVolunteer ? BULK_ADD_VOLUNTEER_DRAWER : BULK_ADD_STAFF_DRAWER
}

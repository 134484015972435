import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Deps } from '../dependencies/schema'
import { CommunitySettings } from '../api/CommunitySettings'

import AsyncReqState, { notStarted, reducerFromAsyncThunk } from '@peachjar/ui/dist/api/AsyncReqState'

type State = {
    settings: AsyncReqState<CommunitySettings>,
    saveSettings: AsyncReqState<CommunitySettings>,
}

type ThunkContext = {
    state: State,
    extra: Deps,
}

export const saveCommunitySettings = createAsyncThunk<CommunitySettings, CommunitySettings, ThunkContext>(
    'community/saveSettings',
    async (params, { extra: { saveCommunitySettings } }) =>
        saveCommunitySettings(params)
)

type Hierarchy = { hierarchyType: string, hierarchyId: number }

export const getCommunitySettings = createAsyncThunk<CommunitySettings, Hierarchy, ThunkContext>(
    'community/settings',
    (params, { extra: { getCommunitySettings} }) =>
        getCommunitySettings(params)
)

const slice = createSlice({
    name: 'community',
    initialState: {
        settings: notStarted(),
        saveSettings: notStarted(),
    } as State,
    reducers: {
        clearReq(state) {
            state.settings = notStarted()
            state.saveSettings = notStarted()
        },
        clearSaveReq(state) {
            state.saveSettings = notStarted()
        }
    },
    extraReducers: (builder) => {
        reducerFromAsyncThunk<State, CommunitySettings>(
            builder,
            'saveSettings',
            saveCommunitySettings,
            (state, settings) => {
                state.settings.model = settings
        })
        reducerFromAsyncThunk(builder, 'settings', getCommunitySettings)
    }
})

export const clearReq = slice.actions.clearReq
export const clearSaveReq = slice.actions.clearSaveReq

export default slice

import React, { FunctionComponent } from 'react'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'

const { Dark: { Note } } = Typo

const NewUserMessage: FunctionComponent<{}> = () => {
    return (
        <div>
            <Note>
        To stay informed, staff members with or without a role are automatically
        set to receive new flyer email notifications for their school. This school ‘following’ setting can be managed in their Account Information.
            </Note>
        </div>
        // <InputNotification // <-- InputNotification is deprecated and should be InlineNotification if this code is used in the future
        //     variant='info'
        //     fullWidth={true}
        //     message={
        //         <span>
        //             To stay informed, staff members with or without a role are automatically
        //             set to receive new flyer email notifications for their school. This school ‘following’ setting can be managed in their Account Information.
        //         </span>
        //     }
        // />
    )
}

export default NewUserMessage

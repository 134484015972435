import { Config } from '../config/'
import { asFunction, asValue, NameAndRegistrationPair } from 'awilix'
import { Deps } from './schema'

import Axios from 'axios'

import redirect from './redirect'
import getAuthenticatedUser from './graphql/getAuthenticatedUser'
import getDistrict from './graphql/getDistrict'
import createDistrict from './graphql/createDistrict'
import updateDistrictInfo from './graphql/updateDistrictInfo'
import getSchool from './graphql/getSchool'
import createSchool from './graphql/createSchool'
import updateSchoolInfo from './graphql/updateSchoolInfo'
import bulkAddUser from './graphql/bulkAddUser'
import addUser from './graphql/addUser'
import updateUser from './graphql/updateUser'
import removeUser from './graphql/removeUser'
import getUserAndSiteRoles from './graphql/getUserAndSiteRoles'
import getUserAndSiteRolesPaginated from './graphql/getUserAndSiteRolesPaginated'
import findAcademicGroupByName from './graphql/findAcademicGroupByName'
import getSisJobs from './graphql/getSisJobs'
import getStaffJobs from './graphql/getStaffJobs'
import getSisCSVUploadLink from './graphql/getSisCSVUploadLink'
import uploadSisCSVToCDN from './http/uploadSisCSVToCDN'
import createSisJob from './graphql/createSisJob'
import createStaffJob from './graphql/createStaffJob'
import getCommunitySettings from './graphql/getCommunitySettings'
import saveCommunitySettings from './graphql/saveCommunitySettings'
import getApprovalSettings from './graphql/getApprovalSettings'
import createApolloClient from './createApolloClient'
import downloadFTPCredentials from './downloaders/downloadFTPCredentials'
import downloadSisCSVFile from './downloaders/downloadSisCSVFile'
import downloadSisChangeCSVFile from './downloaders/downloadSisChangeCSVFile'
import downloadSisErrorFile from './downloaders/downloadSisErrorFile'
import downloadUsersCsv from './downloaders/downloadUsersCsv'
import continueSisJob from './graphql/continueSisJob'
import getSisJobErrors from './graphql/getSisJobErrors'
import launchGroup from './graphql/launchGroup'
import getSchoolsByDistrictNcesId from './graphql/getSchoolsByDistrictNcesId'
import resolveAddresses from './graphql/resolveAddresses'
import backupLogger from './backupLogger'
import saveCsvMapping from './graphql/saveCsvMapping'
import getSisCsvMapping from './graphql/getSisCsvMapping'

declare global {
  interface Window {
    __PJ__: any
  }
}

export default function dependenciesFactory(
  config: Config
): NameAndRegistrationPair<Deps> {
  return {
    config: asValue(config),
    logger: asValue(window?.__PJ__?.logger || backupLogger),
    eventBroker: asValue(window?.__PJ__?.eventBroker),
    apolloClient: asFunction(({ config, logger }: Deps) =>
      createApolloClient(config.REACT_APP_GRAPHQL_BFF_URI, logger)
    ),
    portalBffClient: asFunction(() => {
      return Axios.create({
        baseURL: `https://portal-bff.${config.DOMAIN}`,
        withCredentials: true,
      })
    }),
    redirect: asFunction(redirect),
    // Network Calls
    fetchAuthUser: asFunction(getAuthenticatedUser),
    // Districts
    getDistrict: asFunction(getDistrict),
    createDistrict: asFunction(createDistrict),
    updateDistrictInfo: asFunction(updateDistrictInfo),
    // Schools
    getSchool: asFunction(getSchool),
    createSchool: asFunction(createSchool),
    updateSchoolInfo: asFunction(updateSchoolInfo),
    getSchoolsByDistrictNcesId: asFunction(getSchoolsByDistrictNcesId),
    // Common for Groups
    launchGroup: asFunction(launchGroup),
    // Users
    bulkAddUser: asFunction(bulkAddUser),
    addUser: asFunction(addUser),
    updateUser: asFunction(updateUser),
    removeUser: asFunction(removeUser),
    getUserAndSiteRoles: asFunction(getUserAndSiteRoles),
    getUserAndSiteRolesPaginated: asFunction(getUserAndSiteRolesPaginated),
    findAcademicGroupByName: asFunction(findAcademicGroupByName),
    // Parents
    getSisJobs: asFunction(getSisJobs),
    getStaffJobs: asFunction(getStaffJobs),
    getSisCSVUploadLink: asFunction(getSisCSVUploadLink),
    uploadSisCSVToCDN: asFunction(uploadSisCSVToCDN),
    createSisJob: asFunction(createSisJob),
    createStaffJob: asFunction(createStaffJob),
    downloadFTPCredentials: asFunction(downloadFTPCredentials),
    downloadSisCSVFile: asFunction(downloadSisCSVFile),
    downloadSisChangeCSVFile: asFunction(downloadSisChangeCSVFile),
    downloadSisErrorFile: asFunction(downloadSisErrorFile),
    downloadUsersCsv: asFunction(downloadUsersCsv),
    continueSisJob: asFunction(continueSisJob),
    getSisJobErrors: asFunction(getSisJobErrors),
    saveCsvMapping: asFunction(saveCsvMapping),
    getSisCsvMapping: asFunction(getSisCsvMapping),
    // Community
    getCommunitySettings: asFunction(getCommunitySettings),
    saveCommunitySettings: asFunction(saveCommunitySettings),
    // Utils
    resolveAddresses: asFunction(resolveAddresses),
    // Approval Settings
    getApprovalSettings: asFunction(getApprovalSettings),
  }
}

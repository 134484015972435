import React, { FunctionComponent, useEffect } from 'react'
import ParentsPageBody from '../Parents/ParentsPageBody'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import BaseSchoolPage from './components/BaseIndependentSchoolPage'
import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'

import { useParams } from 'react-router-dom'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { useDispatch, useSelector } from 'react-redux'
import { getSchool } from '../../redux/schools'
import { RootState } from '../../redux'
import { School } from '../../api/School'

type Props = {}

const ParentsSettingPage: FunctionComponent<Props> = () => {

    const panic = usePanic()
    const dispatch = useDispatch()

    const { schoolId: schoolIdParam } = useParams<{ schoolId: string }>()

    const schoolId = parseInt(schoolIdParam, 10)

    const schoolReq = useSelector<RootState, AsyncReqState<School>>(state => state.schools.school)

    useEffect(() => {
        dispatch(getSchool(schoolId))
    }, [])


    if (schoolReq.isLoading()) {
        return <LoadingSpinner />
    }

    if (schoolReq.error) {
        return panic(schoolReq.error)
    }

    const school = schoolReq.model!

    return (
        <BaseSchoolPage
            title='Parent Settings'
            school={school}
            tabIndex={4}
        >
            <ParentsPageBody
              hierarchyId={schoolId}
              hierarchyType='school'
            />
        </BaseSchoolPage>
    )
}

export default ParentsSettingPage

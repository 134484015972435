import { District } from '../../api/District'
import { Address } from '../../api/Address'
import { School, TransitionalGroupTypes } from '../../api/School'
import { GradeLevels } from '../../api/GradeLevels'
import { SisJob } from '../../api/SisJob'
import { isString, isNumber, isDate } from 'lodash'
import { StaffJob } from '../../api/StaffJob';

export type GQLDistrict = {
    id: string,
    districtId: number,
    name: string,
    active: boolean,
    visible: boolean,
    live: boolean,
    treeCounter: boolean,
    sisId: string,
    ncesId: string,
    address: Address | null,
    generalDisclaimer?: string,
    startAt?: Date,
    createdAt?: Date,
    createdBy?: string,
    updatedAt?: Date,
    updatedBy?: string,
    launchedAt?: Date | null,
    launchedBy?: string | null,
    schools?: GQLSchool[]
}

export type GQLSchool = {
    id: string,
    schoolId: number,
    name: string,
    active: boolean,
    visible: boolean,
    live: boolean,
    treeCounter: boolean,
    sisId: string | null,
    ncesId: string | null,
    address: Address | null,
    enrollmentCount: number | null,
    lowGrade: GradeLevels | null,
    highGrade: GradeLevels | null,
    startAt: Date | null,
    generalDisclaimer: string | null,
    // If true, the school is registered/managed outside of a district
    isIndependent?: boolean,
    transitionalGroupType?: TransitionalGroupTypes,
    // System Managed Props
    // Time Stamps
    createdAt?: Date,
    createdBy?: string,
    updatedAt?: Date,
    updatedBy?: string,
    launchedAt?: Date | null,
    launchedBy?: string | null,
    // District - present only if a nested retrieval occurred.
    district?: GQLDistrict,
}

export function normalizeAddress(address: Address | undefined | null): Address | null {
    if (address) {
        if (!address.address || !address.googlePlaceId) {
            return null
        }
        return {
            address: address.address,
            googlePlaceId: address.googlePlaceId,
        }
    }
    return null
}

export function normalizeDistrictForUI(district: GQLDistrict): District {
    return Object.assign({}, district, {
        address: normalizeAddress(district.address),
        visible: undefined,
        invisible: !district.visible,
        createdAt: convertToDate(district.createdAt),
        updatedAt: convertToDate(district.updatedAt),
        launchedAt: convertToDate(district.launchedAt),
        startAt: convertToDate(district.startAt),
        schools: district.schools ? district.schools.map(normalizeSchoolForUI) : []
    }) as any as District
}

export function normalizeDistrictForGQL(district: District): GQLDistrict {
    return Object.assign({}, district, {
        id: undefined,
        address: normalizeAddress(district.address),
        visible: !district.invisible,
        ncesId: district.ncesId || undefined,
        startAt: district.startAt || undefined,
        invisible: undefined,
        schools: undefined,
        createdAt: undefined,
        createdBy: undefined,
        updatedAt: undefined,
        updatedBy: undefined,
        __typename: undefined,
    })
}

export function normalizeSchoolForUI(school: GQLSchool): School {
    return Object.assign({}, school, {
        address: normalizeAddress(school.address),
        visible: undefined,
        invisible: !school.visible,
        createdAt: convertToDate(school.createdAt),
        updatedAt: convertToDate(school.updatedAt),
        launchedAt: convertToDate(school.launchedAt),
        startAt: convertToDate(school.startAt),
        district: school.district ? normalizeDistrictForUI(school.district) : null,
    }) as any as School
}

export function normalizeSchoolForGQL(school: School): GQLSchool {
    return Object.assign({}, school, {
        id: undefined,
        address: normalizeAddress(school.address),
        visible: !school.invisible,
        enrollmentCount: parseInt(school.enrollmentCount + '', 10) || 1,
        ncesId: school.ncesId || undefined,
        startAt: school.startAt || undefined,
        live: undefined,
        invisible: undefined,
        district: undefined,
        createdAt: undefined,
        createdBy: undefined,
        updatedAt: undefined,
        updatedBy: undefined,
        launchedAt: undefined,
        launchedBy: undefined,
        isIndependent: undefined,
        __typename: undefined,
    })
}

function convertToDate(maybeDateLike: Date | string | number | undefined | null): Date | null {
    if (isString(maybeDateLike)) {
        return new Date(maybeDateLike)
    }
    if (isDate(maybeDateLike)) {
        return maybeDateLike
    }
    if (isNumber(maybeDateLike)) {
        return unixToDate(maybeDateLike as number)
    }
    return null
}

function unixToDate(unix: number): Date {
    const d = new Date()
    d.setTime(unix)
    return d
}

export type GQLSisJob = Omit<SisJob, 'createdAt'> & {
    createdAt: number,
}

export type GQLStaffJob = Omit<StaffJob, 'createdAt'> & {
    createdAt: number,
}

export function normalizeSisJobForUI(job: GQLSisJob): SisJob {
    return {
        ...job,
        createdAt: unixToDate(job.createdAt),
    }
}

export function normalizeStaffJobForUI(job: GQLStaffJob): StaffJob {
    return {
        ...job,
        createdAt: unixToDate(job.createdAt)
    }
}

import { LoggerApi } from '@peachjar/kernel-sanders'
import { CommunitySettings } from '../../api/CommunitySettings'

import ApolloClient from 'apollo-boost'

import getCommunitySettings from '../../api/queries/getCommunitySettings'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
    logger: LoggerApi,
}

const QUERY = gql`
    query settings($hierarchyId: Int!, $hierarchyType: String!) {
        settings: communitySettings(hierarchyId: $hierarchyId, hierarchyType: $hierarchyType) {
            hierarchyType
            hierarchyId
            flyerDisclaimer
            guidelinesUrl
            allowOrgsToDistributeToStaff
        }
    }
`

type QueryResult = {
    settings: CommunitySettings
}

export default function factory({ apolloClient, logger }: Deps): getCommunitySettings {
    return async (hierarchy) => {

        try {

            const result = await apolloClient.query<QueryResult>({
                query: QUERY,
                fetchPolicy: 'network-only',
                variables: hierarchy,
            })

            return result.data.settings

        } catch (error) {

            const mappedError = mapError(error)

            logger.captureWithScope(error, { hierarchy })

            throw mappedError
        }
    }
}

import { users } from './data'
import { UIError } from '@peachjar/ui/dist/api/CodedError'
import { UpdateUserParams } from '../api/commands/updateUser'

import UserAndSiteRoles from '../api/UserAndSiteRoles'

import updateUser from '../api/commands/updateUser'
import wait from './wait'

export default function factory(): updateUser {
    return async (params: UpdateUserParams): Promise<UserAndSiteRoles> => {
        await wait(1000)

        const ur = params.previous

        const targetUser = users.find(u => (
            u.userId === ur.userId
            && u.site.id === ur.site!.id
            && u.isVolunteer === ur.isVolunteer
        ))

        if (!targetUser) {
            throw new UIError('request.resource.not_found', 'User or site not found')
        }

        Object.assign(targetUser, params.updates)

        return targetUser
    }
}

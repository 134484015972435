import React, { FunctionComponent, useEffect } from 'react'
import ParentsPageBody from '../Mapping/ParentsPageBody'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import BaseDistrictPage from './components/BaseDistrictPage'

import { useParams } from 'react-router-dom'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { useDispatch } from 'react-redux'
import { getDistrictReq } from '../../redux/districtsSelectors'
import { getDistrict } from '../../redux/districts'

type Props = {}

const ParentsSettingPage: FunctionComponent<Props> = () => {

    const panic = usePanic()
    const dispatch = useDispatch()

    const { districtId: districtIdParam } = useParams<{ districtId: string }>()

    const districtId = parseInt(districtIdParam, 10)

    const districtReq = getDistrictReq()

    useEffect(() => {
        dispatch(getDistrict(districtId))
    }, [])


    if (districtReq.isLoading()) {
        return <LoadingSpinner />
    }

    if (districtReq.error) {
        return panic(districtReq.error)
    }

    const district = districtReq.model!

    return (
        <BaseDistrictPage
            title='Parent List Mapping'
            district={district}
            tabIndex={5}
            showCrumbBackToParentSettings={true}
        >
          <ParentsPageBody
            hierarchyType='district'
            hierarchyId={districtId}
          />
        </BaseDistrictPage>
    )
}

export default ParentsSettingPage

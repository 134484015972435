import { School } from './School'
import { District } from './District'
import { has } from 'lodash'

export type Site = School | District

export type SiteInfo = {
    description?: string
    id: number
    type: 'school' | 'district'
    name: string
}

export function toSiteInfo(site: Site): SiteInfo {
    if (has(site, 'districtId')) {
        const district = site as District
        return {
            id: district.districtId,
            type: 'district',
            name: district.name,
        }
    }
    const school = site as School
    return {
        id: school.schoolId,
        type: 'school',
        name: school.name,
    }
}

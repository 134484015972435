import * as yup from 'yup'

import React, { FunctionComponent } from 'react'
import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInputWrapper'

import { ButtonFlatLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

import useFormBuilder, { yupVal } from '@peachjar/ui/dist/lib/hooks/useFormBuilder'
import colors from '@peachjar/ui/dist/lib/styles/colors'

type Props = {
    initial: string,
    isOpen: boolean,
    onSubmit: (value: string) => void,
    cancel: () => void,
}

type FormModel = {
    url: string,
}

const CustomApprovalGuidelinesModal: FunctionComponent<Props> = ({
    initial,
    isOpen,
    onSubmit,
    cancel,
}) => {

    const formBindings = useFormBuilder<FormModel>({ url: initial }, [
        {
            path: 'url',
            initial,
            validate: yupVal(yup.string()
                .url('Must be a valid URL.')
                .required('You missed this field.')
            )
        },
    ])

    return (
        <Modal title='' open={isOpen} close={() => cancel()}>
            <ModalHeader>Custom Flyer Approval Guidelines</ModalHeader>
            <DialogContent style={{ padding: '16px' }}>
                <DialogContentText style={{ color: colors.prussian }}>
                    Enter the URL of the approval guidelines page on your website you would like to use in place of Peachjar's standard approval guidelines.
                </DialogContentText>
                <Grid container>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <TextInput
                            placeholder=''
                            data-testid='url-input'
                            label='Flyer Approval Guidelines URL'
                            fullWidth={true}
                            {...formBindings.bindings.url}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: '16px 32px' }}>
                <ButtonFlatLarge onClick={() => cancel()} color='primary'>
                    Cancel
                </ButtonFlatLarge>
                <ButtonFlatLarge
                    disabled={!(formBindings.isValid && formBindings.isDirty)}
                    onClick={() => onSubmit(formBindings.value.url)} color='primary'
                >
                    Save
                </ButtonFlatLarge>
            </DialogActions>
        </Modal>
    )
}

export default CustomApprovalGuidelinesModal

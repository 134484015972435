import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import UserListActionBar from './UserListActionBar'
import UsersList from './UsersList'
import UserAndSiteRoles from '../../../api/UserAndSiteRoles'

import { Roles } from '../../../api/Role'
import { SiteInfo } from '../../../api/SiteInfo'
import { UsersListFilters, NoFilters } from './UsersListFilters';
import { setStaffFilters, setVolunteerFilters } from '../../../redux/usersAndRoles'

export type Props = {
    filters?: UsersListFilters,
    isVolunteerList?: boolean,
    userAndSiteRoles: UserAndSiteRoles[],
    sites: SiteInfo[],
    onAddClick: () => void,
    onBulkAddClick: () => void,
    onEditClick: (user: UserAndSiteRoles) => void,
    onRemoveClick: (user: UserAndSiteRoles) => void,
    removingDisabled?: boolean,
    removingUserId?: string | null,
}
const UserListAndActions: FunctionComponent<Props> = ({
    filters = NoFilters,
    userAndSiteRoles,
    sites,
    isVolunteerList = false,
    onAddClick,
    onBulkAddClick,
    onEditClick,
    onRemoveClick,
    removingDisabled = false,
    removingUserId = null,
}) => {

    const [appliedFilters, setFilters] = useState(filters)
    const dispatch = useDispatch()
    const isFiltered = filters.site !== -1 || filters.role !== Roles.All

    return (
        <>
            <UserListActionBar
                sites={sites}
                filters={appliedFilters}
                isVolunteerList={isVolunteerList}
                onAddClick={onAddClick}
                onBulkAddClick={onBulkAddClick}
                onRoleFilterUpdated={role => setFilters(Object.assign({}, appliedFilters, { role }))}
                onSiteFilterUpdated={site => setFilters(Object.assign({}, appliedFilters, { site }))}
            />
            <UsersList
                isVolunteer={isVolunteerList}
                isFiltered={isFiltered}
                userAndSiteRoles={userAndSiteRoles}
                onEditClick={onEditClick}
                onRemoveClick={onRemoveClick}
                onClearFilterClick={() =>  {
                    isVolunteerList 
                    ? dispatch(setVolunteerFilters({...NoFilters}))
                    : dispatch(setStaffFilters({...NoFilters}))
                    setFilters(NoFilters)
                }}
                removingDisabled={removingDisabled}
                removingUserId={removingUserId}
            />
        </>
    )
}

export default UserListAndActions

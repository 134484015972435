import React, { FunctionComponent, ComponentType } from 'react'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import SisErrorIcon from '../../Parents/components/icons/SisErrorIcon'
import Grid from '@material-ui/core/Grid'
import FailedAddendum from '../../Users/components/FailedAddendum'

import { createStyles, makeStyles } from '@material-ui/styles'

import colors from '@peachjar/ui/dist/lib/styles/colors'
import { BulkAddUserResult } from '../../../api/commands/bulkAddUser'
import SisWaitIcon from '../../Parents/components/icons/SisWaitIcon'
const { Dark: { Paragraph } } = Typo

type Props = {
    job?: BulkAddUserResult,
    onContinuePressed?: () => void,
}

const useStyles = makeStyles(createStyles({
    syncStatusBox: {
        padding: '24px',
        border: `1px solid ${colors.silver}`,
        borderRadius: '3px',
    },
}))

const SyncBox: FunctionComponent<{}> = ({ children }) => {
    const classes = useStyles()
    return (
        <div className={classes.syncStatusBox}>{children}</div>
    )
}
const Success: FunctionComponent<Props> = ({ job }) => {
  return (
      <Grid container justify='flex-start' alignItems='flex-start' direction='row' spacing={2}>
          <Grid item xs={12} md={1}>
              <SisWaitIcon color={colors.leaf}/>
          </Grid>
          <Grid item xs={12} md={11}>
              <FailedAddendum job={job!} isSuccess={true} />
          </Grid>
      </Grid>
  )
}
const Errored: FunctionComponent<Props> = ({ job }) => {
    return (
        <Grid container justify='flex-start' alignItems='flex-start' direction='row' spacing={2}>
            <Grid item xs={12} md={1}>
                <SisErrorIcon />
            </Grid>
            <Grid item xs={12} md={11}>
                <FailedAddendum job={job!} isSuccess={false} />
            </Grid>
        </Grid>
    )
}

const BulkAddUserStatus: FunctionComponent<Props> = ({
    job,
    onContinuePressed,
}) => {
    const jobError = job!.error === '' ? false : true
    const Component = !jobError  ? Success : Errored
    return (
        <SyncBox>
            <Component
                job={job}
                onContinuePressed={onContinuePressed}
            />
        </SyncBox>
    )
}

export default BulkAddUserStatus

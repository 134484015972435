import { School } from '../../api/School'
import { normalizeSchoolForUI, GQLSchool, normalizeSchoolForGQL } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import gql from 'graphql-tag'
import mapError from './mapError'
import createSchool, { CreateSchoolOptions, CreateSchoolParams } from '../../api/commands/createSchool'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const CREATE_SCHOOL = gql`
    mutation createSchool($input: V2_CreateSchoolInput!) {
        createSchool(input: $input) {
            id
            schoolId
            name
            active
            visible
            treeCounter
            sisId
            ncesId
            address {
                address
                googlePlaceId
            }
            lowGrade
            highGrade
            enrollmentCount
            startAt
            generalDisclaimer
            isIndependent
            createdAt
            createdBy
            updatedAt
            updatedBy
        }
    }
`

type GQLResult = {
    createSchool: GQLSchool
}

export default function factory({ apolloClient }: Deps): createSchool {
    return async (input: CreateSchoolParams, options: CreateSchoolOptions = {}): Promise<School> => {

        try {

            const result = await apolloClient.mutate<GQLResult>({
                mutation: CREATE_SCHOOL,
                variables: {
                    input: Object.assign(
                        normalizeSchoolForGQL(input as School),
                        options && options.associateToDistrict ? {
                            options: {
                                districtId: options.associateToDistrict,
                            },
                        } : {}
                    ),
                }
            })

            return normalizeSchoolForUI(result.data!.createSchool)

        } catch (error) {

            console.log(error)

            throw mapError(error)
        }
    }
}

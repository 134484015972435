import { LoggerApi } from '@peachjar/kernel-sanders'
import toString from 'lodash/toString'
import gql from 'graphql-tag'
import ApolloClient from 'apollo-boost'
import getApprovalSettings from '../../api/queries/getApprovalSettings'
import { ApprovalSettings } from '../../api/ApprovalSettings'
import mapError from './mapError'

type Deps = {
  apolloClient: ApolloClient<any>
  logger: LoggerApi
}

type ApprovalSettingsResult = {
  approvalSettings: ApprovalSettings
}

const GET_APPROVAL_SETTINGS = gql`
  query approvalSettings($input: ApprovalAudienceInput) {
    approvalSettings: getApprovalSettings(input: $input) {
      workflows {
        levels {
          constraint
          userId
          approvalGroups {
            id
            name
            audiences {
              audienceType
              audienceId
            }
            userIds
          }
        }
      }
      audience {
        hierarchyId
        hierarchyType
        schoolCount
        roles {
          userId
          email
          isApprover
          site {
            id
            type
            name
          }
        }
      }
    }
  }
`

export default function approvalSettingsFactory({
  apolloClient,
  logger,
}: Deps): getApprovalSettings {
  return async (hierarchy) => {
    try {
      const { hierarchyId, hierarchyType } = hierarchy
      const result = await apolloClient.query<ApprovalSettingsResult>({
        query: GET_APPROVAL_SETTINGS,
        fetchPolicy: 'network-only',
        variables: {
          input: (hierarchyId === -1) 
          ? { activeFirst: true } 
          : {
            hierarchyType,
            hierarchyId: toString(hierarchyId),
            activeFirst: true
          },
        },
      })

      return result.data.approvalSettings
    } catch (error) {
      const mappedError = mapError(error as any)
      logger.captureWithScope(error, { hierarchy })
      throw mappedError
    }
  }
}

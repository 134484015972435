import React, { FunctionComponent } from 'react'

const SisPauseIcon: FunctionComponent<{}> = ({}) => {
    return (
        <svg
            width='48px'
            height='48px'
            viewBox='0 0 48 48'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >
            <title>E1388994-C726-4AE4-A450-A0BC4CA4F6B6@1x</title>
            <g id='Settings' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                <g id='Parent-Settings---SFTP-warning' transform='translate(-340.000000, -904.000000)'>
                    <g id='sync-pause-icon' transform='translate(340.000000, 904.000000)'>
                        <circle id='Oval' fill='#FCC35D' cx='24' cy='24' r='24'></circle>
                        <g id='Group' transform='translate(24.000000, 24.000000) rotate(-90.000000) translate(-24.000000, -24.000000) translate(12.000000, 15.000000)' fill='#FFFFFF'>
                            <rect id='Rectangle' x='-2.27373675e-12' y='0' width='24' height='7' rx='1'></rect>
                            <rect id='Rectangle' x='-2.27373675e-12' y='11' width='24' height='7' rx='1'></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SisPauseIcon

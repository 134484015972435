import { normalizeSisJobForUI, GQLSisJob } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import mapError from './mapError'
import gql from 'graphql-tag'
import { CsvMapping } from '../../api/CsvMapping'
import saveCsvMapping from '../../api/commands/saveCsvMapping'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    mutation saveCsvMapping($input: CsvMappingInput) {
        mapping: saveCsvMapping(input: $input) {
            hierarchyId
            hierarchyType
            sisIdMap {
                sisId
                grade
                email
            }
        }
    }
`

type MutationResult = {
    mapping: CsvMapping,
}

export default function factory({ apolloClient }: Deps): saveCsvMapping {
    return async (identifiers) => {

        try {

            const result = await apolloClient.mutate<MutationResult>({
                mutation: QUERY,
                variables: {
                    input: 
                    {
                        hierarchyType: identifiers.hierarchyType,
                        hierarchyId: identifiers.hierarchyId,
                        header: identifiers.header,
                        sisIdMap: identifiers.sisIdMap,
                        gradeLevelMap: identifiers.gradeLevelMap
                    }
                },
            })

            return result.data!.mapping!

        } catch (error) {
            throw mapError(error as any)
        }
    }
}

import ApolloClient from 'apollo-boost'

import launchGroup, { AcademicGroupIdentifiers } from '../../api/commands/launchGroup'
import gql from 'graphql-tag'
import mapError from './mapError'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const MUTATION = gql`
    mutation launchGroup($input: LaunchGroupInput!) {
        result: launchGroup(input: $input) {
            academicGroupId
            academicGroupUuid
            academicGroupType
            academicGroupName
        }
    }
`

type GQLResult = {
    result: AcademicGroupIdentifiers[],
}

const OmittedFields = ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'schools', '__typename']

export default function factory({ apolloClient }: Deps): launchGroup {
    return async (hierarchy) => {

        try {

            const result = await apolloClient.mutate<GQLResult>({
                mutation: MUTATION,
                variables: {
                    input: {
                        hierarchy: {
                            groupId: hierarchy.id,
                            groupType: hierarchy.type,
                        }
                    }
                }
            })

            return result.data!.result

        } catch (error) {

            console.log(error)

            throw mapError(error)
        }
    }
}

import getSisJobs from '../api/queries/getSisJobs'
import { SisJob } from '../api/SisJob'
import { sisJobs } from './data'

export default function factory(): getSisJobs {
  return async (hierarchyId: number, hierarchyType: string): Promise<SisJob[]> => {
      return sisJobs.filter(s =>
          s.academic.groupId === hierarchyId &&
          s.academic.groupType === hierarchyType)
  }
}


import { District } from '../../api/District'
import { omit } from 'lodash'
import { normalizeDistrictForUI, normalizeDistrictForGQL, GQLDistrict } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import updateDistrictInfo, { UpdateableFields } from '../../api/commands/updateDistrictInfo'
import gql from 'graphql-tag'
import mapError from './mapError'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const UPDATE_DISTRICT = gql`
    mutation updateDistrict($input: V2_UpdateDistrictInput!) {
        district: updateDistrictInfo(input: $input) {
            id
            districtId
            name
            active
            visible
            treeCounter
            sisId
            ncesId
            address {
                address
                googlePlaceId
            }
            generalDisclaimer
            startAt
            createdAt
            createdBy
            updatedAt
            updatedBy
            schools {
                id
                schoolId
                name
                active
                visible
                treeCounter
                sisId
                ncesId
                address {
                    googlePlaceId
                    address
                }
                lowGrade
                highGrade
                enrollmentCount
                startAt
                generalDisclaimer
                isIndependent
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
        }
    }
`

type GQLResult = {
    district: GQLDistrict
}

const OmittedFields = [
    'live', 'launchedAt', 'launchedBy', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy',
    'schools', '__typename',
]

export default function factory({ apolloClient }: Deps): updateDistrictInfo {
    return async (updates: Partial<UpdateableFields>): Promise<District> => {

        const input = omit(updates, OmittedFields)

        try {

            const result = await apolloClient.mutate<GQLResult>({
                mutation: UPDATE_DISTRICT,
                variables: {
                    input: normalizeDistrictForGQL(input as District),
                }
            })

            return normalizeDistrictForUI(result.data!.district)

        } catch (error) {

            console.log(error)

            throw mapError(error)
        }
    }
}

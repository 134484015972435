import React, { FunctionComponent } from 'react'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { SelectProps }  from '@material-ui/core/Select'
import { isUndefined } from 'lodash'

import Grid from '@material-ui/core/Grid'
import GradeSelect from './GradeSelect'
import TypographyRedux from '@peachjar/ui/dist/lib/typography/TypographyRedux'

const { Dark: { Paragraph } } = TypographyRedux

// Force the value to be a string.
type GradeSelectBinding = {
    onChange: (value: string) => void,
    value: string | null,
    error: string | null,
    permission?: Grants,
}

export type Props = SelectProps & {
    bindLowGrade: GradeSelectBinding,
    bindHighGrade: GradeSelectBinding,
    permission?: Grants,
}

const GradeRangeField: FunctionComponent<Props> = ({
    bindLowGrade,
    bindHighGrade,
    permission ,
}) => {

    if (permission === Grants.None) {
        return <></>
    }

    const lowGradePermission = isUndefined(bindLowGrade.permission) ?
        Grants.Write : bindLowGrade.permission

    const highGradePermission = isUndefined(bindHighGrade.permission) ?
        Grants.Write : bindHighGrade.permission

    return (
        <Grid container style={{ height: '54px' }}>
            <Grid item style={{ width: '200px' }}>
                <GradeSelect
                    disabled={lowGradePermission < Grants.Write}
                    error={bindLowGrade.error}
                    value={bindLowGrade.value || ''}
                    onChange={bindLowGrade.onChange}
                />
            </Grid>
            <Grid item style={{ padding: '0 13px 4px 13px' }}>
                <Grid style={{ height: '100%' }} container direction='column' justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
                    <Paragraph>to</Paragraph>
                </Grid>
            </Grid>
            <Grid item style={{ width: '200px' }}>
                <GradeSelect
                    disabled={highGradePermission < Grants.Write}
                    error={bindHighGrade.error}
                    value={bindHighGrade.value || ''}
                    onChange={bindHighGrade.onChange}
                />
            </Grid>
        </Grid>
    )
}

export default GradeRangeField

export enum RoleNames {
    Admin = 'admin',
    Uploader = 'uploader',
    Approver = 'approver',
    Internal = 'internal',
}

export enum Roles {
    All,
    Admin,
    Approver,
    Uploader,
    None,
    Default,
}

export function getRoleName(role: Roles): string {
    switch (role) {
        case Roles.Admin: return 'admin'
        case Roles.Approver: return 'approver'
        case Roles.Uploader: return 'uploader'
        case Roles.None: return 'none'
        default: return 'roleOnle'
    }
}

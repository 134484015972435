import { UIError } from '@peachjar/ui/dist/api/CodedError'

type ErrorFactory = () => UIError
type ErrorRegistration = {
    key: string,
    factory: ErrorFactory,
}

const er = (key: string, factory: ErrorFactory): ErrorRegistration => ({
    key,
    factory,
})

const DefaultRegistrations: ErrorRegistration[] = [
    er('NotFound', () => new UIError(
        'request.resource.not_found',
        'Resource was not found'
    )),
    er('Conflict', () => new UIError(
        'request.resource.conflict',
        `Request could not be processed because of a conflict with
                the current of another resource.`
    )),
    er('Gone', () => new UIError(
        'request.resource.gone',
        'Entity is no longer available and will not be available again.'
    )),
    er('BadIdentity', () => new UIError(
        'request.security.authentication.failure.identity_not_found',
        'User account does not exist.'
    )),
    er('BadPassword', () => new UIError(
        'request.security.authentication.failure.invalid_password',
        'Invalid password.'
    )),
    er('Unauthenticated', () => new UIError(
        'request.security.authentication.required',
        'You need to login to access this resource.'
    )),
    er('TooManyAttempts', () => new UIError(
        'request.security.authentication.too_many_attempts',
        'You failed too many times to authenticate.  Try again in 15 minutes.'
    )),
    er('NotAuthorized', () => new UIError(
        'request.security.authorization',
        'You are not allowed to access this resource.'
    )),
    er('InvalidParam', () => new UIError(
        'request.invalid.parameters',
        'One or more request parameters was invalid.'
    )),
    er('BadTransition', () => new UIError(
        'request.invalid.state_transition',
        'Entity cannot transition to the next state.'
    )),
    er('BadAction', () => new UIError(
        'request.invalid.state_action',
        'Entity cannot perform this action in its current state.'
    )),
    er('InvalidBody', () => new UIError(
        'request.invalid.unprocessable',
        'The request is invalid and cannot be processed.'
    )),
    er('InternalConfig', () => new UIError(
        'internal.configuration',
        'The server is not properly configured.'
    )),
    er('NotImplemented', () => new UIError(
        'internal.not_implemented',
        'This feature is not implemented.'
    )),
    er('BadImpl', () => new UIError(
        'internal.bad_implementation',
        'Something went wrong internally.'
    )),
    er('BadState', () => new UIError(
        'internal.state',
        'The internal state of the record is invalid.'
    )),
    er('Unavailable', () => new UIError(
        'internal.unavailable',
        'The backing service is unavailable for requests.'
    )),
    er('MaxAttempts', () => new UIError(
        'internal.unavailable.max_attempts_reached',
        'The maximum number of attempts for the request has been reached.'
    )),
    er('Timeout', () => new UIError(
        'internal.timeout',
        'The request timed out.'
    )),
    er('Partial', () => new UIError(
        'partial',
        'The request partially failed.'
    )),
]

export type CheckErrorFn = (fieldValue: string) => never | void

export default function errorFactory(custom: ErrorRegistration[] = []): CheckErrorFn {
    const registrations: ErrorRegistration[] = [
        ...custom,
        ...DefaultRegistrations,
    ]
    return (fieldValue: string): never | void => {
        const errorReg = registrations.find(r => r.key === fieldValue)
        if (errorReg) {
            const error = errorReg.factory()
            console.log('Throwing error', error)
            throw error
        }
    }
}

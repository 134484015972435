import { normalizeSisJobForUI, GQLSisJob } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import createSisJob from '../../api/commands/createSisJob'
import mapError from './mapError'
import gql from 'graphql-tag'
import { SisGradeResult } from '../../api/SisGradeResult'
import getSisCsvMapping from '../../api/commands/getSisCsvMapping'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    query getSisCsvMapping($input: AcademicGroupInput) {
        mapping: getSisCsvMapping(input: $input) {
            header
            mapping {
                sisIdMap {
                    sisId
                    grade
                    email
                    email2
                    email3
                    email4
                    email5
                }
                gradeLevelMap {
                    prekindergarten
                    kindergarten
                    grade1
                    grade2
                    grade3
                    grade4
                    grade5
                    grade6
                    grade7
                    grade8
                    grade9
                    grade10
                    grade11
                    grade12
                    continueEducation
                }
            }
        }
    }
`

type MutationResult = {
    mapping: SisGradeResult,
}

export default function factory({ apolloClient }: Deps): getSisCsvMapping {
    return async (identifiers) => {

        try {

            const result = await apolloClient.mutate<MutationResult>({
                mutation: QUERY,
                variables: {
                    input: 
                    {
                        academic:
                        {
                            groupType: identifiers.hierarchyType,
                            groupId: identifiers.hierarchyId
                        }
                    }
                },
            })

            return result.data!.mapping!

        } catch (error) {
            throw mapError(error as any)
        }
    }
}

import React, { FunctionComponent, useCallback, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import UploadCSV from '@peachjar/ui/dist/lib/components/Illustrations/UploadCSV'
import { LoadingSpinner } from '@peachjar/ui/dist/lib/components/Spinners'
import ManualJobStatus from './ManualJobStatus'
import InvalidExtensionNotification from './InvalidExtensionNotification'
import CsvUploadFatalErrorNotification from '../../Users/components/CsvUploadFatalErrorNotification'
import FileTooLargeNotification from './FileTooLargeNotification'
import { useDispatch } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { ButtonSecondaryLarge, ButtonPrimarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { uploadSisCSV, setFileToUpload as setFileToUploadAction, setInvalidFile, clearInvalidFile } from '../../../redux/parentSettings'
import { uploadPhase, uploadProgress, uploadSisCSVReq, invalidFile as invalidFileSelector, fileToUpload as fileToUploadSelector } from '../../../redux/parentSettingsSelectors'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import { last } from 'lodash'
import { JobStatus } from '../../../api/SisJob'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import BulkAddUserStatus from '../../Users/components/BulkAddUserStatus'

import colors from '@peachjar/ui/dist/lib/styles/colors'
import SwitchError from '@peachjar/ui/dist/lib/components/Errors/SwitchError'
import { SisJob } from '../../../api/SisJob'
import { StaffJob } from '../../../api/StaffJob';
const { Dark: { Paragraph }, DarkBold: { Paragraph: BoldParagraph  } } = Typo

const MAX_FILE_SIZE_BYTES = 50000000

const useStyles = makeStyles(createStyles({
    dropzone: {
        background: colors.platinum,
        border: `2px dashed ${colors.silver}`,
        borderRadius: '3px',
        padding: '32px 0'
    }
}))

type InvalidFileContext = {
    filename: string,
    reason: 'bad-ext' | 'too-large',
}

type OnDropFn = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void

type Props = {
    csvFileType?: string,
    lastJob?: SisJob | StaffJob,
    hierarchyType: string,
    hierarchyId: number,
    isMapper: boolean,
    forceContinue: boolean,
    onNeedsRefreshing: () => void,
    isStaff?: boolean
}

const ManualUpload: FunctionComponent<Props> = ({
    csvFileType,
    lastJob,
    hierarchyType,
    hierarchyId,
    isMapper,
    forceContinue,
    onNeedsRefreshing,
    isStaff =  false
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const uploadReq = uploadSisCSVReq()
    const phase = uploadPhase()
    const progress = uploadProgress()
    const fileToUpload = fileToUploadSelector()
    const invalidFile = invalidFileSelector()

    const setFileToUpload = (value: File | null) => dispatch(setFileToUploadAction({ file: value }))
    const setFileInvalid = (value: InvalidFileContext | null) => {
        if (value) {
            return dispatch(setInvalidFile({ ctx: value }))
        }
        return dispatch(clearInvalidFile())
    }

    const dropFn: OnDropFn = (acceptedFiles, rejectedFiles) => {

        if (rejectedFiles.length > 0) {

            if (rejectedFiles[0].errors[0].code === 'file-too-large') {

                setFileInvalid({
                    filename: rejectedFiles[0].file.name,
                    reason: 'too-large',
                })

            } else {
                console.log('Unknown Error Code', rejectedFiles[0])
            }
        }

        if (acceptedFiles.length > 0) {

            const extension = last(acceptedFiles[0].name.split('.'))!.trim().toLowerCase()

            if (extension !== 'csv') {
                setFileInvalid({
                    filename: acceptedFiles[0].name,
                    reason: 'bad-ext',
                })
                return
            }

            setFileInvalid(null)

            setFileToUpload(acceptedFiles[0])

            dispatch(uploadSisCSV({
                csvFileType: isStaff ? 'staff' : undefined,
                hierarchyType,
                hierarchyId,
                isMapper,
                forceContinue,
                file: acceptedFiles[0],
            }))
        }
    }

    const onDrop = useCallback(dropFn, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        maxSize: MAX_FILE_SIZE_BYTES,
    })

    useEffect(() => {
        if (uploadReq.status === Statuses.loaded || uploadReq.status === Statuses.failed) {
            onNeedsRefreshing()
        }
    }, [uploadReq])
    const pickError = csvFileType && uploadReq.model ? uploadReq.model.error.toLowerCase().indexOf('header') >= 0 ? 'Header'
        : csvFileType && uploadReq.model.error.indexOf('isResponseError') >= 0 ? 'Internal' : 'none'
        : 'none'
    const showIllustration = (uploadReq.status === Statuses.notStarted) && !invalidFile
    const showJobStatus = uploadReq.status !== Statuses.notStarted
    const bulkAddUserFailed = uploadReq.status === Statuses.failed
    const bulkAddUserWaiting = uploadReq.status === Statuses.loading
    const showBulkAddUserFatalError = pickError !== 'none'
    const showInvalidExtension = !!invalidFile && invalidFile.reason === 'bad-ext'
    const showFileTooLarge = !!invalidFile && invalidFile.reason === 'too-large'
    const typeTitle = isStaff ? 'staff' : 'parent'
    const isProcessing = lastJob && lastJob.status === JobStatus.Processing

    return (
        <>
            <SwitchError error={uploadReq.error} />
            <Grid container>
                {!csvFileType &&
                    <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                        { !isStaff 
                        ? <Paragraph >
                            Manually sync your parent email list by uploading the
                            CSV file below. Make sure the file size is no more than
                            50 MB.
                        </Paragraph> 
                        : <Paragraph >
                            Sync your staff list by uploading the
                            CSV file below. Make sure the file size is no more than
                            50 MB.
                        </Paragraph> 
                        }
                    </Grid>
                }
                {lastJob && !isProcessing &&
                    <Grid item xs={12}>
                        <BoldParagraph >File Upload</BoldParagraph>
                    </Grid>
                }
                {showInvalidExtension &&
                    <Grid item xs={12} style={{ margin: '8px 0 0 0' }}>
                        <InvalidExtensionNotification
                        filename={invalidFile!.filename} />
                    </Grid>}
                {showFileTooLarge &&
                    <Grid item xs={12} style={{ margin: '8px 0 0 0' }}>
                        <FileTooLargeNotification filename={invalidFile!.filename} />
                    </Grid>
                }
                {showBulkAddUserFatalError &&
                    <CsvUploadFatalErrorNotification
                        pickError={pickError}
                    />
                }
                {!csvFileType && showJobStatus &&
                    <Grid item xs={12} style={{ margin: '8px 0 0 0' }}>
                        <ManualJobStatus
                            onContinuePressed={() => onNeedsRefreshing()}
                            filename={fileToUpload!.name}
                            phase={phase}
                            progress={progress}
                            status={(uploadReq.model && uploadReq.model.status) || JobStatus.Processing}
                            sisJob={uploadReq.model!}
                        />
                    </Grid>}

                {!csvFileType && !isProcessing &&
                    <Grid item xs={12} style={{ margin: '8px 0 0 0' }}>
                    <Grid
                        container
                        className={classes.dropzone}
                        justify='center'
                        alignItems='center'
                        direction='column'
                        {...getRootProps()}
                    >
                        {showIllustration && <Grid item xs={12}><UploadCSV /></Grid>}
                        <Grid item xs={12} style={{ marginTop: '16px' }}>
                            <BoldParagraph >
                                {showIllustration ?
                                    'Drag and drop your CSV file here or' :
                                    'Drag and drop a new CSV file to replace your current one or'
                                }
                            </BoldParagraph>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '16px' }}>
                            <ButtonSecondaryLarge>
                                Browse Files...
                                <input {...getInputProps()} />
                            </ButtonSecondaryLarge>
                        </Grid>
                    </Grid>
                    </Grid>
                }
                {csvFileType && showJobStatus && !bulkAddUserFailed &&
                    <Grid item xs={12} style={{ textAlign: 'left', paddingRight: '10px', marginBottom: '8px' }}>
                    <FileIcon style={{color: colors.jungle}} />
                    <span style={{padding: '0 0 0 10px'}}>{fileToUpload!.name}</span>
                    </Grid>
                }
                {csvFileType &&
                    <Grid
                        className={classes.dropzone}
                        style={{marginTop: '0px', padding: 0, border: 0, background: 'white'}}
                        {...getRootProps()}
                    >
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <ButtonPrimarySmall>
                                Browse Files...
                                <input {...getInputProps()} />
                            </ButtonPrimarySmall>
                        </Grid>
                    </Grid>
                }
                {csvFileType && showJobStatus && !uploadReq.model && !showBulkAddUserFatalError && bulkAddUserWaiting &&
                    <Grid item xs={12} style={{ margin: '8px 0 0 0' }}>
                        <LoadingSpinner height="180px" />
                    </Grid>
                }
                {csvFileType && showJobStatus && uploadReq.model && !showBulkAddUserFatalError &&
                    <Grid item xs={12} style={{ margin: '8px 0 0 0' }}>
                        <Grid item xs={12} style={{ margin: '8px 0 8px 0' }}>
                            <BoldParagraph >Bulk Add Staff Status</BoldParagraph>
                        </Grid>
                        <BulkAddUserStatus
                            onContinuePressed={() => onNeedsRefreshing()}
                            job={uploadReq.model!}
                        />
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default ManualUpload

/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux'
import { RootState } from './index'
import { CommunitySettings } from '../api/CommunitySettings'

import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'

export const saveSettingsReq = () => {
    return useSelector<RootState, AsyncReqState<CommunitySettings>>(state => state.community.saveSettings)
}

export const settingsReq = () => {
    return useSelector<RootState, AsyncReqState<CommunitySettings>>(state => state.community.settings)
}

import React, { FunctionComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Create'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import colors from '@peachjar/ui/dist/lib/styles/colors'
import IconButton from '@peachjar/ui/dist/lib/components/Buttons/IconButton'

type Props = {
    showEdit: boolean,
    onEditClick: () => void,
}

const useStyle = makeStyles(createStyles({
    root: {
        backgroundColor: colors.platinum,
    },
    content: {
        padding: '24px 0px 24px 24px'
    },
    edit: {
        paddingTop: '16px',
        paddingRight: '16px',
    },
}))

const PreviewBox: FunctionComponent<Props> = ({
    children,
    showEdit,
    onEditClick,
}) => {
    const classes = useStyle()
    return (
        <Grid container className={classes.root}>
            {!showEdit &&
                <Grid
                    item xs={12}
                    className={classes.content}
                    style={{ paddingRight: '24px' }}
                >{children}</Grid>
            }
            {showEdit &&
                <>
                    <Grid item xs={10} className={classes.content}>{children}</Grid>
                    <Grid item xs={2} className={classes.edit}>
                        <Grid container justify='flex-end'>
                            <Grid item>
                                <IconButton
                                    icon={() => <EditIcon style={{ fontSize: '14px' }} />}
                                    onClick={onEditClick}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </Grid>
    )
}

export default  PreviewBox

import React, { FunctionComponent, useEffect } from 'react'
import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage'
import StaffPageBody from '../Users/StaffPageBody'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import Routes from '../../routes'

import { useParams, useHistory } from 'react-router-dom'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { useDispatch } from 'react-redux'
import { getDistrictReq } from '../../redux/districtsSelectors'
import { getDistrict } from '../../redux/districts'

type Props = {}

const DistrictStaffSettingPage: FunctionComponent<Props> = () => {

    const panic = usePanic()
    const dispatch = useDispatch()
    const history = useHistory()

    const { districtId: districtIdParam } = useParams<{ districtId: string }>()

    const districtId = parseInt(districtIdParam, 10)

    const districtReq = getDistrictReq()

    useEffect(() => {
        dispatch(getDistrict(districtId))
    }, [])


    if (districtReq.isLoading()) {
        return <LoadingSpinner />
    }

    if (districtReq.error) {
        return panic(districtReq.error)
    }

    const district = districtReq.model!

    return (
        <DefaultPage
            title='Bulk Staff Management'
            breadCrumbs={[{
                label: 'User & Role Settings',
                onClick: () => history.push(Routes.districts.users.from(districtId)),
            }]}
        >
          <StaffPageBody
            hierarchyType='district'
            hierarchyId={districtId}
          />
        </DefaultPage>
    )
}

export default DistrictStaffSettingPage

import React, { FunctionComponent, useMemo, useContext } from 'react'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip'
import Grid from '@material-ui/core/Grid'
import LocalFileDownload from '../LocalFileDownload'
import ConfigContext from '@peachjar/ui/dist/lib/contexts/Config'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

import { SiteInfo } from '../../api/SiteInfo'

const { Dark: { Note } } = Typo

const FLYBOARD_TOOLTIP = `Download a file containing code snippets with URLs for your district and school flyer boards.
Place these button code snippets on your websites to provide parents access to your flyers.`

export type FlyerboardButtonsProps = {
    sites?: SiteInfo[]
}
enum ButtonWidth {
    small = 48,
    medium = 175,
    large = 290
}

const flyerboardButtons = [
    {
        name: 'small_blue',
        width: ButtonWidth.small
    },
    {
        name: 'small_gray',
        width: ButtonWidth.small
    },
    {
        name: 'medium_blue',
        width: ButtonWidth.medium
    },
    {
        name: 'medium_gray',
        width: ButtonWidth.medium
    },
    {
        name: 'large_blue',
        width: ButtonWidth.large
    },
    {
        name: 'large_gray',
        width: ButtonWidth.large
    },
]

function createCSV(sites: SiteInfo[], baseDomain: string): string {
    return [
        [
            'name',
            ...flyerboardButtons.map(button => button.name)
        ].join(','),
        ...sites.map(site =>
            [
                site.name,
                ...flyerboardButtons.map(button => [
                    `<script src="https://portal-bff.${baseDomain}/flyerboard/`,
                    site.type === 'district' ? 'districts' : 'schools',
                    '/',
                    site.id,
                    '?button=',
                    button.name,
                    '&width=',
                    button.width,
                    '"></script>',
                ].join('')
                )
            ].join(',')
        )
    ].join('\n')
}

const FlyerboardButtons: FunctionComponent<FlyerboardButtonsProps> = ({
    sites = [],
}) => {
    const config = useContext(ConfigContext)
    const content = useMemo(() => createCSV(sites, config.DOMAIN), [sites])
    return (
        <>
            <Grid container justify='flex-start' direction='row'>
                <Note>
                    Flyer Board Buttons
                    <span style={{ marginLeft: '8px' }}>
                        <Tooltip title={FLYBOARD_TOOLTIP} />
                    </span>
                </Note>
            </Grid>
            <Grid container spacing={1}>
                <Grid item>
                    <LocalFileDownload
                        label={'Download CSV File'}
                        fileContents={content}
                        fileName={'Peachjar Buttons.csv'}
                        mimeType={'text/csv'}
                    />
                </Grid>
                <Grid item>
                    <ButtonSecondarySmall
                          onClick={() => {
                            window.open(
                                'https://cdn.peachjar.com/assets/Peachjar+Button+Instructions.pdf',
                                '_blank'
                            )
                        }}
                    >
                        View Instructions (PDF)
                    </ButtonSecondarySmall>
                </Grid>
            </Grid>
        </>
    )
}

export default FlyerboardButtons

import React, { FunctionComponent } from 'react'
import SortableTable from '@peachjar/ui/dist/lib/components/SortableTable/SortableTable2'
import IconButton from '@peachjar/ui/dist/lib/components/Buttons/IconButton'
import Tag, { Variants } from '@peachjar/ui/dist/lib/components/Tag'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Routes from '../../../routes'

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

import { SortDirections } from '@peachjar/ui/dist/lib/components/SortableTable/sort-utils'
import { School } from '../../../api/School'
import { useHistory } from 'react-router-dom'
import { DistrictPermissions } from '../../../api/permissions/DistrictPermissions'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { formatDate, truncate } from '../../../utils'

type Props = {
    districtId: number,
    defaultStartAt?: Date | null,
    schools: School[],
    permissions: DistrictPermissions,
}

function showDate(dtg: Date | undefined | null): string {
    if (dtg) {
        return formatDate(dtg)
    }
    return '- -'
}

const SchoolsAndSitesList: FunctionComponent<Props> = ({
    districtId,
    defaultStartAt,
    schools,
    permissions,
}) => {

    const history = useHistory()
    const isInternal = permissions.internal > Grants.None

    return (
        <SortableTable
            columnCount={permissions.internal ? 9 : 8}
            items={schools}
            initialSort={{ field: 'name', direction: SortDirections.Asc }}
            thead={({ asSortableLabel }) => (
                <TableRow>
                    <TableCell>{asSortableLabel('Status', 'active')}</TableCell>
                    <TableCell>{asSortableLabel('Public View', 'invisible')}</TableCell>
                    <TableCell>Start Date</TableCell>
                    {isInternal ? <TableCell>Peachjar ID</TableCell> : null}
                    <TableCell>SIS ID</TableCell>
                    <TableCell style={{ minWidth: '150px' }}>{asSortableLabel('Name', 'name')}</TableCell>
                    <TableCell>Physical Address</TableCell>
                    <TableCell>{asSortableLabel('Enrollment', 'enrollmentCount')}</TableCell>
                    <TableCell>{asSortableLabel('Grade Levels', 'lowGrade')}</TableCell>
                    <TableCell />
                </TableRow>
            )}
            tbody={(school) => (
                <TableRow hover key={school.schoolId}>
                    <TableCell>
                        <Tag variant={school.active ? Variants.Active : Variants.Inactive}>
                            {school.active ? 'Active' : 'Inactive'}
                        </Tag>
                    </TableCell>
                    <TableCell>
                        <Tag variant={school.invisible ? Variants.Prussian : Variants.Peach}>
                            {school.invisible ? 'Invisible' : 'Visible'}
                        </Tag>
                    </TableCell>
                    <TableCell>
                        <Tag variant={school.live ? Variants.Mint : Variants.Prussian}>
                            {showDate(school.startAt || defaultStartAt)}
                        </Tag>
                    </TableCell>
                    {isInternal ? <TableCell>{school.schoolId}</TableCell> : null}
                    <TableCell>{truncate(school.sisId || '', 16)}</TableCell>
                    <TableCell>{school.name}</TableCell>
                    <TableCell>{school.address?.address}</TableCell>
                    <TableCell>{school.enrollmentCount}</TableCell>
                    <TableCell>{school.lowGrade} - {school.highGrade}</TableCell>
                    <TableCell>
                        <IconButton
                            icon={KeyboardArrowRightIcon}
                            tooltip='View School / Site'
                            placement='top'
                            onClick={() =>
                                history.push(Routes.districts.schools.update.from(districtId, school.schoolId))}
                        />
                    </TableCell>
                </TableRow>
            )}
        />
    )
}

export default SchoolsAndSitesList

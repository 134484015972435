import React from 'react'

const PendAndPaperIcon = () => (
  <svg
    width="56px"
    height="56px"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Style-Guide"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="Illustrations" transform="translate(-1899.000000, -523.000000)">
        <g
          id="Signing-decorative-icon-Copy-2"
          transform="translate(1899.000000, 523.000000)"
        >
          <circle id="Mask" fill="#DFE1E7" cx={28} cy={28} r={28} />
          <path
            d="M16.277193,11.7894737 L41.4666667,11.7894737 C42.0189514,11.7894737 42.4666667,12.2371889 42.4666667,12.7894737 L42.4666667,40.4221069 C42.4666667,40.9743916 42.0189514,41.4221069 41.4666667,41.4221069 L16.277193,41.4221069 C15.7249082,41.4221069 15.277193,40.9743916 15.277193,40.4221069 L15.277193,12.7894737 C15.277193,12.2371889 15.7249082,11.7894737 16.277193,11.7894737 Z"
            id="Rectangle"
            fill="#47506B"
          />
          <path
            d="M14.2631579,14.122807 L39.4526316,14.122807 C40.0049163,14.122807 40.4526316,14.5705223 40.4526316,15.122807 L40.4526316,42.7554402 C40.4526316,43.307725 40.0049163,43.7554402 39.4526316,43.7554402 L14.2631579,43.7554402 C13.7108731,43.7554402 13.2631579,43.307725 13.2631579,42.7554402 L13.2631579,15.122807 C13.2631579,14.5705223 13.7108731,14.122807 14.2631579,14.122807 Z"
            id="Rectangle"
            fill="#FFFFFF"
          />
          <rect
            id="Rectangle-6"
            fill="#9EA3B7"
            x="17.2912281"
            y="19.4815325"
            width="19.6181931"
            height="1.02433794"
            rx="0.512168969"
          />
          <rect
            id="Rectangle-6"
            fill="#9EA3B7"
            x="17.2912281"
            y="23.4325503"
            width="19.6181931"
            height="1.02433794"
            rx="0.512168969"
          />
          <rect
            id="Rectangle-6"
            fill="#9EA3B7"
            x="17.2912281"
            y="27.3835681"
            width="8.72930411"
            height="1.02433794"
            rx="0.512168969"
          />
          <path
            d="M17.803397,31.1481992 L27.8416965,31.1481992 C28.1245597,31.1481992 28.3538655,31.3775051 28.3538655,31.6603682 L28.3538655,31.6603682 C28.3538655,31.9432313 28.1245597,32.1725371 27.8416965,32.1725371 L17.803397,32.1725371 C17.5205339,32.1725371 17.2912281,31.9432313 17.2912281,31.6603682 L17.2912281,31.6603682 C17.2912281,31.3775051 17.5205339,31.1481992 17.803397,31.1481992 Z"
            id="Rectangle-6"
            fill="#9EA3B7"
          />
          <rect
            id="Rectangle-6"
            fill="#9EA3B7"
            x="17.2912281"
            y="34.3214392"
            width="8.72930411"
            height="1.02433794"
            rx="0.512168969"
          />
          <rect
            id="Rectangle-6"
            fill="#9EA3B7"
            x="17.2912281"
            y="37.4325503"
            width="13.3959708"
            height="1.02433794"
            rx="0.512168969"
          />
          <g
            id="Group"
            transform="translate(38.114404, 35.689432) rotate(-44.000000) translate(-38.114404, -35.689432) translate(35.392182, 23.633877)"
          >
            <path
              d="M1.80662793,1.3133583 L2.50195224,1.3133583 C2.69133827,1.3133583 2.86446983,1.42035949 2.94916583,1.5897515 L3.76252414,3.2164681 C3.88601876,3.46345735 3.78590659,3.76379387 3.53891734,3.8872885 C3.46948962,3.92200236 3.39293309,3.9400749 3.31531054,3.9400749 L0.993269627,3.9400749 C0.717127252,3.9400749 0.493269627,3.71621728 0.493269627,3.4400749 C0.493269627,3.36245235 0.511342173,3.28589582 0.546056032,3.2164681 L1.35941433,1.5897515 C1.44411034,1.42035949 1.6172419,1.3133583 1.80662793,1.3133583 Z"
              id="Rectangle-7"
              fill="#9EA3B7"
            />
            <path
              d="M2.15429008,2.6267166 L2.15429008,2.6267166 C3.24231172,2.6267166 4.12432753,3.50873241 4.12432753,4.59675405 L4.12432753,21.670412 C4.12432753,22.7584336 3.24231172,23.6404494 2.15429008,23.6404494 L2.15429008,23.6404494 C1.06626844,23.6404494 0.184252633,22.7584336 0.184252633,21.670412 L0.184252633,4.59675405 C0.184252633,3.50873241 1.06626844,2.6267166 2.15429008,2.6267166 Z"
              id="Rectangle-4"
              fill="#576183"
            />
            <polygon
              id="Triangle"
              fill="#47506B"
              points="2.15429008 0 2.81096923 1.3133583 1.49761093 1.3133583"
            />
            <path
              d="M4.25448845,9.97591683 L4.56784675,9.97591683 C4.84398913,9.97591683 5.06784675,10.1997745 5.06784675,10.4759168 L5.06784675,18.6694249 C5.06784675,18.9455673 4.84398913,19.1694249 4.56784675,19.1694249 L4.25448845,19.1694249 C3.97834608,19.1694249 3.75448845,18.9455673 3.75448845,18.6694249 L3.75448845,10.4759168 C3.75448845,10.1997745 3.97834608,9.97591683 4.25448845,9.97591683 Z"
              id="Rectangle-5"
              fill="#9EA3B7"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default PendAndPaperIcon

import { Grants, PermissionsMapper } from '@peachjar/ui/dist/api/Permissions'
import { RoleNames } from '../Role'
import { EntityTypes } from '../Entity'

import createMapper, { RoleMatrix } from '@peachjar/ui/dist/lib/contexts/Permissions/matrixRoleMapper'

const { None: N, Read: R, Write: W, Execute: X } = Grants

// Structure of Permissions
export type SchoolPermissions = {
    id: Grants,
    schoolId: Grants,
    name: Grants,
    editName: Grants,
    active: Grants,
    invisible: Grants,
    treeCounter: Grants,
    sisId: Grants,
    ncesId: Grants,
    address: Grants,
    enrollmentCount: Grants,
    lowGrade: Grants,
    highGrade: Grants,
    startAt: Grants,
    generalDisclaimer: Grants,
    isIndependent: Grants,
    transitionalGroupType: Grants,
    createdAt: Grants,
    createdBy: Grants,
    updatedAt: Grants,
    updatedBy: Grants,
    internal: Grants,
    users: Grants,
    community: Grants,
    parents: Grants,
    actions: {
        view: Grants,
        update: Grants,
    }
}

// Base Permissions for a User
export const BaseSchoolPermissions: SchoolPermissions = {
    id: N,
    schoolId: N,
    name: N,
    editName: N,
    active: N,
    invisible: N,
    treeCounter: N,
    sisId: N,
    ncesId: N,
    address: N,
    enrollmentCount: N,
    lowGrade: N,
    highGrade: N,
    startAt: N,
    generalDisclaimer: N,
    isIndependent: N,
    transitionalGroupType: N,
    createdAt: N,
    createdBy: N,
    updatedAt: N,
    updatedBy: N,
    internal: N,
    users: N,
    community: N,
    parents: N,
    actions: {
        view: N,
        update: N,
    }
}

// Mapping of Permissions by Role
const roleMatrix: RoleMatrix = {
    name: 'School',
    roles: [RoleNames.Uploader, RoleNames.Approver, RoleNames.Admin, RoleNames.Internal],
    perms: {
        id: [N, N, N, X],
        schoolId: [N, N, N, X],
        name: [R, R, W, X],
        editName: [R, R, R, X],
        active: [R, R, R, X],
        invisible: [R, R, R, X],
        treeCounter: [R, R, W, X],
        sisId: [N, N, W, X],
        ncesId: [N, N, N, X],
        address: [R, R, W, X],
        enrollmentCount: [R, R, W, X],
        lowGrade: [R, R, W, X],
        highGrade: [R, R, W, X],
        startAt: [N, N, N, X],
        generalDisclaimer: [N, N, W, X],
        isIndependent: [N, N, N, X],
        transitionalGroupType: [N, N, N, X],
        createdAt: [R, R, R, X],
        createdBy: [N, N, N, X],
        updatedAt: [R, R, R, X],
        updatedBy: [R, R, R, X],
        internal: [N, N, N, X],
        users: [N, N, W, X],
        community: [N, N, W, X],
        parents: [N, N, W, X],
        actions: {
            view: [N, N, R, R],
            update: [R, R, W, X],
        }
    }
}

// PermissionsMap registered with MicroApp
export const SchoolPermissionMap: PermissionsMapper<SchoolPermissions> = {
    entityType: EntityTypes.School,
    map: createMapper({ roleMatrix, base: BaseSchoolPermissions })
}

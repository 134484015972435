import { v4 as uuid } from 'uuid'
import { District } from '../api/District'
import { districts } from './data'

import createDistrict, { CreateDistrictParams } from '../api/commands/createDistrict'
import errorFactory from './errorFactory'
import wait from './wait'

let i = 50000

export default function factory(): createDistrict {

    const checkError = errorFactory()

    return async (params: CreateDistrictParams): Promise<District> => {

        await wait(1000)

        checkError(params.generalDisclaimer || '')

        i += 1
        const district = Object.assign({
            id: uuid(),
            schools: [],
            districtId: i,
            live: false,
            createdAt: new Date(),
            createdBy: uuid(),
            updatedAt: new Date(),
            updatedBy: uuid(),
            launchedAt: null,
            launchedBy: null,
        }, params)
        districts.push(district)
        return district
    }
}

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { maxBy } from 'lodash'
import { Box } from '@material-ui/core'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip'
import SwitchError from '@peachjar/ui/dist/lib/components/Errors/SwitchError'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import SisJobTable from './components/SisJobTable'
import UploadModeChooser, { Mode } from './components/UploadModeChooser'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { getSisCsvMapping, getSisJobsReq } from '../../redux/parentSettingsSelectors'
import { downloadCsvFile, getCsvMapping, getSisJobs } from '../../redux/parentSettings'
import SyncStatus from './components/SyncStatus'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'
import { getDistrictReq } from '../../redux/districtsSelectors'
import { GradeLevels, toOrdinal } from '../../api/GradeLevels'
import { useHistory } from 'react-router-dom'
import Routes from '../../routes'
import { css } from 'emotion'

const {
    DarkMuted: { Note: MutedNote },
    Dark: { Paragraph },
    DarkBold: { Paragraph: BoldParagraph }
} = Typo

let infoMessage = "Start setting up your parent sync by setting up your parent list mapping.";

type Props = {
    hierarchyType: string,
    hierarchyId: number,
}
const InfoMessage = () => (
    <span>
        {infoMessage}
    </span>
)

const ParentsPageBodyMapper: FunctionComponent<Props> = ({
    hierarchyId,
    hierarchyType,
}) => {

    // initialize styles
    const styles = {
        mappingStatus: {
            width: "100%",
            height: "100px",
            flexGrow: 0,
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "16px",
            padding: "0 32px",
            borderRadius: "5px",
            border: "solid 1px #b5b5b5"
        },
        inputText: {
          padding: "10px",
          color: "red",
        },
        districtSettings: {
            color: "#3a833c",
        }
    }

    const styles2 = {
        districtSettings: css`
            color: #3a833c;
            font-weight: bold;
            &:hover {
                cursor: pointer;
                text-decoration: underline #3a833c !important;
            }`,
    }

    // initialize state and global values
    const dispatch = useDispatch()
    const panic = usePanic()
    const sisJobsReq = getSisJobsReq()
    const districtReq = getDistrictReq()
    const history = useHistory()
    let mapping = getSisCsvMapping()
    const [hasMapping, setHasMapping] = useState(false)
    const firstLoad = useRef(false)
    const [uploadMode, setUploadMode] = useState<Mode>(Mode.SFTP)
    const [lowGrade, setLowGrade] = useState("")
    const [highGrade, setHighGrade] = useState("")
    const [sisJobsLength, setSisJobsLength] = useState(0)

    // get sis jobs list
    const dispatchGetSisJobs = () => {
        dispatch(getSisJobs({ hierarchyId, hierarchyType }))
    }

    // get sis jobs list and calculate grade range
    useEffect(() => {
        dispatchGetSisJobs()
        if(districtReq.model){
            if(districtReq.model.schools!.length > 0){
                for(let i=0; i<districtReq.model.schools!.length; i++) {
                    if(lowGrade === "" || toOrdinal(districtReq.model.schools![i].lowGrade as GradeLevels) < toOrdinal(lowGrade as GradeLevels)){
                        setLowGrade(districtReq.model.schools![i].lowGrade as string)
                    }
                    if(highGrade === "" || toOrdinal(districtReq.model.schools![i].highGrade as GradeLevels) > toOrdinal(highGrade as GradeLevels)) {
                        setHighGrade(districtReq.model.schools![i].highGrade as string)
                    }
                }
            }
        }
    }, [lowGrade, highGrade])

    // get mapping on first render
    useEffect(() => {
        dispatch(getCsvMapping({ hierarchyId, hierarchyType }))
    }, [])

    // if no mapping, prompt user to start one, otherwise show that there is one
    useEffect(() => {
        if(mapping != undefined) {
            if(mapping.model != null) {
                if(mapping.model.mapping != null) {
                    let sisIdMap = mapping.model.mapping.sisIdMap
                    let gradeLevelMap = mapping.model.mapping.gradeLevelMap
                    let hasSisIdMap = true
                    if(sisIdMap === null || sisIdMap.sisId === "" || sisIdMap.email === "") {
                        hasSisIdMap = false
                    }
                    for (const column in gradeLevelMap) {
                        if(gradeLevelMap[column] !== null && gradeLevelMap[column] !== "GradeLevelMap") {
                        }
                    }
                    if(hasSisIdMap) {
                        setHasMapping(true)
                        infoMessage = "View or change your mapping using the Parent List Mapping tool."
                    }
                }
            }
        }
    }, [mapping])

    // update firstLoad value to track first render
    useEffect(() => {
        if (sisJobsReq.status === Statuses.loaded && !firstLoad.current) {
            firstLoad.current = true
        }
    }, [sisJobsReq])

    // calculate sis job list length
    useEffect(() => {
        if (sisJobsReq.status === Statuses.loaded && sisJobsReq.model) {
            setSisJobsLength(sisJobsReq.model.length)
        }
    }, [sisJobsReq])

    if (sisJobsReq.isLoading() && !firstLoad.current) {
        return <LoadingSpinner />
    }

    if (sisJobsReq.error && !firstLoad.current) {
        return panic(sisJobsReq.error)
    }

    const sisJobs = sisJobsReq.model!

    const lastJob = maxBy(sisJobs, s => s.createdAt)

    return (
        <Sections>
            <Section headline={'File Preparation'}>
                <SwitchError error={sisJobsReq.error} />
                {!hasMapping && (
                    <div>
                        <Paragraph >
                        Start setting up your parent list mapping by uploading a CSV file below. Be sure the CSV file includes all parents 
                        for each school in your district.
                            <br/><br/>
                            <strong>New File Requirements:</strong>
                            <br/><br/>
                            <ul>
                                <li>Your file should contain columns for <strong>Site SIS ID</strong>, <strong>Parent Email</strong>, and <strong>Grade</strong>. 
                                    We recommend to have these columns as headers.</li>
                                <li>Your file should not contain duplicate column names.</li>
                                <li><strong>Site SIS IDs must match</strong> between your CSV file and the District & School Settings for each of your school 
                                    sites in Peachjar.</li>
                                <li>You will be asked to map <strong>grades {lowGrade}-{highGrade}</strong> based on the collective grade range of your school 
                                    sites in Peachjar under the <span className={styles2.districtSettings} onClick={() => history.push(Routes.districts.update.from(hierarchyId))}>District & School Settings</span>.</li>
                                <li>Each <strong>row represents 1 child</strong> in your CSV file and can contain <strong>up to 5 email addresses</strong> in separate columns.</li>
                                <li>Your file size should be less than <strong>50 MB</strong>.</li>
                            </ul>
                        </Paragraph>
                        <div>
                            <InlineNotification 
                                variant='info' 
                                message={<InfoMessage/>} 
                                action=
                                    {<ButtonSecondarySmall
                                        onClick= {() => history.push(Routes.districts.mapping.from(hierarchyId))}
                                    >
                                    Parent List Mapping
                                    </ButtonSecondarySmall>}
                            />
                        </div>
                        <br/><br/>
                        <div>
                            <Paragraph>
                                <strong>Mapping Status:</strong>
                                <br/><br/>
                                <div className='mappingStatus' style={styles.mappingStatus}>
                                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#dsffwl9pda)">
                                        <circle cx="28" cy="28" r="28" fill="#fff"/>
                                        <rect x="11" y="16.723" width="2.621" height="34" rx="1" transform="rotate(-16 11 16.723)" fill="#47506B"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="m10.389 18.894 29.799-8.545L45.7 29.574l-29.799 8.545-5.512-19.225z" fill="#757D99"/>
                                        <path d="M17.057 30.214c.558.28 1.328.321 2.055.113 1.16-.332 1.645-1.09 1.424-1.86-.298-1.037-1.313-.952-2.07-.885-.495.039-.851.038-.925-.222-.07-.242.147-.463.545-.578.51-.146 1.049-.066 1.377.13l.216-.877c-.5-.212-1.132-.293-1.842-.09-1.099.316-1.537 1.106-1.336 1.807.285.995 1.26.902 2.019.835.503-.041.896-.042.98.253.075.259-.103.488-.58.624-.518.15-1.223.042-1.608-.156l-.255.906zm6.074-1.04c.459-.13.718-.336.84-.53l-.471-.764c-.044.078-.182.183-.346.23-.242.07-.438-.09-.512-.35l-.541-1.885.848-.243-.276-.96-.848.242-.327-1.142-1.107.318.327 1.142-.692.198.275.96.692-.198.626 2.18c.213.744.742 1.023 1.512.803zm4.001-1.259 1.099-.315-.774-2.7c-.345-1.202-1.328-1.332-2.28-1.059-.657.188-1.255.585-1.636 1.18l.626.617a1.83 1.83 0 0 1 1.078-.843c.554-.158.996.014 1.118.438l.166.58c-.373-.258-.916-.29-1.47-.131-.666.19-1.352.771-1.071 1.749.268.934 1.184 1.158 1.85.967.545-.156.981-.496 1.17-.915l.124.432zm-.288-1.003c-.112.294-.423.514-.778.616-.432.124-.852 0-.963-.389-.115-.398.173-.733.606-.857.355-.102.735-.08.986.11l.15.52zm2.477.376 1.098-.316-.791-2.76c.105-.32.532-.657.895-.762.122-.034.228-.056.31-.06l-.307-1.073c-.519.148-.954.591-1.141 1.048l-.164-.571-1.099.315 1.199 4.178zm4.548-1.192c.459-.132.718-.337.84-.532l-.471-.763c-.043.078-.182.183-.346.23-.242.07-.438-.09-.512-.35l-.541-1.885.848-.243-.276-.96-.847.242-.328-1.142-1.107.318.327 1.142-.692.198.276.96.692-.198.625 2.18c.213.744.742 1.023 1.512.803zm1.032-2.44.969-.278-.944-3.943-1.332.382 1.307 3.839zm.157 1.331c.107.372.51.603.89.494.381-.11.6-.519.494-.891a.73.73 0 0 0-.893-.502.73.73 0 0 0-.491.899z" fill="#fff"/>
                                    </g>
                                    <defs>
                                        <clipPath id="dsffwl9pda">
                                            <path fill="#fff" d="M0 0h56v56H0z"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span style={{color: "#707070"}}>
                                    Mapping is to be started.
                                </span>
                                </div>
                            </Paragraph>
                        </div>
                    </div>
                )}
                { hasMapping && (
                    <div style={{ marginBottom: '8px' }}>
                        <InlineNotification 
                            variant='info' 
                            message={<InfoMessage/>} 
                            action=
                                {<ButtonSecondarySmall
                                    onClick= {() => history.push(Routes.districts.mapping.from(hierarchyId))}
                                >
                                Parent List Mapping
                                </ButtonSecondarySmall>}
                        />
                        <br/><br/>
                        <div>
                            <Paragraph>
                                <strong>Mapping Status:</strong>
                                <br/><br/>
                                <div className='mappingStatus' style={styles.mappingStatus}>
                                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#6uqk4d94wa)">
                                        <circle cx="28" cy="28" r="28" fill="#fff"/>
                                        <rect x="13" y="16.722" width="2.621" height="34" rx="1" transform="rotate(-16 13 16.722)" fill="#47506B"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.389 18.893 39.915 11l5.513 19.225-27.527 7.893-5.512-19.225z" fill="#61C16C"/>
                                        <path d="m21.864 30.353.131 1.148a3.47 3.47 0 0 0 .625-.106c.792-.248 1.341-.655 1.437-1.63l.633-6.027-1.317.377-.285 3.578-2.138-2.883-1.307.375 3.235 4.17-.06.444c-.044.282-.23.43-.548.52a1.097 1.097 0 0 1-.406.034zm7.49-2.928 1.22-.35-.86-3c-.383-1.335-1.475-1.48-2.532-1.177-.73.21-1.395.65-1.819 1.312l.696.685c.289-.478.698-.793 1.198-.936.615-.177 1.107.015 1.242.486l.185.644c-.415-.287-1.018-.322-1.634-.145-.74.212-1.502.857-1.19 1.943.298 1.038 1.316 1.287 2.056 1.075.605-.174 1.09-.552 1.3-1.018l.138.481zm-.32-1.115c-.125.327-.47.572-.865.685-.48.138-.946 0-1.07-.432-.127-.442.193-.815.673-.952.394-.113.817-.09 1.097.122l.165.577zm2.977 1.133.132 1.148a3.47 3.47 0 0 0 .625-.106c.792-.248 1.341-.655 1.436-1.63l.633-6.027-1.317.378-.285 3.577-2.137-2.883-1.307.375 3.234 4.17-.06.444c-.043.283-.23.43-.547.52a1.097 1.097 0 0 1-.407.034zm4.191-4.24 1.077-.308-1.049-4.38-1.48.424 1.452 4.265zm.175 1.48c.118.413.566.67.989.549a.809.809 0 0 0 .548-.99.81.81 0 0 0-.992-.558.811.811 0 0 0-.545.999z" fill="#fff"/>
                                    </g>
                                    <defs>
                                        <clipPath id="6uqk4d94wa">
                                            <path fill="#fff" d="M0 0h56v56H0z"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span style={{color: "#707070"}}>
                                    Mapping is running successfully.
                                </span>
                                </div>
                            </Paragraph>
                        </div>
                    </div>
                )}
            </Section>
            {(hasMapping || sisJobsLength > 0) &&
                <Section headline={'File Upload'}>
                    <UploadModeChooser
                        mode={uploadMode}
                        onModeChange={setUploadMode}
                        lastJob={lastJob}
                        hierarchyId={hierarchyId}
                        hierarchyType={hierarchyType}
                        onNeedsRefreshing={dispatchGetSisJobs}
                        hasMapping={hasMapping}
                        previousJobNumber={sisJobsLength}
                    />
                </Section>
            }
            {lastJob && <Section>
                <BoldParagraph  style={{ marginBottom: '8px' }}>Last File Uploaded</BoldParagraph>
                <ButtonSecondarySmall onClick={() =>
                    dispatch(downloadCsvFile({
                        hierarchyId: lastJob?.academic.groupId,
                        hierarchyType: lastJob?.academic.groupType,
                        jobId: lastJob?.jobUuid,
                    }))
                }>
                    Download Last Parent CSV File
                </ButtonSecondarySmall>
            </Section>}
            <Section headline={'Data Sync'}>
                <BoldParagraph  style={{ marginBottom: '8px' }}>Sync Status</BoldParagraph>
                <SyncStatus
                    lastJob={lastJob}
                    onContinuePressed={dispatchGetSisJobs}
                    onSwitchManualUpload={() => setUploadMode(Mode.MANUAL)}
                />
            </Section>
            <Section>
                <Box display='flex' alignItems='center' style={{ marginBottom: '8px' }}>
                    <BoldParagraph >
                        Sync Log
                    </BoldParagraph>
                    <Tooltip title={SYNC_LOGS_TOOLTIP as unknown as string} />
                </Box>
                {sisJobsReq.isLoading()
                    ? <LoadingSpinner />
                    : <>
                        <SisJobTable sisJobs={sisJobs} />
                        <MutedNote >The sync logs display the last 50 file sync jobs.</MutedNote>
                    </>
                }
            </Section>
        </Sections>
    )
}

const SYNC_LOGS_TOOLTIP = <>
    <strong>New/Existing/Removed</strong><br/>
    The number of emails per student in the CSV that were added, already existed, or removed from Peachjar’s system.<br/>
    <br/>
    <strong>Self Managed</strong><br/>
    The number of emails associated with parents who have chosen to manage their own account. These parents are automatically notified of relevant changes from the CSV.<br/>
    <br/>
    <strong>Duplicate</strong><br/>
    The number of emails that are repeated for parents in the CSV. Emails for parents who have multiple students in schools (SIS ID) are not considered duplicates.<br/>
    <br/>
    <strong>Invalid</strong><br/>
    The number of emails in the CSV that are either not validly formatted or associated with an unknown school (SIS ID).<br/>
    <br/>
    <strong>Total</strong><br/>
    The total number of emails present in the CSV.<br/>
</>

export default ParentsPageBodyMapper

import { v4 as uuid } from 'uuid'
import { schools, districts } from './data'
import { UIError } from '../api/CodedError'
import { District, canGoLive as districtCanGoLiveMatcher } from '../api/District'
import { School, canGoLive as schoolCanGoLiveMatcher } from '../api/School'

import launchGroup from '../api/commands/launchGroup'
import wait from './wait'

type GroupLike = {
    id: string,
    live: boolean,
    name: string,
    startAt?: Date | null,
    [key: string]: any,
}

export default function factory(): launchGroup {
    return async (hierarchy) => {

        await wait(1200)

        if (hierarchy.type === 'district') {

            const district = districts.find(d => d.districtId === hierarchy.id)

            if (!district) {
                throw new UIError(
                    'request.resource.not_found.district',
                    'District not found.'
                )
            }

            const now = new Date()

            const districtCanGoLive = districtCanGoLiveMatcher()
            const schoolCanGoLive = schoolCanGoLiveMatcher(district.startAt)

            const groups = [
                districtCanGoLive(district) ? district : null,
                ...(district.schools || []).filter(schoolCanGoLive),
            ].filter(Boolean) as GroupLike[]

            groups.forEach(g => Object.assign(g, {
                live: true,
                launchedAt: now,
                launchedBy: uuid(),
            }))

            return groups.map((g) => {
                let academicGroupType = 'school'
                let academicGroupId = -1
                if ((g as District).districtId) {
                    academicGroupType = 'district'
                    academicGroupId = (g as District).districtId
                } else {
                    academicGroupType = 'school'
                    academicGroupId = (g as School).schoolId
                }
                return {
                    academicGroupId,
                    academicGroupUuid: g.id,
                    academicGroupType,
                    academicGroupName: g.name,
                }
            })

        } else {

            const school = schools.find(s => s.schoolId === hierarchy.id)

            if (!school) {
                throw new UIError(
                    'request.resource.not_found.school',
                    'School not found.'
                )
            }

            if (!school.live && school.startAt && school.startAt <= new Date()) {
                return [{
                    academicGroupType: 'school',
                    academicGroupId: school.schoolId,
                    academicGroupName: school.name,
                    academicGroupUuid: school.id,
                }]
            }

            return []
        }
    }
}

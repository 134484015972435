import { baseConfigExtractor } from '@peachjar/ui/dist/api/factories/ConfigFactory'
import { BaseConfig } from '@peachjar/ui/dist/api/factories/ConfigFactory'

export type Config = BaseConfig & {
    CDN_URL: string,
    REACT_APP_API_BASE_URL: string,
    REACT_APP_GOOGLE_AUTH_URL: string,
    REACT_APP_AUTH_URL: string,
    REACT_PORTAL_DASHBOARD_URL: string,
    REACT_APP_GRAPHQL_BFF_URI: string,
    REACT_APP_PEACHJAR_HOMEPAGE: string,
    REACT_APP_ACCOUNTS_APP_URL: string,
    REACT_APP_INTERNAL_APP_URL: string,
    REACT_APP_PORTAL_APP_URL: string,
    REACT_APP_SENTRY_API_KEY: string,
    REACT_APP_SENTRY_PROJECT_ID: string,
    REACT_APP_GEOCORE_LOGIN_URL: string,
}

export function configFactory(queryString: string, hostname: string): Config {
    const baseConfig = baseConfigExtractor('school-management', hostname)
    return Object.assign({
        CDN_URL: `https://cdn-assets.${baseConfig.DOMAIN}`,
        REACT_APP_API_BASE_URL: `https://internal-api.${baseConfig.DOMAIN}`,
        REACT_APP_GOOGLE_AUTH_URL: `https://internal-bff.${baseConfig.DOMAIN}/auth/google`,
        REACT_APP_AUTH_URL: `https://internal-bff.${baseConfig.DOMAIN}/auth`,
        REACT_PORTAL_DASHBOARD_URL: `https://portal.${baseConfig.DOMAIN}/dashboard`,
        REACT_APP_GRAPHQL_BFF_URI: `https://portal-bff.${baseConfig.DOMAIN}/graphql`,
        REACT_APP_PEACHJAR_HOMEPAGE: `https://${baseConfig.DOMAIN}`,
        REACT_APP_ACCOUNTS_APP_URL: `https://accounts.${baseConfig.DOMAIN}`,
        REACT_APP_INTERNAL_APP_URL: `https://internal.${baseConfig.DOMAIN}`,
        REACT_APP_PORTAL_APP_URL: `https://portal.${baseConfig.DOMAIN}`,
        REACT_APP_SENTRY_API_KEY: baseConfig.ENVIRONMENT === 'dev' ?
            'e452b76ede9f4fd3890d418ea13087aa' :
            '7186a3311bd848fcaf7aec62af09512f',
        REACT_APP_SENTRY_PROJECT_ID: baseConfig.ENVIRONMENT === 'dev' ?
            '1186595' :
            '1217883',
        REACT_APP_GEOCORE_LOGIN_URL: `https://${baseConfig.DOMAIN}/index.php?a=4`,
    }, baseConfig)
}

export function getConfig(domain) {
    switch (domain) {
        case 'portal-bff.peachjar.com':
            return {
                REACT_APP_BFF_URI: 'https://portal-bff.peachjar.com'
            }
        case 'internal-bff.peachjar.com':
            return {
                REACT_APP_BFF_URI: 'https://internal-bff.peachjar.com'
            }
        case 'portal-bff.peachjar-kauai.com':
            return {
                REACT_APP_BFF_URI: 'https://portal-bff.peachjar-kauai.com'
            }
        case 'internal-bff.peachjar-kauai.com':
            return {
                REACT_APP_BFF_URI: 'https://internal-bff.peachjar-kauai.com'
            }
        default:
            return {
                REACT_APP_BFF_URI: 'https://portal-bff.peachjar.net:10443'
            }
    }
}

export default getConfig(window.location.hostname)

import React, { FunctionComponent, useEffect, useState } from 'react'
import TransitionalGroupTypeSelect from './TransitionalGroupTypeSelect'
import SwitchInput from '@peachjar/ui/dist/lib/components/Inputs/SwitchInput'

import { TransitionalGroupTypes } from '../../../api/School'
import { InputProps } from '@peachjar/ui/dist/lib/components/Inputs/InputProps'
import { Grid } from '@material-ui/core'

type Props = InputProps<TransitionalGroupTypes>

const TransitionalGroupTypeInput: FunctionComponent<Props> = ({
    value,
    onChange,
}) => {
    
    const [fakeEnabled, setFakeEnabled] = useState(value !== undefined && value === TransitionalGroupTypes.ParentGroup)

    useEffect(() => {
        onChange(fakeEnabled ? TransitionalGroupTypes.ParentGroup : TransitionalGroupTypes.School)
    }, [fakeEnabled])

    return (
        <Grid container style={{ maxWidth: '440px' }}>
            <Grid item xs={12}>
                <SwitchInput
                    name={'fake'}
                    label={'Parent Group (Fake School)'}
                    value={fakeEnabled}
                    onChange={setFakeEnabled}
                />
            </Grid>
        </Grid>
    )
}

export default TransitionalGroupTypeInput

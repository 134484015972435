import { normalizeStaffJobForUI, GQLSisJob, GQLStaffJob } from './normalizeModels';

import ApolloClient from 'apollo-boost'

import createStaffJob from '../../api/commands/createStaffJob'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    mutation createStaffJob($input: SisJobInput) {
        job: createJobForUploadValidateStaffCsv(input: $input) {
            academic {
                groupId
                groupType
            },
            isManual,
            jobUuid
            status
            createdAt
            targetIds
            details {
                invalidRecords {
                    invalidSchools
                    invalidEmails {
                        schoolName
                        numberOfRecords
                    }
                    invalidGrades
                }
                validRecordsDetails {
                    staffAdded
                    staffUpdated
                    staffRemoved
                    staffDupeCount
                    existing
                    total
                    percentageChange
                }
            }
            error {
                filename
                message
            }
        }
    }
`

type MutationResult = {
    job: GQLStaffJob,
}

export default function factory({ apolloClient }: Deps): createStaffJob {
    return async (identifiers) => {

        try {

            const result = await apolloClient.mutate<MutationResult>({
                mutation: QUERY,
                variables: {
                    input: {
                        academic: {
                            groupType: identifiers.hierarchyType,
                            groupId: identifiers.hierarchyId,
                        },
                        jobUuid: identifiers.jobId,
                    }
                },
            })

            return normalizeStaffJobForUI(result.data!.job!)

        } catch (error) {
            throw mapError(error)
        }
    }
}

import React, { FunctionComponent } from 'react'
import SortableTable from '@peachjar/ui/dist/lib/components/SortableTable/SortableTable2'
import Desert from '@peachjar/ui/dist/lib/components/Illustrations/Desert'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import JobStatusTag from './JobStatusTag'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Box from '@material-ui/core/Box'
import { DateTime } from 'luxon'
import { orderBy } from 'lodash'
import { JobStatus, SisJob } from '../../../api/SisJob'
import { useDispatch } from 'react-redux'
import { downloadErrorFile } from '../../../redux/parentSettings'
import { ButtonFlatSmall } from '@peachjar/ui/dist/lib/components/Buttons'
import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'

const { Dark: { Paragraph } } = Typo

type Props = {
    sisJobs: SisJob[]
}

const SisJobTable: FunctionComponent<Props> = ({
    sisJobs,
}) => {
    const jobs = orderBy(sisJobs, s => s.createdAt, 'desc')
    const dispatch = useDispatch()

    if (jobs.length === 0) return (
        <div className={classNames.emptyState}>
            <Box style={{ textAlign: 'center', maxHeight: '120px' }}>
                <Desert />
            </Box>
            <Box style={{ textAlign: 'center', paddingTop: '16px' }}>
                <Paragraph >
                    Upon uploading your parent email list, you can view the results of recent file sync jobs.
                </Paragraph>
            </Box>
        </div>
    )

    return (
        <SortableTable<SisJob>
            columnCount={7}
            items={jobs}
            thead={({asSortableLabel}) => {
                return (
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>{asSortableLabel('Date & Time', 'createdAt')}</TableCell>
                        <TableCell>New</TableCell>
                        <TableCell>Existing</TableCell>
                        <TableCell>Self Managed</TableCell>
                        <TableCell>Duplicate</TableCell>
                        <TableCell>Invalid</TableCell>
                        <TableCell><strong>Total</strong></TableCell>
                        <TableCell>Removed</TableCell>
                    </TableRow>
                )
            }}
            tbody={(sisJob) => {
                const created = DateTime.fromJSDate(sisJob.createdAt).toLocal()
                const hideNumbers = [JobStatus.Paused, JobStatus.Failed, JobStatus.Cancelled].includes(sisJob.status)
                const EMPTY = '- -'
                return (
                    <TableRow hover key={sisJob.jobUuid}>
                        <TableCell>
                            {sisJob.isManual ? 'Manual' : 'SFTP'}
                        </TableCell>
                        <TableCell>
                            <JobStatusTag status={sisJob.status}></JobStatusTag>
                        </TableCell>
                        <TableCell>
                            {sisJob.status === JobStatus.Paused
                                ? EMPTY
                                : created.toFormat('ff ZZZZ')
                            }
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : sisJob.details.validRecordsDetails?.new || EMPTY
                            }
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : sisJob.details.validRecordsDetails?.old || EMPTY
                            }
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : sisJob.details.validRecordsDetails?.selfManaged || EMPTY
                            }
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : sisJob.details.validRecordsDetails?.duplicates || EMPTY
                            }
                        </TableCell>
                        <TableCell>
                            {hideNumbers || !sisJob.details.validRecordsDetails?.invalids
                                ? EMPTY
                                : <Box display='flex' alignItems='center'>
                                    {sisJob.details.validRecordsDetails?.invalids}
                                    <ButtonFlatSmall
                                        onClick={() => dispatch(downloadErrorFile({
                                            hierarchyId: sisJob?.academic.groupId!,
                                            hierarchyType: sisJob?.academic.groupType!,
                                            jobId: sisJob?.jobUuid!,
                                        }))}
                                    >
                                        View
                                    </ButtonFlatSmall>
                                </Box>
                            }
                        </TableCell>
                        <TableCell>
                            <strong>{hideNumbers
                                ? EMPTY
                                : sisJob.details.validRecordsDetails?.total || EMPTY
                            }</strong>
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : sisJob.details.validRecordsDetails?.removed || EMPTY 
                            }
                        </TableCell>
                    </TableRow>
                )
            }}
        />
    )
}

const classNames = {
    emptyState: css`
        padding: 24px;
        border: 1px solid ${colors.silver};
        border-radius: 3px;
    `,
}

export default SisJobTable

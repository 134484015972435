import * as yup from 'yup'
import { css } from 'emotion'
import React, { FunctionComponent, useEffect, useMemo } from 'react'
import Form from '@peachjar/ui/dist/lib/components/Form/FormWrapper'
import WithTooltip from '@peachjar/ui/dist/lib/components/Form/WithTooltip'
import TextField from '@peachjar/ui/dist/lib/components/Inputs/TextInputWrapper'
import AddressInput from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import SwitchInput from '@peachjar/ui/dist/lib/components/Inputs/SwitchInput'
import GradeRangeField from '../../../components/Form/GradeRangeField'
import GraphIds from '../../../components/GraphIDs'
import FlyerboardButtons from '../../../components/FlyerboardButtons'
import CalendarInput from '@peachjar/ui/dist/lib/components/Inputs/CalendarInput'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'
import TransitionalGroupTypeInput from './TransitionalGroupTypeInput'
import LaunchedAt from '../../../components/LaunchedAt/LaunchedAt'

import { toString } from 'lodash'
import { SchoolPermissions } from '../../../api/permissions/SchoolPermissions'
import { School, TransitionalGroupTypes } from '../../../api/School'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { GradeLevels } from '../../../api/GradeLevels'
import { SiteInfo, toSiteInfo } from '../../../api/SiteInfo'
import { getAuthUsersReq } from '../../../redux/authUserSelectors'
import useFormBuilder, { Field, yupVal } from '@peachjar/ui/dist/lib/hooks/useFormBuilder'

const SIS_ID_TOOLTIP = 'A unique identifier for your school. Use the ID from your School Information System (SIS) if your district is using one, otherwise enter a custom ID. The SIS ID can be a combination of numbers and letters.'
const DISCLAIMER_TOOLTIP = 'Displays on the bottom of all emails your parents receive from Peachjar and on the bottom of your district and school flyerboards.'

export type ExternalErrors = {
    sisId: {
        message: string,
        lastNonUniqueVal: string | null,
    },
}

type Props = {
    isEdit?: boolean,
    isIndependent?: boolean,
    school?: School | null,
    permissions?: SchoolPermissions,
    errors: ExternalErrors,
    // This is used to watch/reset form state when an update is successful.
    lastUpdated?: Date | null,
    isSubmitting: boolean
    onSubmit: (updates: Partial<School>) => void,
    onCancel?: () => void,
}

const SchoolForm: FunctionComponent<Props> = ({
    isEdit = true,
    isIndependent = true,
    school = null,
    permissions,
    errors,
    lastUpdated = null,
    isSubmitting,
    onSubmit,
    onCancel = () => {},
}) => {
    const isNcesAddress = school?.address?.googlePlaceId === 'nces'
    const ncesAddress = school?.address?.address
    const authUser = getAuthUsersReq();
    
    const isInternalUser = () => authUser.loginType === 'internal' 

    if (isNcesAddress) {
        school = Object.assign({}, school, { address: null })
    }
    school = Object.assign({}, school, { invisible: isEdit ? !school?.invisible : false })

    const formBindings = useFormBuilder<School>(school || null, [
        {
            path: 'name',
            initial: school?.name || '',
            validate: yupVal(yup.string().required('You missed this field.')),
            permission: isEdit ? permissions?.editName : (permissions?.name || Grants.Write),
        },
        {
            path: 'active',
            initial: school?.active || true,
            useCheckedRules: true,
            permission: permissions?.active || Grants.Write,
        },
        {
            path: 'invisible',
            initial: school?.invisible || true,
            useCheckedRules: true,
            permission: permissions?.invisible || Grants.Write,
        },
        {
            path: 'startAt',
            initial: school?.startAt || null,
            permission: permissions?.startAt || Grants.Write,
        },
        {
            path: 'ncesId',
            initial: school?.ncesId || '',
            validate: (value: string) => {
                if (!value) {
                    return { isValid: true }
                }
                if (![8, 12].includes(value.length)) {

                    return { isValid: false, error: 'Must be either 8 or 12 characters long.' }
                }
                if (!(/^[0-9a-zA-Z]+$/.test(value))) {
                    return { isValid: false, error: 'Must only be numbers or letters' }
                }
                return { isValid: true }
            },
            permission: permissions?.ncesId || Grants.Write,
        },
        !isEdit ? null :
        {
            path: 'sisId',
            initial: school?.sisId || '',
            permission: permissions?.sisId || Grants.Write,
            validate: (value: string) => {
                if (!value) {
                    return {
                        isValid: false,
                        error: 'You missed this field.',
                    }
                }
                if (errors.sisId.lastNonUniqueVal === value) {
                    return {
                        isValid: false,
                        error: errors.sisId.message,
                    }
                }
                return { isValid: true }
            },
        },
        {
            path: 'address',
            initial: school?.address || null,
            validate: yupVal(yup.object().required('You missed this field.').typeError('You missed this field.')),
            permission: permissions?.address || Grants.Write,
        },
        {
            path: 'enrollmentCount',
            initial: toString(school?.enrollmentCount || ''),
            validate: yupVal(yup.number()
                .integer('Enter a whole number.')
                .typeError('Enter a whole number.')
                .min(1, 'Must be greater than zero.')
                .required('You missed this field.')),
            permission: permissions?.enrollmentCount || Grants.Write,
        },
        {
            path: 'generalDisclaimer',
            initial: toString(school?.generalDisclaimer || ''),
            validate: yupVal(
                yup.string().nullable(true)
            ),
            permission: permissions?.generalDisclaimer || Grants.Write,
        },
        {
            path: 'lowGrade',
            initial: school?.lowGrade || GradeLevels.Kindergarten,
            permission: permissions?.lowGrade || Grants.Write,
            validate: yupVal(
                yup.string()
                    .oneOf(Object.values(GradeLevels), 'You missed this field.')
                    .required('You missed this field.')
            ),
        },
        {
            path: 'highGrade',
            initial: school?.highGrade || GradeLevels.Grade5,
            permission: permissions?.highGrade || Grants.Write,
            validate: yupVal(
                yup.string()
                    .oneOf(Object.values(GradeLevels), 'You missed this field.')
                    .required('You missed this field.')
            ),
        },
        {
            path: 'transitionalGroupType',
            initial: school?.transitionalGroupType || TransitionalGroupTypes.School,
            permissions: permissions?.transitionalGroupType || Grants.Write,
        }
        // {
        //     path: 'treeCounter',
        //     initial: school?.treeCounter || true,
        //     useCheckedRules: true,
        //     permission: permissions?.treeCounter || Grants.Write,
        // },
    ].filter(Boolean) as Array<Field<School, any>>)

    const { bindings, reset } = formBindings

    useEffect(() => {
        reset(formBindings.value)
    }, [lastUpdated])

    const sites: SiteInfo[] = useMemo(() => {
        if (school) {
            return [toSiteInfo(school)]
        }
        return []
    }, [school])

    return (
        <div className={styles.container}>
            <Form<School>
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                submitLabel={isEdit ? 'Update' : 'Add'}
                onCancel={onCancel}
                formBindings={formBindings}
                fields={[
                    <TextField name={'name'} label={'School Name'} {...bindings.name} />,
                    [
                        permissions?.internal,
                        <>
                            <SwitchInput name={'active'} label={'Active'} {...bindings.active} />
                            <br />
                            <SwitchInput name={'invisible'} label={'Visible'} {...bindings.invisible} />
                        </>,
                    ],
                    [
                        permissions?.startAt,
                        !!school && school.live ?
                            <LaunchedAt launchedAt={school.launchedAt!} /> :
                            <CalendarInput
                                label='Start Date'
                                {...bindings.startAt}
                                value={bindings.startAt?.value}
                                onChange={(v) => bindings.startAt?.onChange(v)}
                            />,
                    ],
                    [
                        permissions?.id,
                        school?.schoolId ?
                            <GraphIds
                                primaryId={school?.schoolId}
                                graphId={school?.id}
                                permission={permissions?.schoolId}
                            /> : null,
                    ],
                    !isEdit ? null :
                    <WithTooltip tooltip={SIS_ID_TOOLTIP}>
                        <TextField name={'sisId'} label={'SIS ID'} {...bindings.sisId} />
                    </WithTooltip>,
                    [
                        permissions?.ncesId,
                        <TextField name={'ncesId'} label={'NCES ID (optional)'} {...bindings.ncesId} />,
                    ],
                    isNcesAddress ?
                        <InlineNotification
                            variant='info'
                            fullWidth={false}
                            message={<>
                                Please verify the NCES supplied address with Google:<br />
                                <strong>{ncesAddress}</strong>
                            </>}
                        /> : null,
                    <AddressInput label='Physical Address' {...bindings.address} />,
                    <TextField
                        type={'number'}
                        name={'enrollmentCount'}
                        label={'Enrollment Count'}
                        {...bindings.enrollmentCount}
                    />,
                    <GradeRangeField bindLowGrade={bindings.lowGrade} bindHighGrade={bindings.highGrade} />,
                    isInternalUser() ? <TransitionalGroupTypeInput {...bindings.transitionalGroupType} /> : null,
                    school ? <FlyerboardButtons sites={sites} /> : null,
                    isIndependent ?
                        <WithTooltip tooltip={DISCLAIMER_TOOLTIP}>
                            <TextField
                                {...bindings.generalDisclaimer}
                                name={'generalDisclaimer'}
                                label={'General Disclaimer (optional)'}
                                multiline
                            />
                        </WithTooltip> : null,
                    // isIndependent ?
                    //     <SwitchInput
                    //         name={'treeCounter'}
                    //         label={'Tree counter'}
                    //         {...bindings.treeCounter}
                    //     /> : null,
                ]}
            />
        </div>        
    )
}

const styles = {
    container: css`
        .MuiInput-underline.Mui-disabled:before {
            border-bottom: none;
        }
    `
}

export default SchoolForm

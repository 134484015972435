import React, { FunctionComponent, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Section } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { ButtonPrimaryLarge, ButtonFlatLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { getCsvMapping } from '../../../redux/parentSettings'
import { useDispatch } from 'react-redux'
import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core'

type Props = {
    hierarchyId: number,
    hierarchyType: string,
    school_sis_id: string,
    grades: string,
    emails: string,
    emails2: string,
    emails3: string,
    emails4: string,
    emails5: string,
    pkMap: Array<string>,
    kgMap: Array<string>,
    grade1Map: Array<string>,
    grade2Map: Array<string>,
    grade3Map: Array<string>,
    grade4Map: Array<string>,
    grade5Map: Array<string>,
    grade6Map: Array<string>,
    grade7Map: Array<string>,
    grade8Map: Array<string>,
    grade9Map: Array<string>,
    grade10Map: Array<string>,
    grade11Map: Array<string>,
    grade12Map: Array<string>,
    gradeCEMap: Array<string>,
    gradeLevelList: Array<any>,
    setPage: any,
    setSisId: any,
    setEmails: any
    setGrades: any,
    gradeLevelMap: any,
    setTabNumber: any,
    clearState: any
}

const MapSummary: FunctionComponent<Props> = ({
    hierarchyId,
    hierarchyType,
    school_sis_id,
    grades,
    emails,
    emails2,
    emails3,
    emails4,
    emails5,
    gradeLevelMap,
    clearState
}) => {

    // initialize state and global values
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)

    // get mapping on first render
    useEffect(() => {
        dispatch(getCsvMapping({ hierarchyId, hierarchyType }))
    }, [])

    return (
        <Grid container style={{ marginBottom: '24px'}} className="summaryPage">
            <Grid container item xs={6} className="verticalGrid1">
            <Section headline={'Parent List Mapping Overview'}>
                <Grid container item>
                    <Grid item xs={5}>
                        <p>Required Data</p>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={5}>
                        <p>CSV File Data Columns</p>
                    </Grid>
                </Grid>
                <Grid container item alignItems='center'>
                    <Grid item xs={5}>
                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="School SIS ID" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={school_sis_id} InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}/><br/>
                    </Grid>
                </Grid><br/>
                <Grid container item alignItems='center'>
                    <Grid item xs={5}>
                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="Email" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={emails} InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}/><br/>
                    </Grid>
                </Grid><br/>
                {grades !== "" && (
                    <div>
                        <Grid container item>
                            <Grid item xs={5}>
                                <p style={{color: "grey"}}>Required for grade distribution</p>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={5}>
                                <p></p>
                            </Grid>
                        </Grid>
                        <Grid container item alignItems='center'>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="Grade" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                            <div style={{textAlign: "center"}}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#yz0zwx405a)">
                                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                    </g>
                                    <defs>
                                        <clipPath id="yz0zwx405a">
                                            <path fill="#fff" d="M0 0h24v24H0z"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={grades} InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}/><br/>
                            </Grid>
                        </Grid><br/>
                    </div>
                )}
                {((emails2 != null && emails2 !== "") || (emails3 != null && emails3 !== "") || (emails4 != null && emails4 !== "") || (emails5 != null && emails5 !== "")) && (
                    <div>
                        <Grid container item>
                            <Grid item xs={5}>
                                <p>Optional Data</p>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={5}>
                                <p>CSV file Data Columns</p>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {(emails2 != null && emails2 !== "")&& (
                    <div>
                        <Grid container item alignItems='center'>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="Email 2" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={emails2} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                )}
                {(emails3 != null && emails3 !== "") && (
                    <div>
                        <Grid container item alignItems='center'>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="Email 3" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={emails3} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                )}
                {(emails4 != null && emails4 !== "") && (
                    <div>
                        <Grid container item alignItems='center'>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="Email 4" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={emails4} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                )}
                {(emails5 != null && emails5 !== "") && (
                    <div>
                        <Grid container item alignItems='center'>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="Email 5" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{textAlign: "center"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#yz0zwx405a)">
                                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                        </g>
                                        <defs>
                                            <clipPath id="yz0zwx405a">
                                                <path fill="#fff" d="M0 0h24v24H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={emails5} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                            </Grid>
                        </Grid>
                        <br/>
                    </div>
                )}
                {grades !== "" && (
                    <div>
                        <Grid container item style={{marginTop: "32px"}}>
                            <Grid item xs={5}>
                                <p>District Grades</p>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={5}>
                                <p>CSV File Grades</p>
                            </Grid>
                        </Grid>
                        {gradeLevelMap.prekindergarten &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="PK" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.prekindergarten.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.kindergarten &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="KG" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.kindergarten.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade1 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="1" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade1.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade2 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="2" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade2.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade3 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="3" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade3.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade4 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="4" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade4.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade5 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="5" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade5.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade6 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="6" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade6.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade7 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="7" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade7.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade8 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="8" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade8.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade9 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="9" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade9.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade10 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="10" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade10.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade11 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="11" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade11.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.grade12 &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="12" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.grade12.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                        {gradeLevelMap.continueEducation &&
                            <div>
                                <Grid container item alignItems='center'>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value="CE" InputProps={{readOnly: true, style: { fontWeight: 'bold', fontSize: '14px', height: '32px', color: '#707070'}}}></TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{textAlign: "center"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#yz0zwx405a)">
                                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="yz0zwx405a">
                                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField type="text" variant="outlined"  style={{width: '100%'}}  value={gradeLevelMap.continueEducation.join(', ')} InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </div>
                        }
                    </div>
                )}
            </Section>
            </Grid><br/>
            <Grid container
                justifyContent="flex-end"
                className="buttonContainer"
                alignItems="center">
                    <ButtonPrimaryLarge
                    className="changeMappingButton1" 
                        onClick= {() => {setOpen(true)}}
                    >
                        Change Mapping
                    </ButtonPrimaryLarge>
                    <Modal
                        title=''
                        open={open}
                        width='sm'
                        close={() => setOpen(false)}>
                            <ModalHeader>Change Mapping</ModalHeader>
                            <DialogContent style={{ padding: '16px' }}>
                                <DialogContentText id="modalText">
                                You are about to change your parent list mapping from uploading a recent parent list. The current 
                                parent sync will stop working until your new mapping is in place.
                                </DialogContentText>
                                <DialogActions className="actions" style={{ padding: '16px 32px' }}>
                                    <ButtonFlatLarge className="cancelButton" onClick={() => setOpen(false)}>Cancel</ButtonFlatLarge>
                                    <ButtonFlatLarge className="changeMappingButton2" onClick={clearState}>Change Mapping</ButtonFlatLarge>
                                </DialogActions>
                            </DialogContent>
                  </Modal>
            </Grid>
        </Grid>
    )
}

export default MapSummary
import { normalizeSchoolForUI, GQLSchool } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import getSchoolsByDistrictNcesId from '../../api/queries/getSchoolsByDistrictNcesId'
import gql from 'graphql-tag'
import mapError from './mapError'
import { NcesSchool } from '../../api/NcesSchool'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    query getSchoolsByDistrictNcesId($ncesId: String!) {
        result: getPreCreationNcesDistrictSchoolAssociations(input: { ncesId: $ncesId }) {
            existing {
                id
                schoolId
                name
                active
                visible
                treeCounter
                sisId
                ncesId
                address {
                    googlePlaceId
                    address
                }
                lowGrade
                highGrade
                enrollmentCount
                startAt
                generalDisclaimer
                isIndependent
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
            nces {
                id: ncesId
                name
                address
                city
                state
                districtName
                districtNcesId
                lowGrade
                highGrade
                enrollment: enrollmentCount
            }
        }
    }
`

type QueryResult = {
    result: {
        existing: GQLSchool[],
        nces: NcesSchool[],
    }
}

export default function factory({ apolloClient }: Deps): getSchoolsByDistrictNcesId {
    return async (ncesId) => {
        try {

            const result = await apolloClient.query<QueryResult>({
                query: QUERY,
                fetchPolicy: 'network-only',
                variables: { ncesId },
            })

            return {
                existing: result.data.result.existing.map(normalizeSchoolForUI),
                nces: result.data.result.nces,
            }

        } catch (error) {

            throw mapError(error)
        }
    }
}

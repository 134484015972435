import React, { FunctionComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import { useDispatch } from 'react-redux'
import SiteSelectionAutocomplete from './SiteSelectionAutocomplete'
import RoleSelectionAutocomplete from './RoleSelectionAutocomplete'

import { Roles, getRoleName } from '../../../api/Role'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { SiteInfo } from '../../../api/SiteInfo'
import { UsersListFilters } from './UsersListFilters'
import { setStaffFilters, setVolunteerFilters } from '../../../redux/usersAndRoles'
import { downloadUsersCsv } from '../../../redux/parentSettings'
import { getHierarchy } from '../../../redux/approvalSettingsSelectors'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonBar: {
            marginBottom: '8px',
        },
    })
)

type Props = {
    sites: SiteInfo[],
    filters: UsersListFilters,
    isVolunteerList?: boolean,
    onAddClick: (isVolunteer: boolean) => void,
    onBulkAddClick: (isVolunteer: boolean) => void,
    onSiteFilterUpdated: (siteId: number) => void,
    onRoleFilterUpdated: (roleSelection: Roles) => void,
}

const UserListActionBar: FunctionComponent<Props> = ({
    sites,
    filters,
    isVolunteerList = false,
    onAddClick,
    onBulkAddClick,
    onSiteFilterUpdated,
    onRoleFilterUpdated,
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const hierarchy = getHierarchy()

    const onSetFilter = (field: keyof(UsersListFilters), value: Roles) => {
        if ( isVolunteerList ) {
            dispatch(setVolunteerFilters({
                [field]: value
            }))
        } else {
            dispatch(setStaffFilters({
                [field]: value
            }))
        }
    }

    const showDownloadStaff = hierarchy && !isVolunteerList

    return (
        <div>
            <Grid className={classes.buttonBar} container justify='space-between'>
                <Grid item xs={"auto"} style={{ textAlign: 'left' }}>
                    <ButtonSecondarySmall onClick={() => onAddClick(isVolunteerList) }>
                        {isVolunteerList ? 'Add Volunteer' : 'Add Staff'}
                    </ButtonSecondarySmall>
                    {!isVolunteerList &&
                        <ButtonSecondarySmall
                            style={{ marginLeft: '10px' }}
                            onClick={() => onBulkAddClick(isVolunteerList) }
                        >
                            {'Bulk Manage Staff'}
                        </ButtonSecondarySmall>
                    }

                    { showDownloadStaff && <ButtonSecondarySmall 
                        style={{ marginLeft: '10px' }}
                        onClick={() => dispatch(
                            downloadUsersCsv({
                                hierarchyType: hierarchy!.hierarchyType,
                                hierarchyId: hierarchy!.hierarchyId,
                                isStaff: true
                            })
                        )}
                    >
                        Download Staff List
                    </ButtonSecondarySmall> }
                </Grid>
                <Grid item xs={'auto'}>
                    <Grid container alignContent='flex-end' alignItems='flex-end' justify='flex-end'>
                        {sites.length > 1 &&
                            <span style={{marginRight: '8px'}}>
                                <SiteSelectionAutocomplete
                                    key={`sites_${isVolunteerList ? 'vol' : 'staff'}_${filters.site}`}
                                    value={filters.site}
                                    sites={sites}
                                    onSiteSelected={(site) => {
                                        onSiteFilterUpdated(site.id)
                                        onSetFilter('site', site.id)
                                    }}
                                />
                            </span>
                        }
                        <RoleSelectionAutocomplete
                            key={`roles_${isVolunteerList ? 'vol' : 'staff'}_${getRoleName(filters.role)}`}
                            value={filters.role}
                            only={isVolunteerList ? [Roles.None, Roles.Uploader] : undefined}
                            onRoleSelected={(role) => {
                                onRoleFilterUpdated(role)
                                onSetFilter('role', role)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default UserListActionBar

import React, { FunctionComponent } from 'react'
import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import SpinningSubmitButton from '@peachjar/ui/dist/lib/components/Buttons/SpinningSubmit'
import Grid from '@material-ui/core/Grid'

import { District } from '../../../api/District'
import { School } from '../../../api/School'
import { ButtonFlatLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

import colors from '@peachjar/ui/dist/lib/styles/colors'

const LineItem: FunctionComponent<{}> = ({ children }) => (
    <Grid item xs={12} style={{ marginTop: '8px' }}>{children}</Grid>
)

type Props = {
    isOpen: boolean,
    launchableSchools: School[],
    isSubmitting: boolean,
    onSubmit: () => void,
    cancel: () => void,
}

const GoLiveModal: FunctionComponent<Props> = ({
    isOpen,
    launchableSchools,
    isSubmitting,
    onSubmit,
    cancel,
}) => {

    return (
        <Modal title='' open={isOpen} close={() => cancel()}>
            <ModalHeader>Launch Schools</ModalHeader>
            <DialogContent style={{ padding: '16px' }}>
                <DialogContentText style={{ color: colors.prussian }}>
                    <div>These are the schools that have a start date that is today
                        or in the past. Pushing the "Launch" button, performs
                        the following functions at each school listed below:
                        (a) Sends out queued up parent welcome emails,
                        (b) enables flyer email notifications to parents, and
                        (c) enables school flyer boards (Peachjar buttons).
                    </div>
                    <Grid container>
                        {launchableSchools.length > 0 && launchableSchools.map((school) =>
                            <LineItem>{school.name}</LineItem>
                        )}
                        {launchableSchools.length === 0 && (
                            <LineItem>
                                <span style={{ color: colors.dragon }}>No schools will be launched.</span>
                            </LineItem>
                        )}
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '16px 32px' }}>
                <ButtonFlatLarge onClick={() => cancel()} color='primary'>Cancel</ButtonFlatLarge>
                <SpinningSubmitButton
                    onClick={() => onSubmit()}
                    buttonType='flatLarge'
                    disabled={false}
                    loading={isSubmitting}
                >Launch</SpinningSubmitButton>
            </DialogActions>
        </Modal>
    )
}

export default GoLiveModal

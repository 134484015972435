import { v4 as uuid } from 'uuid'
import { School, TransitionalGroupTypes } from '../api/School'
import { districts, schools } from './data'

import createSchool, { CreateSchoolOptions, CreateSchoolParams } from '../api/commands/createSchool'
import wait from './wait'
import errorFactory from './errorFactory'

let i = 50000

export default function factory(): createSchool {

    const checkError = errorFactory()

    return async (
        params: CreateSchoolParams,
        options: CreateSchoolOptions = {}
    ): Promise<School> => {

        await wait(1000)

        checkError(params.generalDisclaimer || '')
        checkError(params.sisId || '')

        i += 1
        const school: School = Object.assign({
            id: uuid(),
            treeCounter: params.treeCounter || null,
            startAt: params.startAt || null,
            live: false,
            schoolId: i,
            transitionalGroupType: TransitionalGroupTypes.School,
            launchedBy: null,
            launchedAt: null,
            createdAt: new Date(),
            createdBy: uuid(),
            updatedAt: new Date(),
            updatedBy: uuid(),
        }, params)
        if (options.associateToDistrict) {
            const district = districts.find(d => d.districtId === options.associateToDistrict)
            if (district) {
                district.schools?.push(school)
                school.district = district
                const startAt = (school.startAt || district.startAt)
                if (district.live && startAt && startAt <= new Date()) {
                    school.live = true
                }
            }
        }
        schools.push(school)
        return school
    }
}

import downloadSisCSVFile from '../../api/queries/downloadSisCSVFile'
import { Config } from '../../config'

type Deps = {
    config: Config,
}

export default function factory({ config }: Deps): downloadSisCSVFile {
    return ({ hierarchyType, hierarchyId, jobId, isStaff }) => {
        const uploadType = isStaff ? 'staff' : 'sis'
        const downloadUrl = `https://portal-bff.${config.DOMAIN}/downloads/${uploadType}/${hierarchyType}/${hierarchyId}/jobs/${jobId}/csv`
        window.open(downloadUrl, '_blank')
    }
}

import downloadSisChangeCSVFile from '../../api/queries/downloadSisChangeCSVFile'
import { Config } from '../../config'

type Deps = {
    config: Config,
}

export default function factory({ config }: Deps): downloadSisChangeCSVFile {
    return ({ hierarchyType, hierarchyId, jobId, isStaff }) => {
        const uploadType = isStaff ? 'staff' : 'sis'
        const downloadUrl = `https://portal-bff.${config.DOMAIN}/downloads/${uploadType}/${hierarchyType}/${hierarchyId}/jobs/${jobId}/changecsv`
        window.open(downloadUrl, '_blank')
    }
}

import getUserAndSiteRolesPaginated, { GetUserAndSiteRolesPaginatedParams, UserAndSiteRolesPaginated } from '../api/queries/getUserAndSiteRolesPaginated'

import { users, districts } from './data'
import wait from './wait'

function getDistrictSiteIds(districtId: number): number[] {
    const district = districts.find(d => d.districtId === districtId)
    if (!district) return []
    return [districtId].concat(district.schools?.map(s => s.schoolId) || [])
}

export default function factory(): getUserAndSiteRolesPaginated {
    return async (params: GetUserAndSiteRolesPaginatedParams): Promise<UserAndSiteRolesPaginated> => {

        await wait(1200)

        const matchingSites = params.includeChildSites && params.academicGroupType === 'district' ?
            getDistrictSiteIds(params.academicGroupId) : [params.academicGroupId]

        const filtered = users.filter((user) => {
            if (matchingSites.includes(user.site.id)) {
                return true
            }
        })
        const { limit = 15, offset = 0 } = params

        return {
            items: filtered,
            total: filtered.length,
            limit,
            offset
        }
    }
}

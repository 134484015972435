import React, { FunctionComponent } from 'react'
import SortableTable from '@peachjar/ui/dist/lib/components/SortableTable/SortableTable2'
import Desert from '@peachjar/ui/dist/lib/components/Illustrations/Desert'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import JobStatusTag from '../../Parents/components/JobStatusTag'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Box from '@material-ui/core/Box'
import ArrowDownwardSharp from '@material-ui/icons/ArrowDownwardSharp'
import { DateTime } from 'luxon'
import { orderBy } from 'lodash'
import { JobStatus, StaffJob } from '../../../api/StaffJob'
import { useDispatch } from 'react-redux'
import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'

const { Dark: { Paragraph } } = Typo

type Props = {
    staffJobs: StaffJob[]
}

const StaffJobTable: FunctionComponent<Props> = ({
    staffJobs,
}) => {
    const jobs = orderBy(staffJobs, s => s.createdAt, 'desc')
    const dispatch = useDispatch()

    if (jobs.length === 0) return (
        <div className={classNames.emptyState}>
            <Box style={{ textAlign: 'center', maxHeight: '120px' }}>
                <Desert />
            </Box>
            <Box style={{ textAlign: 'center', paddingTop: '16px' }}>
                <Paragraph >
                    Upon uploading your staff list, you can view the results of recent file sync jobs.
                </Paragraph>
            </Box>
        </div>
    )

    return (
        <SortableTable<StaffJob>
            columnCount={7}
            items={jobs}
            thead={() => {
                return (
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Date & Time <ArrowDownwardSharp style={{ fontSize: 17 }} /></TableCell>
                        <TableCell>New</TableCell>
                        <TableCell>Existing</TableCell>
                        <TableCell>Duplicate</TableCell>
                        <TableCell><strong>Total</strong></TableCell>
                        <TableCell>Removed</TableCell>
                    </TableRow>
                )
            }}
            tbody={(staffJob) => {
                const created = DateTime.fromJSDate(staffJob.createdAt).toLocal()
                const hideNumbers = [JobStatus.Paused, JobStatus.Failed, JobStatus.Cancelled].includes(staffJob.status)
                const EMPTY = '- -'
                return (
                    <TableRow hover key={staffJob.jobUuid}>
                        <TableCell>
                            {staffJob.isManual ? 'Manual' : 'SFTP'}
                        </TableCell>
                        <TableCell>
                            <JobStatusTag status={staffJob.status}></JobStatusTag>
                        </TableCell>
                        <TableCell>
                            {staffJob.status === JobStatus.Paused
                                ? EMPTY
                                : created.toFormat('ff ZZZZ')
                            }
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : staffJob.details.validRecordsDetails?.staffAdded || EMPTY
                            }
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : staffJob.details.validRecordsDetails?.existing || EMPTY
                            }
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : staffJob.details.validRecordsDetails?.staffDupeCount || EMPTY
                            }
                        </TableCell>
                        <TableCell>
                            <strong>{hideNumbers
                                ? EMPTY
                                : staffJob.details.validRecordsDetails?.total || EMPTY
                            }</strong>
                        </TableCell>
                        <TableCell>
                            {hideNumbers
                                ? EMPTY
                                : staffJob.details.validRecordsDetails?.staffRemoved || EMPTY 
                            }
                        </TableCell>
                    </TableRow>
                )
            }}
        />
    )
}

const classNames = {
    emptyState: css`
        padding: 24px;
        border: 1px solid ${colors.silver};
        border-radius: 3px;
    `,
}

export default StaffJobTable

export enum ApprovalConstraint {
  AnySchoolApprover = 'AnySchoolApprover',
  AnyDistrictApprover = 'AnyDistrictApprover',
  SpecificDistrictGroupApprover = 'SpecificDistrictGroupApprover',
  SpecificSchoolApprover = 'SpecificSchoolApprover',
  SpecificDistrictApprover = 'SpecificDistrictApprover',
}

export enum Levels {
  SingleDistrict = 'SingleDistrict',
  DualDistrict = 'DualDistrict',
  DistrictAndSchool = 'DistrictAndSchool',
  SingleSchool = 'SingleSchool',
  Group = 'Group',
}

export enum ApprovalErrors {
  SingleDistrictLastApprover = 'SingleDistrictLastApprover',
  DualDistrictNonScreener = 'DualDistrictNonScreener',
  DualDistrictScreener = 'DualDistrictScreener',
  DistrictAndSchoolLastDistrictApprover = 'DistrictAndSchoolLastDistrictApprover',
  DistrictAndSchoolLastSchoolApprover = 'DistrictAndSchoolLastSchoolApprover',
  DistrictAndSchoolSingleSchoolLastSchoolApprover = 'DistrictAndSchoolSingleSchoolLastSchoolApprover',
  SingleSchoolLastApprover = 'SingleSchoolLastApprover',
  ApprovalGroupApprover = 'ApprovalGroupApprover',
  None = 'None',
}

export type ApprovalGroupAudience = {
  name: string
  audienceId: string
  audienceType: string
}


export type ApprovalGroup = {
  id: number,
  name: string,
  userIds: string[],
  audiences: ApprovalGroupAudience[]
}

type ApprovalLevel = {
  constraint: string
  userId: string
  approvalGroups?: ApprovalGroup[] | null
}

type Workflow = {
  levels: ApprovalLevel[]
}

type Role = {
  userId: string
  email: string
  isApprover: boolean
  site: {
    id: number
    type: string
    name: string
  }
}

type Audience = {
  hierarchyId: string
  hierarchyType: string
  schoolCount: number
  roles: Role[]
}

export type ApprovalSettings = {
  workflows: Workflow[]
  audience: Audience
}

import ApolloClient from 'apollo-boost'
import UserAndSiteRoles from '../../api/UserAndSiteRoles'

import mapError from './mapError'
import gql from 'graphql-tag'
import removeUser from '../../api/commands/removeUser'
import { UIError } from '@peachjar/ui/dist/api/CodedError'

const REMOVE_STAFF_MEMBER = gql`
    mutation removeUser($input: UserAndSiteInput!) {
        result: removeStaffMember(input: $input) {
            success
        }
    }
`

const REMOVE_VOLUNTEER = gql`
    mutation removeUser($input: UserAndSiteInput!) {
        result: removeVolunteer(input: $input) {
            success
        }
    }
`

type GQLResult = {
    result: {
        success: boolean
    }
}

type Deps = {
    apolloClient: ApolloClient<any>,
}

export default function factory({ apolloClient }: Deps): removeUser {
    return async (ur: UserAndSiteRoles): Promise<UserAndSiteRoles> => {
        try {
            const result = await apolloClient.mutate<GQLResult>({
                mutation: ur.isVolunteer ? REMOVE_VOLUNTEER : REMOVE_STAFF_MEMBER,
                variables: {
                    input: {
                        site: ur.site,
                        userId: ur.userId,
                    }
                }
            })

            if (!result.data?.result.success) {
                throw new UIError('internal.bad_implementation', 'Failed to remove user.')
            }

            return ur

        } catch (error) {
            throw mapError(error)
        }
    }
}

/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux'
import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'
import { RootState } from './index'
import { ApprovalSettings } from '../api/ApprovalSettings'
import { Hierarchy } from './approvalSettings'

export const getApprovalSettingsReq = () => {
  return useSelector<RootState, AsyncReqState<ApprovalSettings>>(
    (state) => state.approvals.settings
  )
}
export const getHierarchy = () => {
  return useSelector<RootState, Hierarchy | undefined>(state =>  state.approvals.hierarchy)
}

export const getSettings = () => {
  return useSelector<RootState, ApprovalSettings | null>( state => state.approvals.settings.model)
}

export const getIsSettingsRefresh = () => {
  return useSelector<RootState, boolean>( state => state.approvals.isSettingsRefresh)
}

import ApolloClient from 'apollo-boost'
import UserAndSiteRoles from '../../api/UserAndSiteRoles'

import mapError from './mapError'
import gql from 'graphql-tag'
import getUserAndSiteRolesPaginated, { UserAndSiteRolesPaginated, GetUserAndSiteRolesPaginatedParams } from '../../api/queries/getUserAndSiteRolesPaginated'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const GET_USER_AND_SITE_ROLES_PAGINATED = gql`
    query getUserAndSiteRolesPaginated($input: V2_GetUserAndSiteRolesPaginatedInput) {
        result: getUserAndSiteRolesPaginated(input: $input) {
            items {
                userId
                site {
                    id
                    type
                    name
                }
                firstName
                lastName
                email
                isAdmin
                isApprover
                isUploader
                isVolunteer
            }
            total
            offset
            limit
        }
    }
`

type QueryResult = {
    result: UserAndSiteRolesPaginated
}
export default function factory({ apolloClient }: Deps): getUserAndSiteRolesPaginated {
    return async (input: GetUserAndSiteRolesPaginatedParams): Promise<UserAndSiteRolesPaginated> => {

        try {

            const result = await apolloClient.query<QueryResult>({
                query: GET_USER_AND_SITE_ROLES_PAGINATED,
                fetchPolicy: 'network-only',
                variables: {
                    input,
                },
            })

            return result.data.result

        } catch (error) {
            throw mapError(error)
        }
    }
}

export enum GradeLevels {
    EarlyChildhoodEducation = 'ECE',
    Prekindergarten = 'PK',
    Kindergarten = 'KG',
    Grade1 = '1',
    Grade2 = '2',
    Grade3 = '3',
    Grade4 = '4',
    Grade5 = '5',
    Grade6 = '6',
    Grade7 = '7',
    Grade8 = '8',
    Grade9 = '9',
    Grade10 = '10',
    Grade11 = '11',
    Grade12 = '12',
    ContinuingEducation = 'CE',
    NotGraded = 'N',
}

export const gradeLevels = {
    EarlyChildhoodEducation: 'ECE',
    Prekindergarten: 'PK',
    Kindergarten: 'KG',
    Grade1: '1',
    Grade2: '2',
    Grade3: '3',
    Grade4: '4',
    Grade5: '5',
    Grade6: '6',
    Grade7: '7',
    Grade8: '8',
    Grade9: '9',
    Grade10: '10',
    Grade11: '11',
    Grade12: '12',
    ContinuingEducation: 'CE',
    NotGraded: 'N',
}

export const GradeLevelsList = Object.values(GradeLevels)

export function toOrdinal(level: GradeLevels): number {
    switch (level) {
        case GradeLevels.EarlyChildhoodEducation: return 0
        case GradeLevels.Prekindergarten: return 1
        case GradeLevels.Kindergarten: return 2
        case GradeLevels.Grade1: return 3
        case GradeLevels.Grade2: return 4
        case GradeLevels.Grade3: return 5
        case GradeLevels.Grade4: return 6
        case GradeLevels.Grade5: return 7
        case GradeLevels.Grade6: return 8
        case GradeLevels.Grade7: return 9
        case GradeLevels.Grade8: return 10
        case GradeLevels.Grade9: return 11
        case GradeLevels.Grade10: return 12
        case GradeLevels.Grade11: return 13
        case GradeLevels.Grade12: return 14
        case GradeLevels.ContinuingEducation: return 15
        default: return 16
    }
}

export function createGradeRange(lowGradeInclusive: GradeLevels, highGradeInclusive: GradeLevels): GradeLevels[] {
    let low = toOrdinal(lowGradeInclusive)
    let high = toOrdinal(highGradeInclusive)
    if (low > high) {
        const swap = high
        high = low
        low = swap
    }
    return GradeLevelsList.slice(low, high + 1)
}

export function withinGradeLevels(
    target: GradeLevels,
    lowBound: GradeLevels,
    highBound: GradeLevels
): boolean {
    const targetOrdinal = toOrdinal(target)
    return toOrdinal(lowBound) <= targetOrdinal &&  targetOrdinal <= toOrdinal(highBound)
}

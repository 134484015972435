import { normalizeSisJobForUI, GQLSisJob } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import createSisJob from '../../api/commands/createSisJob'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    mutation createSisJob($input: SisJobInput) {
        job: createJobForUploadValidateCsv(input: $input) {
            academic {
                groupId
                groupType
            },
            hasHeader
            isManual
            jobUuid
            status
            createdAt
            targetIds
            details {
                invalidRecords {
                    invalidSchools
                    invalidEmails {
                        schoolName
                        numberOfRecords
                    }
                    invalidGrades
                }
                validRecordsDetails {
                    old
                    new
                    removed
                    total
                    percentageChange
                }
            }
            error {
                filename
                message
            }
            mapping {
                sisIdMap
                gradeLevelMap
            }
        }
    }
`

type MutationResult = {
    job: GQLSisJob,
}

export default function factory({ apolloClient }: Deps): createSisJob {
    return async (identifiers) => {

        try {

            const result = await apolloClient.mutate<MutationResult>({
                mutation: QUERY,
                variables: {
                    input: {
                        academic: {
                            groupType: identifiers.hierarchyType,
                            groupId: identifiers.hierarchyId,
                        },
                        jobUuid: identifiers.jobId,
                        isMapper: identifiers.isMapper,
                        forceContinue: identifiers.forceContinue,
                    }
                },
            })

            return normalizeSisJobForUI(result.data!.job!)

        } catch (error) {
            throw mapError(error as any)
        }
    }
}

import { css } from 'emotion'

const cn = {
  appHeader: css`
    padding: 0 0 30px 0;
    margin: 0;
  `
}

export default cn

import { v4 as uuid } from 'uuid'

import ApolloClient from 'apollo-boost'

import getSisCSVUploadLink from '../../api/queries/getSisCSVUploadLink'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    query getSisCsvUploadUrl($input: SisJobInput) {
        result: getSisCsvUploadUrl(input: $input) {
            hierarchyType
            hierarchyId
            jobUuid
            uploadUrl
        }
    }
`

type QueryResult = {
    result: {
        hierarchyType: string,
        hierarchyId: number,
        jobUuid: string,
        uploadUrl: string,
    }
}

export default function factory({ apolloClient }: Deps): getSisCSVUploadLink {
    return async ({ csvFileType, hierarchyId, hierarchyType }) => {

        try {

            const result = await apolloClient.query<QueryResult>({
                query: QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    input: {
                        role: csvFileType,
                        academic: {
                            groupId: hierarchyId,
                            groupType: hierarchyType,
                        },
                        jobUuid: uuid(),
                    }
                },
            })

            return {
                cdnUrl: result.data!.result.uploadUrl,
                jobId: result.data!.result.jobUuid,
            }

        } catch (error) {
            throw mapError(error)
        }
    }
}

import { Grants, PermissionsMapper } from '@peachjar/ui/dist/api/Permissions'
import { RoleNames } from '../Role'
import { EntityTypes } from '../Entity'

import createMapper, { RoleMatrix } from '@peachjar/ui/dist/lib/contexts/Permissions/matrixRoleMapper'

// Rename the Grants exports since we will use them a lot.
const { None: N, Read: R, Write: W, Execute: X } = Grants

// Define the structure of your Permissions object
export type ActionPermissions = {
    createDistrict: Grants,
    createIndependentSchool: Grants,
    searchDistricts: Grants,
    searchSchools: Grants,
    internal: Grants,
}

// Define the base level of permissions (for any user, including anonymous).
// This will typically be None
const BaseActionPermissions: ActionPermissions = {
    createDistrict: N,
    createIndependentSchool: N,
    searchDistricts: N,
    searchSchools: N,
    internal: N,
}

// The permissions system requires a Mapper function that translates applicable Roles into
// a permissions tree matching the one you defined above  (ActionPermissions).
// There is an existing implementation of this mapper function that accepts a RoleMatrix and
// a base set of permissions (should the user not have a role).
// Below is an example of that Matrix.
const roleMatrix: RoleMatrix = {
    // Provide a friendly name
    name: 'Actions',
    // Define the order of roles in the matrix.
    roles: [RoleNames.Uploader, RoleNames.Approver, RoleNames.Admin, RoleNames.Internal],
    // For every key in the Permissions Tree (ActionPermissions), specify the Grant level
    // for each role.  In our case, 4 roles, with the order being: Uploader, Approver, Admin, Internal.
    perms: {
        createDistrict: [N, N, N, X],
        createIndependentSchool: [N, N, N, X],
        searchDistricts: [N, N, N, X],
        searchSchools: [N, N, N, X],
        internal: [N, N, N, X],
    },
}

// Finally, we create the PermissionsMap, which is supplied to MicroApp.
// This says, for the entity of type "Actions", this is how to build the permissions tree
// (ActionPermissions) for the user.
// Since Actions are not entity specific, we specify 'noIdMatchRequired'.  This means that
// a user having a role of type "Actions" matches regardless of the entityId (which will typically
// be a falsy value like -1).
// If you omit this value, the Permissions system will try to match by EntityType and EntityId on
// the user's roles.
// For an example of this, look at "DistrictPermissions".
export const ActionsPermissionsMap: PermissionsMapper<ActionPermissions> = {
    entityType: EntityTypes.Actions,
    noIdMatchRequired: true,
    map: createMapper({ roleMatrix, base: BaseActionPermissions })
}

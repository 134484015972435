import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { District } from '../api/District'
import { Deps } from '../dependencies/schema'
import { UpdateableFields } from '../api/commands/updateDistrictInfo'
import { CreateDistrictParams } from '../api/commands/createDistrict'
import { AcademicGroupIdentifiers, Hierarchy } from '../api/commands/launchGroup'
import { Result as SchoolsForNcesDistrict } from '../api/queries/getSchoolsByDistrictNcesId'
import { MaybeAddress } from '../api/queries/resolveAddresses'

import AsyncReqState, { loaded, notStarted, reducerFromAsyncThunk } from '@peachjar/ui/dist/api/AsyncReqState'

type State = {
    createParams: Partial<District> | null,
    resolveAddress: AsyncReqState<MaybeAddress>,
    district: AsyncReqState<District>,
    updateDistrictInfo: AsyncReqState<District>,
    createDistrict: AsyncReqState<District>,
    launchDistrict: AsyncReqState<AcademicGroupIdentifiers[]>,
    schoolsByDistrictNcesId: AsyncReqState<SchoolsForNcesDistrict>
}

type ThunkContext = {
    state: State,
    extra: Deps,
}

export const getDistrict = createAsyncThunk<District, number, ThunkContext>(
    'districts/get',
    (districtId, { extra: { getDistrict } }) => getDistrict(districtId)
)

export const updateDistrictInfo = createAsyncThunk<District, Partial<UpdateableFields>, ThunkContext>(
    'districts/update',
    (updates, {  extra: { updateDistrictInfo } }) => updateDistrictInfo(updates)
)

export const createDistrict = createAsyncThunk<District, CreateDistrictParams, ThunkContext>(
    'districts/create',
    (params, { extra: { createDistrict } }) => createDistrict(params)
)

export const launchDistrict = createAsyncThunk<AcademicGroupIdentifiers[], number, ThunkContext>(
    'districts/launch',
    (id, { extra: { launchGroup } }) => launchGroup({
        id,
        type: 'district',
    })
)

export const getSchoolsByDistrictNcesId = createAsyncThunk<SchoolsForNcesDistrict, string, ThunkContext>(
    'districts/schoolsByNcesId',
    (ncesId, { extra: { getSchoolsByDistrictNcesId } }) => getSchoolsByDistrictNcesId(ncesId)
)

export const resolveAddressForDistrict = createAsyncThunk<MaybeAddress, string, ThunkContext>(
    'districts/resolveAddress',
    async (addy, { extra: { resolveAddresses } }) => {
        const [result] = await resolveAddresses([addy])
        return result
    }
)

const slice = createSlice({
    name: 'districts',
    initialState: {
        createParams: null,
        district: notStarted(),
        updateDistrictInfo: notStarted(),
        createDistrict: notStarted(),
        launchDistrict: notStarted(),
        schoolsByDistrictNcesId: notStarted(),
        resolveAddress: notStarted(),
    } as State,
    reducers: {
        setCreateParams: (state, action: PayloadAction<Partial<District> | null>) => {
            state.createParams = action.payload
        },
        clearUpdateReq: (state) => {
            state.updateDistrictInfo = notStarted()
        },
        clearLaunchDistrictReq: (state) => {
            state.launchDistrict = notStarted()
        },
        clearReq: (state) => {
            state.district = notStarted()
            state.updateDistrictInfo = notStarted()
            state.createDistrict = notStarted()
            state.launchDistrict = notStarted()
            state.schoolsByDistrictNcesId = notStarted()
        },
    },
    extraReducers: (builder) => {
        reducerFromAsyncThunk(builder, 'district', getDistrict)
        reducerFromAsyncThunk<State, District>(
            builder,
            'updateDistrictInfo',
            updateDistrictInfo,
            (state, district) => {
                state.district = loaded(district)
                return state
            }
        )
        reducerFromAsyncThunk<State, District>(
            builder,
            'createDistrict',
            createDistrict,
            (state, district) => {
                state.district = loaded(district)
                return state
            }
        )
        reducerFromAsyncThunk<State, AcademicGroupIdentifiers[]>(
            builder,
            'launchDistrict',
            launchDistrict,
            (state, groups) => {
                const now = new Date()
                const district = state.district.model!
                const districtGroup = groups.find(g =>
                    g.academicGroupType === 'district' &&
                    g.academicGroupId === district?.districtId
                )
                if (districtGroup) {
                    district.live = true
                    district.launchedAt = now
                    // If we need this later, I will change the interface
                    district.launchedBy = ''
                }
                (district.schools || []).forEach((school) => {
                    const schoolGroup = groups.find(g =>
                        g.academicGroupType === 'school' &&
                        g.academicGroupId === school.schoolId
                    )
                    if (schoolGroup) {
                        school.live = true
                        school.launchedAt = now
                        // If we need this later, I will change the interface
                        school.launchedBy = ''
                    }
                })
            }
        )
        reducerFromAsyncThunk<State, SchoolsForNcesDistrict>(
            builder,
            'schoolsByDistrictNcesId',
            getSchoolsByDistrictNcesId
        )
        reducerFromAsyncThunk<State, MaybeAddress>(
            builder,
            'resolveAddress',
            resolveAddressForDistrict
        )
    }
})

export const clearReq = slice.actions.clearReq
export const clearUpdateReq = slice.actions.clearUpdateReq
export const setCreateParams = slice.actions.setCreateParams
export const clearLaunchDistrictReq = slice.actions.clearLaunchDistrictReq

export default slice

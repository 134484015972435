import React, { FunctionComponent, useEffect, useState } from 'react'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import AsyncReqState, { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import BaseIndependentSchoolPage from './components/BaseIndependentSchoolPage'
import UsersPageBody from '../Users/components/UsersPageBody'
import UserAndSiteRoles from '../../api/UserAndSiteRoles'
import Routes from '../../routes'

import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { getSchool } from '../../redux/schools'
import { RootState } from '../../redux'
import { School } from '../../api/School'
import { getApprovalSettingsReq } from '../../redux/approvalSettingsSelectors'
import { getApprovalSettings } from '../../redux/approvalSettings'
import { getAuthUsersReq } from '../../redux/authUserSelectors'
import { SUBTEXT } from '../District/DistrictUsersAndRolesPage'

const StaffNoteSchool = () => (
  <>
    Staff can be assigned admin, approver, or uploader roles.&nbsp;&nbsp;
    <strong>At least one admin is required.</strong>
  </>
)

const IndependentSchoolUsersAndRolesPage: FunctionComponent<{}> = () => {
  const panic = usePanic()
  const dispatch = useDispatch()
  const history = useHistory()

  const { schoolId: schoolIdParam } = useParams<{ schoolId: string }>()
  const [isRefreshing, setNeedsRefreshing] = useState(false)

  const schoolId = parseInt(schoolIdParam, 10)

  const approvalSettingsReq = getApprovalSettingsReq()
  const schoolReq = useSelector<RootState, AsyncReqState<School>>(
    (state) => state.schools.school
  )
  const authUser = getAuthUsersReq()
  const isPlatformUser = authUser.loginType === 'platform'

  useEffect(() => {
    dispatch(getSchool(schoolId))
    dispatch(
      getApprovalSettings(
        isPlatformUser 
        ? {
          hierarchyId: -1,
          hierarchyType: ''
        }
        : {
          hierarchyId: schoolId,
          hierarchyType: 'school',
      })
    )
  }, [])

  if (
    (schoolReq.isLoading() ||
      approvalSettingsReq.isLoading()) &&
    !isRefreshing
  ) {
    return <LoadingSpinner />
  }

  // Could not load the school
  if (schoolReq.error) {
    return panic(schoolReq.error)
  }

  const school = schoolReq.model!
  const approvalSettings = approvalSettingsReq.model!

  if (school.district) {
    history.push(
      Routes.districts.schools.users.from(
        school.district.districtId,
        school.schoolId
      )
    )
  }

  return (
    <BaseIndependentSchoolPage
      title="User & Role Settings"
      school={school}
      tabIndex={1}
      subtext={SUBTEXT}
    >
      <UsersPageBody
        subheading="School Staff"
        primary={school}
        sites={[school]}
        districtId={school!.district!.districtId}
        districtName={school!.district!.name}
        staffNote={<StaffNoteSchool />}
        approvalSettings={approvalSettings}
        isRefreshing={isRefreshing}
        onNeedsRefreshing={() => {
          setNeedsRefreshing(true)
        }}
        academicGroupType="school"
        academicGroupId={ schoolId }
      />
    </BaseIndependentSchoolPage>
  )
}

export default IndependentSchoolUsersAndRolesPage

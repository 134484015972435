import updateSchoolInfo, { UpdateableFields } from '../api/commands/updateSchoolInfo'

import { School } from '../api/School'
import { UIError } from '../api/CodedError'
import { schools } from './data'

import wait from './wait'
import errorFactory from './errorFactory'

export default function factory(): updateSchoolInfo {

    const checkError = errorFactory()

    return async (updates: Partial<UpdateableFields>): Promise<School> => {
        await wait(2000)

        checkError(updates.generalDisclaimer || '')
        checkError(updates.sisId || '')

        const school = schools.find(s => s.schoolId === updates.schoolId)

        if (!school) {
            throw new UIError(
                'request.resource.not_found.school',
                'School does not exist.'
            )
        }

        return Object.assign(school, updates, {
            createdAt: new Date(),
            createdBy: 'foobar',
            updatedAt: new Date(),
            updatedBy: 'asdfasdf',
        }) as any as School
    }
}

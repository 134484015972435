export enum EntityTypes {
    Actions = 'actions',
    District = 'district',
    School = 'school',
}

export function entityTypeToString(type: EntityTypes): string {
    // More entity types will come later
    switch (type) {
        case EntityTypes.District: return 'district'
        default: return 'school'
    }
}

export type EntityId = string | number

export type Entity = {
    entityType: EntityTypes,
    entityId: EntityId,
}

export function forEntity(entityType: EntityTypes, entityId: number | string): Entity {
    return {
        entityType,
        entityId,
    }
}

import { Tab } from '@peachjar/ui/dist/lib/components/TabBar/TabBar'
import Routes from '../../../routes'
import config from '../../../config'
const { REACT_APP_BFF_URI } = config

const makeTabs = (schoolId: number): Tab[] => [
    {
        label: 'Settings',
        href: Routes.schools.update.from(schoolId),
    },
    {
        label: 'Users & Roles',
        href: Routes.schools.users.from(schoolId),
    },
    {
        label: 'Approvals',
        onClick: () => window.location.href = `${REACT_APP_BFF_URI}/settings/approvals?id=${schoolId}&type=school`
    },
    {
        label: 'Community',
        href: Routes.schools.community.from(schoolId),
    },
    {
        label: 'Parents',
        href: Routes.schools.parents.from(schoolId),
    },
]

export default function getTabBar(schoolId: number, activeTab: number): Tab[] {
    const tabs = makeTabs(schoolId)
    if (activeTab < tabs.length) {
        tabs[activeTab].isActive = true
    }
    return tabs
}

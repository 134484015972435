import { School } from './School'
import { Address } from './Address'

export type District = {
    id: string,
    districtId: number,
    name: string,
    active: boolean,
    live: boolean,
    invisible: boolean,
    treeCounter: boolean,
    sisId: string,
    ncesId: string,
    address: Address | null,
    generalDisclaimer?: string,
    startAt?: Date,
    createdAt: Date,
    createdBy: string,
    updatedAt: Date,
    updatedBy: string,
    launchedAt: Date | null,
    launchedBy: string | null,
    schools?: School[]
}

export type DistrictMatcher = (district: District) => boolean

export function canGoLive(): DistrictMatcher {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
    const tomorrow = new Date()
    tomorrow.setTime(today.getTime() + 24 * 60 * 60 * 1000)
    return (district: District): boolean => {
        // District is the root, so is always launchable if it is live.
        if (district.live) {
            return true
        }
        const startAt = district.startAt
        if (!startAt) {
            return false
        }
        if (!district.active) {
            return false
        }
        return startAt < tomorrow
    }
}

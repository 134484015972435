import getCommunitySettings from '../api/queries/getCommunitySettings'

import { communitySettings } from './data'
import { UIError } from '@peachjar/ui/dist/api/CodedError'

export default function factory(): getCommunitySettings {
    return async (hierarchy) => {
        const settings = communitySettings.find((c) => {
            return c.hierarchyType === hierarchy.hierarchyType &&
                   c.hierarchyId === hierarchy.hierarchyId
        })

        if (!settings) {
            throw new UIError(
                `request.resource.not_found.${hierarchy.hierarchyType}`,
                `${hierarchy.hierarchyType} not found.`
            )
        }

        return settings
    }
}

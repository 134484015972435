/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import UserAndSiteRoles from '../../../api/UserAndSiteRoles'
import DrawerBody from '@peachjar/ui/dist/lib/components/AppDrawer/DrawerBody'
import DrawerFormBody from '@peachjar/ui/dist/lib/components/AppDrawer/DrawerFormBody'
import DrawerButtonBar from '@peachjar/ui/dist/lib/components/AppDrawer/DrawerButtonBar'
import DrawerThreeButtonBar from '@peachjar/ui/dist/lib/components/AppDrawer/DrawerThreeButtonBar'
import FieldWrapper from '@peachjar/ui/dist/lib/components/Inputs/FieldWrapper'
import TypographyRedux from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import SwitchError from '@peachjar/ui/dist/lib/components/Errors/SwitchError'
import ErrorNotification from '@peachjar/ui/dist/lib/components/Errors/ErrorNotification'
import WarnNotification from '@peachjar/ui/dist/lib/components/Errors/WarnNotification'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInputWrapper'
import SelectInput from '@peachjar/ui/dist/lib/components/Inputs/SelectInput'
import RolesSelectionInput from './RolesSelectionInput'
import NewUserMessage from './NewUserMessage'

import { useDispatch, useSelector } from 'react-redux'
import { toString, isBoolean, get, sortBy } from 'lodash'
import { SiteInfo } from '../../../api/SiteInfo'
import { DrawerComponentProps } from '@peachjar/ui/dist/lib/contexts/AppDrawer'
import { object, string } from 'yup'
import { addUser, removeUser, updateUser } from '../../../redux/usersAndRoles'
import {
  addUserReq,
  removeUserReq,
  updateUserReq,
} from '../../../redux/usersAndRolesSelectors'
import { getAuthUsersReq } from '../../../redux/authUserSelectors'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import { Match } from '@peachjar/ui/dist/lib/components/Errors'
import { Errors } from './constants'

import useFormBuilder, {
  yupVal,
} from '@peachjar/ui/dist/lib/hooks/useFormBuilder'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { ApprovalErrors } from '../../../api/ApprovalSettings'
import useApprovalSettings from '../../../hooks/useApprovalSettings'
import { CustomizationProps, selectDistrictId } from './SiteSelectionAutocomplete'
const {
  DarkMuted: { Paragraph },
} = TypographyRedux

const NOTIFICATION_KEY_ADD_EDIT = 'addEditSuccess'
const NOTIFICATION_KEY_REMOVE = 'removeSuccess'
const INDIVIDUAL_UPDATED = 'The individual is updated.'
const INDIVIDUAL_ADDED =
  'The individual is added and an email has been sent to the email address provided.'
const INDIVIDUAL_REMOVED = 'The individual is removed.'

export type FormState = UserAndSiteRoles & {
  userId: string | null
  firstName: string | null
  lastName: string | null
  site: SiteInfo | null
}

export type DrawerProps = DrawerComponentProps & {
  isVolunteer: boolean
  isEdit?: boolean
  isDistrict?: boolean
  user: UserAndSiteRoles | null
  sites: SiteInfo[]
  handleRemove: (user: UserAndSiteRoles) => void
  handleSiteChange: (user: UserAndSiteRoles) => { error: boolean, type: string }
  onAddComplete?: () => void
  onEditComplete?: () => void
  onRemoveComplete?: () => void
}

const AddEditUserDrawer: FunctionComponent<DrawerProps> = ({
  isVolunteer,
  isEdit,
  isDistrict = true,
  user,
  sites = [],
  toggle,
  handleRemove,
  handleSiteChange,
  onAddComplete = () => true,
  onEditComplete = () => true,
  onRemoveComplete = () => true,
}) => {
  const [pageError, setPageError] = useState<boolean>(false)
  const { hasSchoolApproval } = useApprovalSettings()
  const isUserSupplied = !!user && !!user.email
  isEdit = isBoolean(isEdit) ? isEdit : !!user
  const req = isEdit ? updateUserReq() : addUserReq()
  const addEditNotifier = isEdit ? onEditComplete : onAddComplete
  const removeReq = removeUserReq()
  const dispatch = useDispatch()
  const authUser = getAuthUsersReq()
  const districtId = useSelector(selectDistrictId)

  const { notifySuccess } = useGlobalNotifications()

  const initial: FormState = user
    ? user
    : (({
        userId: null,
        isVolunteer: isVolunteer,
        email: '',
        firstName: '',
        lastName: '',
        // If there is only one site, preselect it
        site: sites.length === 1 ? sites[0] : null,
        isAdmin: false,
        isApprover: false,
        isUploader: false || isVolunteer,
      } as any) as FormState)

  const {
    value: formValue,
    bindings,
    isDirty,
    isValid,
    forceValidateAll,
  } = useFormBuilder<FormState>(initial, [
    {
      path: 'email',
      // Not required on edit
      validate: !user
        ? yupVal(
            string()
              .email('Enter a valid email address.')
              .required('You missed this field.')
          )
        : undefined,
      validateOnBlur: true,
    },
    {
      path: 'firstName',
      // Not required on edit
      validate: !user
        ? yupVal(
            string()
              .required('You missed this field.')
          )
        : undefined,
      validateOnBlur: true,
    },
    {
      path: 'lastName',
      // Not required on edit
      validate: !user
        ? yupVal(
            string()
              .required('You missed this field.')
          )
        : undefined,
      validateOnBlur: true,
    },
    {
      path: 'site',
      validate: yupVal(
        object()
          .required('You missed this field.')
          .typeError('You missed this field.')
      ),
    },
    {
      path: 'isAdmin',
    },
    {
      path: 'isUploader',
    },
    {
      path: 'isApprover',
    },
  ])

  // If the form is valid and this is initial add, enable submit.
  // If editing, the form has to be dirty and valid to enable submit
  const isSubmitDisabled = isEdit ? !isDirty || !isValid || pageError : !isValid
  const isAuthUser = user?.email === authUser.email

  const handleSubmit = () => {
    const update: FormState = {
      ...formValue,
      isApprover: formValue.site.type === 'school' && !hasSchoolApproval ? false : formValue.isApprover
    }
    if (isEdit) {
      return dispatch(updateUser({ updates: update, previous: user! }))
    }
    return dispatch(addUser(update))
  }

  const handleLocalSiteOnChange = (value) => {
    const { error } = handleSiteChange(user!)
    if (!error) {
      bindings.site.onChange(value)
    }
  }

  const handleLocalRoleOnChange = (roleName: string, value: boolean) => {
    // validate approval settings in edit mode for approvers only
    bindings[roleName].onChange(value)
    if (isEdit && roleName === 'isApprover' && !value) {
      const { error, type } = handleSiteChange(user!)
      if (error && type !== ApprovalErrors.DistrictAndSchoolLastSchoolApprover) {
        setPageError(true)
        return
      }
      setPageError(false)
    }
    setPageError(false)
  }

  // Note, you CANNOT toggle for both req and removeReq in the same useEffect.
  // If you specify both req and removeReq as deps, they will both change causing
  // a "double" toggle.  We might want to not have "toggle" but rather specific
  // "open" and "close" actions for Drawer (making the action idempotent).
  useEffect(() => {
    if (req.status === Statuses.loaded) {
      notifySuccess({
        message: isEdit ? INDIVIDUAL_UPDATED : INDIVIDUAL_ADDED,
        key: NOTIFICATION_KEY_ADD_EDIT,
      })
      toggle()
      addEditNotifier()
    }
  }, [req])

  useEffect(() => {
    if (removeReq.status === Statuses.loaded) {
      notifySuccess({
        message: INDIVIDUAL_REMOVED,
        key: NOTIFICATION_KEY_REMOVE,
      })
      toggle()
      onRemoveComplete()
    }
  }, [removeReq])


  return (
    <>
      <SwitchError error={req.error}>
        <Match code={Errors.Internal.code}>
          <ErrorNotification
            id="internal"
            error={req.error}
            text={Errors.Internal.message}
          />
        </Match>
        <Match code={Errors.NonUniqueGroupMembership.code}>
          <ErrorNotification
            id="nonUniqueGroupMembership"
            error={req.error}
            text={
              isVolunteer
                ? Errors.NonUniqueGroupMembership.messages.alreadyVolunteer
                : Errors.NonUniqueGroupMembership.messages.alreadyStaff
            }
          />
        </Match>
        <Match code={Errors.AlreadyAssigned.code}>
          <WarnNotification
            id="alreadyAssignedToGroup"
            error={req.error}
            text={Errors.AlreadyAssigned.messages(
              get(formValue, 'site.name', ''),
              isVolunteer
            )}
          />
        </Match>
        <Match code={Errors.RemoveLastAdmin.code}>
          <ErrorNotification
            id="lastAdmin"
            error={req.error}
            text={
              isDistrict
                ? Errors.RemoveLastAdmin.messages.district
                : Errors.RemoveLastAdmin.messages.indySchool
            }
          />
        </Match>
      </SwitchError>
      <SwitchError error={removeReq.error}>
        <Match code={Errors.Internal.code}>
          <ErrorNotification
            id="internal"
            error={removeReq.error}
            text={Errors.Internal.message}
          />
        </Match>
        <Match code={Errors.RemoveLastAdmin.code}>
          <ErrorNotification
            id="lastAdmin"
            error={removeReq.error}
            text={
              isDistrict
                ? Errors.RemoveLastAdmin.messages.district
                : Errors.RemoveLastAdmin.messages.indySchool
            }
          />
        </Match>
      </SwitchError>
      <DrawerBody>
        <DrawerFormBody
          fields={
            [
              <TextInput
                  label="First Name"
                  disabled={false}
                  fullWidth={true}
                  {...bindings.firstName}
              />,
              <TextInput
                  label="Last Name"
                  disabled={false}
                  fullWidth={true}
                  {...bindings.lastName}
              />,
              !isUserSupplied ? (
                <TextInput
                  label="Email Address"
                  disabled={false}
                  fullWidth={true}
                  {...bindings.email}
                />
              ) : (
                // Email is not editable.
                <FieldWrapper
                  label="Email Address"
                  fullWidth={true}
                  labelShrink={true}
                >
                  <Paragraph  style={{ marginTop: '24px' }}>
                    {bindings.email.value}
                  </Paragraph>
                </FieldWrapper>
              ),
              sites.length > 1 ? (
                <SelectInput<SiteInfo & CustomizationProps>
                  label="Site"
                  // Strangely, when exiting the drawer,
                  // React attempts to render this component
                  // with sites as undefined.
                  options={sortBy(sites || [], (s) => s.name)}
                  promotedOptionId={districtId}
                  smallDescriptiveText='Schools'
                  picker={(site) => [site.description || site.name, toString(site.id), { disabled: site.disabled }]}
                  {...bindings.site}
                  onChange={(value) => handleLocalSiteOnChange(value)}
                  disabled={ isAuthUser }
                />
              ) : // If there is only one site, there is no point in showing the control.
              null,
              <RolesSelectionInput
                isVolunteer={isVolunteer}
                label={isVolunteer ? 'Roles' : 'Roles (optional)'}
                value={{
                  isAdmin: bindings.isAdmin.value,
                  isApprover: bindings.isApprover.value,
                  isUploader: bindings.isUploader.value,
                }}
                onChange={(roleName, value) =>
                  handleLocalRoleOnChange(roleName, value)
                }
                user={ formValue }
              />,
              !isEdit && !isVolunteer ? <NewUserMessage /> : null,
            ].filter(Boolean) as ReactElement[]
          }
        />
      </DrawerBody>
      {isEdit ? (
        <DrawerThreeButtonBar
          isRemoving={removeReq.status === Statuses.loading}
          onRemove={() => handleRemove(user!)}
          isLoading={req.status === Statuses.loading}
          submitDisabled={isSubmitDisabled}
          submitLabel={isEdit ? 'Update' : 'Add'}
          onSubmit={handleSubmit}
          onCancel={() => toggle()}
          forceValidate={forceValidateAll}
          removeDisabled={ isAuthUser }
        />
      ) : (
        <DrawerButtonBar
          isLoading={req.status === Statuses.loading}
          submitDisabled={isSubmitDisabled}
          submitLabel={isEdit ? 'Update' : 'Add'}
          onSubmit={handleSubmit}
          onCancel={() => toggle()}
          forceValidate={forceValidateAll}
        />
      )}
    </>
  )
}

export default AddEditUserDrawer

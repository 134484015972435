import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage'

const SamplePage = () => (
  <DefaultPage title='School Management'>
    <div>Actions:</div>
    <Link to={'/districts/new'}>Create District</Link><br />
    <Link to={'/schools/new'}>Create School</Link>
  </DefaultPage>
)

export default withRouter(SamplePage)

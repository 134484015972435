import { v4 as uuid } from 'uuid'

import getSisCSVUploadLink from '../api/queries/getSisCSVUploadLink'
import wait from './wait'

export default function factory(): getSisCSVUploadLink {
    return async (params) => {
        await wait(2000)
        const jobId = uuid()
        const filePath = params.csvFileType ? `staff/` : ''
        return {
            jobId,
            cdnUrl: `https://csv.peachjar.com/${params.hierarchyType}_${params.hierarchyId}/${filePath}${jobId}.csv`,
        }
    }
}

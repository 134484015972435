import React, { FunctionComponent } from 'react'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'


type Props = {
  pickError: string
}

const CsvUploadFatalErrorNotification: FunctionComponent<Props> = (
  {pickError}
) => {
  if (pickError === 'Header') {
    return (
      <InlineNotification
          variant='error'
          message={
              <span>
                The file failed to upload due to the <strong>column headers
                being either missing, named incorrectly, or out of order</strong>.
                If needed, review the CSV file instructions above to ensure the
                file is set up properly.
              </span>
          }
      />
    )
  } else {
    return (
      <InlineNotification
          variant='error'
          message={
              <span>
                Unable to upload file due to an <strong>error on our system</strong>.
                Please notify us at (858) 997-2117.
              </span>
          }
      />
    )
  }

}

export default CsvUploadFatalErrorNotification

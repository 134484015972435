import React, { FC, memo, useMemo, useCallback } from 'react'
import AutocompleteFilter from '@peachjar/ui/dist/lib/components/Filters/AutocompleteFilter'

export type StatusOption = {
    status: boolean,
    description: string,
}

const statusOptions: StatusOption[] = [
    { status: true, description: 'Active' },
    { status: false, description: 'Inactive' },
]

type Props = {
    key?: string,
    value?: boolean | null,
    onStatusSelected: (status: boolean|null) => void,
}

const StatusSelectionAutocomplete: FC<Props> = ({
    key = 'status_selection',
    value = null,
    onStatusSelected,
}) => {

    const statusSelection = useMemo(() => {
        return statusOptions.find(option => option.status === value) || null
    }, [value])

    const handleChange = useCallback((statusOption: StatusOption) => {
        if (statusOption) {
            onStatusSelected(statusOption.status)
        } else {
            onStatusSelected(null)
        }
    }, [onStatusSelected])

    return (
        <AutocompleteFilter<StatusOption>
            key={key}
            label='Status'
            variant='outlined'
            choices={statusOptions}
            value={statusSelection}
            onChange={handleChange}
        />
    )
}

export default memo(StatusSelectionAutocomplete)

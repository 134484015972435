import { GQLSisJob, normalizeSisJobForUI } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import getSisJobs from '../../api/queries/getSisJobs'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    query getSisJobs($input: SisJobInput) {
        jobs: getSisJobStatus(input: $input) {
            academic {
                groupId
                groupType
            },
            hasHeader
            isManual
            jobUuid
            status
            createdAt
            targetIds
            details {
                invalidRecords {
                    invalidSchools
                    invalidEmails {
                        schoolName
                        numberOfRecords
                    }
                    invalidGrades
                }
                validRecordsDetails {
                    old
                    new
                    removed
                    duplicates
                    selfManaged
                    invalids
                    total
                    percentageChange
                }
            }
            error {
                filename
                message
            }
        }
    }
`

type QueryResult = {
    jobs: GQLSisJob[]
}

export default function factory({ apolloClient }: Deps): getSisJobs {
    return async (groupId, groupType) => {

        try {

            const result = await apolloClient.query<QueryResult>({
                query: QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    input: {
                        academic: {
                            groupId,
                            groupType,
                        },
                    }
                },
            })

            return result.data.jobs!.map(normalizeSisJobForUI)

        } catch (error) {
            throw mapError(error)
        }
    }
}

import { registerMicroAppLifeCycles } from '@peachjar/kernel-sanders'
import SchoolManagement from './microapp'
import CONFIG from './config/constants'

const lifeCycles = registerMicroAppLifeCycles({
    rootComponent: SchoolManagement,
    mountTargetId: CONFIG.APP_SUB_APP_ROOT_ID
})

export function bootstrap(props) {
    return lifeCycles.bootstrap(props)
}

const DELAY_BETWEEN_MOUNT_ATTEMPTS = 100

export function mount(props) {
    function waitForElement() {
        let tries = 0
        const poll = (resolve, reject) => {
            tries += 1
            if (document.getElementById(CONFIG.APP_SUB_APP_ROOT_ID)) {
                // TODO - Remove this after we get a sense of how long it should take.
                console.log(`
                    ${CONFIG.APP_SUB_APP_ROOT_ID} element resolved after ${tries * DELAY_BETWEEN_MOUNT_ATTEMPTS} ms
                    (${tries} attempts)
                `)
                return resolve()
            }
            if (tries > 40) {
                return reject(`
                    Failed to find ${CONFIG.APP_SUB_APP_ROOT_ID} element in DOM before
                    max wait time exceeded.
                `)
            }

            return setTimeout(() => poll(resolve, reject), DELAY_BETWEEN_MOUNT_ATTEMPTS)
        }
        return new Promise(poll)
    }
    return waitForElement().then(() => {
        return lifeCycles.mount(props)
    })
}

export function unmount(props) {
    return lifeCycles.unmount(props)
}

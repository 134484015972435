import React, { FunctionComponent, useEffect, useState } from 'react'
import UsersPageBody from '../Users/components/UsersPageBody'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import BaseDistrictPage from './components/BaseDistrictPage'
import UserAndSiteRoles from '../../api/UserAndSiteRoles'

import { useParams } from 'react-router-dom'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { useDispatch } from 'react-redux'
import { getDistrictReq } from '../../redux/districtsSelectors'
import { getApprovalSettingsReq, getIsSettingsRefresh } from '../../redux/approvalSettingsSelectors'
import { getDistrict } from '../../redux/districts'
import { getApprovalSettings } from '../../redux/approvalSettings'
import { UIError } from '@peachjar/ui/dist/api/CodedError'
import { getAuthUsersReq } from '../../redux/authUserSelectors'
import { SUBTEXT } from './DistrictUsersAndRolesPage'

type UrlParams = {
  districtId: string
  schoolId: string
}

type Props = {}

const SchoolAdminStaffNote = () => (
  <>Staff can be assigned admin, approver, or uploader roles.</>
)

const DistrictUsersPage: FunctionComponent<Props> = () => {
  const panic = usePanic()
  const dispatch = useDispatch()

  const {
    districtId: districtIdParam,
    schoolId: schoolIdParam,
  } = useParams<UrlParams>()
  const [isRefreshing, setNeedsRefreshing] = useState(false)

  const districtId = parseInt(districtIdParam, 10)
  const schoolId = parseInt(schoolIdParam, 10)

  const districtReq = getDistrictReq()
  const approvalSettingsReq = getApprovalSettingsReq()
  const isSettingsRefresh = getIsSettingsRefresh()
  const authUser = getAuthUsersReq()
  const isPlatformUser = authUser.loginType === 'platform'

  useEffect(() => {
    dispatch(getDistrict(districtId))
    dispatch(
      getApprovalSettings(
        isPlatformUser 
        ? {
          hierarchyId: -1,
          hierarchyType: ''
        }
        : {
          hierarchyId: districtId,
          hierarchyType: 'district',
      })
    )
  }, [])

  if (
    (districtReq.isLoading() ||
      approvalSettingsReq.isLoading()) &&
    !isRefreshing &&
    !isSettingsRefresh
  ) {
    return <LoadingSpinner />
  }

  if (districtReq.error) {
    return panic(districtReq.error)
  }

  const district = districtReq.model!
  const school = (district.schools || []).find((s) => s.schoolId === schoolId)
  const approvalSettings = approvalSettingsReq.model!

  if (!school) {
    return panic(
      new UIError('request.resource.not_found.school', 'School not found.')
    )
  }

  return (
    <BaseDistrictPage
      title="User & Role Settings"
      district={district}
      tabIndex={1}
      subText={SUBTEXT}
    >
      <UsersPageBody
        subheading="School Staff"
        primary={school}
        sites={[school]}
        districtId={district.districtId}
        districtName={district.name}
        staffNote={<SchoolAdminStaffNote />}
        approvalSettings={approvalSettings}
        isRefreshing={isRefreshing}
        academicGroupType="school"
        academicGroupId={ schoolId }
      />
    </BaseDistrictPage>
  )
}

export default DistrictUsersPage

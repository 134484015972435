import React, { CSSProperties, FunctionComponent } from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import colors from '@peachjar/ui/dist/lib/styles/colors'

const useStyle = makeStyles(createStyles({
    root: {
        color: colors.jungle,
        '&:hover': {
            color: colors.peach,
        }
    }
}))

type Props = {
    href?: string,
    onClick?: () => void,
    target?: string,
    style?: CSSProperties,
}

const AnchorTextButton: FunctionComponent<Props> = ({
    children,
    href = 'javascript:void(0)',
    onClick = () => true,
    target,
    style,
}) => {
    const classes = useStyle()
    return (
        <a
            href={href || '#'}
            onClick={onClick}
            className={classes.root}
            target={target}
            style={style}
        >
            {children}
        </a>
    )
}

export default AnchorTextButton

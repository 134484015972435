import { districts, schools, nces } from './data'
import { District } from '../api/District'
import { School } from '../api/School'

import findAcademicGroupByName, { Params, Results, AcademicGroupSelection } from '../api/queries/findAcademicGroupByName'
import wait from './wait'
import errorFactory from './errorFactory'

function getMatchingDistricts(nameMatch: string): AcademicGroupSelection[] {
    return districts
        .filter(d => d.name.toLowerCase().includes(nameMatch.toLowerCase()))
        .map((d) => ({
            name: d.name,
            initial: d,
            city: 'Poway',
            state: 'CA',
        }))
}

function getMatchingSchools(nameMatch: string): AcademicGroupSelection[] {
    return schools
        .filter(s => s.isIndependent)
        .filter(s => s.name.toLowerCase().includes(nameMatch.toLowerCase()))
        .map((s) => ({
            name: s.name,
            initial: s,
            city: 'Poway',
            state: 'CA',
        }))
}

function getMatchingNCESDistricts(nameMatch: string): AcademicGroupSelection[] {
    return nces.districts
        .filter(d => d.name.toLowerCase().includes(nameMatch.toLowerCase()))
        .map((d) => ({
            name: d.name,
            city: d.city,
            state: d.state,
            initial: {
                name: d.name,
                ncesId: d.id,
                address: {
                    googlePlaceId: 'nces',
                    address: d.address,
                },
            } as Partial<District>
        }))
}

function getMatchingNCESSchools(nameMatch: string): AcademicGroupSelection[] {
    return nces.schools
        .filter(s => s.name.toLowerCase().includes(nameMatch.toLowerCase()))
        .map((s) => ({
            name: s.name,
            city: s.city,
            state: s.state,
            initial: {
                name: s.name,
                ncesId: s.id,
                address: {
                    googlePlaceId: 'nces',
                    address: s.address,
                },
                enrollmentCount: s.enrollment,
                lowGrade: s.lowGrade,
                highGrade: s.highGrade,
            } as Partial<School>
        }))
}

export default function factory(): findAcademicGroupByName {

    const checkError = errorFactory()

    return async ({ nameMatch, entityType }: Params): Promise<Results> => {

        await wait(1200)

        checkError(nameMatch)

        if (entityType === 'district') {
            return {
                nces: getMatchingNCESDistricts(nameMatch),
                existing: getMatchingDistricts(nameMatch),
            }
        }
        return {
            nces: getMatchingNCESSchools(nameMatch),
            existing: getMatchingSchools(nameMatch),
        }
    }
}

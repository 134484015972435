import { GQLStaffJob, normalizeStaffJobForUI } from './normalizeModels';

import ApolloClient from 'apollo-boost'

import getStaffJobs from '../../api/queries/getStaffJobs'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const QUERY = gql`
    query getStaffJobs($input: SisJobInput) {
        jobs: getStaffJobStatus(input: $input) {
            academic {
                groupId
                groupType
            },
            isManual,
            jobUuid
            status
            createdAt
            targetIds
            details {
                invalidRecords {
                    invalidSchools
                    invalidEmails {
                        schoolName
                        numberOfRecords
                    }
                    invalidGrades
                }
                validRecordsDetails {
                    staffAdded
                    staffUpdated
                    staffRemoved
                    staffDupeCount
                    existing
                    total
                    percentageChange
                }
            }
            error {
                filename
                message
            }
        }
    }
`

type QueryResult = {
    jobs: GQLStaffJob[]
}

export default function factory({ apolloClient }: Deps): getStaffJobs {
    return async (groupId, groupType) => {

        try {

            const result = await apolloClient.query<QueryResult>({
                query: QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    input: {
                        academic: {
                            groupId,
                            groupType,
                        },
                    }
                },
            })

            return result.data.jobs!.map(normalizeStaffJobForUI)

        } catch (error) {
            throw mapError(error)
        }
    }
}

import { JobStatus, SisJob } from '../api/SisJob'
import { sisJobs } from './data'

import createSisJob from '../api/commands/createSisJob'
import wait from './wait'
import { UIError } from '@peachjar/ui/dist/api/CodedError'

function getJobStatus(filename: string): JobStatus {
    if (filename.toLowerCase().includes(JobStatus.Failed)) {
        return JobStatus.Failed
    }
    if (filename.toLowerCase().includes(JobStatus.Paused)) {
        return JobStatus.Paused
    }
    if (filename.toLowerCase().includes(JobStatus.Success)) {
        return JobStatus.Success
    }
    if (filename.toLowerCase().includes(JobStatus.Cancelled)) {
        return JobStatus.Cancelled
    }
    return JobStatus.Processing
}

export default function factory(): createSisJob {
    return async (params) => {
        await wait(3000)

        if (params.file.name.includes('error')) {
            throw new UIError('internal.bad_implementation', 'Kaboom!')
        }

        const status = getJobStatus(params.file.name)

        const sisJob: SisJob = {
            academic: {
                groupId: params.hierarchyId,
                groupType: params.hierarchyType,
            },
            isMapper: params.isMapper,
            forceContinue: params.forceContinue,
            jobUuid: params.jobId,
            status,
            createdAt: new Date(),
            isManual: true,
            details: {
                invalidRecords: {
                    invalidEmails: [],
                    invalidGrades: 0,
                    invalidSchools: [],
                },
                validRecordsDetails: {
                    percentageChange: 0,
                    new: 0,
                    old: 0,
                    removed: 0,
                    total: 0,
                    duplicates: 0,
                    selfManaged: 0,
                    invalids: 0
                }
            },
            error: status === JobStatus.Failed ? {
                message: '[25] email address formatting issues. Download the invalid entries to view the list.',
                filename: 'foobar.txt',
            } : undefined,
        }

        sisJobs.push(sisJob)

        return sisJob
    }
}


import React, { FunctionComponent } from 'react'
import RadioGroupInput from '@peachjar/ui/dist/lib/components/Inputs/RadioGroupInput'

import { BindToInput } from '@peachjar/ui/dist/lib/hooks/useFormBuilder'

const choices = [
    {
        label: 'District',
        value: 'district',
    },
    {
        label: 'Independent School',
        value: 'independentSchool',
        tooltip: 'Single School Districts, Private Schools, and Independent Charter Schools.',
    }
]

type Props = {
    binding: BindToInput<any, string>
}

const DistrictOrIndySchoolPicker: FunctionComponent<Props> = ({
    binding,
}) => {
    return (
        <RadioGroupInput<string>
            label='Which one are you setting up?'
            choices={choices}
            keyMapper={v => v}
            {...binding}
        />
    )
}

export default DistrictOrIndySchoolPicker

import ApolloClient from 'apollo-boost'
import User from '@peachjar/ui/dist/api/User'
import gql from 'graphql-tag'
import mapError from './mapError'
import getAuthenticatedUser from '../../api/queries/getAuthenticatedUser'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const AUTH_USER = gql`
  query AUTH_USER {
    me {
      id
      firstName
      lastName
      email
      loginType
      roles(scope: ["school-management"]) {
        roles
        forEntity {
          entityId
          entityType
        }
      }
    }
  }
`

type AuthUserResult = {
    me: User,
}

export default function factory({ apolloClient }: Deps): getAuthenticatedUser {
    return async (): Promise<User> => {

        try {

            const result = await apolloClient.query<AuthUserResult>({
                query: AUTH_USER,
                fetchPolicy: 'network-only',
            })

            return result.data.me

        } catch (error) {

            throw mapError(error)
        }
    }
}

/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux'
import { RootState } from './index'
import { SisJob } from '../api/SisJob'
import { InvalidFileContext } from './parentSettings'

import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'
import { StaffJob } from '../api/StaffJob';


export const getSisJobsReq = () => {
    return useSelector<RootState, AsyncReqState<SisJob[]>>(state => state.parentSettings.getSisJobs)
}
export const getStaffJobsReq = () => {
    return useSelector<RootState, AsyncReqState<StaffJob[]>>(state => state.parentSettings.getStaffJobs)
}

export const uploadSisCSVReq = () => {
    return useSelector<RootState, AsyncReqState<any>>(state => state.parentSettings.uploadCsv)
}

export const continueSisJobReq = () => {
    return useSelector<RootState, AsyncReqState<SisJob>>(state => state.parentSettings.continueSisJob)
}

export const uploadProgress = () => {
    return useSelector<RootState, number>(state => state.parentSettings.uploadCsvProgress)
}

export const getSaveCsvMapping = () => {
    return useSelector<RootState, AsyncReqState<any>>(state => state.parentSettings.saveMappingResults)
}

export const getSisCsvMapping = () => {
    return useSelector<RootState, AsyncReqState<any>>(state => state.parentSettings.sisCsvMapping)
}

export const uploadPhase = () => {
    return useSelector<RootState, string>(state => state.parentSettings.uploadPhase)
}

export const uploadErrorsReq = () => {
    return useSelector<RootState, AsyncReqState<string[]>>(state => state.parentSettings.uploadErrors)
}

export const invalidFile = () => {
    return useSelector<RootState, InvalidFileContext | null>(state => state.parentSettings.invalidFile)
}

export const fileToUpload = () => {
    return useSelector<RootState, File | null>(state => state.parentSettings.fileToUpload)
}

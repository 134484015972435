/**
 * Redirects a user to another webpage.  This is defined here to allow
 */
import redirect from '../api/redirect'

/**
 * This is the actual implementation of redirect.
 */
export default function factory(): redirect {
    return (url: string) => {
        window.location.href = url
    }
}

import UserAndSiteRoles from '../api/UserAndSiteRoles'
import getUserAndSiteRoles, { GetUserAndSiteRolesParams } from '../api/queries/getUserAndSiteRoles'

import { users, districts } from './data'
import wait from './wait'

function getDistrictSiteIds(districtId: number): number[] {
    const district = districts.find(d => d.districtId === districtId)
    if (!district) return []
    return [districtId].concat(district.schools?.map(s => s.schoolId) || [])
}

export default function factory(): getUserAndSiteRoles {
    return async (params: GetUserAndSiteRolesParams): Promise<UserAndSiteRoles[]> => {

        await wait(1200)

        const matchingSites = params.includeChildSites && params.siteType === 'district' ?
            getDistrictSiteIds(params.siteId) : [params.siteId]

        const filtered = users.filter((user) => {
            if (matchingSites.includes(user.site.id)) {
                return true
            }
        })

        return filtered
    }
}

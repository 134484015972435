

export const Errors = {
    RemoveLastAdmin: {
        code: 'request.invalid.state_action.removeLastAdmin',
        messages: {
            district: 'At least one admin is required at the district office.  Assign a district staff member to this role before removing the existing staff member.',
            indySchool: 'At least one admin is required.  Assign a staff member to this role before removing the existing staff member.',
        }
    },
    NonUniqueSisID: {
        code: 'request.resource.conflict.nonUniqueSisIDForHierarchy',
        message: 'This SIS ID is in use in this district. Enter a unique ID.',
    },
    NonUniqueGroupMembership: {
        code: 'request.resource.conflict.cannotAssignMultipleMemberships',
        messages: {
            alreadyStaff: 'This individual is currently added as a staff member for this school. To add them as a volunteer, remove this individual from the staff list first.',
            alreadyVolunteer: 'This individual is currently added as a volunteer for this school. To add them as a staff member, remove this individual from the volunteer list first.',
        }
    },
    AlreadyAssigned: {
        code: 'request.resource.conflict.membershipAlreadyAssigned',
        messages: (groupName: string, isVolunteer: boolean) => `This individual user has already been added to ${groupName} as a ${isVolunteer ? 'volunteer' : 'staff'}. To update this user, select the pencil icon on their row in the list. You may need to change the Roles filter to “No Roles” to find this individual in the list.`,
    },
    Internal: {
        code: 'internal',
        message: 'Unfortunately, something went wrong and we were unable to save this information. Please try again.',
    },
}

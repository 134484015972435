import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { School } from '../School'
import { DistrictPermissions } from './DistrictPermissions'

import User from '@peachjar/ui/dist/api/User'
import { isParent } from '../../utils';

export type FilterParams = {
    permissions: DistrictPermissions,
    user: User,
    role: string,
    schools?: School[],
}

// Simple function to filter schools
export function filterSchoolsToRole({ permissions, user, role, schools }: FilterParams): School[] {
    if (!schools) {
        return []
    }
    return schools.filter((s) => {
        if ( !isParent(s)) return false
        // Granted at the district level
        if (permissions.schools > Grants.None) {
            return true
        }
        const schoolMembership = user.roles.find(r =>
            r.forEntity.entityType === 'school' &&
            r.forEntity.entityId === s.schoolId
        )
        if (schoolMembership && schoolMembership.roles.includes(role)) {
            return true
        }
        return false
    })
}

import React, { FunctionComponent } from 'react'
import { css, cx } from 'emotion'
import { Grid } from '@material-ui/core'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import UploadSVG from '../UploadSVG/UploadSVG'
import PenAndPaperSVG from '../PenAndPaperSVG/PenAndPaperSVG'
import ChecklistSVG from '../ChecklistSVG/ChecklistSVG'

export enum TabIcon {
  Upload = 'upload',
  Pen = 'pen',
  CheckList = 'checklist',
}

type Tab = {
  id: number
  isActive: boolean
  icon: TabIcon
  label: string
  enabled: boolean
  onClickHandler: () => void
}

type Props = {
  tab: Tab
  number: number
}

const iconMapper = {
  [TabIcon.Upload]: UploadSVG,
  [TabIcon.Pen]: PenAndPaperSVG,
  [TabIcon.CheckList]: ChecklistSVG,
}

const Tab: FunctionComponent<Props> = ({ tab, number }) => {

  const styles = {
    gridItem: css`
      width: ${97/number}%;
      padding: 0 !important;
      margin: 0px 0.5rem 1rem;
      position: relative;
    `,
    highlighter: css`
      transition: width 0.2s ease;
      position: absolute;
      height: 4px;
      top: 0;
      left: 0;
      width: 0%;
      margin: auto;
      border-radius: 3px;
      cursor: pointer;
  
      &.disabled {
      }
  
      &.active {
        background-color: ${colors.leaf};
        width: 100%;
      }
    `,
    container: css`
      display: flex;
      align-items: center;
      border-radius: 3px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      overflow: hidden;
      background-color: white;
      padding: 1rem 1.5rem;
      &:hover {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  
        .inactive {
          background-color: ${colors.silver};
          width: 100%;
        }
      }
    `,
    disabled: css`
      opacity: 0.2;
    `,
    disabledHover: css`
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      }
    `,
    icon: css`
      margin-right: 16px;
    `,
    text: css`
      & > h4 {
        font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 1rem;
        color: ${colors.prussian}
      }
    `,
    lockedOpacity: css`
      opacity: 0.2;
    `,
  }

  const { isActive, icon, label, enabled, onClickHandler } = tab
  const Icon = iconMapper[icon]
  const getHoverState = () => {
    if (!enabled) {
      return 'disabled'
    }

    if (isActive) {
      return 'active'
    }

    return 'inactive'
  }

  const disabledStyles = !enabled ? styles.disabled : ''

  return (
    <Grid item className={styles.gridItem}>
      <div
        className={cx(styles.container, !enabled ? styles.disabledHover : '')}
        onClick={onClickHandler}
      >
        <div className={cx(styles.highlighter, getHoverState())} />
        <div className={cx(styles.icon, disabledStyles)}>
          <Icon />
        </div>
        <div className={cx(styles.text, disabledStyles)}>
          <h4>{label}</h4>
        </div>
      </div>
    </Grid>
  )
}

export default Tab

import ApolloClient from 'apollo-boost'
import { LoggerApi } from '@peachjar/kernel-sanders'

export default function createApolloClient(uri: string, logger: LoggerApi) {
    return new ApolloClient({
        uri,
        credentials: 'include',
        onError: ({ networkError, graphQLErrors }) => {
            console.log('Network Error', networkError)
            console.log('GraphQL Error', graphQLErrors)
            if (networkError) logger.capture(networkError)
            if (graphQLErrors) logger.capture(graphQLErrors)
        },
    })
}

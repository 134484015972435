import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
  useRef,
} from 'react'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import Grid from '@material-ui/core/Grid'
import { ButtonSecondaryLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import DrawerBody from '@peachjar/ui/dist/lib/components/AppDrawer/DrawerBody'
import DrawerFormBody from '@peachjar/ui/dist/lib/components/AppDrawer/DrawerFormBody'
import DrawerButtonBar from '@peachjar/ui/dist/lib/components/AppDrawer/DrawerButtonBar'
import DrawerThreeButtonBar from '@peachjar/ui/dist/lib/components/AppDrawer/DrawerThreeButtonBar'
import FieldWrapper from '@peachjar/ui/dist/lib/components/Inputs/FieldWrapper'
import TypographyRedux from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'
import NewUserMessage from './NewUserMessage'
import { useDispatch } from 'react-redux'
import { css } from 'emotion'
import { SiteInfo } from '../../../api/SiteInfo'
import { DrawerComponentProps } from '@peachjar/ui/dist/lib/contexts/AppDrawer'
import { object, string } from 'yup'
import { bulkAddUser } from '../../../redux/usersAndRoles'
import {
  uploadSisCSVReq
} from '../../../redux/parentSettingsSelectors'
import ManualUpload from '../../Parents/components/ManualUpload'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import useFormBuilder, {
  yupVal,
} from '@peachjar/ui/dist/lib/hooks/useFormBuilder'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'

const { Dark: { Paragraph, Note }, DarkMuted: { Note: MutedNote }, DarkBold: { Paragraph: DarkParagraph } } = TypographyRedux
// const {
//   DarkMuted: { Paragraph },
// } = TypographyRedux

const NOTIFICATION_KEY_ADD_EDIT = 'bulkAddUserSuccess'

const INDIVIDUAL_ADDED =
  'Each individual is added and an email has been sent to the email address provided.'

export type FormState = {
  districtId: number | null
  districtName: string | null
}

export type BulkDrawerProps = DrawerComponentProps & {
  isVolunteer: boolean
  districtId: number
  districtName: string
  onNeedsRefreshing: () => void,
  onAddComplete?: () => void
}

const BulkAddUserDrawer: FunctionComponent<BulkDrawerProps> = ({
  isVolunteer,
  districtId,
  districtName,
  onNeedsRefreshing,
  toggle,
  onAddComplete = () => true,
}) => {
  const [pageError, setPageError] = useState<boolean>(false)
  const uploadReq = uploadSisCSVReq()
  const addEditNotifier = onAddComplete
  const dispatch = useDispatch()

  const { notifySuccess } = useGlobalNotifications()

  const initial: FormState = (({
      districtId : null,
      districtName: null,
    } as any) as FormState)
  const {
    value,
    bindings,
    isDirty,
    isValid,
    forceValidateAll,
  } = useFormBuilder<FormState>(initial, [])

  const isDoneDisabled =  (uploadReq && !uploadReq.model) || (uploadReq && uploadReq.model && uploadReq!.model!.error !== '')

  return (
    <div className={styles.container}>
      <DrawerBody>
        <Paragraph  style={{ marginTop: '10px', marginBottom: '8px' }}>
        Upload a CSV file to bulk add and update district and school staff. Omitting a staff member for a district/school site you are updating, will remove them from the staff list <b>unless they have an Admin or Approver role at the district site</b>.
        </Paragraph>
        <InlineNotification
            variant='warning'
            message={
                <span>
                Due to the importance of district staff with the admin or approver role, they must be updated or removed individually in the Users & Roles Settings.
                </span>
            }
        />
        <Paragraph  style={{ marginTop: '24px' }}>
        <InlineNotification
            variant='info'
            message={
                <span>
                To add a staff member to multiple schools, enter them on separate rows with the appropriate SIS ID and roles for each site.
                </span>
            }
        />
        </Paragraph>
        <Paragraph  style={{ marginTop: '24px' }}>
          <div><strong>File Preparation</strong></div>
          <a style={{ color: colors.jungle }}
          href="https://cdn.peachjar.com/assets/staff_csv_file_instruction.pdf" target="_blank">View the CSV File Instructions (PDF)</a> for the correct format.
        </Paragraph>
        <DrawerFormBody
          fields={
            [
            <FieldWrapper
              label=""
              fullWidth={true}
              labelShrink={true}
            >
            {/* <Paragraph  style={{ marginTop: '24px' }}>
              {districtName}
              {bindings.districtId}
            </Paragraph> */}
            <Paragraph  style={{ margin: '0 0 10px 0'}}>
              <div><strong>File Upload</strong></div>
              Once you have prepared your CSV file, upload it below.
            </Paragraph>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
            <ManualUpload
                csvFileType={'staff'}
                hierarchyType={'district'}
                hierarchyId={districtId}
                isMapper={false}
                forceContinue={false}
                onNeedsRefreshing={() => {}}
            />

            </Grid>
            </FieldWrapper>,
              <NewUserMessage />,
            ].filter(Boolean) as ReactElement[]
          }
        />

      </DrawerBody>
      <Grid item xs={12} style={{ textAlign: 'right', marginRight: '32px' }}>
      <ButtonSecondaryLarge
          isLoading={false}
          disabled={isDoneDisabled}
          onClick={() => toggle()}
        >
          Done
      </ButtonSecondaryLarge>
      </Grid>

    </div>
  )

}

const styles = {
  container: css`
    & .MuiIcon-root {
      overflow: unset !important;
    }
  `,
}

export default BulkAddUserDrawer

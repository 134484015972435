import React, { FunctionComponent, useContext, useEffect, useState, useMemo } from 'react'
import BaseDistrictPage from './components/BaseDistrictPage'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import SchoolAndSitesTable from './components/SchoolsAndSitesList'
import Routes from '../../routes'
import RestrictTo from '@peachjar/ui/dist/lib/components/Permissions/RestrictTo'
import SuccessNotification from '@peachjar/ui/dist/lib/components/Notifications/SuccessNotification'
import QueryStringNotification from '@peachjar/ui/dist/lib/components/Notifications/QueryStringNotification'
import InvokeOnError from '@peachjar/ui/dist/lib/components/Errors/InvokeOnError'
import GoLiveModal from './components/GoLiveModal'
import DistrictForm from './components/DistrictForm'
import UserContext from '@peachjar/ui/dist/lib/contexts/User/index'

import { filterSchoolsToRole } from '../../api/permissions/utils'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { invalidParam } from '../../api/CodedError'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { Match, SwitchError } from '@peachjar/ui/dist/lib/components/Errors'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { useDistrictPermissions } from '../../hooks/usePermissions'
import { canGoLive as districtCanGoLivePredicate } from '../../api/District'
import {
    clearLaunchDistrictReq,
    clearUpdateReq,
    getDistrict,
    launchDistrict,
    updateDistrictInfo
} from '../../redux/districts'
import { getDistrictReq, launchDistrictReq, updateDistrictInfoReq } from '../../redux/districtsSelectors'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { ExternalErrors } from '../School/components/SchoolForm'
import { Errors } from '../Users/components/constants'
import { canGoLive } from '../../api/School'
import { notAuthorized } from '@peachjar/ui/dist/api/CodedError'

import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { Box } from '@material-ui/core'
import StatusSelectionAutocomplete from './components/StatusSelectionAutocomplete'

const { Dark: { Paragraph } } = Typo

const UpdateDistrictPage: FunctionComponent<{}> = () => {

    const panic = usePanic()
    const dispatch = useDispatch()
    const history = useHistory()
    const { user } = useContext(UserContext)

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null)
    const [lastSisId, setLastSisId] = useState<string | null>(null)
    const [errors, setErrors] = useState<ExternalErrors>({
        sisId: {
            lastNonUniqueVal: lastSisId,
            message: Errors.NonUniqueSisID.message,
        },
    })
    const [lastLaunched, setLastLaunched] = useState<Date | null>(null)
    const [launchSuccessMessage, setLaunchSuccessMessage] = useState('')
    const [isGoLiveModalOpen, setGoLiveModalOpen] = useState(false)
    const [isGoLiveSubmitting, setGoLiveSubmitting] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState<boolean | null>(true)

    const { close: closeNotification } = useGlobalNotifications()

    const { districtId: districtIdParam } = useParams<{ districtId: string }>()
    const districtId = parseInt(districtIdParam, 10)

    if (!districtId) {
        panic(invalidParam('districtId', 'District IDs must be numbers.'))
    }

    const permissions = useDistrictPermissions(districtId)

    const canEditDistrict = permissions.actions.update >= Grants.Write
    const canEditSchools = permissions.schools >= Grants.Write

    const req = getDistrictReq()
    const updateReq = updateDistrictInfoReq()
    const launchReq = launchDistrictReq()

    useEffect(() => {
        dispatch(getDistrict(districtId))
    }, [dispatch, districtId])

    useEffect(() => {
        if (updateReq.status === Statuses.loading) {
            closeNotification()
        }
        if (updateReq.status === Statuses.loaded) {
            setLastUpdated(updateReq.model?.updatedAt || null)
            dispatch(clearUpdateReq())
        }
    }, [closeNotification, dispatch, updateReq])

    useEffect(() => {
        setGoLiveSubmitting(launchReq.status === Statuses.loading)
        if ([Statuses.loaded, Statuses.failed].includes(launchReq.status)) {
            setGoLiveModalOpen(false)
        }
        if (launchReq.status === Statuses.loaded) {
            setLastLaunched(new Date())
            setLaunchSuccessMessage(`${launchReq.model!.length} district and/or schools launched.`)
        }
    }, [launchReq])

    const district = useMemo(() => {
        return req.model
    }, [req])

    const isDistrictLaunchable = useMemo(() => {
        if (district) {
            return districtCanGoLivePredicate()(district!)
        }

        return false
    }, [district])

    const hasSchools = useMemo(() => {
        return !!district?.schools && district?.schools?.length > 0
    }, [district])

    const viewableSchools = useMemo(() => {
        return filterSchoolsToRole({
            permissions,
            role: 'admin',
            user,
            schools: district?.schools,
        })
    }, [district, permissions, user])

    const filteredSchools = useMemo(() => {
        return viewableSchools.filter(
            school => selectedStatus === null || school.active === selectedStatus
        )
    }, [selectedStatus, viewableSchools])

    const launchableSchools = useMemo(() => {
        return !hasSchools ? [] : district?.schools!.filter(canGoLive(district?.startAt))
    }, [district, hasSchools])

    // Could not load the district
    if (req.error) return panic(req.error)

    if (req.isLoading()) return <LoadingSpinner />

    // School Admins of a single school are automatically redirected to the School Setting page.
    if (!canEditDistrict && viewableSchools.length === 1) {
        history.push(Routes.districts.schools.update.from(districtId, viewableSchools[0].schoolId))
    }

    if (!canEditDistrict && viewableSchools.length === 0) {
        return panic(notAuthorized('District', 'ViewSettings'))
    }

    return (
        <BaseDistrictPage
            title={canEditDistrict ? 'District & School Settings' : 'School Settings'}
            district={district!}
            tabIndex={0}
        >
            <GoLiveModal
                isOpen={isGoLiveModalOpen}
                launchableSchools={launchableSchools!}
                isSubmitting={isGoLiveSubmitting}
                onSubmit={() => {
                    dispatch(launchDistrict(district?.districtId!))
                }}
                cancel={() => setGoLiveModalOpen(false)}
            />
            <QueryStringNotification />
            <Sections>
                {canEditDistrict && (
                    <Section headline='District'>
                        {/* dynamic ID is a trick to ensure each update request gets a unique notification */}
                        <SuccessNotification
                            id={`update_req_${lastUpdated}`}
                            isVisible={!!lastUpdated}
                            message='District settings are updated.'
                        />
                        <SuccessNotification
                            id={`launch_req_${lastUpdated}`}
                            isVisible={!!lastLaunched}
                            message={launchSuccessMessage}
                        />
                        <SwitchError error={updateReq.error}>
                            <Match code={Errors.NonUniqueSisID.code}>
                                <InvokeOnError
                                    error={updateReq.error}
                                    onError={() => {
                                        setErrors(Object.assign(errors, {
                                            sisId: {
                                                lastNonUniqueVal: lastSisId,
                                                message: Errors.NonUniqueSisID.message,
                                            }
                                        }))
                                    }}
                                />
                            </Match>
                        </SwitchError>
                        <SwitchError error={launchReq.error} />
                        <DistrictForm
                            isEdit={true}
                            permissions={permissions}
                            errors={errors}
                            district={district}
                            onSubmit={(updates) => {
                                setLastSisId(updates.sisId || null)
                                dispatch(updateDistrictInfo({
                                    ...updates,
                                    invisible: !updates?.invisible
                                }))
                            }}
                            onCancel={() => history.goBack()}
                            isSubmitting={updateReq.status === Statuses.loading}
                            lastUpdated={lastUpdated}
                        />
                    </Section>
                )}
                <Section headline='Schools / Sites'>
                    <Box
                        display="flex"
                        style={{
                            marginBottom: '8px',
                            marginTop: canEditSchools ? '0' : '-40px'
                        }}
                    >
                        <Box flexGrow={1} >
                            <RestrictTo permission={permissions.schools} minLevel={Grants.Write}>
                                <ButtonSecondarySmall
                                    onClick={() => history.push(Routes.districts.schools.new.from(districtId))}
                                >
                                    Add School / Site
                                </ButtonSecondarySmall>
                                {isDistrictLaunchable &&
                                    <ButtonSecondarySmall
                                        style={{ marginLeft: '8px' }}
                                        disabled={launchableSchools && launchableSchools.length === 0}
                                        onClick={() => {
                                            dispatch(clearLaunchDistrictReq())
                                            setGoLiveModalOpen(true)
                                        }}
                                    >
                                        Launch Schools
                                    </ButtonSecondarySmall>
                                }
                            </RestrictTo>
                        </Box>
                        <Box>
                            <StatusSelectionAutocomplete
                                value={selectedStatus}
                                onStatusSelected={setSelectedStatus}
                            />
                        </Box>
                    </Box>
                    <div>
                        {hasSchools ?
                            <SchoolAndSitesTable
                                districtId={district?.districtId!}
                                defaultStartAt={district?.startAt!}
                                schools={filteredSchools}
                                permissions={permissions}
                            /> :
                            <Paragraph >No schools are registered with the district.</Paragraph>
                        }
                    </div>
                </Section>
            </Sections>
        </BaseDistrictPage>
    )
}

export default UpdateDistrictPage

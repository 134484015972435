import ApolloClient from 'apollo-boost'
import UserAndSiteRoles from '../../api/UserAndSiteRoles'

import addUser, { AddUserParams } from '../../api/commands/addUser'
import mapError from './mapError'
import gql from 'graphql-tag'

const ADD_STAFF_MEMBER = gql`
    mutation addUser($input: AddStaffMemberInput!) {
        user: addStaffMember(input: $input) {
            userId
            firstName
            lastName
            site {
                id
                type
                name
            }
            email
            isApprover
            isUploader
            isAdmin
        }
    }
`

const ADD_VOLUNTEER = gql`
    mutation addUser($input: AddVolunteerInput!) {
        user: addVolunteer(input: $input) {
            userId
            firstName
            lastName
            site {
                id
                type
                name
            }
            email
            isUploader
        }
    }
`

type Deps = {
    apolloClient: ApolloClient<any>,
}

type GQLResult = {
    user: UserAndSiteRoles,
}

export default function factory({ apolloClient }: Deps): addUser {
    return async (ur: AddUserParams): Promise<UserAndSiteRoles> => {
        try {

            const result = await apolloClient.mutate<GQLResult>({
                mutation: ur.isVolunteer ? ADD_VOLUNTEER : ADD_STAFF_MEMBER,
                variables: {
                    input: Object.assign({
                        firstName: ur.firstName,
                        lastName: ur.lastName,
                        site: ur.site,
                        email: ur.email,
                        isUploader: ur.isUploader,
                    }, ur.isVolunteer ? {} : {
                        isAdmin: ur.isAdmin,
                        isApprover: ur.isApprover,
                    })
                }
            })

            return Object.assign({
                isAdmin: false,
                isApprover: false,
            }, result.data!.user)

        } catch (error) {
            throw mapError(error)
        }
    }
}

import React, { FunctionComponent } from 'react'
import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage'
import CreateSchoolPageBody from './components/CreateSchoolPageBody'
import Routes from '../../routes'

import { useHistory } from 'react-router-dom'

const CreateIndependentSchoolPage: FunctionComponent<any> = () => {
    const history = useHistory()
    return (
        <DefaultPage
            title='Add Independent School'
            breadCrumbs={[{
                label: 'New District / Ind. School',
                onClick: () => history.push(Routes.workflows.newDistrictOrIndySchool.from())
            }]}
        >
            <CreateSchoolPageBody
                isIndependent={true}
                cancelRedirectUri={Routes.workflows.newDistrictOrIndySchool.from()}
                onCreateSuccess={
                    (school) => history.push(Routes.schools.update.from(school.schoolId))
                }
            />
        </DefaultPage>
    )
}

export default CreateIndependentSchoolPage

import React, { FunctionComponent, useEffect, useState } from 'react'
import SwitchInput from '@peachjar/ui/dist/lib/components/Inputs/SwitchInput'
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip'
import PreviewBox from './PreviewBox'
import Grid from '@material-ui/core/Grid'
import AnchorTextButton from '../../../components/Buttons/AnchorTextButton'
import DescriptionIcon from '@material-ui/icons/Description'
import CustomApprovalGuidelinesModal from './CustomApprovalGuidelinesModal'
import StandardApprovalGuidelinesModal from './StandardApprovalGuidelinesModal'

import { DEFAULT_APPROVAL_GUIDELINES_URL } from '../../../config/constants'
import { InputProps } from '@peachjar/ui/dist/lib/components/Inputs/InputProps'

type Model = {
    enabled: boolean,
    url: string,
}

export type Props = InputProps<Model> & {
    hierarchyName: string,
}

const TOOLTIP = 'These set of guidelines are presented to community organizations to acknowledge and follow when submitting flyers for approval.'

const DEFAULT_URL = DEFAULT_APPROVAL_GUIDELINES_URL

function truncate(str: string): string {
    // Just in case, make sure we don't operate on falsy string.
    if (!str) {
        return ''
    }
    if (str.length <= 36) {
        return str
    }
    return str.slice(0, 33) + '...'
}

const ApprovalGuidelinesInput: FunctionComponent<Props> = ({
    value,
    onChange,
    hierarchyName,
}) => {

    const [enabled, setEnabled] = useState(value.enabled)
    const [useCustom, setUseCustom] = useState(!!value.url && value.url !== DEFAULT_URL)
    const [customUrl, setCustomUrl] = useState(value.url === DEFAULT_URL ? '' : value.url)
    const [isEditModalOpen, setEditModalOpen] = useState(false)
    const [isStandardModalOpen, setIsStandardModalOpen] = useState(false)

    useEffect(() => {
        onChange({ enabled, url: useCustom ? customUrl : DEFAULT_URL })
    }, [enabled, customUrl, useCustom])

    return (
        <Grid container>
            <Grid item xs={12}>
                <SwitchInput
                    label='Flyer Approval Guidelines'
                    value={enabled}
                    onChange={v => setEnabled(v)}
                />
                <Tooltip title={TOOLTIP} placement='right' />
            </Grid>
            {value.enabled && (
                <Grid item xs={12} style={{ paddingLeft: '48px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <PreviewBox
                                showEdit={useCustom}
                                onEditClick={() => setEditModalOpen(true)}
                            >
                                <AnchorTextButton
                                    href={useCustom ? customUrl : undefined}
                                    target={useCustom ? '_blank' : undefined}
                                    style={{ fontSize: '16px' }}
                                    onClick={() => {
                                        if (!useCustom) {
                                            setIsStandardModalOpen(true)
                                        }
                                    }}
                                >
                                    <DescriptionIcon />
                                    <span style={{ marginLeft: '16px' }}>
                                        {useCustom ?
                                            truncate(customUrl) :
                                            `Peachjar's Flyer Approval Guidelines`
                                        }
                                    </span>
                                </AnchorTextButton>
                            </PreviewBox>
                        </Grid>
                        <Grid item xs={12}>
                            <AnchorTextButton
                                onClick={() => {
                                    if (!useCustom) {
                                        setEditModalOpen(true)
                                    } else {
                                        setUseCustom(false)

                                    }
                                }}
                            >
                                {useCustom ?
                                    'Use Peachjar\'s Version' :
                                    'Use Custom Version'
                                }
                            </AnchorTextButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <CustomApprovalGuidelinesModal
                initial={customUrl}
                isOpen={isEditModalOpen}
                cancel={() => setEditModalOpen(false)}
                onSubmit={(url) => {
                    setUseCustom(true)
                    setCustomUrl(url)
                    setEditModalOpen(false)
                }}
            />
            <StandardApprovalGuidelinesModal
                hierarchyName={hierarchyName}
                isOpen={isStandardModalOpen}
                cancel={() => setIsStandardModalOpen(false)}
            />
        </Grid>
    )
}

export default ApprovalGuidelinesInput

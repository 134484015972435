import React, { FunctionComponent } from 'react'
import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { ButtonFlatLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

import colors from '@peachjar/ui/dist/lib/styles/colors'
import UserAndSiteRoles from '../../../api/UserAndSiteRoles'

export enum APPROVER_REMOVAL_VARIANTS {
  singleDistrictLastDistrictApprover = 'SINGLE_DISTRICT_LAST_DISTRICT_APPROVER',
  dualDistrictApproverNonScreener = 'DUAL_DISTRICT_APPROVER_NON_SCREENER',
  dualDistrictScreener = 'DUAL_DISTRICT_SCREENER',
  districtAndSchoolLastSchoolApprover = 'DISTRICT_AND_SCHOOL_LAST_SCHOOL_APPROVER',
  districtAndSchoolOneSchoolLastApprover = 'DISTRICT_AND_SCHOOL_ONE_SCHOOL_LAST_APPROVER',
  schoolLastApprover = 'SCHOOL_LAST_APPROVER',
}

type Props = {
  isOpen: boolean
  user: UserAndSiteRoles
  children: React.ReactNode
  submitText?: string
  onSubmit: (user: UserAndSiteRoles) => void
  onCancel: () => void
}

const ApproverRemovalModal: FunctionComponent<Props> = ({
  isOpen,
  user,
  children,
  submitText = 'Remove',
  onSubmit,
  onCancel,
}) => (
  <Modal title='' open={isOpen} close={() => onCancel()}>
    <ModalHeader color="#FFEBE5">Approver Removal Confirmation</ModalHeader>
    <DialogContent style={{ padding: '16px' }}>
      <DialogContentText style={{ color: colors.prussian }}>
        <div>{children}</div>
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ padding: '16px 32px' }}>
      <ButtonFlatLarge onClick={() => onCancel()} color="primary">
        Cancel
      </ButtonFlatLarge>
      <ButtonFlatLarge onClick={() => onSubmit(user)}>
        {submitText}
      </ButtonFlatLarge>
    </DialogActions>
  </Modal>
)

export default ApproverRemovalModal

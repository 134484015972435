import React, { FunctionComponent, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { css } from 'emotion'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@peachjar/ui/dist/lib/components/Inputs/Checkbox'
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip'

import { InputProps } from '@peachjar/ui/dist/lib/components/Inputs/InputProps'

import colors from '@peachjar/ui/dist/lib/styles/colors'
import UserAndSiteRoles from '../../../api/UserAndSiteRoles'
import { getAuthUsersReq } from '../../../redux/authUserSelectors'
import useApprovalSettings from '../../../hooks/useApprovalSettings'

export type Roles = {
  isAdmin: boolean
  isApprover: boolean
  isUploader: boolean
}

export type Props = Omit<InputProps<Roles>, 'onChange'> & {
  onChange: (changedRole: keyof Roles, value: boolean) => void
  isVolunteer: boolean
  label?: string
  user: UserAndSiteRoles | null
}

const RolesSelectionInput: FunctionComponent<Props> = ({
  isVolunteer,
  label = 'Roles',
  onChange,
  value = {
    isAdmin: false,
    isApprover: false,
    isUploader: false,
  },
  user,
}) => {
  const [rolesState, setRolesState] = useState<Roles>(value)
  const { hasSchoolApproval } = useApprovalSettings()
  const authUser = getAuthUsersReq()
  

  const handleChange = (role: keyof Roles, state: boolean, event: any) => {
    // for some reason react is invoking onChange on render.
    // this prevents calling onChange prematurely
    if (!isEmpty(event)) {
      setRolesState({
        ...rolesState,
        [role]: state,
      })
      onChange(role, state)
    }
  }

  return (
    <div>
      <FormLabel component="legend" style={{ color: colors.prussian }}>
        {label}
      </FormLabel>
      <FormGroup>
        {!isVolunteer && (
          <div className={styles.row}>
            <Checkbox
              value="Admin"
              checked={rolesState.isAdmin}
              disabled={user?.email === authUser.email}
              onChange={({ isChecked, event }) => {
                handleChange('isAdmin', isChecked, event)
              }}
            />
            <div className={styles.tooltipContainer}>
              {/* FIXME update peachjar-ui tooltip to accept Nodes */ }
              <Tooltip title={ADMIN_TOOLTIP as unknown as string}/>
            </div>
          </div>
        )}
        {!isVolunteer && (
          <div className={styles.row}>
            <Checkbox
              value="Approver"
              checked={rolesState.isApprover}
              disabled={ user?.site?.type === 'school' && !hasSchoolApproval }
              onChange={({ isChecked, event }) => {
                handleChange('isApprover', isChecked, event)
              }}
            />
            <div className={styles.tooltipContainer}>
              <Tooltip title={APPROVER_TOOLTIP as unknown as string}/>
            </div>
          </div>
        )}
        <div className={styles.row}>
          <Checkbox
            value="Uploader"
            checked={rolesState.isUploader}
            disabled={isVolunteer}
            onChange={({ isChecked, event }) => {
              handleChange('isUploader', isChecked, event)
            }}
          />
          <div className={styles.tooltipContainer}>
            <Tooltip title={UPLOADER_TOOLTIP as unknown as string}/>
          </div>
        </div>
      </FormGroup>
    </div>
  )
}

const ADMIN_TOOLTIP = <>Admins assigned to the district manage school district settings and view user activity across all school sites.<br/><br/>Admins assigned to a school site manage the site's settings and view all of the site's user activity.</>
const APPROVER_TOOLTIP = <>Approvers assigned to the district approve flyers across all school sites.<br/><br/>Approvers assigned to a school site approve flyers for the site.</>
const UPLOADER_TOOLTIP = <>Uploaders assigned to the district submits flyers across all school sites.<br/><br/>Uploaders assigned to a school site submits flyers for the site.</>

const styles = {
  row: css`
    display: flex;
    align-items: center;
  `,
  tooltipContainer: css`
    margin-bottom: 0.5rem;
  `
}

export default RolesSelectionInput

import 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

export type QueryParams = Record<string, string | string[] | null | undefined>

export default function useQueryParams(): QueryParams {
    const location = useLocation()
    return qs.parse(location.search)
}

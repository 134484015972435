import getSchool from '../api/queries/getSchool'
import { School } from '../api/School'
import { schools } from './data'
import { UIError } from '@peachjar/ui/dist/api/CodedError'
import wait from './wait'

export default function factory(): getSchool {
    return async (schoolId: number): Promise<School> => {
        await wait(1000)
        const school = schools.find(s => s.schoolId === schoolId)
        if (!school) {
            throw new UIError('request.resource.not_found.school', 'School not found.')
        }
        return school
    }
}

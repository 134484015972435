import colors from '@peachjar/ui/dist/lib/styles/colors';
import React from 'react';
import { IconButton, InputAdornment, MenuItem, TextField, createStyles, makeStyles, Select } from '@material-ui/core';
import CloseIcon from '@peachjar/ui/dist/lib/components/Icons/CloseIcon';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';


const useStyles = makeStyles(createStyles({
  root: {
    ':hover': {
      borderBottomColor: colors.prussian
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${colors.stone}`
    },
    '& .MuiInput-underline:after': {
      borderBottom: `1px solid ${colors.prussian}`
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${colors.prussian}`
    },
    '&:hover:not(.Mui-disabled):after': {
      borderBottom: `2px solid ${colors.prussian}`
    }, 
    "& .MuiSelect-outlined": {
      paddingRight: "0px"
    },
  },
  adornedEnd: {
    ':hover': {
      borderBottomColor: colors.prussian
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${colors.stone}`
    },
    '& .MuiInput-underline:after': {
      borderBottom: `1px solid ${colors.prussian}`
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${colors.prussian}`
    },
    '&:hover:not(.Mui-disabled):after': {
      borderBottom: `2px solid ${colors.prussian}`
    }, 
    "& .MuiSelect-outlined": {
      paddingRight: "0px"
    },
    "& .MuiSelect-outlined:focus": {
      background: "none"
    },
    "& .Mui-disabled": {
      color: "black",
      fontWeight: "bold"
    },
    "& .MuiIconButton-root": {
      marginRight: "-12px"
    }
  },
  error: {
    "& .MuiSelect-outlined": {
      color: "#F44336"
    },
  }
}));

type ClearableSelectProps = {
  options: string[];
  value?: string;
  error?: boolean;
  onChange: any;
  show: any;
  ['data-testid']?: string;
};

export function ClearableSelect({
  options, value, error, onChange, show, "data-testid": dataTestId
}: ClearableSelectProps) {
  const classes = useStyles();

  const hasSelection = (value !== null && value !== "" && value !== undefined);
  function handleChange(event: any) {
    onChange(event.target.value);
  };
  function clearSelection() {
    onChange("");
  }

  return (<>
    <Select
      onChange={handleChange}
      variant="outlined"
      displayEmpty
      error={error !== undefined ? error : false}
      disabled={hasSelection}
      className={hasSelection ? classes.adornedEnd : (error ? classes.error : classes.root)}
      fullWidth
      SelectDisplayProps={{ style: {padding: '0 !important'}}}
      style={{width: '100%', fontSize: '14px', height: '32px' }}
      value={value}
      defaultValue=""
      IconComponent={hasSelection ? () => null : DropdownIcon}
      inputProps={{ sx: { paddingRight: hasSelection ? '0px !important' : '14px'} }}
      MenuProps={{
          anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
          },
          transformOrigin: {
              vertical: "top",
              horizontal: "left"
          },
          getContentAnchorEl: null
      }}
        endAdornment={hasSelection &&
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={clearSelection}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        }
  >
      <MenuItem disabled hidden={true} value="">Select...</MenuItem>
      {options.map((column) => <MenuItem hidden={!show(column)} style={{fontSize: '14px', width: '100%'}} className={"option"+options.indexOf(column)} value={column}>{column}</MenuItem>)}
  </Select><br/>
  </>);
}

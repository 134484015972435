import saveCommunitySettings from '../api/commands/saveCommunitySettings'

import { communitySettings } from './data'
import { UIError } from '@peachjar/ui/dist/api/CodedError'

export default function factory(): saveCommunitySettings {
    return async (updates) => {
        const settings = communitySettings.find((c) => {
            return c.hierarchyType === updates.hierarchyType &&
                   c.hierarchyId === updates.hierarchyId
        })

        if (!settings) {
            throw new UIError(
                `request.resource.not_found.${updates.hierarchyType}`,
                `${updates.hierarchyType} not found.`
            )
        }

        return Object.assign(settings, updates)
    }
}

import { EntityTypes } from '../api/Entity'
import { DistrictPermissions } from '../api/permissions/DistrictPermissions'
import { SchoolPermissions } from '../api/permissions/SchoolPermissions'
import { ActionPermissions } from '../api/permissions/ActionPermissions'
import { usePermissions }  from '@peachjar/ui/dist/lib/hooks/usePermissions'

export function useSchoolPermissions(
    schoolId: number,
): SchoolPermissions {
    return usePermissions<SchoolPermissions>(EntityTypes.School, schoolId)
}

export function useDistrictPermissions(
    districtId: number,
): DistrictPermissions {
    return usePermissions<DistrictPermissions>(EntityTypes.District, districtId)
}

export function useActionPermissions(): ActionPermissions {
    return usePermissions<ActionPermissions>(EntityTypes.Actions, -1)
}

import React, { FunctionComponent } from 'react'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'

type Props = {
    filename: string,
}

const InvalidExtensionNotification: FunctionComponent<Props> = ({
    filename,
}) => {
    return (
        <InlineNotification
            variant='error'
            message={
                <span>
                    Unable to upload file because the <strong>file type is not a
                    CSV</strong>. Try saving or exporting the file as a CSV file.
                </span>
            }
        />
    )
}

export default InvalidExtensionNotification

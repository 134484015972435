import updateDistrictInfo, { UpdateableFields } from '../api/commands/updateDistrictInfo'
import { District } from '../api/District'
import { UIError } from '../api/CodedError'
import { districts } from './data'

import wait from './wait'
import errorFactory from './errorFactory'

export default function factory(): updateDistrictInfo {

    const checkError = errorFactory()

    return async (updates: Partial<UpdateableFields>): Promise<District> => {
        await wait(1200)

        checkError(updates.generalDisclaimer || '')

        const district = districts.find(d => d.districtId === updates.districtId)

        return Object.assign(district, updates, {
            createdAt: new Date(),
            createdBy: 'foobar',
            updatedAt: new Date(),
            updatedBy: 'asdfasdf',
        }) as any as District
    }
}

import { District } from '../api/District'
import { UIError } from '../api/CodedError'
import { districts } from './data'

import User, { LoginTypes } from '@peachjar/ui/dist/api/User'

import getDistrict from '../api/queries/getDistrict'
import wait from './wait'
import { getMockState } from '@peachjar/ui/dist/lib/contexts/Mocks/store'
import { RoleNames } from '../api/Role'
import { EntityTypes } from '../api/Entity'

type Deps = {
    mockUsers: User[]
}

export default function factory({ mockUsers }): getDistrict {
    return async (districtId: number): Promise<District> => {
        const state = getMockState()
        const user: User = mockUsers.find(u => u.id === state.userId)

        if (!user) {
            console.error('User not found in mock context', state, mockUsers)
            throw new UIError('internal.configuration', 'Mock configuration is invalid.')
        }

        const isDistrictAdmin = user.loginType === LoginTypes.Internal || !!user.roles
            .find((r) => {
                return r.roles.includes(RoleNames.Admin) &&
                       r.forEntity.entityType === EntityTypes.District &&
                       r.forEntity.entityId === districtId
            })

        const adminOfSchools = user.roles
            .filter((r) => {
                return r.roles.includes(RoleNames.Admin) &&
                       r.forEntity.entityType === EntityTypes.School
            })
            .map(r => r.forEntity.entityId)

        const district = districts.find(d => d.districtId === districtId)

        await wait(1000)

        if (!district) {
            throw new UIError('request.resource.not_found.district', 'District not found.')
        }

        return Object.assign({}, district, {
            schools: district.schools?.filter((s) => isDistrictAdmin || adminOfSchools.includes(s.schoolId)),
        })
    }
}

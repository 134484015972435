import { Grants, PermissionsMapper } from '@peachjar/ui/dist/api/Permissions'
import { RoleNames } from '../Role'
import { EntityTypes } from '../Entity'

import createMapper, { RoleMatrix } from '@peachjar/ui/dist/lib/contexts/Permissions/matrixRoleMapper'

const { None: N, Read: R, Write: W, Execute: X } = Grants

// Structure of Permissions
export type DistrictPermissions = {
    id: Grants,
    districtId: Grants,
    name: Grants,
    editName: Grants,
    active: Grants,
    invisible: Grants,
    live: Grants,
    treeCounter: Grants,
    sisId: Grants,
    ncesId: Grants,
    address: Grants,
    startAt: Grants,
    generalDisclaimer: Grants,
    createdAt: Grants,
    createdBy: Grants,
    updatedAt: Grants,
    updatedBy: Grants,
    internal: Grants,
    schools: Grants,
    users: Grants,
    parents: Grants,
    community: Grants,
    actions: {
        update: Grants,
    }
}

// Base Permissions for Users
const BaseDistrictPermissions: DistrictPermissions = {
    id: N,
    districtId: N,
    name: N,
    editName: N,
    active: N,
    invisible: N,
    live: N,
    treeCounter: N,
    sisId: N,
    ncesId: N,
    address: N,
    startAt: N,
    generalDisclaimer: N,
    createdAt: N,
    createdBy: N,
    updatedAt: N,
    updatedBy: N,
    internal: N,
    schools: N,
    users: N,
    parents: N,
    community: N,
    actions: {
        update: N,
    },
}

// Mapping of Permissions by Role
const roleMatrix: RoleMatrix = {
    name: 'District',
    roles: [RoleNames.Uploader, RoleNames.Approver, RoleNames.Admin, RoleNames.Internal],
    perms: {
        id: [N, N, N, X],
        districtId: [N, N, N, X],
        name: [N, N, W, X],
        editName: [N, R, R, X],
        active: [N, N, N, X],
        invisible: [N, N, N, X],
        live: [N, N, N, R],
        treeCounter: [N, N, W, X],
        sisId: [N, N, W, X],
        ncesId: [N, N, N, X],
        address: [N, N, W, X],
        startAt: [N, N, N, X],
        generalDisclaimer: [N, N, W, X],
        createdAt: [N, N, R, X],
        createdBy: [N, N, N, X],
        updatedAt: [N, N, R, X],
        updatedBy: [N, N, R, X],
        internal: [N, N, N, X],
        schools: [N, N, R, X],
        users: [N, N, X, X],
        parents: [N, N, X, X],
        community: [N, N, X, X],
        actions: {
            update: [N, N, X, X],
        }
    }
}

// PermissionsMap registered with MicroApp
export const DistrictPermissionsMap: PermissionsMapper<DistrictPermissions> = {
    entityType: EntityTypes.District,
    map: createMapper({ roleMatrix, base: BaseDistrictPermissions })
}

import React, { FunctionComponent } from 'react'
import RestrictTo from '@peachjar/ui/dist/lib/components/Permissions/RestrictTo'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'

import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { styled } from '@material-ui/core/styles'

import colors from '@peachjar/ui/dist/lib/styles/colors'

const { DarkMuted: { Note, Paragraph } } = Typo

const MutedSpan = styled('span')({
    fontFamily: '"Proxima Nova"',
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '8px',
    color: colors.slate,
})

type Props = {
    primaryId?: number,
    graphId?: string,
    permission?: Grants,
}

const GraphIds: FunctionComponent<Props> = ({
    primaryId,
    graphId,
    permission = Grants.None,
}) => {

    if (!graphId || !primaryId) {
        return <></>
    }

    return (
        <RestrictTo permission={permission}>
            <div><Note >Peachjar ID</Note></div>
            <Paragraph >{primaryId} (GID: {graphId})</Paragraph>
        </RestrictTo>
    )
}

export default GraphIds

import React, { FunctionComponent } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { css } from 'emotion'
import { Grid } from '@material-ui/core'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import Tab, { TabIcon } from '../Tab/Tab'

type Props = {
  page: number,
  setPage: any,
  csvUploaded: boolean,
  columnsMapped: boolean,
  grades: string,
  number: number
}

const Tabs: FunctionComponent<Props> = ({
  page,
  setPage,
  csvUploaded,
  columnsMapped,
  grades,
  number
}) => {

  const { notifyError } = useGlobalNotifications()

  const handleStep1Click = () => {
    if (page === 0) return 

    else {
    // generate url based on editDraft mode vs new post flyer submission
      setPage(0)
    }

  }

  const handleStep2Click = () => {
    if (page === 1) return 
    if (csvUploaded) {
      setPage(1)
    }
  }

  const handleStep3Click = () => {
    if (page === 2) return

    if (columnsMapped) {
      setPage(2)
    }
  }

  let tabs: any[] = []
  
  if(grades !== "") {
    tabs = [
      {
        id: 1,
        isActive: page === 0,
        icon: TabIcon.Upload,
        label: 'Step 1 Upload CSV File',
        enabled: true, // starting point, always enabled
        onClickHandler: handleStep1Click,
      },
      {
        id: 2,
        isActive: page === 1,
        icon: TabIcon.Pen,
        label: 'Step 2 Map CSV File Columns',
        enabled: csvUploaded,
        onClickHandler: handleStep2Click,
      },
      {
        id: 3,
        isActive: page === 2,
        icon: TabIcon.CheckList,
        label: 'Step 3 Map CSV File Grades',
        enabled: columnsMapped,
        onClickHandler: handleStep3Click,
      },
    ]
  }
  else {
    tabs = [
      {
        id: 1,
        isActive: page === 0,
        icon: TabIcon.Upload,
        label: 'Step 1 Upload CSV File',
        enabled: true, // starting point, always enabled
        onClickHandler: handleStep1Click,
      },
      {
        id: 2,
        isActive: page === 1,
        icon: TabIcon.Pen,
        label: 'Step 2 Map CSV File Columns',
        enabled: csvUploaded,
        onClickHandler: handleStep2Click,
      },
    ]
  }

  return (
    <Grid container spacing={1} className={styles.container}>
      {tabs.map((tab) => (
        <Tab tab={tab} data-testid={tab.id} key={tab.id} number={number} />
      ))}
    </Grid>
  )
}

const styles = {
  container: css`
    display: flex;
    justify-content: space-between;
    width: 100% !important;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  `,
}

export default Tabs

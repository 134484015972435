import React, { FunctionComponent } from 'react'

const SisErrorIcon: FunctionComponent<{}> = ({}) => {
    return (
        <svg
            width='48px'
            height='48px'
            viewBox='0 0 48 48'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >
            <title>53B4E710-ADD9-4CBB-8D4C-BA7C1ECDA9D1@1x</title>
            <g id='Settings' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                <g id='Parent-Settings---SFTP-error' transform='translate(-340.000000, -904.000000)'>
                    <g id='sync-error-icon' transform='translate(340.000000, 904.000000)'>
                        <circle id='Oval' fill='#AC0B1F' cx='24' cy='24' r='24'></circle>
                        <g id='Group' transform='translate(21.000000, 10.000000)' fill='#FFFFFF'>
                            <rect id='Rectangle' x='0' y='0' width='7' height='21' rx='1'></rect>
                            <rect id='Rectangle' transform='translate(3.500000, 26.000000) scale(1, -1) translate(-3.500000, -26.000000) ' x='0' y='23' width='7' height='6' rx='1'></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SisErrorIcon

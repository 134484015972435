import { Config } from '../config/'
import { asFunction, NameAndRegistrationPair } from 'awilix'
import { Deps } from '../dependencies/schema'

import createDistrict from './createDistrict'
import createSchool from './createSchool'
import getDistrict from './getDistrict'
import getSchool from './getSchool'
import getUserAndSiteRoles from './getUserAndSiteRoles'
import getUserAndSiteRolesPaginated from './getUserAndSiteRolesPaginated'
import findAcademicGroupByName from './findAcademicGroupByName'
import updateDistrictInfo from './updateDistrictInfo'
import updateSchoolInfo from './updateSchoolInfo'
import addUser from './addUser'
import updateUser from './updateUser'
import removeUser from './removeUser'
import getSisJobs from './getSisJobs'
import getStaffJobs from './getStaffJobs'
import continueSisJob from './continueSisJob'
import getSisCSVUploadLink from './getSisCSVUploadLink'
import createSisJob from './createSisJob'
import createStaffJob from './createStaffJob'
import uploadSisCSVToCDN from './uploadSisCSVToCDN'
import getCommunitySettings from './getCommunitySettings'
import saveCommunitySettings from './saveCommunitySettings'
import getSisJobErrors from './getSisJobErrors'
import launchGroup from './launchGroup'
import getSchoolsByDistrictNcesId from './getSchoolsByDistrictNcesId'
import resolveAddresses from './resolveAddresses'

export default function mocksFactory(config: Config): NameAndRegistrationPair<Partial<Deps>> {
    return {
        createDistrict: asFunction(createDistrict),
        createSchool: asFunction(createSchool),
        launchGroup: asFunction(launchGroup),
        getDistrict: asFunction(getDistrict),
        getSchool: asFunction(getSchool),
        updateDistrictInfo: asFunction(updateDistrictInfo),
        updateSchoolInfo: asFunction(updateSchoolInfo),
        getUserAndSiteRoles: asFunction(getUserAndSiteRoles),
        getUserAndSiteRolesPaginated: asFunction(getUserAndSiteRolesPaginated),
        findAcademicGroupByName: asFunction(findAcademicGroupByName),
        addUser: asFunction(addUser),
        updateUser: asFunction(updateUser),
        removeUser: asFunction(removeUser),
        getSisJobs: asFunction(getSisJobs),
        getStaffJobs: asFunction(getStaffJobs),
        continueSisJob: asFunction(continueSisJob),
        getSisCSVUploadLink: asFunction(getSisCSVUploadLink),
        createSisJob: asFunction(createSisJob),
        createStaffJob: asFunction(createStaffJob),
        uploadSisCSVToCDN: asFunction(uploadSisCSVToCDN),
        getCommunitySettings: asFunction(getCommunitySettings),
        saveCommunitySettings: asFunction(saveCommunitySettings),
        getSisJobErrors: asFunction(getSisJobErrors),
        getSchoolsByDistrictNcesId: asFunction(getSchoolsByDistrictNcesId),
        resolveAddresses: asFunction(resolveAddresses),
    }
}

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Checkbox, FormControlLabel, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { Section } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { ButtonPrimaryLarge, ButtonFlatLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { uploadSisCSV, saveCsvMapping } from '../../../redux/parentSettings'
import { uploadSisCSVReq } from '../../../redux/parentSettingsSelectors'
import { useDispatch } from 'react-redux'
import { SisIdMap } from '../../../api/CsvMapping'
import { sortBy } from 'lodash'
import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import { ClearableSelect } from './ClearableSelect'

type Props = {
    hierarchyType: string,
    hierarchyId: number,
    isMapper: boolean,
    forceContinue: boolean,
    onNeedsRefreshing: () => void,
    isStaff?: boolean,
    csvColumns: Array<string>,
    setCsvColumns: any,
    selectColumns: Array<string>,
    setSelectColumns: any,
    page: number,
    setPage: any,
    jobId: string,
    setColumnsMapped: any,
    school_sis_id: string,
    setSchool_sis_id: any,
    grades: string,
    setGrades: any,
    emails: string,
    setEmails: any,
    sisIndex: number,
    setSisIndex: any,
    gradesIndex: number,
    setGradesIndex: any,
    emailsIndex: number,
    setEmailsIndex: any,
    emails2: string,
    setEmails2: any,
    emails3: string,
    setEmails3: any,
    emails4: string,
    setEmails4: any,
    emails5: string,
    setEmails5: any,
    setCsvGrades: any,
    acceptedFiles: Array<any>,
    data: Array<any>,
    setData: any,
    header: boolean,
    setHeader: any,
    setTabNumber: any,
    setJobId: any
}

const MapColumns: FunctionComponent<Props> = ({
    hierarchyId,
    hierarchyType,
    isMapper,
    forceContinue,
    isStaff = false,
    selectColumns,
    setSelectColumns,
    page,
    setPage,
    jobId,
    setColumnsMapped,
    school_sis_id,
    setSchool_sis_id,
    grades,
    setGrades,
    emails,
    setEmails,
    sisIndex,
    setSisIndex,
    gradesIndex,
    setGradesIndex,
    emailsIndex,
    setEmailsIndex,
    emails2,
    setEmails2,
    emails3,
    setEmails3,
    emails4,
    setEmails4,
    emails5,
    setEmails5,
    setCsvGrades,
    acceptedFiles,
    data,
    setData,
    header,
    setHeader,
    setTabNumber,
    setJobId
}) => {

    // initialize data structures
    const dispatch = useDispatch()
    let uploadReq = uploadSisCSVReq()
    let sisIdMap: SisIdMap = {sisId: school_sis_id, grade: grades, email: emails}

    // add extra emails to sisIdMap on rerender
    if(emails2 !== "")
        sisIdMap.email2 = emails2
    if(emails3 !== "")
        sisIdMap.email3 = emails3
    if(emails4 !== "")
        sisIdMap.email4 = emails4
    if(emails5 !== "")
        sisIdMap.email5 = emails5
    let gradeLevelMap = {}

    // initialize state
    const [isLoading, setIsLoading] = useState(false)
    const [duplicateColumns, setDuplicateColumns] = useState(false)
    const [numRecords, setNumRecords] = useState(0)
    const [headerValues, setHeaderValues] = useState([])
    const isMounted = useRef(false)
    const [sisError, setSisError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [gradeError, setGradeError] = useState(false)
    const [open, setOpen] = useState(false)

    // error function for red highlight
    function setErrors() {
        if(school_sis_id === "")
            setSisError(true)
        else
            setSisError(false)
        if(emails === "")
            setEmailError(true)
        else
            setEmailError(false)
        if(grades === "")
            setGradeError(true)
        else
            setGradeError(false)
    }

    // function for front-end CSV processing
    function processFile(file:any){
        var file = file;
        var reader = new FileReader();
        var data: string[] = [];
        reader.readAsText(file);
      
        //if you need to read a csv file with a 'ISO-8859-1' encoding
        /*reader.readAsText(file,'ISO-8859-1');*/
      
        //When the file finish load
        reader.onload = function(event) {
      
          //get the file.
          var csv:any = event.target?.result;
      
          //split and get the rows in an array
          var rows = csv.trim().split('\n');
          var trimRows = rows.length >= 5 ? 5 : rows.length - 1
          var i = header ? 1 : 0
          rows[0] = rows[0].replace(/\r?\n|\r/g, "")
          setHeaderValues(rows[0].split(','))
          setNumRecords(rows.length - i)
      
          //move line by line
          for (i; i <= trimRows; i++) {
            //split by separator (,) and get the columns
            data.push(rows[i].split(','));
      
            //move column by column
            // for (var j = 0; j < cols.length; j++) {
            //   /*the value of the current column.
            //   Do whatever you want with the value*/
            //   var value = cols[j];
            // }
          }
        }
        return data;
      }

    // check for duplicate headers
    useEffect(() => {
        if(headerValues.length > 0)
            setDuplicateColumns(!(headerValues.length === new Set(headerValues).size))
    }, [headerValues])

    // open modal if duplicate headers
    useEffect(() => {
        if(header && duplicateColumns)
            setOpen(true)
    }, [header, duplicateColumns])

    // change tab number based on grade mapping
    useEffect(() => {
        grades !== "" ? setTabNumber(3) : setTabNumber(2)
    }, [grades])

    // update data when upload changes
    useEffect(() => {
        if (!uploadReq.model || !uploadReq.model.mapping) return

        if(uploadReq.model) {
            if(uploadReq.model.mapping.sisIdMap.length > 0) {
                setSelectColumns(uploadReq.model.mapping.sisIdMap)
            }
        }

        if(uploadReq.model.mapping.gradeLevelMap != null) {
            if(uploadReq.model.mapping.gradeLevelMap.length > 0) {
                let intArray = sortBy(uploadReq.model.mapping.gradeLevelMap.filter((x) => parseInt(x)), Number, ['asc'])
                let stringArray = sortBy(uploadReq.model.mapping.gradeLevelMap.filter((x) => !parseInt(x)))
                setCsvGrades([...intArray, ...stringArray])
            }
        }
        if(uploadReq.model.jobUuid != null)
            setJobId(uploadReq.model.jobUuid)

        setData(processFile(acceptedFiles[0]))

    }, [uploadReq])

    // restart upload and clear selections if header boolean value changes
    useEffect(() => {
        if (isMounted.current) {
            dispatch(saveCsvMapping({
                hierarchyType,
                hierarchyId,
                header,
                sisIdMap: {},
                gradeLevelMap: {}}))
            dispatch(uploadSisCSV({
                csvFileType: isStaff ? 'staff' : undefined,
                hierarchyType,
                hierarchyId,
                isMapper,
                forceContinue: false,
                file: acceptedFiles[0],
                }))

            setData(processFile(acceptedFiles[0]))
            setSisIndex(-1)
            setGradesIndex(-1)
            setEmailsIndex(-1)
            setSchool_sis_id("")
            setGrades("")
            setEmails("")
            setEmails2("")
            setEmails3("")
            setEmails4("")
            setEmails5("")
        }
        else
            isMounted.current = true
    }, [header])


    // save column mapping and call upload to return grade values
    // skips grade page is no grade column is mapped
    async function handleContinue() {
        setColumnsMapped(true)

        dispatch(saveCsvMapping({
        hierarchyType,
        hierarchyId,
        header,
        sisIdMap,
        gradeLevelMap}))

        setIsLoading(true)
        
        await dispatch(uploadSisCSV({
        csvFileType: isStaff ? 'staff' : undefined,
        hierarchyType,
        hierarchyId,
        jobId,
        isMapper,
        forceContinue,
        file: acceptedFiles[0],
        }))
        
        setIsLoading(false)

        if(grades !== "")
            setPage(page+1)
        else {
            setPage(page+2)
            dispatch(uploadSisCSV({
                csvFileType: isStaff ? 'staff' : undefined,
                hierarchyType,
                hierarchyId,
                jobId,
                isMapper: false,
                forceContinue: true,
                file: acceptedFiles[0],
                }))
        }
    }

    // function for header checkbox
    function handleChange() {
        setHeader(!header)
    }

    // function for modal reupload button
    function handleReupload() {
        setOpen(false)
        setPage(0)
    }

    // function to force headerless upload if there are duplicate columns
    function handleDuplicate() {
        setHeader(false)
        setOpen(false)
    }

    // function to remove value from dopdown if already selected
    function displayValue(value) {
        if(school_sis_id === value || emails === value || grades === value || emails2 === value 
            || emails3 === value || emails4 === value || emails5 === value)
            return false
        else
            return true
    }

    return (
        <Grid container style={{ marginBottom: '24px'}} spacing={8} className="columnPage">
            <Modal
                    title=''
                    open={open}
                    width='sm'
                    close={() => setOpen(false)}>
                        <ModalHeader>Duplicate header</ModalHeader>
                        <DialogContent style={{ padding: '16px' }}>
                            <DialogContentText id="modalText">
                            Your CSV file contains multiple columns with same first row. 
                            Please edit column header names and upload your CSV file again. If the first row of your 
                            CSV file is not header, please select Map without Header.
                            </DialogContentText>
                            <DialogActions className="actions" style={{ padding: '16px 32px' }}>                                <ButtonFlatLarge className="withoutHeaderButton" onClick={handleDuplicate}>Map without Header</ButtonFlatLarge>
                                <ButtonFlatLarge className="reuploadButton" onClick={() => handleReupload()}>Reupload CSV File</ButtonFlatLarge>
                            </DialogActions>
                        </DialogContent>
            </Modal>
            <Grid container item xs={6} className="verticalContainer1">
            <Section headline={'Map CSV File Columns'}>
                <p>Select the CSV file columns to match required information. No selection is needed for the additional email addresses that are optional.</p>
                {header && (
                    <FormControlLabel control={
                        <Checkbox
                            checked={header}
                            onChange={handleChange}
                            style={{color: "green"}}
                            className="headerCheckbox"
                        />}
                        label="First row of CSV file is a header row"/>
                )}
                {!header && (
                    <FormControlLabel control={
                        <Checkbox
                            checked={header}
                            onChange={handleChange}
                            style={{color: "black"}}
                            className="headerCheckbox"
                        />}
                        label="First row of CSV file is a header row"/>
                )}
                <Grid container item>
                    <Grid item xs={5}>
                        <p>Required Data</p>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={5}>
                        <p>CSV File Data Columns</p>
                    </Grid>
                </Grid>
                <Grid container item alignItems='center' className="sisIdGrid">
                    <Grid item xs={5} className="labelContainer">
                        <TextField className="label" type="text" variant="outlined" style={{width: '100%', fontSize: '14px', height: '32px'}}  value="School SIS ID" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}/>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5} className="selectContainer">
                        <ClearableSelect
                            onChange={event => {setSchool_sis_id(event as string); setSisIndex(selectColumns.indexOf(event as string) as number)}}
                            value={school_sis_id}
                            options={selectColumns}
                            show={displayValue}
                            error={sisError}
                        />
                    </Grid>
                </Grid><br/>
                <Grid container item alignItems='center' className="emailGrid">
                    <Grid item xs={5} className="labelContainer">
                        <TextField className="label" type="text" variant="outlined" style={{width: '100%', fontSize: '14px', height: '32px'}}  value="Email" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5} className="selectContainer">
                        <ClearableSelect
                            onChange={event => {setEmails(event as string); setEmailsIndex(selectColumns.indexOf(event as string) as number)}}
                            value={emails}
                            options={selectColumns}
                            show={displayValue}
                            error={emailError}
                        />
                    </Grid>
                </Grid><br/>
                <Grid container item>
                    <Grid item xs={5}>
                        <p style={{color: "grey"}}>Required for grade distribution</p>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={5}>
                        <p></p>
                    </Grid>
                </Grid>
                <Grid container item alignItems='center' className="gradeGrid">
                    <Grid item xs={5} className="labelContainer">
                        <TextField className="label" type="text" variant="outlined" style={{width: '100%', fontSize: '14px', height: '32px'}}  value="Grade" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5} className="selectContainer">
                        <ClearableSelect
                            onChange={event => {setGrades(event as string); setGradesIndex(selectColumns.indexOf(event as string) as number)}}
                            value={grades}
                            options={selectColumns}
                            show={displayValue}
                            error={gradeError}
                        />
                    </Grid>
                </Grid><br/>
                <Grid container item>
                    <Grid item xs={5}>
                        <p>Optional Data</p>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={5}>
                        <p>CSV File Data Columns</p>
                    </Grid>
                </Grid>
                <Grid container item alignItems='center' className="email2Grid">
                    <Grid item xs={5} className="labelContainer">
                        <TextField className="label" type="text" variant="outlined" style={{width: '100%', fontSize: '14px', height: '32px'}}  value="Email 2" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5} className="selectContainer">
                        <ClearableSelect
                            onChange={event => {setEmails2(event as string)}}
                            value={emails2}
                            options={selectColumns}
                            show={displayValue}
                        />
                    </Grid>
                </Grid><br/>
                <Grid container item alignItems='center' className="email3Grid">
                    <Grid item xs={5} className="labelContainer">
                        <TextField className="label" type="text" variant="outlined" style={{width: '100%', fontSize: '14px', height: '32px'}}  value="Email 3" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5} className="selectContainer">
                        <ClearableSelect
                            onChange={event => {setEmails3(event as string)}}
                            value={emails3}
                            options={selectColumns}
                            show={displayValue}
                        />
                    </Grid>
                </Grid><br/>
                <Grid container item alignItems='center' className="email4Grid">
                    <Grid item xs={5} className="labelContainer">
                        <TextField className="label" type="text" variant="outlined" style={{width: '100%', fontSize: '14px', height: '32px'}}  value="Email 4" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5} className="selectContainer">
                        <ClearableSelect
                            onChange={event => {setEmails4(event as string)}}
                            value={emails4}
                            options={selectColumns}
                            show={displayValue}
                        />
                    </Grid>
                </Grid><br/>
                <Grid container item alignItems='center' className="email5Grid">
                    <Grid item xs={5} className="labelContainer">
                        <TextField type="text" variant="outlined" style={{width: '100%', fontSize: '14px', height: '32px'}}  value="Email 5" InputProps={{readOnly: true, style: { fontWeight: 'bold',  fontSize: '14px', height: '32px'}}}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{textAlign: "center"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#yz0zwx405a)">
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#2D344B"/>
                                </g>
                                <defs>
                                    <clipPath id="yz0zwx405a">
                                        <path fill="#fff" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={5} className="selectContainer">
                        <ClearableSelect
                            onChange={event => {setEmails5(event as string)}}
                            value={emails5}
                            options={selectColumns}
                            show={displayValue}
                        />
                    </Grid>
                </Grid>
            </Section>
            </Grid><br/>
            <Grid container item xs={6} className="verticalContainer2">
                <Section headline={'Column Mapping Preview'}>
                    
                        {(sisIndex == -1 && gradesIndex == -1 && emailsIndex == -1) &&
                        <table className="previewTable" style={{width: '100%', border: "1px solid grey", color: "grey"}}>
                            <tr className="headerRow" style={{backgroundColor: "#f5f5f5"}}>
                                <th className="column1" style={{padding: "10px"}}>School SIS ID</th>
                                <th className="column2" style={{padding: "10px"}}>Email</th>
                            </tr>
                            <tr className="row1">
                                <td className="column1" style={{padding: "10px"}}>--</td>
                                <td className="column2" style={{padding: "10px"}}>--</td>
                            </tr>
                        </table>
                        }
                        {(sisIndex != -1 || gradesIndex != -1 || emailsIndex != -1) &&
                            <table className="previewTable" style={{width: '100%', border: "1px solid grey", color: "grey"}}>
                                <tr className="headerRow" style={{backgroundColor: "#f5f5f5"}}>
                                    <th className="column1" style={{padding: "10px"}}>School SIS ID</th>
                                    <th className="column2" style={{padding: "10px"}}>Email</th>
                                    {grades !== "" && (
                                        <th className="column3" style={{padding: "10px"}}>Grade</th>
                                    )}
                                </tr>
                                {data.length > 0 &&
                                    <tr className="row1">
                                        <td className="column1" style={{padding: "10px"}}>{sisIndex != -1 ? data[0][sisIndex] as string : '--'}</td>
                                        <td className="column2" style={{padding: "10px"}}>{emailsIndex != -1 ? data[0][emailsIndex] as string : '--'}</td>
                                        {grades !== "" && (
                                            <td className="column3" style={{padding: "10px"}}>{gradesIndex != -1 ? data[0][gradesIndex] as string : '--'}</td>
                                        )}
                                    </tr>
                                }
                                {data.length > 1 &&
                                    <tr className="row2" style={{backgroundColor: "#f5f5f5"}}>
                                        <td className="column1" style={{padding: "10px"}}>{sisIndex != -1 ? data[1][sisIndex] as string : '--'}</td>
                                        <td className="column2" style={{padding: "10px"}}>{emailsIndex != -1 ? data[1][emailsIndex] as string : '--'}</td>
                                        {grades !== "" && (
                                            <td className="column3" style={{padding: "10px"}}>{gradesIndex != -1 ? data[1][gradesIndex] as string : '--'}</td>
                                        )}
                                    </tr>
                                }
                                {data.length > 2 &&
                                    <tr className="row3">
                                        <td className="column1" style={{padding: "10px"}}>{sisIndex != -1 ? data[2][sisIndex] as string : '--'}</td>
                                        <td className="column2" style={{padding: "10px"}}>{emailsIndex != -1 ? data[2][emailsIndex] as string : '--'}</td>
                                        {grades !== "" && (
                                            <td className="column3" style={{padding: "10px"}}>{gradesIndex != -1 ? data[2][gradesIndex] as string : '--'}</td>
                                        )}
                                    </tr>
                                }
                                {data.length > 3 &&
                                    <tr  className="row4" style={{backgroundColor: "#f5f5f5"}}>
                                        <td className="column1" style={{padding: "10px"}}>{sisIndex != -1 ? data[3][sisIndex] as string : '--'}</td>
                                        <td className="column2" style={{padding: "10px"}}>{emailsIndex != -1 ? data[3][emailsIndex] as string : '--'}</td>
                                        {grades !== "" && (
                                            <td className="column3" style={{padding: "10px"}}>{gradesIndex != -1 ? data[3][gradesIndex] as string : '--'}</td>
                                        )}
                                    </tr>
                                }
                                {data.length > 4 &&
                                    <tr className="row5">
                                        <td className="column1" style={{padding: "10px"}}>{sisIndex != -1 ? data[4][sisIndex] as string : '--'}</td>
                                        <td className="column2" style={{padding: "10px"}}>{emailsIndex != -1 ? data[4][emailsIndex] as string : '--'}</td>
                                        {grades !== "" && (
                                            <td className="column3" style={{padding: "10px"}}>{gradesIndex != -1 ? data[4][gradesIndex] as string : '--'}</td>
                                        )}
                                    </tr>
                                }
                            </table>
                        }
                        {(sisIndex === -1 && emailsIndex === -1) &&
                            <p style={{color: "grey"}}>You haven't selected any mapping yet.</p>
                        }
                        {((sisIndex === -1 || emailsIndex === -1) && !(sisIndex === -1 && emailsIndex === -1)) &&
                            <p style={{color: "grey"}}>Please map all of the required fields.</p>
                        }
                        {(sisIndex !== -1 && emailsIndex !== -1) &&
                            <p style={{color: "grey"}}>{numRecords} records will be created.</p>
                        }
                </Section>
            </Grid>
            <Grid container
                justifyContent="flex-end"
                className="buttonContainer"
                alignItems="center">
                    <ButtonFlatLarge
                        className="backButton" 
                        onClick={() => {setPage(page-1)}}>
                        Back
                    </ButtonFlatLarge>
                    <div onClick={setErrors}>
                        <ButtonPrimaryLarge disabled={(school_sis_id === "" || emails === "" || (duplicateColumns && header))}
                            className="continueButton"
                            isLoading={isLoading}
                            onClick={() => {handleContinue()}}>
                            Continue
                        </ButtonPrimaryLarge>
                    </div>
            </Grid>
        </Grid>
    )
}

export default MapColumns
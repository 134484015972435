import React, { FunctionComponent } from 'react'
import useQueryParams from '../../hooks/useQueryParams'
import AppHeader from '../../components/AppHeader/AppHeader'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import Paragraph from '@peachjar/ui/dist/lib/typography/Paragraph'
import Headline2 from '@peachjar/ui/dist/lib/typography/Headline2'

type Props = {}

export const NotFoundPage: FunctionComponent<Props> = () => {
    const queryParams = useQueryParams()

    const message = queryParams.msg || 'The resource you request was not found.'

    return (
        <>
            <AppHeader>Error</AppHeader>
            <ContentContainer>
            <Headline2>Not Found</Headline2><br />
            <Paragraph>{message}</Paragraph>
            </ContentContainer>
        </>
    )
}

import React, { FunctionComponent } from 'react'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Grid from '@material-ui/core/Grid'
import Tag, { Variants } from '@peachjar/ui/dist/lib/components/Tag'

import { formatDate } from '../../utils'

const { DarkMuted: { Note, Paragraph } } = Typo

type Props = {
    launchedAt: Date,
}

const LaunchedAt: FunctionComponent<Props> = ({
    launchedAt,
}) => {
    return (
        <>
            <Grid container spacing={2} alignItems='flex-end'>
                <Grid item>
                    <div><Note >Start Date</Note></div>
                    <Paragraph >{launchedAt && formatDate(launchedAt)}</Paragraph>
                </Grid>
                <Grid item>
                    <Tag variant={Variants.Mint}>Launched</Tag>
                </Grid>
            </Grid>
        </>
    )
}

export default LaunchedAt

import React, { FunctionComponent, useEffect } from 'react'
import BaseDistrictPage from './components/BaseDistrictPage'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'
import UpdateSchoolPageBody from '../School/components/UpdateSchoolPageBody'
import Routes from '../../routes'

import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import { getDistrict, clearReq } from '../../redux/districts'
import { RootState } from '../../redux'
import { District } from '../../api/District'
import { UIError, ErrorCodes } from '@peachjar/ui/dist/api/CodedError'

const ViewUpdateSchoolPage: FunctionComponent<{}> = () => {

    const panic = usePanic()
    const dispatch = useDispatch()
    const history = useHistory()

    const { schoolId: schoolIdParam, districtId: districtIdParam } = useParams<{ schoolId: string, districtId: string }>()
    const schoolId = parseInt(schoolIdParam, 10)
    const districtId = parseInt(districtIdParam, 10)
    const districtReq = useSelector<RootState, AsyncReqState<District>>(state => state.districts.district)

    useEffect(() => { dispatch(getDistrict(districtId)) }, [])

    if (districtReq.isLoading()) {
        return <LoadingSpinner />
    }

    // Could not load the school
    if (districtReq.error) {
        return panic(districtReq.error)
    }

    const district = districtReq.model!
    const school = district.schools!.find(s => s.schoolId === schoolId)

    if (!school) {
        return panic(new UIError(ErrorCodes.Resource.NotFound, `School with id ${schoolId} not found.`))
    }

    const hasMoreThanOneSchool = district.schools!.length > 1

    return (
        <BaseDistrictPage
            title={'School Settings'}
            district={district!}
            tabIndex={0}
            showCrumbBackToDistrict={hasMoreThanOneSchool}
        >
            <UpdateSchoolPageBody
                school={school}
                isIndependent={false}
                onSubmitSuccess={() => {
                    dispatch(clearReq())
                    history.push(Routes.districts.update.from(district!.districtId, {
                        notify: {
                            key: 'school_updated',
                            variant: 'success',
                            message: 'School settings are updated.',
                        },
                    }))
                }}
            />
        </BaseDistrictPage>
    )
}

export default ViewUpdateSchoolPage

import { School } from '../../api/School'
import { omit } from 'lodash'
import { normalizeSchoolForUI, GQLSchool, normalizeSchoolForGQL } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import updateSchoolInfo, { UpdateableFields } from '../../api/commands/updateSchoolInfo'
import gql from 'graphql-tag'
import mapError from './mapError'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const UPDATE_SCHOOL = gql`
    mutation updateSchool($input: V2_UpdateSchoolInput!) {
        updateSchoolInfo(input: $input) {
            id
            schoolId
            name
            active
            visible
            treeCounter
            sisId
            ncesId
            address {
                address
                googlePlaceId
            }
            lowGrade
            highGrade
            enrollmentCount
            startAt
            generalDisclaimer
            isIndependent
            createdAt
            createdBy
            updatedAt
            updatedBy
        }
    }
`

type GQLResult = {
    updateSchoolInfo: GQLSchool
}

const OmittedFields = ['live', 'launchedAt', 'launchedBy', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy']

export default function factory({ apolloClient }: Deps): updateSchoolInfo {
    return async (updates: Partial<UpdateableFields>): Promise<School> => {

        const input = omit(updates, OmittedFields)

        try {

            const result = await apolloClient.mutate<GQLResult>({
                mutation: UPDATE_SCHOOL,
                variables: {
                    input: normalizeSchoolForGQL(input as School),
                }
            })

            return normalizeSchoolForUI(result.data!.updateSchoolInfo)

        } catch (error) {

            throw mapError(error)
        }
    }
}

import React, { FunctionComponent, useState } from 'react'
import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage'
import Form from '@peachjar/ui/dist/lib/components/Form/FormWrapper'
import DistrictOrIndySchoolPicker from './components/DistrictOrIndySchoolPicker'
import FindEntityByNamePicker, { EntitySelection, SelectionSources } from './components/FindEntityByNamePicker'
import TypographyRedux from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import Routes from '../../routes'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { District } from '../../api/District'
import { School } from '../../api/School'
import { setCreateParams as setDistrictCreateParams } from '../../redux/districts'
import { setCreateParams as setSchoolCreateParams } from '../../redux/schools'

import useFormBuilder, { yupVal } from '@peachjar/ui/dist/lib/hooks/useFormBuilder'

import * as yup from 'yup'

const { Dark: { Note } } = TypographyRedux

const CreateDistrictOrIndySchoolPage: FunctionComponent<{}> = ({}) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [entityType, setEntityType] = useState<string>('')

    const entityTypePickerBindings = useFormBuilder<{ entityType: string }>({ entityType: '' }, [
        {
            path: 'entityType',
            validate: yupVal(yup.string().required('You missed this field.')),
        }
    ])

    const entityPickerBindings = useFormBuilder<{ entity: EntitySelection }>(null, [
        {
            path: 'entity',
            validate: yupVal(
                yup.object()
                    .required('You missed this field.')
                    .typeError('You missed this field.')
            ),
            initial: null,
        }
    ])

    const redirectOnSubmit = () => {
        const selection = entityPickerBindings.bindings.entity.value as EntitySelection
        switch (selection.source) {
            // Enter new school or district
            case SelectionSources.NCES:
                if (entityType === 'district') {
                    dispatch(setDistrictCreateParams(selection.initial as Partial<District>))
                    return history.push(Routes.districts.new.from())
                }
                dispatch(setSchoolCreateParams(selection.initial as Partial<School>))
                return history.push(Routes.schools.new.from())
            // Existing school or district
            case SelectionSources.Peachjar:
                return history.push(
                    entityType === 'district' ?
                        Routes.districts.update.from((selection.initial as District).districtId) :
                        Routes.schools.update.from((selection.initial as School).schoolId)
                )
            // New selection
            default:
                return history.push(
                    entityType === 'district' ?
                        Routes.districts.new.from() :
                        Routes.schools.new.from()
                )
        }
    }

    return (
        <DefaultPage title='New District / Independent School'>
            <Sections>
                {!entityType &&
                <Section>
                    <Form
                        isSubmitting={false}
                        formBindings={entityTypePickerBindings}
                        submitLabel='Next'
                        onSubmit={() => setEntityType(entityTypePickerBindings.bindings.entityType.value)}
                        onCancel={() => true}
                        hideCancel={true}
                        fields={[
                            <DistrictOrIndySchoolPicker binding={entityTypePickerBindings.bindings.entityType} />
                        ]}
                    />
                </Section>}
                {entityType && <Section>
                    <div style={{ marginBottom: '8px' }}>
                        <Note >
                            Enter {entityType === 'district' ? 'district' : 'school'} name and select the best option.
                        </Note>
                    </div>
                    <Form
                        isSubmitting={false}
                        formBindings={entityPickerBindings}
                        submitLabel='Next'
                        onSubmit={redirectOnSubmit}
                        onCancel={() => {
                                entityPickerBindings.reset()
                                setEntityType('')
                            }
                        }
                        fields={[
                            <FindEntityByNamePicker
                                entityType={entityType}
                                binding={entityPickerBindings.bindings.entity}
                            />
                        ]}
                    />
                </Section>}
            </Sections>
        </DefaultPage>
    )
}

export default CreateDistrictOrIndySchoolPage

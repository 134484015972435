import { District } from '../../api/District'
import { LoggerApi } from '@peachjar/kernel-sanders'
import { normalizeDistrictForUI, GQLDistrict } from './normalizeModels'

import ApolloClient from 'apollo-boost'

import getDistrict from '../../api/queries/getDistrict'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
    logger: LoggerApi,
}

const GET_DISTRICT = gql`
    query district($districtId: Int!) {
        district: getDistrict(input: { districtId: $districtId }) {
            id
            districtId
            name
            active
            visible
            live
            treeCounter
            sisId
            ncesId
            address {
                googlePlaceId
                address
            }
            generalDisclaimer
            startAt
            createdAt
            createdBy
            updatedAt
            updatedBy
            launchedAt
            launchedBy
            schools {
                id
                schoolId
                name
                active
                visible
                live
                treeCounter
                sisId
                ncesId
                address {
                    googlePlaceId
                    address
                }
                lowGrade
                highGrade
                enrollmentCount
                startAt
                generalDisclaimer
                isIndependent
                transitionalGroupType
                createdAt
                createdBy
                updatedAt
                updatedBy
                launchedAt
                launchedBy
            }
        }
    }
`

type QueryResult = {
    district: GQLDistrict | null
}

export default function factory({ apolloClient, logger }: Deps): getDistrict {
    return async (districtId: number): Promise<District> => {

        try {

            const result = await apolloClient.query<QueryResult>({
                query: GET_DISTRICT,
                fetchPolicy: 'network-only',
                variables: { districtId },
            })

            return normalizeDistrictForUI(result.data.district!)

        } catch (error) {

            const mappedError = mapError(error)

            logger.captureWithScope(error, { districtId })

            throw mappedError
        }
    }
}

import continueSisJob from '../api/commands/continueSisJob'

import { sisJobs } from './data'
import wait from './wait'
import { JobStatus } from '../api/SisJob'

export default function factory(): continueSisJob {
    return async (params) => {
        await wait(1000)
        const sisJob = sisJobs.find(s => s.jobUuid === params.jobId)!
        sisJob.status = JobStatus.Pending
        return sisJob
    }
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage'
import Routes from '../../routes'
import DistrictForm from './components/DistrictForm'
import AsyncReqState, { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import QueryStringNotification from '@peachjar/ui/dist/lib/components/Notifications/QueryStringNotification'
import AssociatedSchoolsList from './components/AssociatedSchoolsList'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SwitchError } from '@peachjar/ui/dist/lib/components/Errors'
import { RootState } from '../../redux'
import {
    clearReq,
    createDistrict,
    getSchoolsByDistrictNcesId,
    resolveAddressForDistrict,
    setCreateParams as setDistrictCreateParams,
} from '../../redux/districts'
import { District } from '../../api/District'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { useActionPermissions } from '../../hooks/usePermissions'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { createDistrictParams, resolvedDistrictAddress, schoolsByDistrictNcesId } from '../../redux/districtsSelectors'
import { LoadingSpinner } from '@peachjar/ui/dist/lib/components/Spinners'

import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'

const CreateDistrictPage: FunctionComponent<{}> = () => {

    const { close: closeNotification } = useGlobalNotifications()

    const actionPermissions = useActionPermissions()
    const history = useHistory()
    const dispatch = useDispatch()
    const req = useSelector<RootState, AsyncReqState<District>>(state => state.districts.createDistrict)

    const createParams = createDistrictParams() as District
    const schoolsByNcesDistrictReq = schoolsByDistrictNcesId()
    const resolveDistrictAddressReq = resolvedDistrictAddress()

    const shouldLoadSchools = !!createParams?.ncesId
    const shouldResolveAddress = !!createParams?.address?.address && createParams?.address?.googlePlaceId === 'nces'

    const clearInitialParams = () => dispatch(setDistrictCreateParams(null))

    // If the req is successful, redirect to the update page.
    useEffect(() => {
        if (req.status === Statuses.loaded) {
            dispatch(clearReq())
            history.push(Routes.districts.update.from(req.model?.districtId!))
        }
        if (req.status === Statuses.loading) {
            closeNotification()
        }
    }, [req])

    useEffect(() => {
        if (shouldResolveAddress) {
            dispatch(resolveAddressForDistrict(createParams.address?.address!))
        }
    }, [])

    useEffect(() => {
        if (shouldLoadSchools) {
            dispatch(getSchoolsByDistrictNcesId(createParams.ncesId))
        }
    }, [])

    const shouldShowForm = !shouldResolveAddress ||
        [Statuses.loaded, Statuses.failed].includes(resolveDistrictAddressReq.status)

    return (
        <DefaultPage
            title='Add District'
            breadCrumbs={actionPermissions.searchDistricts === Grants.Execute ? [{
                label: 'Add District / Ind. School',
                onClick: () => history.push(Routes.workflows.newDistrictOrIndySchool.from()),
            }] : []}
        >
            <Sections>
                <Section>
                    <QueryStringNotification />
                    <SwitchError error={req.error} />
                    {shouldShowForm && <DistrictForm
                        isEdit={false}
                        district={{
                            ...createParams,
                            address: shouldResolveAddress ?
                                (resolveDistrictAddressReq?.model || createParams?.address)
                                : createParams?.address,
                        }}
                        errors={{ sisId: { message: '', lastNonUniqueVal: null } }}
                        onSubmit={(params) => {
                            clearInitialParams()
                            dispatch(createDistrict({
                                name: params.name!,
                                active: params.active!,
                                invisible: !params.invisible!,
                                treeCounter: params.treeCounter!,
                                address: params.address!,
                                ncesId: params.ncesId!,
                                sisId: params.sisId!,
                                startAt: params.startAt,
                                generalDisclaimer: params.generalDisclaimer,
                            }))
                        }}
                        onCancel={() => {
                            clearInitialParams()
                            history.goBack()
                        }}
                        isSubmitting={req.status === Statuses.loading}
                    />}
                    {!shouldShowForm && <LoadingSpinner />}
                </Section>
                {shouldLoadSchools &&
                    <Section headline={`Existing and NCES Schools (${(schoolsByNcesDistrictReq.model?.existing?.length || 0) + (schoolsByNcesDistrictReq.model?.nces?.length || 0)})`}>
                        {schoolsByNcesDistrictReq.isLoading() && <LoadingSpinner />}
                        {schoolsByNcesDistrictReq.status === Statuses.loaded && (
                            <AssociatedSchoolsList
                                existing={schoolsByNcesDistrictReq.model!.existing}
                                nces={schoolsByNcesDistrictReq.model!.nces}
                            />
                        )}
                        {schoolsByNcesDistrictReq.status === Statuses.failed && (
                            <div>An error occurred loading schools.</div>
                        )}
                    </Section>
                }
            </Sections>
        </DefaultPage>
    )
}

export default CreateDistrictPage

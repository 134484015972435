import { LoggerApi } from '@peachjar/kernel-sanders'
import { ErrorInfo } from '@peachjar/kernel-sanders/dist/types/logger'

const backupLogger: LoggerApi  = {
    capture: function (p1: any) {
        console.info(p1)
    },
    captureWithScope: function (p1: any, p2: ErrorInfo) {
        console.info(p1, p2)
    },
    debug: function (p1: string | object) {
        console.debug(p1)
    },
    init: function () {}
}

export default backupLogger

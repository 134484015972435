import resolveAddresses from '../api/queries/resolveAddresses'
import wait from './wait'

export default function factory(): resolveAddresses {
    return async (queries) => {
        await wait(1500)
        if (queries.length === 1) {
            return [
                {
                    address: '2169 Moonridge Ln, Fort Mohave, AZ 86426, USA',
                    googlePlaceId: 'ChIJX8K74AA_zoAR3DARhwGE2Ko'
                }
            ]
        }
        return queries.map(q => null)
    }
}

import React, { FunctionComponent, useMemo } from 'react'
import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage'
import Routes from '../../../routes'

import { useHistory } from 'react-router-dom'
import { useActionPermissions, useDistrictPermissions } from '../../../hooks/usePermissions'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { District } from '../../../api/District'

import getTabBar from './Tabs'

export type Props = {
    title: string,
    subText?: string,
    district: District,
    tabIndex: number,
    showCrumbBackToDistrict?: boolean,
    showCrumbBackToParentSettings?: boolean,
}

const BaseDistrictPage: FunctionComponent<Props> = ({
    title,
    district,
    tabIndex,
    showCrumbBackToDistrict = false,
    showCrumbBackToParentSettings = false,
    subText = undefined,
    children,
}) => {

    const actionPermissions = useActionPermissions()
    const permissions = useDistrictPermissions(district.districtId)
    const history = useHistory()

    const isInternal = actionPermissions.internal > Grants.None
    const isDistrictAdmin = permissions.actions.update > Grants.None

    const tabs = useMemo(() => {
        if (isInternal) {
            return getTabBar(district.districtId, tabIndex)
        }
        return undefined
    }, [district.districtId, isInternal])

    let crumbs = showCrumbBackToDistrict ? [{
        label: isDistrictAdmin ? 'District & School Settings' : 'Schools / Sites',
        onClick: () => history.push(Routes.districts.update.from(district.districtId))
    }] : undefined
    
    crumbs = showCrumbBackToParentSettings ? [{
        label: 'Parent Settings',
        onClick: () => history.push(Routes.districts.parents.from(district.districtId))
    }] : undefined

    return (
        <DefaultPage
            title={title}
            tabs={tabs}
            subText={isInternal ? district.name : subText}
            breadCrumbs={crumbs}
        >
            {children}
        </DefaultPage>
    )
}

export default BaseDistrictPage

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Deps } from '../dependencies/schema'
import { ApprovalSettings } from '../api/ApprovalSettings'
import AsyncReqState, {
  notStarted,
  reducerFromAsyncThunk,
} from '@peachjar/ui/dist/api/AsyncReqState'
import { FAILSAFE_SCHEMA } from 'js-yaml'

type State = {
  settings: AsyncReqState<ApprovalSettings>
  hierarchy?: Hierarchy
  isSettingsRefresh: boolean
}

type ThunkContext = {
  state: State
  extra: Deps
}

export type Hierarchy = {
  hierarchyId: number
  hierarchyType: string
}

export const getApprovalSettings = createAsyncThunk<
  ApprovalSettings,
  Hierarchy,
  ThunkContext
>('approvals/settings', (params, { extra: { getApprovalSettings } }) =>
  getApprovalSettings(params)
)

const slice = createSlice({
  name: 'approvals',
  initialState: {
    settings: notStarted(),
    hierarchy: undefined,
    isSettingsRefresh: false,
  } as State,
  reducers: {
    updateHierarchy(state, action: PayloadAction<Hierarchy>) {
      state.hierarchy = action.payload
    },
    setIsSettingsRefresh(state, action: PayloadAction<boolean>) {
      state.isSettingsRefresh = action.payload
    }
  },
  extraReducers: (builder) => {
    reducerFromAsyncThunk<State>(builder, 'settings', getApprovalSettings, (state) => state.isSettingsRefresh = false)
  },
})

export const updateHierarchy = slice.actions.updateHierarchy
export const setIsSettingsRefresh = slice.actions.setIsSettingsRefresh

export default slice

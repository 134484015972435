import React, { FunctionComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import CsvFileIcon from './icons/CsvFileIcon'
import PausedAddendum from './PausedAddendum'
import FailedAddendum from './FailedAddendum'

import { JobStatus, SisJob } from '../../../api/SisJob'
import { createStyles, makeStyles } from '@material-ui/core'

import colors from '@peachjar/ui/dist/lib/styles/colors'

const { Dark: { Note }, DarkBold: { Paragraph: DarkBoldParagraph } } = Typo

const useStyles = makeStyles(createStyles({
    container: {
        padding: '16px',
        borderRadius: '3px',
        border: `1px solid ${colors.platinum}`,
    },
    progressBar: {
        width: '100%',
    }
}))

const useSuccessBarStyles = makeStyles(createStyles({
    colorPrimary: {
        backgroundColor: colors.platinum,
    },
    bar: {
        backgroundColor: colors.leaf,
    },
    statusText: {
        color: colors.jungle,
    }
}))

const useWarningBarStyles = makeStyles(createStyles({
    colorPrimary: {
        backgroundColor: colors.platinum,
    },
    bar: {
        backgroundColor: colors.peach,
    },
    statusText: {
        color: colors.peach,
    }
}))

const useErrorBarStyles = makeStyles(createStyles({
    colorPrimary: {
        backgroundColor: colors.platinum,
    },
    bar: {
        backgroundColor: colors.dragon,
    },
    statusText: {
        color: colors.dragon,
    }
}))

const useCancelledBarStyles = makeStyles(createStyles({
    colorPrimary: {
        backgroundColor: colors.platinum,
    },
    bar: {
        backgroundColor: colors.mercury,
    },
    statusText: {
        color: colors.slate,
    }
}))

const ProgressContainer: FunctionComponent<{}> = ({ children }) => {
    const classes = useStyles()
    return (
        <Grid className={classes.container} container>{children}</Grid>
    )
}

type ProgressBarProps = {
    filename: string,
    status: JobStatus,
    percentage: number,
    phase: string,
}

function translatePhase(phase: string): string {
    switch (phase) {
        case 'get-upload-url': return 'Preparing Upload'
        case 'uploading': return 'Uploading File'
        case 'complete': return 'Success'
        case 'failed': return 'Unexpected Error'
        default: return 'Processing CSV'
    }
}

function getHumanizedStatus(phase: string, status: JobStatus): string {
    switch (status) {
        case JobStatus.Processing: return translatePhase(phase)
        case JobStatus.Cancelled: return 'Cancelled'
        case JobStatus.Failed: return 'Error'
        case JobStatus.Paused: return 'Warning'
        default: return 'Success'
    }
}

function getStyleHook(phase: string, status: JobStatus) {
    switch (status) {
        case JobStatus.Pending: return useSuccessBarStyles
        case JobStatus.Processing:
            return phase === 'failed' ? useErrorBarStyles : useSuccessBarStyles
        case JobStatus.Cancelled: return useCancelledBarStyles
        case JobStatus.Failed: return useErrorBarStyles
        case JobStatus.Paused: return useWarningBarStyles
        default: return useSuccessBarStyles
    }
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({
    filename,
    status,
    percentage,
    phase,
}) => {
    const classes = getStyleHook(phase, status)()
    return (
        <Grid container style={{ width: '100%' }}>
            <Grid item xs={10}>
                <DarkBoldParagraph >{filename}</DarkBoldParagraph>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
                <Note  className={classes.statusText}>
                    {getHumanizedStatus(phase, status)}
                </Note>
            </Grid>
            <Grid item xs={12}>
                <LinearProgress variant='determinate' value={percentage} classes={classes} />
            </Grid>
        </Grid>
    )
}

type Props = {
    onContinuePressed?: () => void,
    filename: string,
    status: JobStatus,
    sisJob?: SisJob,
    progress: number,
    phase: string,
}

const ManualJobStatus: FunctionComponent<Props> = ({
    onContinuePressed,
    filename,
    status,
    sisJob,
    progress,
    phase,
}) => {
    return (
        <ProgressContainer>
            <Grid item md={1} xs={3}>
                <CsvFileIcon />
            </Grid>
            <Grid item md={11} xs={9}>
                <ProgressBar
                    filename={filename}
                    status={status}
                    percentage={progress}
                    phase={phase}
                />
            </Grid>
            {sisJob && sisJob.status === JobStatus.Paused && (
                <>
                    <Grid item  md={1} xs={3} />
                    <Grid item md={11} xs={9}>
                        <PausedAddendum
                            onContinuePressed={onContinuePressed}
                            sisJob={sisJob!}
                            isManual={true}
                        />
                    </Grid>
                </>
            )}
            {sisJob && sisJob.status === JobStatus.Failed && (
                <>
                    <Grid item  md={1} xs={3} />
                    <Grid item md={11} xs={9}>
                       <FailedAddendum sisJob={sisJob} isManual={true} />
                    </Grid>
                </>
            )}
        </ProgressContainer>
    )
}

export default ManualJobStatus

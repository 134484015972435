import React, { FunctionComponent, useContext, useState, useEffect } from 'react'
import AutocompleteInput, { LookupFn } from '@peachjar/ui/dist/lib/components/Inputs/AutocompleteInput'
import Grid from '@material-ui/core/Grid'
import TypographRedux from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import DependenciesContext from '@peachjar/ui/dist/lib/contexts/Dependencies'
import SwitchError from '@peachjar/ui/dist/lib/components/Errors/SwitchError'

import { BindToInput } from '@peachjar/ui/dist/lib/hooks/useFormBuilder'
import { School } from '../../../api/School'
import { District } from '../../../api/District'

import findAcademicGroupByName from '../../../api/queries/findAcademicGroupByName'
import CodedError from '../../../api/CodedError'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'

const { Dark: { Paragraph }, DarkMuted: { Note } } = TypographRedux

export type EntitySelection = {
    id: string,
    description: string,
    source: string,
    city: string,
    state: string,
    initial: Partial<School> | Partial<District>,
}

export enum SelectionSources {
    Peachjar = 'Peachjar',
    NCES = 'NCES',
    New = 'new',
}

export const EnterNewSelection: EntitySelection = {
    id: 'thisisanewdistrict',
    description: 'Enter New',
    source: SelectionSources.New,
    city: '',
    state: '',
    initial: {}
}

type Props = {
    entityType: string,
    binding: BindToInput<any, any>
}

const FindEntityByNamePicker: FunctionComponent<Props> = ({
    entityType,
    binding,
}) => {

    const { close: closeError } = useGlobalNotifications()

    const [error, setError] = useState<CodedError | null>(null)
    const { container } = useContext(DependenciesContext)
    const findAcademicGroupByName = container
        .resolve<findAcademicGroupByName>('findAcademicGroupByName')

    useEffect(() => {
        if (error === null) {
            closeError()
        }
    }, [error])

    const lookup: LookupFn<EntitySelection> = async (options, callback) => {
        try {
            const results = await findAcademicGroupByName({
                showOnlyAvailableNCES: true,
                nameMatch: options.input,
                entityType,
            })
            setError(null)
            if (results.nces.length === 0 && results.existing.length === 0) {
                return callback([EnterNewSelection])
            }
            return callback([
                ...results.existing.map(e => ({
                    id: e.initial?.id!,
                    description: e.name,
                    source: SelectionSources.Peachjar,
                    ...e,
                }) as EntitySelection),
                ...results.nces.map(e => ({
                    id: e.initial?.id!,
                    description: e.name,
                    source: SelectionSources.NCES,
                    ...e,
                }))
            ])
        } catch (error) {
            setError(error)
        }
    }

    return (
        <>
            <SwitchError error={error} />
            <AutocompleteInput<EntitySelection>
                label='Name'
                lookup={lookup}
                renderOption={(e) => {
                    const isEnterNew = e.source === SelectionSources.New
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <Paragraph >{e.description}</Paragraph>
                            </Grid>
                            {!isEnterNew && <Grid item xs={12}>
                                <Note >{e.city || 'N/A'}, {e.state || 'N/A'}</Note>
                            </Grid>}
                        </Grid>
                    )
                }}
                isEqual={(t1, t2) => (t1?.id === t2?.id) || false}
                groupBy={e => e.source === SelectionSources.New ? '' : e.source}
                {...binding}
                value={binding.value || null}
            />
        </>
    )
}

export default FindEntityByNamePicker

import React, { FunctionComponent } from 'react'
import SelectInput from '@peachjar/ui/dist/lib/components/Inputs/SelectInput'

import { GradeLevels } from '../../api/GradeLevels'
import { InputProps } from '@peachjar/ui/dist/lib/components/Inputs/InputProps'

type Props = InputProps<string | null>

const gradeLevels = Object.assign({}, GradeLevels)
delete (gradeLevels as any).NotGraded
delete (gradeLevels as any).EarlyChildhoodEducation

const GradeSelect: FunctionComponent<Props> = ({
    disabled = false,
    value,
    error,
    onChange,
}) => {
    return (
        <SelectInput<string>
            label={'Grade'}
            fullWidth={true}
            disabled={disabled}
            error={error}
            value={value || ''}
            onChange={(v) => onChange(v)}
            options={Object.values(gradeLevels)}
            picker={v => [v, v]}
        />
    )
}

export default GradeSelect

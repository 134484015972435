import { AxiosInstance } from 'axios'
import { LoggerApi } from '@peachjar/kernel-sanders'

import uploadSisCSVToCDN from '../../api/commands/uploadSisCSVToCDN'
import { UIError } from '@peachjar/ui/dist/api/CodedError'

type Deps = {
    portalBffClient: AxiosInstance,
    logger: LoggerApi,
}

export default function factory({ portalBffClient, logger }: Deps): uploadSisCSVToCDN {
    return async ({ cdnUrl, file, onUploadProgress: setProgress }) => {
        try {
            await portalBffClient.put(cdnUrl, file, {
                onUploadProgress(progress: ProgressEvent) {
                    setProgress(Math.round((progress.loaded * 100) / progress.total))
                }
            })
        } catch (error) {
            logger.capture(error)
            throw new UIError('response.internal', error.message, error)
        }
    }
}


import React, { FunctionComponent, useEffect, useState } from 'react'
import QueryStringNotification from '@peachjar/ui/dist/lib/components/Notifications/QueryStringNotification'
import CommunitySettingsForm from './components/CommunitySettingsForm'
import Grid from '@material-ui/core/Grid'
import { getCommunitySettings, saveCommunitySettings, clearSaveReq } from '../../redux/communitySettings'
import { saveSettingsReq, settingsReq } from '../../redux/communitySettingsSelectors'
import { useDispatch } from 'react-redux'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { SwitchError } from '@peachjar/ui/dist/lib/components/Errors'
import { LoadingSpinner } from '@peachjar/ui/dist/lib/components/Spinners'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'

import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import SuccessNotification from '@peachjar/ui/dist/lib/components/Notifications/SuccessNotification'

type Props = {
    hierarchyType: string,
    hierarchyId: number,
    hierarchyName: string,
}

const CommunityPageBody: FunctionComponent<Props> = ({
    hierarchyType,
    hierarchyId,
    hierarchyName,
}) => {

    const dispatch = useDispatch()
    const req = settingsReq()
    const saveReq = saveSettingsReq()
    const panic = usePanic()

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null)

    const { close: closeNotification } = useGlobalNotifications()

    useEffect(() => {
        dispatch(getCommunitySettings({ hierarchyId, hierarchyType }))
    }, [])

    useEffect(() => {
        if (saveReq.status === Statuses.loading) {
            closeNotification()
        }
        if (saveReq.status === Statuses.loaded) {
            setLastUpdated(new Date())
            dispatch(clearSaveReq())
        }
    }, [saveReq])

    if (req.isLoading()) {
        return <LoadingSpinner />
    }

    if (req.error) {
        return panic(req.error)
    }

    const settings = req.model!

    return (
        <Sections>
            <Section>
                <QueryStringNotification />
                <SuccessNotification
                    id={`update_req_${lastUpdated}`}
                    isVisible={!!lastUpdated}
                    message='Community settings are updated.'
                />
                <SwitchError error={saveReq.error} />
                <Grid xs={12} md={6}>
                    <CommunitySettingsForm
                        isSubmitting={false}
                        onSubmit={updates => dispatch(saveCommunitySettings(updates))}
                        hierarchyName={hierarchyName}
                        settings={settings}
                        onCancel={()=>{
                            window.location.reload()
                        }}
                    />
                </Grid>
            </Section>
        </Sections>
    )
}

export default CommunityPageBody

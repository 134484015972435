
import ApolloClient from 'apollo-boost'

import bulkAddUser, { BulkAddUserResult } from '../../api/commands/bulkAddUser'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
  apolloClient: ApolloClient<any>,
}

type MutationResult = {
  job: BulkAddUserResult
}

const QUERY = gql`
    mutation bulkAddUser($input: CsvBulkAddStaffInput) {
        job: csvBulkAddStaff(input: $input) {
            hierarchyId
            hierarchyType
            staffAdded
            staffUpdated
            staffRemoved
            staffDupeCount
            error
        }
    }
`

export default function factory({ apolloClient }: Deps): bulkAddUser {
    return async (identifiers) => {

        try {

            const result = await apolloClient.mutate<MutationResult>({
                mutation: QUERY,
                variables: {
                    input: {
                      hierarchyId: identifiers.hierarchyId.toString(),
                      hierarchyType: identifiers.hierarchyType,
                      filename: identifiers.filename,
                    }
                },
            })

            return result.data!.job!

        } catch (error) {
            throw mapError(error)
        }
    }
}

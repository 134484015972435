import { v4 as uuid } from 'uuid'
import { AddUserParams } from '../api/commands/addUser'
import { users } from './data'

import UserAndSiteRoles from '../api/UserAndSiteRoles'

import addUser from '../api/commands/addUser'
import wait from './wait'

export default function factory(): addUser {
    return async (ur: AddUserParams): Promise<UserAndSiteRoles> => {
        await wait(1000)
        const newUser = Object.assign({
            userId: uuid(),
            firstName: '',
            lastName: '',
        }, ur)
        users.push(newUser)
        return newUser
    }
}

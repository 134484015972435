import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react'
import { JobStatus } from '../../../api/SisJob'
import Tag, { Variants } from '@peachjar/ui/dist/lib/components/Tag'

type Props = {
  status: JobStatus
}

function getVariantJobStatus(  status: JobStatus ) {
  switch (status) {
    case JobStatus.Cancelled:
      return Variants.Muted
    case JobStatus.Failed:
      return Variants.Rose
    case JobStatus.Paused:
      return Variants.Blonde
    case JobStatus.Pending:
      return Variants.Prussian
    case JobStatus.Processing:
      return Variants.Prussian
    case JobStatus.Success:
      return Variants.Mint
  }
}

function getTextJobStatus(status: JobStatus) {
  switch (status) {
    case JobStatus.Cancelled:
      return 'Canceled'
    case JobStatus.Failed:
      return 'Failed'
    case JobStatus.Paused:
      return 'Paused'
    case JobStatus.Pending:
      return 'Pending'
    case JobStatus.Processing:
      return 'Processing'
    case JobStatus.Success:
      return 'Success'
  }
}
const JobStatusTag: FunctionComponent<Props> = (
  {
    status
  }
) => {
  return (
    <Tag variant={getVariantJobStatus(status)}>
      {getTextJobStatus(status)}
    </Tag>
  )
}

export default JobStatusTag


const CONSTANTS = Object.freeze({
  APP_NAME: 'mapp-school-management', // todo: update with your app name
  APP_ROOT_ID: 'root',
  APP_SUB_APP_ROOT_ID: 'root-sub-apps',
})

export const APP_ROUTES = Object.freeze({
  appBasename: '/mgmt', // todo: path under which your app will render
  index: '/',
})

export const DEFAULT_APPROVAL_GUIDELINES_URL = 'https://cdn.peachjar.com/assets/Flyer%20Approval%20Guidelines.pdf'

export default CONSTANTS

import React, { FunctionComponent, useState } from 'react'
import { css } from 'emotion'
import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import PreviewBox from './PreviewBox'
import AnchorTextButton from '../../../components/Buttons/AnchorTextButton'
import CloseIcon from '@material-ui/icons/Close'
import CopyToClipboard from 'react-copy-to-clipboard'
import CheckIcon from '@material-ui/icons/Check'

import { DEFAULT_APPROVAL_GUIDELINES_URL } from '../../../config/constants'
import { ButtonFlatLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

import colors from '@peachjar/ui/dist/lib/styles/colors'

type Props = {
    hierarchyName: string,
    isOpen: boolean,
    cancel: () => void,
}

const StandardApprovalGuidelinesModal: FunctionComponent<Props> = ({
    hierarchyName,
    isOpen,
    cancel,
}) => {

    const [isCopied, setIsCopied] = useState(false)

    return (
        <Modal title='' open={isOpen} close={() => cancel()} className={styles.modal}>
            <ModalHeader
                icon={() => <CloseIcon fontSize='large' onClick={() => cancel()} />}
            >
                Peachjar's Flyer Approval Guidelines
            </ModalHeader>
            <DialogContent style={{ padding: '16px' }}>
                <DialogContentText style={{ color: colors.prussian }}>
                    <ul>
                        <li>
                            Support the basic educational mission of the district,
                            directly benefit the students, or be of intrinsic value
                            to the students or their parents/guardians.
                        </li>
                        <li>
                            Should not imply {hierarchyName}'s endorsement of any
                            identified product and/or services.
                        </li>
                        <li>
                            Should not be lewd, obscene, libelous, or slanderous.
                        </li>
                        <li>
                            Should not incite students to commit unlawful acts,
                            violate school rules, or disrupt the orderly operation
                            of the schools.
                        </li>
                        <li>
                            Should not promote any particular political interest,
                            candidate, party, or ballot measure, unless the
                            candidates or advocates from all sides are provided
                            the opportunity to present their views to the students
                            during school hours or during scheduled events.
                        </li>
                        <li>
                            Should not discriminate against, attack, or denigrate any group
                            on account of gender, race, age, color, religion,
                            ancestry, national origin, disability, or other
                            unlawful consideration.
                        </li>
                        <li>
                            Should not promote the use or sale of materials or
                            services that are illegal or inconsistent with school
                            objectives, including, but not limited to, materials or
                            advertisements for tobacco, intoxicants, and movies
                            or products unsuitable for children.
                        </li>
                    </ul>
                </DialogContentText>
                <Grid container>
                    <PreviewBox showEdit={false} onEditClick={() => false}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>Public PDF URL</Grid>
                            <Grid item xs={12}>
                                <AnchorTextButton
                                    href={DEFAULT_APPROVAL_GUIDELINES_URL}
                                    target='_blank'
                                >
                                    {DEFAULT_APPROVAL_GUIDELINES_URL}
                                </AnchorTextButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify='flex-end'>
                                    <CopyToClipboard
                                        text={DEFAULT_APPROVAL_GUIDELINES_URL}
                                        onCopy={() => setIsCopied(true)}
                                    >
                                        <ButtonFlatLarge
                                            startIcon={isCopied ? <CheckIcon /> : undefined}
                                        >
                                            Copy PDF URL
                                        </ButtonFlatLarge>
                                    </CopyToClipboard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PreviewBox>
                </Grid>
            </DialogContent>
        </Modal>
    )
}

const styles = {
    modal: css`
     & > .MuiDialog-container {
        & .MuiPaper-root {
            max-width: 720px !important;
        }
     }
    `,
}

export default StandardApprovalGuidelinesModal

import { combineReducers } from 'redux'

import districtsSlice from './districts'
import schoolsSlice from './schools'
import usersSlice from './usersAndRoles'
import parentSettingsSlice from './parentSettings'
import communitySlice from './communitySettings'
import approvalsSlice from './approvalSettings'

export const ReducerMap = {
  districts: districtsSlice.reducer,
  schools: schoolsSlice.reducer,
  usersAndRoles: usersSlice.reducer,
  parentSettings: parentSettingsSlice.reducer,
  community: communitySlice.reducer,
  approvals: approvalsSlice.reducer,
}

// This is done only to auto gen the RootState.
// The actual root reducer will be created by the framework.
const reducer = combineReducers(ReducerMap)

// TypeScript for the win; Java would die crying like a baby
// if I made a type inference like this!
export type RootState = ReturnType<typeof reducer>

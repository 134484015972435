import { Tab } from '@peachjar/ui/dist/lib/components/TabBar/TabBar'
import Routes from '../../../routes'
import config from '../../../config'

const { REACT_APP_BFF_URI } = config

const makeTabs = (districtId: number): Tab[] => [
    {
        label: 'District & Schools',
        href: Routes.districts.update.from(districtId),
    },
    {
        label: 'Users & Roles',
        href: Routes.districts.users.from(districtId),
    },
    {
        label: 'Distribution',
        onClick: () => window.location.href = `${REACT_APP_BFF_URI}/settings/distribution?districtId=${districtId}`
    },
    {
        label: 'Approvals',
        onClick: () => window.location.href = `${REACT_APP_BFF_URI}/settings/approvals?id=${districtId}&type=district`
    },
    {
        label: 'Community',
        href: Routes.districts.community.from(districtId),
    },
    {
        label: 'Parents',
        href: Routes.districts.parents.from(districtId),
    },
]

export default function getTabBar(districtId: number, activeTab: number): Tab[] {
    const tabs = makeTabs(districtId)
    if (activeTab < tabs.length) {
        tabs[activeTab].isActive = true
    }
    return tabs
}

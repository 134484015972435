import { District } from '../api/District'
import { School, TransitionalGroupTypes } from '../api/School'
import { GradeLevels } from '../api/GradeLevels'
import { RoleNames } from '../api/Role'
import { EntityTypes, forEntity } from '../api/Entity'
import { CommunitySettings } from '../api/CommunitySettings'
import { JobStatus, SisJob } from '../api/SisJob'

import UserAndSiteRoles from '../api/UserAndSiteRoles'
import User, { LoginTypes } from '@peachjar/ui/dist/api/User'
import { StaffJob } from '../api/StaffJob';

const district: District = {
    id: '50054a72-7ed3-11ea-8b5e-df4913899c36',
    districtId: 123,
    name: 'Peachjar Public School District',
    active: true,
    invisible: false,
    live: false,
    treeCounter: true,
    generalDisclaimer: '',
    sisId: '',
    ncesId: 'X123456',
    launchedAt: null,
    launchedBy: null,
    address: {
        googlePlaceId: '234234',
        address: '15373 Innovation Dr UNIT 300, San Diego, CA 92128',
    },
    startAt: new Date('2018-10-15T09:00:00.000Z'),
    createdAt: new Date('2018-09-29T05:00:19.123Z'),
    createdBy: '2f5b976c-7ed4-11ea-87fe-dba7ac700696',
    updatedAt: new Date('2020-01-05T12:46:16.799Z'),
    updatedBy: '34c1576e-7ed4-11ea-af12-37e68374ffce',
}

export const schools: School[] = [
    {
        id: 'bb70dbe2-835a-11ea-bafb-17f335fe6249',
        schoolId: 234,
        name: 'Peachjar Elementary',
        active: true,
        live: false,
        sisId: 'PJS-001',
        ncesId: 'X12345678901',
        address: {
            googlePlaceId: 'foobar',
            address: '12554 Peachy Ave, San Diego, CA 92128',
        },
        enrollmentCount: 200,
        lowGrade: GradeLevels.Grade7,
        highGrade: GradeLevels.Grade9,
        isIndependent: true,
        treeCounter: true,
        startAt: new Date(),
        generalDisclaimer: '',
        invisible: true,
        transitionalGroupType: TransitionalGroupTypes.School,
        launchedAt: null,
        launchedBy: null,
        createdAt: new Date('2018-09-29T05:00:19.123Z'),
        createdBy: 'aca680e4-835a-11ea-b4ed-ebc5bb833b4b',
        updatedAt: new Date('2020-01-05T12:46:16.799Z'),
        updatedBy: 'a7dd6d20-835a-11ea-a4a9-6f0034194d64',
        district,
    },
    {
        id: 'e1e06586-835a-11ea-8d14-0b1c510e7c68',
        schoolId: 235,
        name: 'Peachjar Middle School',
        active: true,
        live: false,
        sisId: 'PJS-002',
        ncesId: 'X12345678902',
        address: {
            googlePlaceId: '23234',
            address: '15373 Innovation Dr UNIT 301, San Diego, CA 92128',
        },
        enrollmentCount: 563,
        lowGrade: GradeLevels.Prekindergarten,
        highGrade: GradeLevels.Grade6,
        isIndependent: true,
        treeCounter: true,
        startAt: new Date(),
        generalDisclaimer: '',
        invisible: false,
        transitionalGroupType: TransitionalGroupTypes.School,
        launchedAt: null,
        launchedBy: null,
        createdAt: new Date('2018-09-29T05:00:19.123Z'),
        createdBy: 'aca680e4-835a-11ea-b4ed-ebc5bb833b4b',
        updatedAt: new Date('2020-01-05T12:46:16.799Z'),
        updatedBy: 'a7dd6d20-835a-11ea-a4a9-6f0034194d64',
        district,
    },
    {
        id: 'ea8c7b02-835a-11ea-9e9e-0b6fb36fc1e2',
        schoolId: 236,
        name: 'Peachjar High School',
        active: true,
        live: false,
        sisId: 'PJS-003',
        ncesId: 'X12345678903',
        address: {
            googlePlaceId: '234234',
            address: '15373 Innovation Dr UNIT 301, San Diego, CA 92128',
        },
        enrollmentCount: 1203,
        lowGrade: GradeLevels.Grade10,
        highGrade: GradeLevels.Grade12,
        isIndependent: true,
        treeCounter: true,
        startAt: new Date(),
        generalDisclaimer: '',
        transitionalGroupType: TransitionalGroupTypes.School,
        launchedAt: null,
        launchedBy: null,
        invisible: false,
        createdAt: new Date('2018-09-29T05:00:19.123Z'),
        createdBy: 'aca680e4-835a-11ea-b4ed-ebc5bb833b4b',
        updatedAt: new Date('2020-01-05T12:46:16.799Z'),
        updatedBy: 'a7dd6d20-835a-11ea-a4a9-6f0034194d64',
        district,
    },
    {
        id: 'd0cd8a5e-83ef-11ea-ac40-c779d7ea764b',
        schoolId: 999,
        name: 'Independence High School',
        active: true,
        live: false,
        sisId: '',
        ncesId: 'XXII01234567',
        address: {
            googlePlaceId: '254654',
            address: '999 Jefferson Way, Independence, CA 12312',
        },
        enrollmentCount: 123,
        lowGrade: GradeLevels.Grade9,
        highGrade: GradeLevels.Grade12,
        isIndependent: true,
        treeCounter: true,
        startAt: new Date(),
        generalDisclaimer: '',
        invisible: true,
        transitionalGroupType: TransitionalGroupTypes.School,
        launchedAt: null,
        launchedBy: null,
        createdAt: new Date('2018-09-29T05:00:19.123Z'),
        createdBy: 'aca680e4-835a-11ea-b4ed-ebc5bb833b4b',
        updatedAt: new Date('2020-01-05T12:46:16.799Z'),
        updatedBy: 'a7dd6d20-835a-11ea-a4a9-6f0034194d64',
    },
]

export const districts: District[] = [
    Object.assign(district, { schools: schools.slice(0, 3) }),
]

export const users: UserAndSiteRoles[] = [
    {
        userId: '54b2b0a2-8e94-11ea-aeae-57ee50cba270',
        site: {
            type: 'district',
            id: district.districtId,
            name: district.name,
        },
        firstName: 'Richard',
        lastName: 'Clayton',
        email: 'richard@peachjar.usd.ca.gov',
        isVolunteer: false,
        isApprover: true,
        isUploader: true,
        isAdmin: true,
    },
    {
        userId: 'ab8735e2-8e94-11ea-a8cc-27ed1c4db5af',
        site: {
            type: 'district',
            id: district.districtId,
            name: district.name,
        },
        firstName: 'Ping',
        lastName: 'Au-Yeung',
        email: 'ping@peachjar.usd.ca.gov',
        isVolunteer: false,
        isApprover: true,
        isUploader: true,
        isAdmin: false,
    },
    {
        userId: 'cc63ccbc-8e94-11ea-be31-f3e7b9171c49',
        site: {
            type: 'district',
            id: district.districtId,
            name: district.name,
        },
        firstName: 'Jared',
        lastName: 'Libby',
        email: 'jared@peachjar.usd.ca.gov',
        isVolunteer: true,
        isApprover: false,
        isUploader: true,
        isAdmin: false,
    },
    {
        userId: 'eb4d1714-8e94-11ea-be5d-6306fb9ed62f',
        site: {
            type: 'district',
            id: district.districtId,
            name: district.name,
        },
        firstName: 'Wes',
        lastName: 'van Rensburg',
        email: 'wes@peachjar.usd.ca.gov',
        isVolunteer: true,
        isApprover: false,
        isUploader: false,
        isAdmin: false,
    },
    {
        userId: 'defd1714-8e94-11ea-be5d-6306fb9edabc',
        site: {
            type: 'school',
            id: schools[0].schoolId,
            name: schools[0].name,
        },
        firstName: 'Laura',
        lastName: 'Wyatt',
        email: 'laura@peachjar.usd.ca.gov',
        isVolunteer: false,
        isApprover: false,
        isUploader: false,
        isAdmin: true,
    },
    {
        userId: '75a2525a-17f7-47e2-a9ba-41ec63365b8d',
        site: {
            type: 'school',
            id: schools[3].schoolId,
            name: schools[3].name,
        },
        firstName: 'Levi',
        lastName: 'Morales',
        email: 'levi@indy.edu.ca.gov',
        isVolunteer: false,
        isApprover: false,
        isUploader: false,
        isAdmin: true,
    },
    {
        userId: '9cf3a75e-3dd6-4c1f-8ce7-075af65467f1',
        site: {
            type: 'school',
            id: schools[3].schoolId,
            name: schools[3].name,
        },
        firstName: 'Jesse',
        lastName: 'Herman',
        email: 'jessie@gmail.com',
        isVolunteer: true,
        isApprover: false,
        isUploader: true,
        isAdmin: false,
    },
]

export const userMap: { [role: string]: User } = {
    districtAdmin: {
        id: 'districtAdmin',
        firstName: 'Chidi',
        lastName: 'Anagonye',
        email: 'canagonye@springfield.usd.ca.gov',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Admin],
            forEntity: forEntity(EntityTypes.District, 123),
        }, {
            roles: [RoleNames.Admin],
            forEntity: forEntity(EntityTypes.School, 234),
        }, {
            roles: [RoleNames.Admin],
            forEntity: forEntity(EntityTypes.School, 235),
        }, {
            roles: [RoleNames.Admin],
            forEntity: forEntity(EntityTypes.School, 236),
        }]
    },
    districtUploader: {
        id: 'districtUploader',
        firstName: 'Tahani',
        lastName: 'Al-Jamil',
        email: 'taljamil@springfield.usd.ca.gov',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Uploader],
            forEntity: forEntity(EntityTypes.District, 123),
        }]
    },
    districtApprover: {
        id: 'districtApprover',
        firstName: 'Elanor',
        lastName: 'Shellstrop',
        email: 'eshellstrop@springfield.usd.ca.gov',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Approver],
            forEntity: forEntity(EntityTypes.District, 123),
        }]
    },
    multiSchoolAdmin: {
        id: 'multiSchoolAdmin',
        firstName: 'Foo',
        lastName: 'Manchu',
        email: 'foo@casita.usd.ca.gov',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Admin],
            forEntity: forEntity(EntityTypes.School, 235),
        }, {
            roles: [RoleNames.Admin],
            forEntity: forEntity(EntityTypes.School, 236),
        }]
    },
    schoolAdmin: {
        id: 'schoolAdmin',
        firstName: 'Jason',
        lastName: 'Mendoza',
        email: 'canagonye@casita.usd.ca.gov',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Admin],
            forEntity: forEntity(EntityTypes.School, 234),
        }]
    },
    schoolUploader: {
        id: 'schoolUploader',
        firstName: 'Janet',
        lastName: 'Della-Denunzio',
        email: 'jdelladenunzio@casita.usd.ca.gov',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Uploader],
            forEntity: forEntity(EntityTypes.School, 234),
        }]
    },
    schoolApprover: {
        id: 'schoolApprover',
        firstName: 'Michael',
        lastName: 'TheDemon',
        email: 'michael@casita.usd.ca.gov',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Approver],
            forEntity: forEntity(EntityTypes.School, 234),
        }]
    },
    diffDistrict: {
        id: 'asdfasdfasdf',
        firstName: 'Shawn',
        lastName: 'TheDemon',
        email: 'shawn@foo.bar',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Approver],
            forEntity: forEntity(EntityTypes.District, 657),
        }],
    },
    indySchoolAdmin: {
        id: 'indySchoolAdmin',
        firstName: 'Vicky',
        lastName: 'TheDemon',
        email: 'vicky@indy.ca.gov',
        loginType: LoginTypes.Platform,
        roles: [{
            roles: [RoleNames.Admin],
            forEntity: forEntity(EntityTypes.School, 999),
        }],
    },
    internalUser: {
        id: 'internalUser',
        firstName: 'Judge',
        lastName: 'Gen',
        email: 'thejudge@heaven.org',
        loginType: LoginTypes.Internal,
        roles: [],
    },
}

export const authUsers: User[] = Object.values(userMap)

export const nces = {
    districts: [
        {
            id: '0400021',
            name: 'Colorado City Unified District',
            city: 'Colorado City',
            state: 'AZ',
            address: '325 North Cottonwood St, Colorado City, AZ 86021'
        },
        {
            id: '0400074',
            name: 'Kingman Academy Of Learning',
            city: 'Kingman',
            state: 'AZ',
            address: '3410 N. Burbank St., Kingman, AZ 86409'
        },
        {
            id: '0400075',
            name: 'Young Scholars Academy Charter S',
            city: 'Bullhead City',
            state: 'AZ',
            address: '1501 East Valencia Rd., Bullhead City, AZ 86426'
        },
        {
            id: '0400082',
            name: 'Colorado River Union High School',
            city: 'Fort Mohave',
            state: 'AZ',
            address: '5744 S. Hwy 95, Fort Mohave, AZ 86426'
        },
        {
            id: '0400248',
            name: 'Telesis Center for Learning  Inc',
            city: 'Lake Havasu City',
            state: 'AZ',
            address: '2598 Starlite Lane, Lake Havasu City, AZ 86403'
        },
        {
            id: '0400280',
            name: 'Mohave Accelerated Learning Cent',
            city: 'Bullhead City',
            state: 'AZ',
            address: '625 Marina Blvd, Bullhead City, AZ 86442'
        },
        {
            id: '0400281',
            name: 'Masada Charter School  Inc.',
            city: 'Colorado City',
            state: 'AZ',
            address: '365 West Cannon Avenue, Colorado City, AZ 86021'
        },
        {
            id: '0400295',
            name: 'Kingman Unified School District',
            city: 'Kingman',
            state: 'AZ',
            address: '3033 MacDonald Ave., Kingman, AZ 86401'
        },
        {
            id: '0400413',
            name: 'Mohave Accelerated Elementary Sc',
            city: 'Bullhead City',
            state: 'AZ',
            address: '625 Marina Blvd., Bullhead City, AZ 86442'
        },
        {
            id: '0400414',
            name: 'Academy of Building Industries',
            city: 'Fort Mohave',
            state: 'AZ',
            address: '1547 E. Lipan Boulevard, Fort Mohave, AZ 86426'
        },
        {
            id: '0400461',
            name: 'Mohave County Juvenile Detention',
            city: 'Kingman',
            state: 'AZ',
            address: '809 E Beale St, Kingman, AZ 86401'
        },
        {
            id: '0400794',
            name: 'Western Arizona Vocational Distr',
            city: 'Kingman',
            state: 'AZ',
            address: '700 W. Beale Street, Kingman, AZ 86401'
        },
        {
            id: '0400852',
            name: 'Kaizen Education Foundation dba',
            city: 'Lake Havasu City',
            state: 'AZ',
            address: '3155 Maricopa Avenue, Lake Havasu City, AZ 86406'
        },
        {
            id: '0400922',
            name: 'Desert Star Academy',
            city: 'Fort Mohave',
            state: 'AZ',
            address: '5599 Highway 95, Fort Mohave, AZ 86426'
        },
        {
            id: '0401500',
            name: 'Bullhead City School District',
            city: 'Bullhead City',
            state: 'AZ',
            address: '1004 Hancock Rd, Bullhead City, AZ 86442'
        },
        {
            id: '0403660',
            name: 'Hackberry School District',
            city: 'Kingman',
            state: 'AZ',
            address: '9501 Nellie Dr, Kingman, AZ 86401'
        },
        {
            id: '0404280',
            name: 'Lake Havasu Unified District',
            city: 'Lake Havasu City',
            state: 'AZ',
            address: '2200 Havasupai Blvd, Lake Havasu City, AZ 86403'
        },
        {
            id: '0404410',
            name: 'Littlefield Unified District',
            city: 'Beaver Dam',
            state: 'AZ',
            address: '3475 Rio Virgin Rd, Beaver Dam, AZ 86432'
        },
        {
            id: '0405190',
            name: 'Mohave Valley Elementary Distric',
            city: 'Mohave Valley',
            state: 'AZ',
            address: '8450 South Olive, Mohave Valley, AZ 86440'
        }
    ],
    schools: [
        {
            id: '040497000229',
            name: 'Kerr Elementary School',
            city: 'Mesa',
            state: 'AZ',
            address: '125 E McLellan, Mesa, AZ 85201',
            enrollment: 676,
            lowGrade: 'KG',
            highGrade: '6',
        },
        {
            id: '040423000677',
            name: 'Kyrene de las Manitas School',
            city: 'Tempe',
            state: 'AZ',
            address: '1201 W Courtney Lane, Tempe, AZ 85284',
            enrollment: 549,
            lowGrade: 'PK',
            highGrade: '5',
        },
        {
            id: '040497000399',
            name: 'Lindbergh Elementary School',
            city: 'Mesa',
            state: 'AZ',
            address: '930 S Lazona, Mesa, AZ 85204',
            enrollment: 469,
            lowGrade: 'PK',
            highGrade: '6',
        },
        {
            id: '040082002672',
            name: 'Northpoint Expeditionary Learnin',
            city: 'Prescott',
            state: 'AZ',
            address: '551 First Street, Prescott, AZ 86301',
            enrollment: 169,
            lowGrade: '9',
            highGrade: '12',
        },
        {
            id: '040406000324',
            name: 'Monument Valley High School',
            city: 'Kayenta',
            state: 'AZ',
            address: 'Highway 163, Kayenta, AZ 86033',
            enrollment: 693,
            lowGrade: '9',
            highGrade: '12',
        },
        {
            id: '040960003430',
            name: 'Yuma District One Digital Learni',
            city: 'Yuma',
            state: 'AZ',
            address: '450 W. 6th St., Yuma, AZ 85364',
            enrollment: 3,
            lowGrade: 'KG',
            highGrade: '8',
        },
        {
            id: '040423000656',
            name: 'Kyrene de las Brisas School',
            city: 'Chandler',
            state: 'AZ',
            address: '777 N. Desert Breeze Blvd. E., Chandler, AZ 85226',
            enrollment: 768,
            lowGrade: 'PK',
            highGrade: '5',
        },
        {
            id: '040423000660',
            name: 'Kyrene de los Cerritos School',
            city: 'Phoenix',
            state: 'AZ',
            address: '14620 S. Desert Foothills Pkwy, Phoenix, AZ 85048',
            enrollment: 521,
            lowGrade: 'PK',
            highGrade: '5',
        },
        {
            id: '040039502305',
            name: 'Leading Edge Academy Gilbert Ele',
            city: 'Gilbert',
            state: 'AZ',
            address: '717 W. Ray Road, Gilbert, AZ 85234',
            enrollment: 213,
            lowGrade: 'KG',
            highGrade: '6',
        },
        {
            id: '040497000394',
            name: 'Salk Elementary School',
            city: 'Mesa',
            state: 'AZ',
            address: '7029 E Brown Road, Mesa, AZ 85207',
            enrollment: 647,
            lowGrade: 'KG',
            highGrade: '6',
        },
        {
            id: '040240001087',
            name: 'Rancho Viejo Elementary School',
            city: 'Yuma',
            state: 'AZ',
            address: '1020 Avenue C, Yuma, AZ 85364',
            enrollment: 366,
            lowGrade: 'KG',
            highGrade: '6',
        },
        {
            id: '040621002274',
            name: 'Rio Vista Elementary',
            city: 'Avondale',
            state: 'AZ',
            address: '10237 W Encanto Blvd, Avondale, AZ 85323',
            enrollment: 764,
            lowGrade: 'KG',
            highGrade: '8',
        },
        {
            id: '040831000778',
            name: 'Laird Elementary School',
            city: 'Tempe',
            state: 'AZ',
            address: '1500 N Scovel St, Tempe, AZ 85281',
            enrollment: 425,
            lowGrade: 'KG',
            highGrade: '8',
        },
        {
            id: '040396000315',
            name: 'Isaac Middle School',
            city: 'Phoenix',
            state: 'AZ',
            address: '3402 W. McDowell Rd., Phoenix, AZ 85009',
            enrollment: 861,
            lowGrade: '6',
            highGrade: '8',
        },
        {
            id: '040423001877',
            name: 'Kyrene de la Estrella Elementary',
            city: 'Phoenix',
            state: 'AZ',
            address: '2620 E. Liberty Lane, Phoenix, AZ 85048',
            enrollment: 429,
            lowGrade: 'PK',
            highGrade: '5',
        },
        {
            id: '040373000295',
            name: 'Leonor Hambly K-8',
            city: 'Winkelman',
            state: 'AZ',
            address: '824 Thorne Ave., Winkelman, AZ 85292',
            enrollment: 190,
            lowGrade: 'PK',
            highGrade: '8',
        },
        {
            id: '040963000997',
            name: 'Yuma High School',
            city: 'Yuma',
            state: 'AZ',
            address: '400 6th Ave, Yuma, AZ 85364',
            enrollment: 1146,
            lowGrade: '9',
            highGrade: '12',
        },
        {
            id: '040032102155',
            name: 'Pinnacle Charter High School',
            city: 'Tempe',
            state: 'AZ',
            address: '1712 E. Guadalupe Rd.  Suite 1, Tempe, AZ 85283',
            enrollment: 43,
            lowGrade: '9',
            highGrade: '12',
        }
    ],
}

export const communitySettings: CommunitySettings[] = [
    {
        hierarchyId: 123,
        hierarchyType: 'district',
        flyerDisclaimer: '',
        guidelinesUrl: '',
        allowOrgsToDistributeToStaff: false,
    },
    {
        hierarchyId: 999,
        hierarchyType: 'school',
        flyerDisclaimer: '',
        guidelinesUrl: '',
        allowOrgsToDistributeToStaff: false,
    }
]

export const sisJobs: SisJob[] = [
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: false,
        isMapper: false,
        forceContinue: false,
        jobUuid: '3b3f0a8e-1cc5-11eb-8748-13cb233a0703',
        status: JobStatus.Paused,
        createdAt: new Date('2020-08-27T21:00:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: 0,
                invalidSchools: [],
            },
            validRecordsDetails: {
                new: 0,
                removed: 0,
                total: 0,
                old: 0,
                percentageChange: 0,
                duplicates: 0,
                selfManaged: 0,
                invalids: 0
            },
        },
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: false,
        isMapper: false,
        forceContinue: false,
        jobUuid: '59565057-05b4-43b7-92a6-b125b21f680c',
        status: JobStatus.Failed,
        createdAt: new Date('2020-08-27T21:00:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: 0,
                invalidSchools: [],
            },
            validRecordsDetails: {
                new: 0,
                removed: 0,
                total: 2530,
                old: 0,
                percentageChange: 0,
                duplicates: 0,
                selfManaged: 0,
                invalids: 0
            },
        },
        error: {
            filename: 'district_123-error.txt',
            message: 'line 1 has invalid email lin@kk@.com',
        },
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: false,
        isMapper: false,
        forceContinue: false,
        jobUuid: '402e6cc4-1cc5-11eb-8f23-0fde5863258e',
        status: JobStatus.Failed,
        createdAt: new Date('2020-08-05T06:00:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: 0,
                invalidSchools: [],
            },
            validRecordsDetails: {
                new: 25,
                removed: 0,
                total: 2530,
                old: 0,
                percentageChange: 0,
                duplicates: 0,
                selfManaged: 0,
                invalids: 0
            },
        }
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: true,
        isMapper: false,
        forceContinue: false,
        jobUuid: '48c3daf4-1cc5-11eb-8884-873f29472dc9',
        status: JobStatus.Success,
        createdAt: new Date('2020-07-18T20:49:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: 0,
                invalidSchools: [],
            },
            validRecordsDetails: {
                new: 5,
                removed: 0,
                total: 2505,
                old: 0,
                percentageChange: 0,
                duplicates: 0,
                selfManaged: 0,
                invalids: 0
            },
        }
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: false,
        isMapper: false,
        forceContinue: false,
        jobUuid: '4ff2f576-1cc5-11eb-b54e-53196c2d8ed4',
        status: JobStatus.Cancelled,
        createdAt: new Date('2020-07-01T17:00:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: 0,
                invalidSchools: [],
            },
            validRecordsDetails: {
                new: 0,
                removed: 1500,
                total: 1000,
                old: 0,
                percentageChange: 0,
                duplicates: 0,
                selfManaged: 0,
                invalids: 0
            },
        }
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: true,
        isMapper: false,
        forceContinue: false,
        jobUuid: '57cdc47e-1cc5-11eb-a8f2-bb52b2c6a3b0',
        status: JobStatus.Success,
        createdAt: new Date('2020-06-28T15:38:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: 0,
                invalidSchools: [],
            },
            validRecordsDetails: {
                new: 2500,
                removed: 200,
                total: 2500,
                old: 0,
                percentageChange: 0.12,
                duplicates: 0,
                selfManaged: 0,
                invalids: 0
            },
        }
    },
]

export const staffJobs: StaffJob[] = [
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: true,
        jobUuid: '3b3f0a8e-1cc5-11eb-8748-13cb233a0703',
        status: JobStatus.Paused,
        createdAt: new Date('2022-08-09T21:00:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: [],
                invalidSchools: [],
            },
            validRecordsDetails: {
                staffAdded: 5,
                staffRemoved: 2,
                staffDupeCount: 0,
                staffUpdated: 2,
                existing: 2,
                total: 10,
                percentageChange: 25
            },
        },
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: true,
        jobUuid: '59565057-05b4-43b7-92a6-b125b21f680c',
        status: JobStatus.Failed,
        createdAt: new Date('2022-08-07T21:00:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: [],
                invalidSchools: [],
            },
            validRecordsDetails: {
                staffAdded: 0,
                staffRemoved: 0,
                staffDupeCount: 0,
                staffUpdated: 0,
                existing: 0,
                total: 0,
                percentageChange: 0
            },
        },
        error: {
            filename: 'district_123-error.txt',
            message: 'line 1 has invalid email lin@kk@.com',
        },
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: true,
        jobUuid: '402e6cc4-1cc5-11eb-8f23-0fde5863258e',
        status: JobStatus.Failed,
        createdAt: new Date('2020-08-05T06:00:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: [],
                invalidSchools: [],
            },
            validRecordsDetails: {
                staffAdded: 0,
                staffRemoved: 0,
                staffDupeCount: 0,
                staffUpdated: 0,
                existing: 0,
                total: 0,
                percentageChange: 0
            },
        }
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: true,
        jobUuid: '48c3daf4-1cc5-11eb-8884-873f29472dc9',
        status: JobStatus.Success,
        createdAt: new Date('2022-08-09T20:49:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: [],
                invalidSchools: [],
            },
            validRecordsDetails: {
                staffAdded: 200,
                staffRemoved: 5,
                staffDupeCount: 0,
                staffUpdated: 10,
                existing: 0,
                total: 215,
                percentageChange: 100
            },
        }
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: true,
        jobUuid: '4ff2f576-1cc5-11eb-b54e-53196c2d8ed4',
        status: JobStatus.Cancelled,
        createdAt: new Date('2020-07-01T17:00:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: [],
                invalidSchools: [],
            },
            validRecordsDetails: {
                staffAdded: 0,
                staffRemoved: 5,
                staffDupeCount: 0,
                staffUpdated: 10,
                existing: 0,
                total: 15,
                percentageChange: 25
            },
        }
    },
    {
        academic: {
            groupId: 123,
            groupType: 'district',
        },
        isManual: true,
        jobUuid: '57cdc47e-1cc5-11eb-a8f2-bb52b2c6a3b0',
        status: JobStatus.Success,
        createdAt: new Date('2020-06-28T15:38:00-0700'),
        details: {
            invalidRecords: {
                invalidEmails: [],
                invalidGrades: [],
                invalidSchools: [],
            },
            validRecordsDetails: {
                staffAdded: 800,
                staffRemoved: 10,
                staffDupeCount: 0,
                staffUpdated: 20,
                existing: 20,
                total: 830,
                percentageChange: 25
            },
        }
    },
]

import React, { FunctionComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'

import { useDispatch } from 'react-redux'
import { SisJob } from '../../../api/SisJob'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import { downloadErrorFile } from '../../../redux/parentSettings'
import { StaffJob } from '../../../api/StaffJob';

const { Dark: { Paragraph, Note }, DarkMuted: { Note: MutedNote } } = Typo

const ManualMessage = () => (
    <Paragraph >
        The automatic sync job failed to run due to one or more issues listed below.
        Check your file to fix the issues and reupload it through Manual Sync.
        The sync job will automatically run with the new file.
    </Paragraph>
)

const AutomaticMessage = () => (
    <Paragraph >
        The automatic sync job failed to run due to one or more issues listed below.
        Check your file to fix the issues and reupload it through SFTP.
        The sync job will automatically run with the new file.
    </Paragraph>
)

type Props = {
    sisJob: SisJob | StaffJob,
    isManual: boolean,
    isStaff?: boolean
}

const FailedAddendum: FunctionComponent<Props> = ({
    sisJob,
    isManual,
    isStaff
}) => {
    const dispatch = useDispatch()
    const errorMessages = sisJob.error ? sisJob.error.message.split(';') : []
    
    return (
        <>
            <Grid container>
                <Grid item xs={12}>{isStaff ? <ManualMessage /> :  isManual ? <ManualMessage /> : <AutomaticMessage />}</Grid>
                <Grid item xs={12} style={{ marginTop: '16px' }}>
                    <Note  style={{ color: colors.dragon }}>Error:</Note>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '8px' }}>
                <MutedNote >
                {errorMessages.map((m, i) => (<div key={`error_msg_${i}`}>{m}</div>))}
                </MutedNote>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '8px' }}>
                    <ButtonSecondarySmall onClick={() =>
                        dispatch(downloadErrorFile({
                            hierarchyId: sisJob.academic.groupId,
                            hierarchyType: sisJob.academic.groupType,
                            jobId: sisJob.jobUuid,
                            isStaff
                        }))
                    }>
                        Download Invalid Entries (CSV)
                    </ButtonSecondarySmall>
                </Grid>
            </Grid>
        </>
    )
}

export default FailedAddendum

import getStaffJobs from '../api/queries/getStaffJobs'
import { StaffJob } from '../api/StaffJob'
import { staffJobs } from './data'

export default function factory(): getStaffJobs {
  return async (hierarchyId: number, hierarchyType: string): Promise<StaffJob[]> => {
      return staffJobs.filter(s =>
          s.academic.groupId === hierarchyId &&
          s.academic.groupType === hierarchyType)
  }
}


import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import UserAndSiteRoles from '../../../api/UserAndSiteRoles'
import SortableTable from '@peachjar/ui/dist/lib/components/SortableTable/SortableTable2'
import TypographyRedux from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@peachjar/ui/dist/lib/components/Buttons/IconButton'
import EditIcon from '@material-ui/icons/Create'
import RemoveIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import { omit, get } from 'lodash'
import { ButtonFlatSmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'

import colors from '@peachjar/ui/dist/lib/styles/colors'
import { updateUserReq } from '../../../redux/usersAndRolesSelectors'
import { updateUser } from '../../../redux/usersAndRoles'
import { getSettings } from '../../../redux/approvalSettingsSelectors'
import validateApproverChanges from '../../District/utils/validateApproverChanges'
import { ApprovalErrors } from '../../../api/ApprovalSettings'
import { Errors } from './constants';
import { getAuthUsersReq } from '../../../redux/authUserSelectors'
import useApprovalSetttings, { useAppovalRefresh } from '../../../hooks/useApprovalSettings'
import { SCHOOL_STAFF_REGEX } from '../../../utils'

const { Dark: { Paragraph }, DarkMuted: { Note } } = TypographyRedux
const { leaf, slate } = colors

const EMPTY_VOLUNTEER_LIST = `Add a volunteer to get started. Volunteers include members such as PTA
member, room parent, and event helper.`
const EMPTY_STAFF_LIST = 'Add staff to get started'
const FILTERED_EMPTY_VOLUNTEER_LIST = 'No volunteers found based on your filter.'
const FILTERED_EMPTY_STAFF_LIST = 'No staff found based on your filter.'
const FILTERED_EMPTY_STAFF_RESET = 'Try a different filter setting or reset them to view all staff.'
const FILTERED_EMPTY_VOLUNTEER_RESET = 'Try a different filter setting or reset them to view all volunteers.'

enum Roles {
    isAdmin = 'isAdmin',
    isUploader = 'isUploader',
    isApprover = 'isApprover'
}

export type UsersListSchema = Omit<UserAndSiteRoles, 'site'> & {
    siteId: number,
    siteName: string,
    siteType: 'school' | 'district',
    id: string
}

function flatten(ur: UserAndSiteRoles): UsersListSchema {
    return Object.assign(
        omit(ur, ['site']),
        { 
            siteId: ur.site.id, 
            siteName: ur.site.name || '', 
            siteType: ur.site.type,
            id: btoa([ur.userId, ur.site.type, ur.site.id].join('_'))
        }
    )
}

function unflatten(uls: UsersListSchema): UserAndSiteRoles {
    return Object.assign(
        omit(uls, ['siteName', 'siteType', 'siteId', 'id']),
        { site: { id: uls.siteId, type: uls.siteType, name: uls.siteName } }
    )
}

export type Props = {
    isVolunteer: boolean,
    isFiltered: boolean,
    onEditClick: (user: UserAndSiteRoles) => void,
    onRemoveClick: (user: UserAndSiteRoles) => void,
    onClearFilterClick: () => void,
    userAndSiteRoles: UserAndSiteRoles[],
    removingDisabled?: boolean,
    removingUserId?: string | null,
    classes: any
}

const Checked: FunctionComponent<{ isChecked: boolean }> = ({ isChecked }) => {
    return isChecked ? <CheckIcon style={{ color: colors.slate }} /> : null
}

const UsersList: FunctionComponent<Props> = ({
    isVolunteer,
    isFiltered,
    userAndSiteRoles,
    onEditClick,
    onRemoveClick,
    onClearFilterClick,
    removingDisabled = false,
    removingUserId = null,
    classes
}) => {
    const updateReq = updateUserReq()
    const settings = getSettings()
    const [users, setUsers] = useState<UsersListSchema[]>([])
    const dispatch = useDispatch()
    const { notifyError } = useGlobalNotifications()
    const lastUpdatedUser = useRef<UsersListSchema>()
    const user = getAuthUsersReq()
    const { hasSchoolApproval } = useApprovalSetttings()
    const { refreshApprovalSettings, isSettingsRefresh } = useAppovalRefresh()

    useEffect(() => {
      setUsers(userAndSiteRoles.filter(u => !u.site.name.match(SCHOOL_STAFF_REGEX)).map(flatten))
    }, [userAndSiteRoles])

    useEffect(() => {
        if( updateReq.model !== null && lastUpdatedUser.current) {
            if (lastUpdatedUser.current.isApprover !== updateReq.model.isApprover) {
                refreshApprovalSettings()
            }
        }
        if (!updateReq.error || updateReq.error === null || !lastUpdatedUser.current ) return

        if ( updateReq.error?.code === Errors.RemoveLastAdmin.code ) {
            notifyError({ message: Errors.RemoveLastAdmin.messages.district })
        } else {
            notifyError({ message: Errors.Internal.message })
        }

        const newUsers = users.map( currentUser => {
            const { isAdmin, isApprover, isUploader  } = lastUpdatedUser?.current!
            if( currentUser.id === lastUpdatedUser.current?.id ) {
                return { ...currentUser, isAdmin, isApprover, isUploader }
            }
            return currentUser
        })
        setUsers(newUsers)
        lastUpdatedUser.current = undefined
    }, [updateReq])
    
    const onRoleChange = (role: Roles, user: UsersListSchema ) => {

        if (role === Roles.isApprover) {
            const approvalError = validateApproverChanges({
                user: unflatten(user),
                settings: settings!,
            })
            const { type, message } = approvalError
            if ( type !== ApprovalErrors.None) {
                notifyError({ message })
                return
            }
        }

        lastUpdatedUser.current = user

        const roleStatus: boolean = get(user, role);
        const newUsers = users.map( currentUser => {
            if( currentUser.id === user.id ) {
                return { ...currentUser, [role]: !roleStatus }
            }
            return currentUser
        })

        setUsers(newUsers)
        dispatch(updateUser({ previous: unflatten(user), updates: unflatten({ ...user, [role]: !roleStatus})}))
    }

    return (
        <SortableTable<UsersListSchema>
            columnCount={9}
            items={users}
            isFiltered={isFiltered}
            thead={({ asSortableLabel }) => (
                <TableRow>
                    <TableCell>
                        Site
                    </TableCell>
                    <TableCell style={{ width: '180px' }}>
                        Email Address
                    </TableCell>
                    <TableCell>
                        First Name
                    </TableCell>
                    <TableCell>
                        Last Name
                    </TableCell>
                    <TableCell style={{ width: '100px' }}>
                        Admin
                    </TableCell>
                    <TableCell style={{ width: '100px' }}>
                        Approver
                    </TableCell>
                    <TableCell style={{ width: '100px' }}>
                        Uploader
                    </TableCell>
                    <TableCell style={{ width: '60px' }} />
                    <TableCell style={{ width: '60px' }} />
                </TableRow>
            )}
            tbody={(ur) => (
                <TableRow key={ur.id}>
                    <TableCell>{ur.siteName}</TableCell>
                    <TableCell style={{ wordWrap: 'break-word' }}>{ur.email}</TableCell>
                    <TableCell>{ur.firstName}</TableCell>
                    <TableCell>{ur.lastName}</TableCell>
                    <TableCell style={{ paddingLeft: '16px' }}>
                        { isVolunteer 
                            ? <Checked isChecked={ur.isAdmin} />
                            : <Checkbox 
                                checked={ ur.isAdmin } 
                                onChange={ () => onRoleChange( Roles.isAdmin, ur ) }
                                classes={{ 
                                    root: classes.checkboxRoot, 
                                    checked: ur.email !== user.email ? classes.checked : undefined, 
                                    disabled: classes.disabled 
                                }} 
                                disabled={ ur.email === user.email }
                              />
                        }
                    </TableCell>
                    <TableCell style={{ paddingLeft: '24px' }}>
                        { isVolunteer 
                            ? <Checked isChecked={ur.isApprover} />
                            : <Checkbox 
                                checked={ ur.isApprover } 
                                onChange={ () => onRoleChange( Roles.isApprover, ur ) }
                                classes={{ 
                                    root: classes.checkboxRoot, 
                                    checked: ur.siteType === 'school' && !hasSchoolApproval ? undefined : classes.checked, 
                                    disabled: classes.disabled
                                }} 
                                disabled={ (ur.siteType === 'school' && !hasSchoolApproval) || isSettingsRefresh }
                              />
                        }
                    </TableCell>
                    <TableCell style={{ paddingLeft: '24px' }}>
                        { isVolunteer 
                            ? <Checked isChecked={ur.isUploader} />
                            : <Checkbox 
                                checked={ ur.isUploader } 
                                onChange={ () => onRoleChange( Roles.isUploader, ur ) }
                                classes={{ root: classes.checkboxRoot, checked: classes.checked }} 
                              />
                        }
                    </TableCell>
                    <TableCell>
                        <IconButton
                            aria-label='edit'
                            tooltip='Edit Individual'
                            onClick={() => onEditClick(unflatten(ur))}
                            icon={EditIcon}
                        />
                    </TableCell>
                    <TableCell>
                        {removingUserId && removingUserId === ur.userId ?
                            <CircularProgress
                                color='inherit'
                                size='2rem'
                                style={{ color: colors.leaf, width: '2rem' }}
                            /> :
                            <IconButton
                                tooltip='Remove Individual'
                                aria-label='delete'
                                onClick={() => onRemoveClick(unflatten(ur))}
                                icon={RemoveIcon}
                                disabled={removingDisabled || ur.email === user.email}
                            />}
                    </TableCell>
                </TableRow>
            )}
            emptyTableAdornment={
                <Paragraph >
                    {isVolunteer ? EMPTY_VOLUNTEER_LIST : EMPTY_STAFF_LIST}
                </Paragraph>
            }
            emptyFilteredTableAdornment={
                <>
                    <Paragraph >
                        {isVolunteer ? FILTERED_EMPTY_VOLUNTEER_LIST : FILTERED_EMPTY_STAFF_LIST}
                    </Paragraph>
                    <p>
                        <Note >{ isVolunteer ? FILTERED_EMPTY_VOLUNTEER_RESET : FILTERED_EMPTY_STAFF_RESET }</Note>
                    </p>
                    <ButtonFlatSmall onClick={() => onClearFilterClick()}>Reset Filters</ButtonFlatSmall>
                </>
            }
        />
    )
}

const styles = () => ({
    checkboxRoot: {
      color: slate,
      '&$checked': {
        color: leaf,
      },
      '&:hover': {
        backgroundColor: alpha(leaf, 0.15)
      }
    },
    checked: { color: leaf },
    disabled: { backgroundColor: 'red' },
    listItem: {
      marginTop: '16px',
    },
  })

export default withStyles(styles)(UsersList)

import React, { FunctionComponent, useMemo, useContext } from 'react'
import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage'
import Routes from '../../../routes'
import ConfigContext from '@peachjar/ui/dist/lib/contexts/Config'
import { useActionPermissions } from '../../../hooks/usePermissions'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { useHistory } from 'react-router-dom'
import { School } from '../../../api/School'

import getTabBar from './Tabs'

export type Props = {
    title: string,
    school: School,
    tabIndex: number,
    subtext?: string,
}

const BaseIndependentSchoolPage: FunctionComponent<Props> = ({
    title,
    tabIndex,
    school,
    subtext,
    children,
}) => {
    const config = useContext(ConfigContext)

    const actionPermissions = useActionPermissions()
    const history = useHistory()
    const isInternal = actionPermissions.internal > Grants.None

    const tabs = useMemo(() => {
        if (isInternal) {
            return getTabBar(school.schoolId, tabIndex)
        }
        return undefined
    }, [school.schoolId, isInternal])

    const crumbs = [{
        label: 'Search',
        onClick: () => window.location.href = `https://internal.${config.DOMAIN}/search`,
    }]

    return (
        <DefaultPage
            title={title}
            tabs={tabs}
            subText={isInternal ? school.name : subtext}
            breadCrumbs={isInternal ? crumbs : undefined}
        >
            {children}
        </DefaultPage>
    )
}

export default BaseIndependentSchoolPage

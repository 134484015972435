import ApolloClient from 'apollo-boost'
import UserAndSiteRoles from '../../api/UserAndSiteRoles'

import getUserAndSiteRoles, { GetUserAndSiteRolesParams } from '../../api/queries/getUserAndSiteRoles'
import mapError from './mapError'
import gql from 'graphql-tag'

type Deps = {
    apolloClient: ApolloClient<any>,
}

const GET_USER_AND_SITE_ROLES = gql`
    query getUserAndSiteRoles($input: V2_GetUserAndSiteRolesInput) {
        users: getUserAndSiteRoles(input: $input) {
            userId
            site {
                id
                type
                name
            }
            firstName
            lastName
            email
            isAdmin
            isApprover
            isUploader
            isVolunteer
        }
    }
`

type QueryResult = {
    users: UserAndSiteRoles[]
}

export default function factory({ apolloClient }: Deps): getUserAndSiteRoles {
    return async (input: GetUserAndSiteRolesParams): Promise<UserAndSiteRoles[]> => {

        try {

            const result = await apolloClient.query<QueryResult>({
                query: GET_USER_AND_SITE_ROLES,
                fetchPolicy: 'network-only',
                variables: {
                    input,
                },
            })

            return result.data.users!

        } catch (error) {
            throw mapError(error)
        }
    }
}

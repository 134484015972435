import 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ApprovalConstraint } from '../api/ApprovalSettings'
import { getSettings, getIsSettingsRefresh } from '../redux/approvalSettingsSelectors'
import { getApprovalSettings, setIsSettingsRefresh } from '../redux/approvalSettings'
import { getAuthUsersReq } from '../redux/authUserSelectors'

type ApprovalSettingsStatus = {
    hasSchoolApproval: boolean
    hasDistrictApproval: boolean
}

type ApprovalRefresh = {
    refreshApprovalSettings: () => void
    isSettingsRefresh: boolean
}

export function useAppovalRefresh(): ApprovalRefresh {
    const { districtId: districtIdParam } = useParams<{ districtId: string }>()
    const authUser = getAuthUsersReq()
    const isPlatformUser = authUser.loginType === 'platform' 
    const districtId = parseInt(districtIdParam, 10)
    const dispatch = useDispatch()
    const refreshApprovalSettings = () => {
        dispatch(
            setIsSettingsRefresh(true)
        )
        dispatch(
          getApprovalSettings(
            isPlatformUser 
            ? {
              hierarchyId: -1,
              hierarchyType: ''
            }
            : {
              hierarchyId: districtId,
              hierarchyType: 'district',
            })
        )
    }

    return {
        refreshApprovalSettings,
        isSettingsRefresh: getIsSettingsRefresh()
    }
}

export default function useApprovalSettings(): ApprovalSettingsStatus {
    const settings = getSettings()

    if (!settings) {
        return {
            hasSchoolApproval: false,
            hasDistrictApproval: false,
           
        }
    }

    const hasSchoolApproval = settings.workflows.some( workflow => 
        workflow.levels.some( level => 
            level.constraint === ApprovalConstraint.AnySchoolApprover
        )
    )

    const hasDistrictApproval = settings.workflows.some( workflow => 
        workflow.levels.some( level => 
            level.constraint === ApprovalConstraint.AnyDistrictApprover ||
            level.constraint === ApprovalConstraint.SpecificDistrictApprover
        )
    )

    return {
        hasSchoolApproval,
        hasDistrictApproval,
    }

}
import * as yup from 'yup'

import React, { FunctionComponent, useEffect, useMemo } from 'react'
import Form from '@peachjar/ui/dist/lib/components/Form/FormWrapper'

import FlyerDisclaimerInput from './FlyerDisclaimerInput'
import ApprovalGuidelinesInput from './ApprovalGuidelinesInput'

import { CommunitySettings } from '../../../api/CommunitySettings'

import useFormBuilder, { Field, yupVal } from '@peachjar/ui/dist/lib/hooks/useFormBuilder'

type Props = {
    hierarchyName: string,
    settings: CommunitySettings,
    // This is used to watch/reset form state when an update is successful.
    lastUpdated?: Date | null,
    isSubmitting: boolean
    onSubmit: (updates: CommunitySettings) => void,
    onCancel?: () => void,
}

type FormModel = {
    flyerDisclaimer: {
        enabled: boolean,
        text: string,
    },
    guidelinesUrl: {
        enabled: boolean,
        url: string,
    }
}

const CommunitySettingsForm: FunctionComponent<Props> = ({
     hierarchyName,
     settings,
     lastUpdated = null,
     isSubmitting,
     onSubmit,
     onCancel = () => {},
}) => {

    const formModel: FormModel = {
        guidelinesUrl: {
            enabled: !!settings.guidelinesUrl,
            url: settings.guidelinesUrl || '',
        },
        flyerDisclaimer: {
            enabled: !!settings.flyerDisclaimer,
            text: settings.flyerDisclaimer || '',
        }
    }

    const formBindings = useFormBuilder<FormModel>(formModel, [
        {
            path: 'flyerDisclaimer',
            initial: formModel.flyerDisclaimer,
        },
        {
            path: 'guidelinesUrl',
            initial: formModel.guidelinesUrl,
        }
    ])

    const { bindings, reset } = formBindings

    useEffect(() => {
        reset(formBindings.value)
    }, [lastUpdated])

    return (
        <Form<FormModel>
            onSubmit={(model) => {
                const updates = {
                    hierarchyId: settings.hierarchyId,
                    hierarchyType: settings.hierarchyType,
                    flyerDisclaimer: model.flyerDisclaimer.enabled ? model.flyerDisclaimer.text : '',
                    guidelinesUrl: model.guidelinesUrl.enabled ? model.guidelinesUrl.url : '',
                } as CommunitySettings
                onSubmit(updates)
            }}
            isSubmitting={isSubmitting}
            submitLabel='Save'
            onCancel={onCancel}
            formBindings={formBindings}
            fields={[
                <FlyerDisclaimerInput {...bindings.flyerDisclaimer} />,
                <ApprovalGuidelinesInput {...bindings.guidelinesUrl} hierarchyName={hierarchyName} />
            ]}
        />
    )
}

export default CommunitySettingsForm

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React, { FunctionComponent } from 'react'
import App from './App'
import MicroApp from '@peachjar/ui/dist/lib/contexts/MicroApp/MicroApp'

import { configFactory, Config } from './config'
import { mocksFactory } from './mocks'
import { dependenciesFactory, Dependencies } from './dependencies'
import { ReducerMap } from './redux'
import { permissions } from './api/permissions'
import { authUsers } from './mocks/data'

import theme from '@peachjar/ui/dist/lib/theming/materialTheme'

const SchoolManagement: FunctionComponent<{}> = () => {
    return (
        <MicroApp<Config, Dependencies>
            config={configFactory}
            mocks={mocksFactory}
            deps={dependenciesFactory}
            reducers={() => ReducerMap}
            theme={theme}
            permissions={permissions}
            mockUsers={authUsers}
            app={App}
        />
    )
}

export default SchoolManagement

import * as yup from 'yup'

import { EntityTypes } from './api/Entity'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { encodeNotifications, Options as NotifyOptions } from '@peachjar/ui/dist/lib/components/Notifications/QueryStringNotification'

const districtIdSchema = yup.object().shape({
    districtId: yup.number().integer().min(1).required(),
})

const districtAndSchoolIdSchema = yup.object().shape({
    districtId: yup.number().integer().min(1).required(),
    schoolId: yup.number().integer().min(1).required(),
})

const schoolIdSchema = yup.object().shape({
    schoolId: yup.number().integer().min(1).required(),
})

export type QueryOptions = {
    notify?: NotifyOptions | NotifyOptions[],
}

function makeQueryString(options: QueryOptions = {}): string {
    const fields: string[] = []
    if (options.notify) {
        fields.push(...encodeNotifications(options.notify).map(n => `notify=${n}`))
    }
    return fields.length === 0 ? '' : '?' + fields.join('&')
}

/**
 * I really hate the convention people have taken with React where the
 * routing is bifurcated between declaration and builders.  That's
 * why I'm putting it in one place so I can update it all at once
 * should routes change.
 */
export default Object.freeze({
    workflows: {
        newDistrictOrIndySchool: {
            path: '/new',
            permission: 'internal',
            from: (options?: QueryOptions) =>
                '/new' + makeQueryString(options),
        }
    },
    districts: {
        search: {
            path: '/districts',
            permission: 'searchDistricts',
            from: (options?: QueryOptions) =>
                '/districts' + makeQueryString(options),
        },
        new: {
            path: '/district/new',
            permission: 'createDistrict',
            from: (options?: QueryOptions) =>
                '/district/new' + makeQueryString(options),
        },
        update: {
            template: '/district/new',
            exact: true,
            path: '/district/:districtId',
            paramSchema: districtIdSchema,
            entityType: EntityTypes.District,
            entityIdParam: 'districtId',
            from: (districtId: number, options?: QueryOptions): string =>
                `/district/${districtId}` + makeQueryString(options),
        },
        users: {
            exact: true,
            path: '/district/:districtId/users',
            paramSchema: districtIdSchema,
            entityType: EntityTypes.District,
            entityIdParam: 'districtId',
            permission: 'users',
            minGrant: Grants.Write,
            from: (districtId: number, options?: QueryOptions): string =>
                `/district/${districtId}/users` + makeQueryString(options),
        },
        staffUsers: {
            exact: true,
            path: '/district/:districtId/users/staff',
            paramSchema: districtIdSchema,
            entityType: EntityTypes.District,
            entityIdParam: 'districtId',
            permission: 'users',
            minGrant: Grants.Write,
            from: (districtId: number, options?: QueryOptions): string =>
                `/district/${districtId}/users/staff` + makeQueryString(options),
        },
        parents: {
            exact: true,
            path: '/district/:districtId/parents',
            paramSchema: districtIdSchema,
            entityType: EntityTypes.District,
            entityIdParam: 'districtId',
            permission: 'parents',
            minGrant: Grants.Write,
            from: (districtId: number, options?: QueryOptions): string =>
                `/district/${districtId}/parents` + makeQueryString(options),
        },
        mapping: {
            exact: true,
            path: '/district/:districtId/mapping',
            paramSchema: districtIdSchema,
            entityType: EntityTypes.District,
            entityIdParam: 'districtId',
            permission: 'parents',
            minGrant: Grants.Write,
            from: (districtId: number, options?: QueryOptions): string =>
                `/district/${districtId}/mapping` + makeQueryString(options),
        },
        community: {
            exact: true,
            path: '/district/:districtId/community',
            paramSchema: districtIdSchema,
            entityType: EntityTypes.District,
            entityIdParam: 'districtId',
            permission: 'community',
            minGrant: Grants.Write,
            from: (districtId: number, options?: QueryOptions): string =>
                `/district/${districtId}/community` + makeQueryString(options),
        },
        schools: {
            new: {
                exact: true,
                path: '/district/:districtId/school/new',
                paramSchema: districtIdSchema,
                entityType: EntityTypes.District,
                entityIdParam: 'districtId',
                permission: 'schools',
                minGrant: Grants.Write,
                from: (districtId: number, options?: QueryOptions): string =>
                    `/district/${districtId}/school/new` + makeQueryString(options),
            },
            update: {
                exact: true,
                path: '/district/:districtId/school/:schoolId',
                paramSchema: districtAndSchoolIdSchema,
                entityType: EntityTypes.School,
                entityIdParam: 'schoolId',
                permission: 'actions.update',
                minGrant: Grants.Write,
                from: (districtId: number, schoolId: number, options?: QueryOptions): string =>
                    `/district/${districtId}/school/${schoolId}` + makeQueryString(options),
            },
            users: {
                exact: true,
                path: '/district/:districtId/school/:schoolId/users',
                paramSchema: districtAndSchoolIdSchema,
                entityType: EntityTypes.School,
                entityIdParam: 'schoolId',
                permission: 'users',
                minGrant: Grants.Write,
                from: (districtId: number, schoolId: number, options?: QueryOptions): string =>
                    `/district/${districtId}/school/${schoolId}/users` + makeQueryString(options),
            },
            staffUsers: {
                exact: true,
                path: '/district/:districtId/school/:schoolId/users/staff',
                paramSchema: districtAndSchoolIdSchema,
                entityType: EntityTypes.School,
                entityIdParam: 'schoolId',
                permission: 'users',
                minGrant: Grants.Write,
                from: (districtId: number, schoolId: number, options?: QueryOptions): string =>
                    `/district/${districtId}/school/${schoolId}/users/staff` + makeQueryString(options),
            },
        },
    },
    schools: {
        search: {
            path: '/schools',
            permission: 'searchSchools',
            from: (options?: QueryOptions) =>
                '/schools' + makeQueryString(options),
        },
        new: {
            path: '/school/new',
            permission: 'createIndependentSchool',
            from: (options?: QueryOptions) =>
                '/school/new' + makeQueryString(options),
        },
        update: {
            exact: true,
            path: '/school/:schoolId',
            paramSchema: schoolIdSchema,
            entityType: EntityTypes.School,
            entityIdParam: 'schoolId',
            permission: 'actions.update',
            from: (schoolId: number, options?: QueryOptions): string =>
                `/school/${schoolId}` + makeQueryString(options),
        },
        users: {
            exact: true,
            path: '/school/:schoolId/users',
            paramSchema: schoolIdSchema,
            entityType: EntityTypes.School,
            entityIdParam: 'schoolId',
            permission: 'users',
            minGrant: Grants.Write,
            from: (schoolId: number, options?: QueryOptions): string =>
                `/school/${schoolId}/users` + makeQueryString(options),
        },
        parents: {
            exact: true,
            path: '/school/:schoolId/parents',
            paramSchema: schoolIdSchema,
            entityType: EntityTypes.School,
            entityIdParam: 'schoolId',
            permission: 'parents',
            from: (schoolId: number, options?: QueryOptions): string =>
                `/school/${schoolId}/parents` + makeQueryString(options),
        },
        community: {
            exact: true,
            path: '/school/:schoolId/community',
            paramSchema: schoolIdSchema,
            entityType: EntityTypes.School,
            entityIdParam: 'schoolId',
            permission: 'community',
            minGrant: Grants.Write,
            from: (schoolId: number, options?: QueryOptions): string =>
                `/school/${schoolId}/community` + makeQueryString(options),
        },
    },
})

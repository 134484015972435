export enum JobStatus  {
  Pending = 'pending',
  Processing = 'processing',
  Paused = 'paused',
  Cancelled = 'cancelled',
  Success = 'success',
  Failed = 'failed',
  InvalidFound = 'invalid',
}


type CsvRecord = {
  schoolName: string,
  numberOfRecords: number,
}

export type SisJob = {
  academic: {
    groupType: string,
    groupId: number,
  },
  isMapper: boolean,
  forceContinue: boolean,
  isManual: boolean,
  jobUuid: string,
  status: JobStatus,
  createdAt: Date,
  details: {
    invalidRecords: {
      invalidSchools: string[],
      invalidEmails: CsvRecord[],
      invalidGrades: number,
    }
    validRecordsDetails: ValidRecordDetail,
  },
  error?: {
    filename: string,
    message: string,
  },
}

export type ValidRecordDetail = {
  new: number,
  old: number,
  removed: number,
  total: number,
  percentageChange: number,
  duplicates: number,
  selfManaged: number,
  invalids: number,
}

export type SisJobIdentifiers = {
  hierarchyId: number,
  hierarchyType: string,
  jobId: string,
  isStaff?: boolean,
}

import React from 'react'

const CheckListIcon = () => (
  <svg
    width="56px"
    height="56px"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Style-Guide"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="Illustrations" transform="translate(-1686.000000, -358.000000)">
        <g
          id="Campaign-details-decorative-icon-Copy"
          transform="translate(1686.000000, 358.000000)"
        >
          <circle id="Mask" fill="#DFE1E7" cx={28} cy={28} r={28} />
          <path
            d="M17.277193,11.7894737 L42.4666667,11.7894737 C43.0189514,11.7894737 43.4666667,12.2371889 43.4666667,12.7894737 L43.4666667,40.4221069 C43.4666667,40.9743916 43.0189514,41.4221069 42.4666667,41.4221069 L17.277193,41.4221069 C16.7249082,41.4221069 16.277193,40.9743916 16.277193,40.4221069 L16.277193,12.7894737 C16.277193,12.2371889 16.7249082,11.7894737 17.277193,11.7894737 Z"
            id="Rectangle"
            fill="#47506B"
          />
          <path
            d="M15.2631579,14.122807 L40.4526316,14.122807 C41.0049163,14.122807 41.4526316,14.5705223 41.4526316,15.122807 L41.4526316,42.7554402 C41.4526316,43.307725 41.0049163,43.7554402 40.4526316,43.7554402 L15.2631579,43.7554402 C14.7108731,43.7554402 14.2631579,43.307725 14.2631579,42.7554402 L14.2631579,15.122807 C14.2631579,14.5705223 14.7108731,14.122807 15.2631579,14.122807 Z"
            id="Rectangle"
            fill="#FFFFFF"
          />
          <path
            d="M23.7766546,19.4815325 L37.3972522,19.4815325 C37.6801154,19.4815325 37.9094212,19.7108384 37.9094212,19.9937015 L37.9094212,19.9937015 C37.9094212,20.2765646 37.6801154,20.5058705 37.3972522,20.5058705 L23.7766546,20.5058705 C23.4937915,20.5058705 23.2644857,20.2765646 23.2644857,19.9937015 L23.2644857,19.9937015 C23.2644857,19.7108384 23.4937915,19.4815325 23.7766546,19.4815325 Z"
            id="Rectangle-6"
            fill="#C6C9D5"
          />
          <path
            d="M23.7766546,25.3210217 L37.3972522,25.3210217 C37.6801154,25.3210217 37.9094212,25.5503276 37.9094212,25.8331907 L37.9094212,25.8331907 C37.9094212,26.1160538 37.6801154,26.3453596 37.3972522,26.3453596 L23.7766546,26.3453596 C23.4937915,26.3453596 23.2644857,26.1160538 23.2644857,25.8331907 L23.2644857,25.8331907 C23.2644857,25.5503276 23.4937915,25.3210217 23.7766546,25.3210217 Z"
            id="Rectangle-6"
            fill="#C6C9D5"
          />
          <path
            d="M23.7766546,31.1605108 L37.3972522,31.1605108 C37.6801154,31.1605108 37.9094212,31.3898167 37.9094212,31.6726798 L37.9094212,31.6726798 C37.9094212,31.9555429 37.6801154,32.1848488 37.3972522,32.1848488 L23.7766546,32.1848488 C23.4937915,32.1848488 23.2644857,31.9555429 23.2644857,31.6726798 L23.2644857,31.6726798 C23.2644857,31.3898167 23.4937915,31.1605108 23.7766546,31.1605108 Z"
            id="Rectangle-6"
            fill="#C6C9D5"
          />
          <path
            d="M23.7766546,37 L37.3972522,37 C37.6801154,37 37.9094212,37.2293059 37.9094212,37.512169 L37.9094212,37.512169 C37.9094212,37.7950321 37.6801154,38.0243379 37.3972522,38.0243379 L23.7766546,38.0243379 C23.4937915,38.0243379 23.2644857,37.7950321 23.2644857,37.512169 L23.2644857,37.512169 C23.2644857,37.2293059 23.4937915,37 23.7766546,37 Z"
            id="Rectangle-6"
            fill="#C6C9D5"
          />
          <rect
            id="Rectangle-23"
            stroke="#C6C9D5"
            fillRule="nonzero"
            x="17.5"
            y="18.5"
            width={3}
            height={3}
            rx={1}
          />
          <rect
            id="Rectangle-23-Copy"
            stroke="#C6C9D5"
            fillRule="nonzero"
            x="17.5"
            y="24.1666667"
            width={3}
            height={3}
            rx={1}
          />
          <rect
            id="Rectangle-23-Copy-2"
            stroke="#C6C9D5"
            fillRule="nonzero"
            x="17.5"
            y="29.8333333"
            width={3}
            height={3}
            rx={1}
          />
          <rect
            id="Rectangle-23-Copy-3"
            stroke="#C6C9D5"
            fillRule="nonzero"
            x="17.5"
            y="35.5"
            width={3}
            height={3}
            rx={1}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default CheckListIcon

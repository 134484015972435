import React, { FunctionComponent, useEffect } from 'react'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import BaseDistrictPage from './components/BaseDistrictPage'
import Routes from '../../routes'
import CreateSchoolPageBody from '../School/components/CreateSchoolPageBody'

import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getDistrict, clearReq } from '../../redux/districts'
import { getDistrictReq } from '../../redux/districtsSelectors'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'

const AddSchoolToDistrictPage: FunctionComponent<any> = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const panic = usePanic()

    let districtId = -1

    const { districtId: districtIdParam } = useParams<{ districtId: string }>()

    if (districtIdParam) {
        districtId = parseInt(districtIdParam, 10)
    }

    const districtReq = getDistrictReq()

    useEffect(() => {
         dispatch(getDistrict(districtId))
    }, [])

    if (districtReq.isLoading()) return <LoadingSpinner />

    if (districtReq.error) return panic(districtReq.error)

    const district = districtReq.model!

    return (
        <BaseDistrictPage
            title='Add School / Site'
            district={district}
            tabIndex={0}
            showCrumbBackToDistrict={true}
        >
            <CreateSchoolPageBody
                isIndependent={false}
                districtId={districtId}
                onCreateSuccess={
                    (school) => {
                        dispatch(clearReq())
                        history.push(Routes.districts.update.from(districtId, {
                            notify: {
                                key: 'school_site_added',
                                variant: 'success',
                                message: 'School / Site is added.',
                            }
                        }))
                    }
                }
            />
        </BaseDistrictPage>
    )
}

export default AddSchoolToDistrictPage

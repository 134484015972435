import uploadSisCSVToCDN from '../api/commands/uploadSisCSVToCDN'

import wait from './wait'

export default function factory(): uploadSisCSVToCDN {
    return async ({ cdnUrl, file, onUploadProgress }) => {
        await wait(1000)
        for (let i = 1; i < 11; i++) {
            await wait(200)
            onUploadProgress(i * 10)
        }
    }
}

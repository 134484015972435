import get from 'lodash/get'
import uniq from 'lodash/uniq'
import {
  Levels,
  ApprovalErrors,
  ApprovalSettings,
  ApprovalConstraint,
} from '../../../api/ApprovalSettings'
import UserAndSiteRoles from '../../../api/UserAndSiteRoles'
import { ApprovalGroup } from '../../../api/ApprovalSettings'

type Deps = {
  user: UserAndSiteRoles
  settings: ApprovalSettings
}

export type ValidationError = {
  type: ApprovalErrors
  message: string
}

const approvalErrorsConfig = {
  [ApprovalErrors.SingleDistrictLastApprover]: () =>
    'Your flyer approvals require at least one approver. Assign another staff member to the Approver role before removing this approver.',
  [ApprovalErrors.DualDistrictNonScreener]: () =>
    'Your flyer approvals require at least two approvers. Assign another staff member to the Approver role before removing this approver or update your Approvals Settings to a single district level.',
  [ApprovalErrors.DualDistrictScreener]: () =>
    'This approver is assigned as the screener of flyer approvals. Assign another staff member to the Approver role before removing this approver or update your Approvals Settings to a single district level.',
  [ApprovalErrors.DistrictAndSchoolLastDistrictApprover]: () =>
    'Your flyer approvals require at least one district approver. Assign another staff member at the district to the Approver role before removing this approver.',
  [ApprovalErrors.DistrictAndSchoolLastSchoolApprover]: (schoolName: string) =>
    `This staff member is the only approver at ${schoolName}. Removing the only approver from ${schoolName} will allow flyers approved by the district to be distributed to this school's parents without school approval. If you wish to retain school approval, assign another staff member at this school to the Approver role before removing this approver.`,
  [ApprovalErrors.DistrictAndSchoolSingleSchoolLastSchoolApprover]: () =>
    'Your flyer approvals require at least one school approver. Assign another staff member to the Approver role before removing this approver or update your Approvals Settings to remove the school level.',
  [ApprovalErrors.SingleSchoolLastApprover]: (schoolName: string) =>
    `Your flyer approvals require at least one approver at each school. Assign another staff member at ${schoolName} to the Approver role before removing this approver.`,
  [ApprovalErrors.ApprovalGroupApprover]: () => 
    'This user is an approver of a school group and cannot be removed. Your flyer approvals require at least one approver for each school group. Assign another district approver to the school group before removing this approver.'
}

const getApproverLevel = (approverLevels) => {
  const levelLength = approverLevels.length

  if (
    levelLength === 1 &&
    approverLevels[0].constraint === ApprovalConstraint.AnyDistrictApprover
  ) {
    return Levels.SingleDistrict
  }

  if ( levelLength === 1 &&
    approverLevels[0].constraint === ApprovalConstraint.SpecificDistrictGroupApprover
  ) {
    return Levels.Group
  }


  if (
    levelLength === 2 &&
    approverLevels[0].constraint ===
      ApprovalConstraint.SpecificDistrictApprover &&
    approverLevels[1].constraint === ApprovalConstraint.AnyDistrictApprover
  ) {
    return Levels.DualDistrict
  }

  if (
    levelLength === 2 &&
    approverLevels[0].constraint === ApprovalConstraint.AnyDistrictApprover &&
    approverLevels[1].constraint === ApprovalConstraint.AnySchoolApprover
  ) {
    return Levels.DistrictAndSchool
  }

  if (
    levelLength === 1 &&
    (approverLevels[0].constraint === ApprovalConstraint.AnySchoolApprover ||
      approverLevels[0].constraint ===
        ApprovalConstraint.SpecificSchoolApprover)
  ) {
    return Levels.SingleSchool
  }
}

// validates user removal against District approval settings
// to prevent invalid Users and Roles actions - example: removing last district approver
export default function validateApproverChanges({
  user,
  settings,
}: Deps): ValidationError {
  const approverLevels = get(settings, 'workflows[0].levels', [])
  const roles = get(settings, 'audience.roles', [])
  const {
    userId: removedUserId,
    email: removedUserEmail,
    site: removedUserSite,
    isApprover: isRemovedUserAnApprover,
  } = user

  if (!approverLevels.length || !roles.length || !isRemovedUserAnApprover) {
    // no errors
    return { type: ApprovalErrors.None, message: '' }
  }

  const districtApprovers = roles.filter(
    ({ isApprover, site }) => isApprover && site.type === 'district'
  )
  const schoolApprovers = roles.filter(
    ({ isApprover, site }) => isApprover && site.type === 'school'
  )

  // 1 District level
  // cant remove last district approver
  if (
    getApproverLevel(approverLevels) === Levels.SingleDistrict &&
    districtApprovers.length === 1 &&
    districtApprovers[0].email === removedUserEmail
  ) {
    return {
      type: ApprovalErrors.SingleDistrictLastApprover,
      message: approvalErrorsConfig[
        ApprovalErrors.SingleDistrictLastApprover
      ](),
    }
  }

  // 2 District levels
  if (getApproverLevel(approverLevels) === Levels.DualDistrict) {
    const screenerUserId = approverLevels[0].userId

    // require at least two approvers
    if (districtApprovers.length === 2) {
      return {
        type: ApprovalErrors.DualDistrictNonScreener,
        message: approvalErrorsConfig[ApprovalErrors.DualDistrictNonScreener](),
      }
    }

    // cant remove screener
    if (removedUserId === screenerUserId) {
      return {
        type: ApprovalErrors.DualDistrictScreener,
        message: approvalErrorsConfig[ApprovalErrors.DualDistrictScreener](),
      }
    }
  }

  // 2 levels, District AND School
  if (getApproverLevel(approverLevels) === Levels.DistrictAndSchool) {
    const mySchoolName = removedUserSite.name || ''
    const approversFromMySchool = schoolApprovers.filter(
      ({ site }) => site.name === mySchoolName
    )
    const schoolsWithApprovers = schoolApprovers.map(({ site }) => site.name)
    const uniqSchoolsWithApprovers = uniq(schoolsWithApprovers)

    // cant remove last district approver
    if (
      districtApprovers.length === 1 &&
      districtApprovers[0].email === removedUserEmail
    ) {
      return {
        type: ApprovalErrors.DistrictAndSchoolLastDistrictApprover,
        message: approvalErrorsConfig[
          ApprovalErrors.DistrictAndSchoolLastDistrictApprover
        ](),
      }
    }

    // cant remove school approver from school when school
    // is the only school with an approver in the district
    if (
      uniqSchoolsWithApprovers.length === 1 &&
      uniqSchoolsWithApprovers[0] === removedUserSite.name
    ) {
      return {
        type: ApprovalErrors.DistrictAndSchoolSingleSchoolLastSchoolApprover,
        message: approvalErrorsConfig[
          ApprovalErrors.DistrictAndSchoolSingleSchoolLastSchoolApprover
        ](),
      }
    }

    // cant remove last school approver at a school
    // show modal confirmation
    if (
      approversFromMySchool.length === 1 &&
      approversFromMySchool[0].email === removedUserEmail
    ) {
      return {
        type: ApprovalErrors.DistrictAndSchoolLastSchoolApprover,
        message: approvalErrorsConfig[
          ApprovalErrors.DistrictAndSchoolLastSchoolApprover
        ](mySchoolName),
      }
    }
  }

  // 1 School level
  if (getApproverLevel(approverLevels) === Levels.SingleSchool) {
    const mySchoolName = removedUserSite.name || ''
    const mySchoolApprovers = schoolApprovers.filter(
      ({ site }) => site.name === removedUserSite.name
    )

    // cant remove last school approver at a school
    if (
      mySchoolApprovers.length === 1 &&
      mySchoolApprovers[0].userId === removedUserId
    ) {
      return {
        type: ApprovalErrors.SingleSchoolLastApprover,
        message: approvalErrorsConfig[ApprovalErrors.SingleSchoolLastApprover](
          mySchoolName
        ),
      }
    }
  }

  if (getApproverLevel(approverLevels) === Levels.Group) {
    const groups: ApprovalGroup[] =  get(approverLevels[0], 'approvalGroups', []) || []

    const isGroupApprover = groups.some( group => group.userIds.includes(removedUserId))
    
    if ( isGroupApprover ) {
      return {
        type: ApprovalErrors.ApprovalGroupApprover,
        message: approvalErrorsConfig[ApprovalErrors.ApprovalGroupApprover]()
      } 
    }
  }

  // fall through - shouldnt happen but meh
  return { type: ApprovalErrors.None, message: '' }
}

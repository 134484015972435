import React, { FunctionComponent, useEffect } from 'react'
import BaseDistrictPage from './components/BaseDistrictPage'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'
import CommunityPageBody from '../Community/CommunityPageBody'

import { Section, Sections } from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { invalidParam } from '../../api/CodedError'
import { RootState } from '../../redux'
import { District } from '../../api/District'
import { getDistrict } from '../../redux/districts'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'

const DistrictCommunityPage: FunctionComponent<{}> = () => {

    const panic = usePanic()
    const dispatch = useDispatch()

    const { districtId: districtIdParam } = useParams<{ districtId: string }>()
    const districtId = parseInt(districtIdParam, 10)

    if (!districtId) {
        panic(invalidParam('districtId', 'District IDs must be numbers.'))
    }

    const req = useSelector<RootState, AsyncReqState<District>>(state => state.districts.district)

    useEffect(() => {
        dispatch(getDistrict(districtId))
    }, [])

    // Could not load the district
    if (req.error) return panic(req.error)

    if (req.isLoading()) return <LoadingSpinner />

    const district = req.model

    return (
        <BaseDistrictPage
            title='Community Settings'
            district={district!}
            tabIndex={4}
        >
            <Sections>
                <Section>
                    <CommunityPageBody
                        hierarchyType='district'
                        hierarchyId={districtId}
                        hierarchyName={district!.name}
                    />
                </Section>
            </Sections>
        </BaseDistrictPage>
    )
}

export default DistrictCommunityPage

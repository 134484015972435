import getSisJobErrors from '../api/queries/getSisJobErrors'
import wait from './wait'

export default function factory(): getSisJobErrors {
    return async () => {
        await wait(1200)
        return [
            '[25] email address formatting issues. Download the invalid entries to view the list.',
            '[49] email address formatting issues. Download the invalid entries to view the list.',
        ]
    }
}

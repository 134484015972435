import React, { FunctionComponent, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import SwitchError from '@peachjar/ui/dist/lib/components/Errors/SwitchError'

import { BulkAddUserResult } from '../../../api/commands/bulkAddUser'
import {  uploadSisCSVReq } from '../../../redux/parentSettingsSelectors'

import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import { css } from 'emotion'


const { Dark: { Paragraph, Note }, DarkMuted: { Note: MutedNote } } = Typo

type Props = {
    job: BulkAddUserResult,
    isSuccess: boolean,
}

const FailedAddendum: FunctionComponent<Props> = ({
    job,
    isSuccess,
}) => {
    const errorMessages = job.error !== '' ? job.error.split(';') : []
    const uploadReq = uploadSisCSVReq()
    return (
        <>
            <SwitchError error={uploadReq.error} />
            {uploadReq.status === Statuses.loaded && isSuccess &&
                <Grid container>
                    <>
                        <Paragraph  style={{ marginTop: '0px', textAlign: 'left', padding: '0px 10px 0 24px', marginLeft: '16px' }}>
                            Your CSV file successfully processed and the staff list is updated.
                            <Grid item xs={12} style={{ marginTop: '16px' }}>
                                <Note  style={{ color: colors.prussian }}>Entry rows processed:</Note>
                            </Grid>
                            <Grid item xs={12}>
                                <MutedNote >
                                    <div className={styles.rowsText}>[{job.staffAdded}] new entries added</div>
                                    <div className={styles.rowsText}>[{job.staffUpdated}] existing entries updated</div>
                                    <div className={styles.rowsText}>[{job.staffRemoved}] omitted entries without roles removed</div>
                                </MutedNote>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '16px' }}>
                                <Note  style={{ color: colors.prussian }}>Entry rows ignored:</Note>
                            </Grid>
                            <Grid item xs={12}>
                                <MutedNote >
                                    <div className={styles.rowsText}>[{job.staffDupeCount}] duplicate entries</div>
                                </MutedNote>
                            </Grid>
                        </Paragraph>
                    </>
                </Grid>
            }

            {uploadReq.status === Statuses.loaded && !isSuccess &&
                <Grid container>
                    <>
                        <Paragraph  style={{ marginTop: '0px', textAlign: 'left', padding: '0px 10px 0 24px', marginLeft: '16px'}}>
                            Your CSV file failed to process due to one or
                            more issues listed below. Check your file to fix
                            the issues and reupload it.
                            <Grid item xs={12} style={{ marginTop: '16px' }}>
                            <Note  style={{ color: colors.dragon }}>Error:</Note>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '8px' }}>
                            <MutedNote >
                            {errorMessages.map((m, i) => (<div key={`error_msg_${i}`}>{m}</div>))}
                            </MutedNote>
                            </Grid>
                        </Paragraph>
                    </>
                </Grid>
            }
        </>
    )
}

const styles = {
    rowsText: css`
        line-height: 1.5rem;
    `,
}

export default FailedAddendum

import React, { FunctionComponent, useMemo } from 'react'
import SortableTable from '@peachjar/ui/dist/lib/components/SortableTable/SortableTable2'

import { School } from '../../../api/School'
import { NcesSchool } from '../../../api/NcesSchool'
import { toString } from 'lodash'
import { SortDirections } from '@peachjar/ui/dist/lib/components/SortableTable/sort-utils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tag, { Variants } from '@peachjar/ui/dist/lib/components/Tag'

type SchoolDescription = {
    type: 'existing' | 'nces',
    id: string,
    name: string,
    address: string,
    enrollment: number,
    lowGrade: string,
    highGrade: string,
}

type Props = {
    existing: School[],
    nces: NcesSchool[]
}

const AssociatedSchoolsList: FunctionComponent<Props> = ({
    existing,
    nces,
}) => {

    const schools = useMemo<SchoolDescription[]>(() => {
        return [
            ...existing.map(e => ({
                type: 'existing',
                id: toString(e.schoolId),
                name: e.name,
                address: e.address?.address,
                enrollment: e.enrollmentCount,
                lowGrade: e.lowGrade,
                highGrade: e.highGrade,
            })),
            ...nces.map(n => ({
                type: 'nces',
                ...n,
            }))
        ] as SchoolDescription[]
    }, [existing, nces])

    return (
        <SortableTable<SchoolDescription>
            columnCount={6}
            items={schools}
            initialSort={{ field: 'type', direction: SortDirections.Asc }}
            thead={({ asSortableLabel }) => (
                <TableRow>
                    <TableCell>{asSortableLabel('Type', 'type')}</TableCell>
                    <TableCell>{asSortableLabel('ID', 'id')}</TableCell>
                    <TableCell>{asSortableLabel('Name', 'name')}</TableCell>
                    <TableCell>{asSortableLabel('Address', 'address')}</TableCell>
                    <TableCell>{asSortableLabel('Enrollment', 'enrollment')}</TableCell>
                    <TableCell>{asSortableLabel('Grade Levels', 'lowGrade')}</TableCell>
                </TableRow>
            )}
            tbody={(school) => (
                <TableRow hover key={school.id}>
                    <TableCell>
                        <Tag variant={school.type === 'existing' ? Variants.Active : Variants.Inactive}>
                            {school.type === 'existing' ? 'Existing' : 'NCES'}
                        </Tag>
                    </TableCell>
                    <TableCell>{school.id}</TableCell>
                    <TableCell>{school.name}</TableCell>
                    <TableCell>{school.address}</TableCell>
                    <TableCell>{school.enrollment}</TableCell>
                    <TableCell>{school.lowGrade} - {school.highGrade}</TableCell>
                </TableRow>
            )}
        />
    )
}

export default AssociatedSchoolsList

import React, { FunctionComponent, useMemo, useRef } from 'react'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

export type LocalFileDownloadProps = {
    label?: string,
    mimeType: string,
    fileName: string,
    fileContents: string,
    encoding?: string,
    doNotBase64?: boolean,
    variant?: 'buttonSecondarySmall'
}

const LocalFileDownload: FunctionComponent<LocalFileDownloadProps> = ({
    label = 'Download File',
    mimeType,
    fileName,
    fileContents,
    encoding = 'base64',
    doNotBase64 = false,
}) => {
    const dataUrl = useMemo(() => {
        const content = doNotBase64 ? fileContents : btoa(fileContents)
        return `data:${mimeType};${encoding},${content}`
    }, [mimeType, fileName, fileContents])

    const anchorRef = useRef<HTMLAnchorElement>(null)

    const handleClick = () => {
        anchorRef.current?.click()
    }

    return (
        <>
            <ButtonSecondarySmall onClick={handleClick}>{label}</ButtonSecondarySmall>
            <a
                ref={anchorRef!}
                download={fileName}
                href={dataUrl}
                style={{ display: 'none' }}
            />
        </>
    )
}

export default LocalFileDownload

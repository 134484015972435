export enum JobStatus  {
  Pending = 'pending',
  Processing = 'processing',
  Paused = 'paused',
  Cancelled = 'cancelled',
  Success = 'success',
  Failed = 'failed',
  InvalidFound = 'invalid',
}


type CsvRecord = {
  schoolName: string,
  numberOfRecords: number,
}

export type StaffJob = {
  academic: {
    groupType: string,
    groupId: number,
  },
  isManual: boolean,
  jobUuid: string,
  status: JobStatus,
  createdAt: Date,
  details: {
    invalidRecords: {
      invalidSchools: string[],
      invalidEmails: CsvRecord[],
      invalidGrades: string[],
    }
    validRecordsDetails: ValidRecordDetail,
  },
  error?: {
    filename: string,
    message: string,
  },
}

export type ValidRecordDetail = {
  staffAdded: number,
  staffUpdated: number,
  staffRemoved: number,
  staffDupeCount: number,
  existing: number,
  total: number,
  percentageChange: number,  
}

export type StaffJobIdentifiers = {
  hierarchyId: number,
  hierarchyType: string,
  jobId: string,
}

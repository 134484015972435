import * as yup from 'yup'
import { css } from 'emotion'
import React, { FunctionComponent, memo, useEffect, useMemo } from 'react'
import FormWrapper from '@peachjar/ui/dist/lib/components/Form/FormWrapper'
import WithTooltip from '@peachjar/ui/dist/lib/components/Form/WithTooltip'
import TextField from '@peachjar/ui/dist/lib/components/Inputs/TextInputWrapper'
import AddressInput from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import SwitchInput from '@peachjar/ui/dist/lib/components/Inputs/SwitchInput'
import CalendarInput from '@peachjar/ui/dist/lib/components/Inputs/CalendarInput'
import InlineNotification from '@peachjar/ui/dist/lib/components/Notifications/InlineNotification'
import GraphIds from '../../../components/GraphIDs'
import FlyerboardButtons from '../../../components/FlyerboardButtons'

import { District } from '../../../api/District'
import { DistrictPermissions } from '../../../api/permissions/DistrictPermissions'
import { Grants } from '@peachjar/ui/dist/api/Permissions'
import { isEmpty, toString } from 'lodash'
import { Site, SiteInfo, toSiteInfo } from '../../../api/SiteInfo'

import useFormBuilder, { Field, ValidationResult, yupVal } from '@peachjar/ui/dist/lib/hooks/useFormBuilder'
import LaunchedAt from '../../../components/LaunchedAt/LaunchedAt'

const DISCLAIMER_TOOLTIP = 'Displays on the bottom of all emails your parents receive from Peachjar and on the bottom of your district and school flyerboards.'

export type ExternalErrors = {
    sisId: {
        message: string,
        lastNonUniqueVal: string | null,
    },
}

type Props = {
    isEdit?: boolean,
    permissions?: DistrictPermissions,
    district?: District | null,
    errors: ExternalErrors,
    isSubmitting: boolean,
    // This is used to watch/reset form state when an update is successful.
    lastUpdated?: Date | null,
    onSubmit: (updates: Partial<District>) => void,
    onCancel: () => void,
}

const DistrictForm: FunctionComponent<Props> = ({
    isEdit = false,
    district,
    errors,
    permissions,
    lastUpdated = null,
    onSubmit,
    onCancel,
    isSubmitting,
}) => {
    const isNcesAddress = district?.address?.googlePlaceId === 'nces'
    const ncesAddress = district?.address?.address

    if (isNcesAddress) {
        district = Object.assign({}, district, { address: null })
    }
    district = Object.assign({}, district, { invisible: isEdit ? !district?.invisible : false })

    const formBindings = useFormBuilder<District>(district || null, [
        {
            path: 'name',
            initial: district?.name || '',
            validate: yupVal(yup.string().required('You missed this field.')),
            permission: isEdit ? permissions?.editName : (permissions?.name || Grants.Write),
        },
        {
            path: 'active',
            initial: district?.active || true,
            useCheckedRules: true,
            permission: permissions?.active || Grants.Write,
        },
        {
            path: 'invisible',
            initial: !district?.invisible || false,
            useCheckedRules: true,
            permission: permissions?.invisible || Grants.Write,
        },
        {
            path: 'startAt',
            initial: district?.startAt || null,
            permission: permissions?.startAt || Grants.Write,
        },
        {
            path: 'ncesId',
            initial: district?.ncesId || '',
            // Unfortunately, Yup does not deal with empty strings well :(
            validate: (value: any): ValidationResult => {
                const normValue = (value || '').trim()
                if (isEmpty(normValue)) {
                    return { isValid: true }
                }
                const isValid = /[a-zA-Z0-9]{7}/.test(normValue)
                return {
                    isValid,
                    error: isValid ? undefined : 'District NCES IDs are 7 characters.',
                }
            },
            permission: permissions?.ncesId || Grants.Write,
        },
        !isEdit ? null : {
            path: 'sisId',
            initial: district?.sisId || '',
            permission: (permissions?.sisId || Grants.Write),
            validate: (value: string) => {
                if (!value) {
                    return {
                        isValid: false,
                        error: 'You missed this field.',
                    }
                }
                if (errors.sisId.lastNonUniqueVal === value) {
                    return {
                        isValid: false,
                        error: errors.sisId.message,
                    }
                }
                return { isValid: true }
            },
        },
        {
            path: 'address',
            initial: district?.address || null,
            validate: (value: any) => {
                if (!value) {
                    return { isValid: false, error: 'You missed this field.' }
                }
                return { isValid: true }
            },
            permission: permissions?.address || Grants.Write,
        },
        {
            path: 'generalDisclaimer',
            initial: toString(district?.generalDisclaimer || ''),
            validate: yupVal(
                yup.string().nullable(true)
            ),
            permission: permissions?.generalDisclaimer || Grants.Write,
        },
        // {
        //     path: 'treeCounter',
        //     initial: district?.treeCounter || true,
        //     useCheckedRules: true,
        //     permission: permissions?.treeCounter || Grants.Write,
        // },
    ].filter(Boolean) as Array<Field<District, any>>)

    const { bindings, reset } = formBindings

    useEffect(() => {
        reset(formBindings.value)
    }, [lastUpdated])

    const sites: SiteInfo[] = useMemo(() => {
        const allSites: Site[] = []
        if (district) {
            allSites.push(district)
            if (district.schools) {
                allSites.push(...district.schools)
            }
        }
        return allSites.map(toSiteInfo)
    }, [district])

    return (
        <div className={styles.container}>
            <FormWrapper<District>
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                submitLabel={isEdit ? 'Update' : 'Add'}
                onCancel={onCancel}
                formBindings={formBindings}
                fields={[
                    <TextField name={'name'} label={'District Name'} {...bindings.name} />,
                    [permissions?.internal,
                        <>
                            <div>
                                <SwitchInput name={'active'} label={'Active'} {...bindings.active} />
                            </div>
                            <div>
                                <SwitchInput
                                    name={'invisible'}
                                    label={'Visible'}
                                    {...bindings.invisible}
                                />
                            </div>
                        </>
                    ],
                    [permissions?.startAt,
                        !!district && district.live ?
                            <LaunchedAt launchedAt={district.launchedAt!} /> :
                            <CalendarInput
                                label='Start Date'
                                {...bindings.startAt}
                                value={bindings.startAt?.value}
                                onChange={(v) => bindings.startAt?.onChange(v)}
                            />
                    ],
                    [permissions?.internal,
                        district?.districtId ?
                            <GraphIds
                                primaryId={district?.districtId}
                                graphId={district?.id}
                                permission={permissions!.districtId}
                            /> : null
                    ],
                    !isEdit ? null :
                    <TextField name={'sisId'} label={'SIS ID'} {...bindings.sisId} />,
                    [permissions?.ncesId, <TextField name={'ncesId'} label={'NCES ID (optional)'} {...bindings.ncesId} />],
                    isNcesAddress ?
                        <InlineNotification
                            variant='info'
                            fullWidth={false}
                            message={<>
                                Please verify the NCES supplied address with Google:<br />
                                <strong>{ncesAddress}</strong>
                            </>}
                        /> : null,                            
                    <AddressInput label='Physical Address' {...bindings.address} />,
                    district && isEdit ? <FlyerboardButtons sites={sites} /> : null,
                    isEdit ? 
                        <WithTooltip tooltip={DISCLAIMER_TOOLTIP}>
                            <TextField
                                {...bindings.generalDisclaimer!}
                                name={'generalDisclaimer'}
                                label={'General Disclaimer (optional)'}
                                multiline
                            />
                        </WithTooltip>
                    : null,
                    // <SwitchInput name={'treeCounter'} label={'Tree counter'} {...bindings.treeCounter} />,
                ]}
            />
        </div>        
    )
}

const styles = {
    container: css`
        .MuiInput-underline.Mui-disabled:before {
            border-bottom: none;
        }
    `
}

export default memo(DistrictForm)

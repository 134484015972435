import React, { FunctionComponent, useState, useEffect } from 'react'
import SwitchInput from '@peachjar/ui/dist/lib/components/Inputs/SwitchInput'
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip'
import PreviewBox from './PreviewBox'
import Grid from '@material-ui/core/Grid'
import AnchorTextButton from '../../../components/Buttons/AnchorTextButton'
import FlyerDisclaimerModal from './FlyerDisclaimerModal'

import { InputProps } from '@peachjar/ui/dist/lib/components/Inputs/InputProps'

type Model = {
    enabled: boolean,
    text: string,
}

export type Props = InputProps<Model>

const TOOLTIP = 'This disclaimer displays on the bottom of all community organization flyers'

const DEFAULT_DISCLAIMER = 'Our district approves flyers from organizations as a community service and does not imply sponsorship nor endorsement of this program or event. Please contact the organization directly for additional information.'

const FlyerDisclaimerInput: FunctionComponent<Props> = ({
    value,
    onChange,
}) => {

    const [enabled, setEnabled] = useState(value.enabled)
    const [useCustom, setUseCustom] = useState(!!value.text && value.text !== DEFAULT_DISCLAIMER)
    const [customText, setCustomText] = useState(value.text === DEFAULT_DISCLAIMER ? '' : value.text)
    const [isEditModalOpen, setEditModalOpen] = useState(false)

    useEffect(() => {
        onChange({
            enabled,
            text: !enabled ? '' : (useCustom ? customText : DEFAULT_DISCLAIMER)
        })
    }, [enabled, useCustom, customText])

    return (
        <Grid container>
            <Grid item xs={12}>
                <SwitchInput
                    label='Flyer Disclaimer'
                    value={value.enabled}
                    onChange={v => setEnabled(v)}
                />
                <Tooltip title={TOOLTIP} placement='right' />
            </Grid>
            {value.enabled && (
                <Grid item xs={12} style={{ paddingLeft: '48px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <PreviewBox
                                showEdit={useCustom}
                                onEditClick={() => setEditModalOpen(true)}
                            >
                                {useCustom ? customText : DEFAULT_DISCLAIMER}
                            </PreviewBox>
                        </Grid>
                        <Grid item xs={12}>
                            <AnchorTextButton
                                onClick={() => {
                                    if (!useCustom) {
                                        setEditModalOpen(true)
                                    } else {
                                        setUseCustom(false)

                                    }
                                }}
                            >
                                {useCustom ?
                                    'Use Peachjar\'s Version' :
                                    'Use Custom Version'
                                }
                            </AnchorTextButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <FlyerDisclaimerModal
                initial={customText}
                isOpen={isEditModalOpen}
                onSubmit={(text) => {
                    setUseCustom(true)
                    setCustomText(text)
                    setEditModalOpen(false)
                }}
                cancel={() => setEditModalOpen(false)}
            />
        </Grid>
    )
}

export default FlyerDisclaimerInput

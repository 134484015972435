/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux'
import { RootState } from './index'
import { District } from '../api/District'
import { AcademicGroupIdentifiers } from '../api/commands/launchGroup'
import { Result as SchoolsForNcesDistrict } from '../api/queries/getSchoolsByDistrictNcesId'

import AsyncReqState from '@peachjar/ui/dist/api/AsyncReqState'
import { MaybeAddress } from '../api/queries/resolveAddresses'

export const createDistrictParams = () => {
    return useSelector<RootState, Partial<District> | null>(state => state.districts.createParams)
}

export const createDistrictReq = () => {
    return useSelector<RootState, AsyncReqState<District>>(state => state.districts.createDistrict)
}

export const getDistrictReq = () => {
    return useSelector<RootState, AsyncReqState<District>>(state => state.districts.district)
}

export const updateDistrictInfoReq = () => {
    return useSelector<RootState, AsyncReqState<District>>(state => state.districts.updateDistrictInfo)
}

export const launchDistrictReq = () => {
    return useSelector<RootState, AsyncReqState<AcademicGroupIdentifiers[]>>(state => state.districts.launchDistrict)
}

export const schoolsByDistrictNcesId = () => {
    return useSelector<RootState, AsyncReqState<SchoolsForNcesDistrict>>(state => state.districts.schoolsByDistrictNcesId)
}

export const resolvedDistrictAddress = () => {
    return useSelector<RootState, AsyncReqState<MaybeAddress>>(state => state.districts.resolveAddress)
}

import React, { FunctionComponent } from 'react'
import DefaultPage from '@peachjar/ui/dist/lib/components/PageLayouts/DefaultPage'

const SearchPage: FunctionComponent<{}> = ({}) => {
    return (
        <DefaultPage title='Search' subText='Find Users, Sites, Districts'>
            <div>
                This will only be viewable to Internal Users.
            </div>
        </DefaultPage>
    )
}

export default SearchPage

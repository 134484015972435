/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { css } from 'emotion'
import idx from 'idx'
import UserAndSiteRoles from '../../../api/UserAndSiteRoles'
import UserListAndActions from './UserListAndActions'
import Tooltip from '@peachjar/ui/dist/lib/components/Tooltip'
import QueryStringDrawerPopper from '@peachjar/ui/dist/lib/components/AppDrawer/QueryStringDrawerPopper'
import QueryStringNotification from '@peachjar/ui/dist/lib/components/Notifications/QueryStringNotification'
import { usePanic } from '@peachjar/ui/dist/lib/hooks/usePanic'
import Pagination from '@peachjar/components/build/components/Pagination/Pagination'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import ErrorNotification from '@peachjar/ui/dist/lib/components/Errors/ErrorNotification'

import { useDispatch } from 'react-redux'
import { DrawerProps } from './AddEditUserDrawer'
import { Site, SiteInfo, toSiteInfo } from '../../../api/SiteInfo'
import { School, TransitionalGroupTypes } from '../../../api/School';
import { District } from '../../../api/District'
import { has, uniqBy, get } from 'lodash'
import { ADD_VOLUNTEER_DRAWER, drawers, mapToDrawerId } from './Drawers'
import { clearReq, removeUser } from '../../../redux/usersAndRoles'
import {
  getStaffFilters,
  getVolunteerFilters,
  getStaffUsersAndRolesReq,
  getVolunteerUsersAndRolesReq,
  removeUserReq
} from '../../../redux/usersAndRolesSelectors'
import { Statuses } from '@peachjar/ui/dist/api/AsyncReqState'
import {
  Section,
  Sections,
} from '@peachjar/ui/dist/lib/components/PageLayouts/Sections'
import { Match, SwitchError } from '@peachjar/ui/dist/lib/components/Errors'
import { Errors } from './constants'
import validateApproverChanges, {
  ValidationError as ApprovalValidationError,
} from '../../District/utils/validateApproverChanges'
import { ApprovalSettings, ApprovalErrors } from '../../../api/ApprovalSettings'
import { updateHierarchy } from '../../../redux/approvalSettings'
import ApproverRemovalModal from './ApproverRemovalModal'
import useAppDrawer from '@peachjar/ui/dist/lib/hooks/useAppDrawer'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { getStaffUsersAndRolesPaginated, getVolunteerUsersAndRolesPaginated } from '../../../redux/usersAndRoles'
import Routes from '../../../routes'
import { isParent, SCHOOL_STAFF_REGEX } from '../../../utils'

const VOLUNTEER_TOOLTIP =
  'Volunteers include members such as PTA member, room parent, and event helper.'
const NOTIFICATION_KEY_REMOVE = 'removeSuccess'
const INDIVIDUAL_REMOVED = 'The individual is removed.'

type Props = {
  primary: District | School
  sites?: Site[]
  districtId: number
  districtName: string
  subheading: string
  staffNote?: string | ReactElement
  approvalSettings: ApprovalSettings
  isRefreshing?: boolean
  onNeedsRefreshing?: () => void
  academicGroupType?: 'district' | 'school'
  academicGroupId?: number  
}

type ApproverErrorModalConfig = {
  isOpen: boolean
  message: string
  userContext: UserAndSiteRoles | null
  isDeleting: boolean
}

const approvalErrorModalInitialState = {
  isOpen: false,
  message: '',
  userContext: null,
  isDeleting: true
}

const UsersPageBody: FunctionComponent<Props> = ({
  primary,
  sites = [],
  districtId,
  districtName,
  approvalSettings,
  subheading,
  staffNote,
  isRefreshing = false,
  onNeedsRefreshing = () => true,
  academicGroupType,
  academicGroupId,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [staffUsers, setStaffUsers] = useState<UserAndSiteRoles[]>([])
  const [volunteerUsers, setVolunteerUsers] = useState<UserAndSiteRoles[]>([])
  const removeReq = removeUserReq()
  const staffUsersReq = getStaffUsersAndRolesReq()
  const staffFilters = getStaffFilters()
  const volunteerUsersReq = getVolunteerUsersAndRolesReq()
  const volunteerFilters = getVolunteerFilters()

  const isDistrict = has(primary, 'districtId')
  const [removingUserId, setRemovingUserId] = useState<string | null>(null)
  const [
    approverErrorModal,
    setApproverErrorModal,
  ] = useState<ApproverErrorModalConfig>(approvalErrorModalInitialState)
  const removingDisabled = removeReq.status === Statuses.loading || isRefreshing
  const { notifySuccess, notifyError, close } = useGlobalNotifications()
  const LIMIT = 50;
  const ITEMS_PER_PAGE = 50;

  const isStaffRefreshing = staffUsersReq.isLoading()
  const staffOffset = idx(staffUsersReq, _ => _.model.offset) || 0 
  const staffTotal = idx(staffUsersReq, _ => _.model.total) || 0
  const staffPageCount = Math.ceil(staffTotal / LIMIT)
  const staffPageNumber = staffOffset / LIMIT + 1

  const isVolunteerRefreshing = volunteerUsersReq.isLoading()
  const volunteerOffset = idx(volunteerUsersReq, _ => _.model.offset) || 0 
  const volunteerTotal = idx(volunteerUsersReq, _ => _.model.total) || 0
  const volunteerPageCount = Math.ceil(volunteerTotal / LIMIT)
  const volunteerPageNumber = volunteerOffset / LIMIT + 1

  const onRefreshUsers = (isVolunteer = false) => {
    if ( isVolunteer ) {
      refreshVolunteer(volunteerOffset)
    } else {
      refreshStaff(staffOffset)
    }
    onNeedsRefreshing()
  }

  const refreshStaff = (offset = 0) => {
    dispatch(
      getStaffUsersAndRolesPaginated({
        academicGroupType: academicGroupType!,
        academicGroupId: academicGroupId!,
        includeChildSites: true,
        memberType: 'staff',
        limit: LIMIT,
        offset: offset,
        role: staffFilters.role,
        siteId: staffFilters.site === -1 ? undefined : staffFilters.site
      })
    )
  }
  const refreshVolunteer = (offset = 0) => {
    dispatch(
      getVolunteerUsersAndRolesPaginated({
        academicGroupType: academicGroupType!,
        academicGroupId: academicGroupId!,
        includeChildSites: true,
        memberType: 'volunteer',
        limit: LIMIT,
        offset: offset,
        role: volunteerFilters.role,
        siteId: volunteerFilters.site === -1 ? undefined : volunteerFilters.site
      })
    )
  }

  const {
    toggleDrawer,
    registerComponents,
    setDrawerContext,
    purgeComponentRegistry,
  } = useAppDrawer()

  useEffect(() => {
    registerComponents(drawers)
    return () => purgeComponentRegistry(drawers)
  }, [
    toggleDrawer,
    setDrawerContext,
    registerComponents,
    purgeComponentRegistry,
  ])

  useEffect(() => {
    if (removeReq.status === Statuses.loaded) {
      notifySuccess({
        key: NOTIFICATION_KEY_REMOVE,
        message: INDIVIDUAL_REMOVED,
      })
      setRemovingUserId(null)
      refreshStaff(staffOffset)
      refreshVolunteer(volunteerOffset)
      onNeedsRefreshing()
    }
    if (removeReq.status === Statuses.failed) {
      setRemovingUserId(null)
    }
  }, [removeReq])

  useEffect(() => {
      refreshStaff()
  }, [staffFilters])

  useEffect(() => {
    refreshVolunteer()
  }, [volunteerFilters])

  useEffect(() => {
    if (staffUsersReq.status === Statuses.loaded) {
      setStaffUsers(staffUsersReq.model!.items)
    }
  }, [staffUsersReq.model])

  useEffect(() => {
    if (volunteerUsersReq.status === Statuses.loaded) {
      setVolunteerUsers(volunteerUsersReq.model!.items)
    }
  }, [volunteerUsersReq.model])

  useEffect(() => {
    dispatch(updateHierarchy({
      hierarchyType: academicGroupType!,
      hierarchyId: academicGroupId!
    }))
  }, [])
  
  const filterNonStaffGroup = ( site: Site ) => {
    const school = (site as School)
    return isParent(school)
  }

   const allSites: SiteInfo[] = useMemo(() => {
    return uniqBy(
      [primary].concat(sites.filter(filterNonStaffGroup))
        .filter((site) => site.active)
        .map(toSiteInfo),
      (s: SiteInfo) => s.type + ':' + s.id
    )
  }, [sites, primary, isDistrict])

  const handleAddEditUserClick = (
    isVolunteer: boolean,
    user: UserAndSiteRoles | null
  ) => {
    close()
    dispatch(clearReq())
    setDrawerContext(mapToDrawerId(isVolunteer, user))
    toggleDrawer({
      isVolunteer,
      isDistrict,
      user,
      handleRemove: handleRemoveUserClick,
      handleSiteChange,
      sites: allSites,
      onRemoveComplete: () => onRefreshUsers(isVolunteer),
      onAddComplete: () => onRefreshUsers(isVolunteer),
      onEditComplete: () => onRefreshUsers(isVolunteer),
    } as DrawerProps)
  }

  const handleBulkAddUserClick = () => {
    if (isDistrict) {
      history.push(Routes.districts.staffUsers.from(districtId))
    } else {
      const school = primary as School
      history.push(Routes.districts.schools.staffUsers.from(districtId, school.schoolId))
    }
  }
  const handleApprovalModalCancel = () => {
    close()
    setApproverErrorModal(approvalErrorModalInitialState)
  }

  const handleApprovalError = (
    error: ApprovalValidationError,
    user: UserAndSiteRoles,
    isDeleting: boolean = true
  ) => {
    const { type, message } = error
    // show approverRemovalModal DistrictAndSchoolLastApprover type
    if (type === ApprovalErrors.DistrictAndSchoolLastSchoolApprover) {
      setApproverErrorModal({
        isOpen: true,
        userContext: user,
        message: message,
        isDeleting
      })
      return
    }

    notifyError({ message })
  }

  const handleRemoveUser = (user?: UserAndSiteRoles) => {
    if(approverErrorModal.isDeleting){
      const userCtx = user || approverErrorModal.userContext!
      setRemovingUserId(userCtx.userId)
      dispatch(removeUser(userCtx))
    }
    setApproverErrorModal(approvalErrorModalInitialState)
  }

  const handleSiteChange = (
    user: UserAndSiteRoles | null
  ): { error: boolean, type?: string } => {
    if (!user) {
      return { error: false }
    }

    const approvalError = validateApproverChanges({
      user,
      settings: approvalSettings,
    })

    if (approvalError.type !== ApprovalErrors.None) {
      handleApprovalError(approvalError, user, false)
      return { error: true, type: approvalError.type }
    }

    return { error: false }
  }

  const handleRemoveUserClick = (user: UserAndSiteRoles) => {
    close()
    const approvalError = validateApproverChanges({
      user,
      settings: approvalSettings,
    })

    if (approvalError.type !== ApprovalErrors.None) {
      handleApprovalError(approvalError, user)
      return
    }

    handleRemoveUser(user)
  }

  const handleStaffPageChange = (newPageNumber) => {
    const newOffset = (newPageNumber - 1) * ITEMS_PER_PAGE
    refreshStaff(newOffset)
  } 
  const handleVolunteerPageChange = (newPageNumber) => {
    const newOffset = (newPageNumber - 1) * ITEMS_PER_PAGE
    refreshVolunteer(newOffset)
  } 

  return (
    <>
      <Sections>
        <Section headline={subheading} subtext={staffNote}>
          <QueryStringDrawerPopper
            transform={(user, drawer) => {
              const isVolunteer = drawer === ADD_VOLUNTEER_DRAWER
              return {
                state: {
                  isVolunteer, 
                  isDistrict,
                  isEdit: false,
                  user,
                  sites: allSites,
                  onRemoveComplete: () => onRefreshUsers(isVolunteer),
                  onAddComplete: () => onRefreshUsers(isVolunteer),
                  onEditComplete: () => onRefreshUsers(isVolunteer),
                },
                shouldOpen: true,
              }
            }}
          />
          <QueryStringNotification />
          <SwitchError error={removeReq.error}>
            <Match code={Errors.Internal.code}>
              <ErrorNotification
                id="internal"
                error={removeReq.error}
                text={Errors.Internal.message}
              />
            </Match>
            <Match code={Errors.RemoveLastAdmin.code}>
              <ErrorNotification
                id="lastAdmin"
                error={removeReq.error}
                text={
                  isDistrict
                    ? Errors.RemoveLastAdmin.messages.district
                    : Errors.RemoveLastAdmin.messages.indySchool
                }
              />
            </Match>
          </SwitchError>
          <Snackbar open={isStaffRefreshing || isVolunteerRefreshing} autoHideDuration={6000}>
            <Alert severity="info">Refreshing User Lists...</Alert>
          </Snackbar>
          <UserListAndActions
            filters={ getStaffFilters() }
            userAndSiteRoles={ staffUsers }
            sites={allSites}
            onAddClick={() => handleAddEditUserClick(false, null)}
            onBulkAddClick={() => handleBulkAddUserClick()}
            onEditClick={(user) => handleAddEditUserClick(false, user)}
            onRemoveClick={handleRemoveUserClick}
            removingDisabled={removingDisabled}
            removingUserId={removingUserId}
          />
          { staffPageCount > 1 && <Grid item xs={12}>
              <div className={`${cn.pagination}`}>
                <Pagination 
                  activePageNumber={staffPageNumber}
                  pageCount={staffPageCount}
                  testId="staff-users-pagination"
                  onPageChange={handleStaffPageChange}
                />
              </div>
          </Grid>}
        </Section>
        <Section
          headline={
            <Grid container justify="flex-start" alignItems="center">
              <Grid item>District & School Volunteers</Grid>
              {volunteerUsers.length > 0 && (
                <Grid item>
                  <Tooltip title={VOLUNTEER_TOOLTIP} placement="right" />
                </Grid>
              )}
            </Grid>
          }
          subtext="Volunteers can be assigned an uploader role at district and school sites. Volunteers can only be managed individually."
        >
          <UserListAndActions
            filters={getVolunteerFilters()}
            userAndSiteRoles={ volunteerUsers }
            sites={allSites}
            isVolunteerList={true}
            onAddClick={() => handleAddEditUserClick(true, null)}
            onBulkAddClick={() => handleBulkAddUserClick()}
            onEditClick={(user) => handleAddEditUserClick(true, user)}
            onRemoveClick={handleRemoveUserClick}
            removingDisabled={removingDisabled}
            removingUserId={removingUserId}
          />
          { volunteerPageCount > 1 && <Grid item xs={12}>
              <div className={`${cn.pagination}`}>
                <Pagination 
                  activePageNumber={volunteerPageNumber}
                  pageCount={volunteerPageCount}
                  testId="staff-users-pagination"
                  onPageChange={handleVolunteerPageChange}
                />
              </div>
          </Grid>}
        </Section>
      </Sections>
      <ApproverRemovalModal
        isOpen={approverErrorModal.isOpen}
        user={approverErrorModal.userContext!}
        onSubmit={handleRemoveUser}
        submitText={approverErrorModal.isDeleting ? 'Remove' : 'Confirm'}
        onCancel={handleApprovalModalCancel}
      >
        {approverErrorModal.message}
      </ApproverRemovalModal>
    </>
  )
}

const cn = {
  pagination: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  `
}
export default UsersPageBody
